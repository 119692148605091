import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PortfolioItem } from 'src/app/models/portfolio-item';
import { PortfolioService } from 'src/app/services/portfolio.service';

@Component({
  selector: 'app-provider-menu',
  templateUrl: './provider-menu.component.html',
  styleUrls: ['./provider-menu.component.scss']
})
export class ProviderMenuComponent implements OnInit {
  portfolios: PortfolioItem[] = [];
  selectedPortfolio: PortfolioItem | null = null;

  constructor(
    private portfolioService: PortfolioService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.portfolioService.portfolios$.subscribe(p => {
      if (p) {
        this.portfolios = p.portfolios;
      }
      else this.portfolios = [];
    });

    this.portfolioService.portfolio$
      .subscribe((data: any) => {
        if (data) {
          this.selectedPortfolio = data as PortfolioItem;
        }
      });
  }

  setPortfolio(p: PortfolioItem) {
    this.portfolioService.porfolioChanged(p.customerGuid);
    this.router.navigate([`/home/${p.customerGuid}`]);
  }
}
