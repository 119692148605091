import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-yes-no-popup',
  templateUrl: './yes-no-popup.component.html',
  styleUrls: ['./yes-no-popup.component.scss']
})
export class YesNoPopupComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<YesNoPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: YesNoDialogData,
  ) {}

  onClick(msg: string): void {
    this.dialogRef.close(msg);
  }

  ngOnInit(): void {
  }

}

export interface YesNoDialogData {
  title: string;
  msg: string;
  yesText: string;
  noText: string;
  subTitle: string;
}
