import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationService, ConfirmEventType, MenuItem } from 'primeng/api';
import { forkJoin } from 'rxjs';
import { ManualPaymentMethod, PaperCheckFundingInfo } from 'src/app/models/csr/agent-tools';
import { CustomerModel } from 'src/app/models/customer-model';
import { ApiService } from 'src/app/services/api.service';
import { CustomerService } from 'src/app/services/customer.service';
import { GuidService } from 'src/app/services/guid.service';
import { HelperService } from 'src/app/services/helper.service';
import { ToastService } from 'src/app/services/toast.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-manual-payments-page',
  templateUrl: './manual-payments-page.component.html',
  styleUrls: ['./manual-payments-page.component.scss']
})
export class ManualPaymentsPageComponent implements OnInit {

  showPaymentModal: boolean = false;
  manualPaymentFormLoaded: boolean = false;
  showPaperCheckFunding: boolean = false;
  
  paperCheckFundAmount: number = 0;
  paperFundConfirmKey: string = 'paperCheckFundConfKey';

  bcItems: MenuItem[] = [];

  customer: CustomerModel | null = null;
  paperFundingInfo: PaperCheckFundingInfo = {} as PaperCheckFundingInfo;

  minDate: Date = new Date();
  maxDate: Date = new Date();

  manualPaymentForm: FormGroup = new FormGroup({});

  paymentOptions: ManualPaymentMethod[] = [];

  customerGuid: any;
  targetGuid: any;
  campaignGuid: any;


  private parser = new DOMParser();

  constructor(
    private activatedRoute: ActivatedRoute,
    private guidService: GuidService,
    private userService: UserService,
    private customerService: CustomerService,
    private confirmService: ConfirmationService,
    private helperService: HelperService,
    private toastService: ToastService,
    private fb: FormBuilder,
    private apiService: ApiService
  ) { }

  ngOnInit(): void {
    let today = this.maxDate
    let month = today.getMonth();
    let year = today.getFullYear();
    let prevMonth = (month <= 1) ? month + 10 : month - 2;
    let prevYear = (prevMonth >= 10) ? year - 1 : year;
    this.minDate.setMonth(prevMonth);
    this.minDate.setFullYear(prevYear);

    this.activatedRoute.paramMap.subscribe(async paramMap => {
      let cGuid = paramMap.get('customerGuid')?.toString() ?? '';
      if (this.guidService.isValidGuid(cGuid)) {
        this.customerGuid = cGuid;
        this.customerService.initCustomer(this.customerGuid);
        await this.userService.subscribeAccess();
        let data = this.activatedRoute.snapshot.data;
        if (data && data['pageId']) {
          this.userService.checkPageAccess(data['pageId']);
        }
      }
      let campGuid = paramMap.get('campaignGuid')?.toString() ?? '';
      let tGuid = paramMap.get('targetGuid')?.toString() ?? '';
      if (this.guidService.isValidGuid(campGuid)) this.campaignGuid = campGuid;
      if (this.guidService.isValidGuid(tGuid)) this.targetGuid = tGuid;
      this.customerService.customer$.subscribe(cust => {
        this.customer = cust;
      })
      this.bcItems = [
        { label: 'Agent Tools', routerLink: [`/agent-tools/${this.customerGuid}/${this.campaignGuid}/${this.targetGuid}`] },
        { label: "Manual Payments" }
      ];

      this.getData();
    });
  }

  getData() {
    const fSub = forkJoin({
      manPay: this.apiService.get(`agenttools/manual-payment/options/${this.customerGuid}`),
      paperFund: this.apiService.get(`agenttools/manual-payment/paper-check-info/${this.customerGuid}/${this.campaignGuid}/${this.targetGuid}`)
    })
      .subscribe({
        next: (data: any) => {
          this.paymentOptions = data.manPay;
          this.paperFundingInfo = data.paperFund;
          this.initPage();
        },
        error: (err: any) => {
          this.toastService.addError("Unable to get initial Collections data. See log for details.");
          console.error(err);
        },
        complete: () => { fSub.unsubscribe(); }
      });
  }

  initPage() {
    let today = new Date();
    if (this.paperFundingInfo.checkDates && this.paperFundingInfo.checkDates.paymentMinDate)
      this.minDate = new Date(this.paperFundingInfo.checkDates.paymentMinDate);
    else
      this.minDate.setDate(today.getDate() - 60);

    if (this.paperFundingInfo.checkDates && this.paperFundingInfo.checkDates.paymentMaxDate)
      this.maxDate = new Date(this.paperFundingInfo.checkDates.paymentMaxDate);
    else
      this.maxDate.setDate(today.getDate());

    if (this.paperFundingInfo.checkFunding && this.paperFundingInfo.checkFunding.paperCheckFundingAvailable != null) {
      this.showPaperCheckFunding = this.helperService.getBoolean(this.paperFundingInfo.checkFunding.paperCheckFundingAvailable);
            
      if (this.showPaperCheckFunding) {
        let amt = this.paperFundingInfo.checkFunding.paperCheckFundingAmount;
        if (amt) {
          this.paperCheckFundAmount = +amt;
        }
      }
    }

    this.manualPaymentForm = this.fb.group({
      paymentAmount: new FormControl<number | null>(null, { nonNullable: true, validators: [Validators.required] }),
      paymentType: new FormControl<number | null>(null, { nonNullable: true, validators: [Validators.required] }),
      refCode: new FormControl<string | null>(null, { nonNullable: true, validators: [Validators.required] }),
      paymentDate: new FormControl<Date | null>(null, { nonNullable: true, validators: [Validators.required] })
    });
    this.manualPaymentFormLoaded = true;
  }

  confirmManualPayment(): void {
    if (!this.manualPaymentForm.valid) {
      this.toastService.addError('Unable to process payment. See log for details.');
    } else {
      this.showPaymentModal = true;
    }
  }

  getPaymentMethodDescription() {
    let desc: string = 'Unknown';
    if (this.manualPaymentForm.value.paymentType) {
      let payType = this.paymentOptions.find(p => p.paymentMethodNo == this.manualPaymentForm.value.paymentType);
      if (payType) desc = payType.paymentMethod;
    }
    return desc;
  }

  abortManPayment() {
    this.toastService.addWarn('Manual Payment was not processed.');
  }

  postManualPayment(): void {
    let body = {
      campaignGuid: this.campaignGuid,
      customerGuid: this.customerGuid,
      targetGuid: this.targetGuid,
      amount: this.manualPaymentForm.value.paymentAmount,
      paymentMethod: this.manualPaymentForm.value.paymentType,
      referenceCode: this.manualPaymentForm.value.refCode,
      date: this.manualPaymentForm.value.paymentDate,
      url: window.location.href
    }

    let postSub = this.apiService.postStringResponse('AgentTools/manual-payment', body)
      .subscribe({
        next: () => {
          this.toastService.addSuccess('Manual payment created.');
        },
        error: (err: any) => {
          this.toastService.addError('Unable to post payment.');
          console.error(err);
        },
        complete: () => { postSub.unsubscribe(); }
      });
  }

  confirmPaperFunding() {
    let msg = `
    <div>
      <p>Please confirm the Paper Check Funding for ${this.paperFundingInfo.checkFunding.fullName} in the amount of $${this.paperCheckFundAmount}.</p>
    </div>`;
    let doc = this.parser.parseFromString(msg, 'text/html');
    const serializer = new XMLSerializer();
    let confMsg = serializer.serializeToString(doc);
    this.confirmService.confirm({
      key: this.paperFundConfirmKey,
      message: confMsg,
      header: 'Initiate Paper Check Funding',
      rejectLabel: 'Back',
      rejectButtonStyleClass: 'p-button-outlined',
      acceptLabel: 'Confirm',
      accept: () => {
        this.initiatePaperFunding();
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.toastService.add({ severity: 'warn', summary: 'Declined', detail: 'Payment(s) will not be deferred.' });
            break;
          case ConfirmEventType.CANCEL:
            this.toastService.add({ severity: 'warn', summary: 'Cancelled', detail: 'Operation cancelled.' });
            break;
        }
      }
    });
  }

  initiatePaperFunding() { 
    let body = {
      campaignGuid: this.campaignGuid,
      customerGuid: this.customerGuid,
      targetGuid: this.targetGuid,
      paperCheckCampaignGuid: this.paperFundingInfo.checkFunding.paperCheckCampaignGuid, 
      paperCheckTargetGuid: this.paperFundingInfo.checkFunding.paperCheckTargetGuid
    };

    let postSub = this.apiService.postStringResponse('agenttools/paper-funding', body)
      .subscribe({
        next: () => {
          this.toastService.addSuccess('Manual payment created.');
        },
        error: (err: any) => {
          this.toastService.addError('Unable to post payment.');
          console.error(err);
        },
        complete: () => { postSub.unsubscribe(); }
      });

  }




}
