<div *ngIf="!loaded">
    <div class="cds-card">
        <div class="pds-card-header-highlight flex">
            <span class="pr-3 py-3 pl-4">Verification Checks</span>
        </div>
        <div class="h-10rem text-center py-4">
            <i class="pi pi-spinner pi-spin cds-text-color" style="font-size: 8rem;"></i>
        </div>
    </div>
</div>

<div *ngIf="loaded">

    <div *ngIf="verificationHistory">
        <div class="cds-card" *ngFor="let group of reasonGroups; index as i;">
            <div class="pds-card-header-highlight flex">
                <span class="pr-3 py-3 pl-4">Verification Checks</span>
            </div>
            <div class="p-2 pb-4 flex flex-wrap">
                <div class="w-12 font-medium text-xl pt-2">
                    {{group[0].customDescriptionTitle}}
                </div>
                <div class="w-12 pt-3">
                    {{group[0].customDescription}}
                </div>
            </div>
            <div class="p-2 flex flex-wrap" *ngIf="showGroupVerifySteps(group)">
                <div class="w-12 font-medium text-xl pb-4">
                    Verify Steps
                </div>
                <div *ngFor="let verDoc of getGroupDocs(group); index as j" class="w-12 py-2 flex flex-wrap">
                    <div class="w-12 font-medium">
                        {{(j +1)}}. {{getGroupDocDescription(verDoc)}}
                    </div>
                    <div class="w-12 flex pl-2 pt-2">
                        <span class="px-2" *ngIf="showGroupDocButton(verDoc, 'pass')">
                            <p3solved-button id="btndoc{{j}}Pass" label="Pass"
                                [class]="getGroupDocButtonCSS(verDoc, 'pass')" [disabled]="true"></p3solved-button>
                        </span>
                        <span class="px-2" *ngIf="showGroupDocButton(verDoc, 'neutral')">
                            <p3solved-button id="btndoc{{j}}Neutral" label="Waive"
                                [class]="getGroupDocButtonCSS(verDoc, 'neutral')" [disabled]="true"></p3solved-button>
                        </span>
                        <span class="px-2" *ngIf="showGroupDocButton(verDoc, 'fail')">
                            <p3solved-button id="btndoc{{j}}Fail" label="Fail"
                                [class]="getGroupDocButtonCSS(verDoc, 'fail')" [disabled]="true"></p3solved-button>
                        </span>
                    </div>
                </div>
                <div class="w-12 flex flex-wrap" *ngIf="getGroupObjectCount(group) > 0">
                    <div class="w-12 font-medium text-xl py-4">
                        Final Decision
                    </div>
                    <div class="w-12 text-3xl font-bold pb-4" [style.color]="getGroupFinalDecisionCSS(group)">
                        {{getGroupFinalStatus(group)}}
                    </div>
                </div>
            </div>
            <div class="w-12 flex flex-wrap" *ngIf="showGroupRecommendButtons(group)">
                <div class="w-12 flex pl-2 py-2" *ngIf="group[group.length-1].decisionType.toUpperCase() != 'FACTA'">
                    <span class="px-2">
                        <p3solved-button *ngIf="getGroupFinalStatus(group) == 'APPROVE'" id="ButtonOKApprove"
                            label="Confirm" [disabled]="true"></p3solved-button>
                    </span>
                    <span class="px-2">
                        <p3solved-button *ngIf="getGroupFinalStatus(group) == 'DECLINE'" id="ButtonOKDecline"
                            label="Confirm" class="danger" [disabled]="true"></p3solved-button>
                    </span>
                    <span class="px-2">
                        <p3solved-button *ngIf="getGroupFinalStatus(group) == 'DECLINE'" id="ButtonForceAPPROVE"
                            label="Override" class="success" [disabled]="true"></p3solved-button>
                    </span>
                    <span class="px-2">
                        <p3solved-button *ngIf="getGroupFinalStatus(group) == 'APPROVE'" id="ButtonForceRFAI"
                            label="Override" class="danger" [disabled]="true"></p3solved-button>
                    </span>
                </div>

                <div class="w-12 flex pl-2 py-2" *ngIf="group[group.length-1].decisionType.toUpperCase() == 'FACTA' && getGroupObjectCount(group) > 0">
                    <span class="px-2">
                        <p3solved-button *ngIf="getGroupFinalStatus(group) == 'PASS'" id="btnFactPass" label="Pass"
                            [disabled]="true"></p3solved-button>
                    </span>
                    <span class="px-2">
                        <p3solved-button *ngIf="getGroupFinalStatus(group) == 'NEUTRAL'" id="btnFACTANeutral"
                            label="Verify" class="secondary" [disabled]="verificationDisabled"
                            (buttonClick)="cvConfirmChangeStatus(12)"></p3solved-button>
                    </span>
                    <span class="px-2">
                        <p3solved-button *ngIf="getGroupFinalStatus(group) == 'FAIL'" id="btnFACTAFail" label="Fail"
                            class="danger" [disabled]="true"></p3solved-button>
                    </span>
                </div>
                <div class="w-12 flex pl-2 py-2" *ngIf="group[group.length-1].decisionType.toUpperCase() == 'FACTA' && getGroupObjectCount(group) == 0">
                    <span class="px-2">
                        <p3solved-button id="btnFactPass" label="Pass"
                            [disabled]="true"
                            (buttonClick)="cvConfirmChangeStatus(11)"></p3solved-button>
                    </span>
                    <span class="px-2">
                        <p3solved-button id="btnFACTANeutral"
                            label="Verify" class="secondary" [disabled]="true"
                            (buttonClick)="cvConfirmChangeStatus(12)"></p3solved-button>
                    </span>
                    <span class="px-2">
                        <p3solved-button id="btnFACTAFail" label="Fail"
                            class="danger" [disabled]="true"></p3solved-button>
                    </span>
                </div>
            </div>
        </div>
    </div>

    <div class="cds-card" *ngIf="inVerificationMode && verificationData.decisions.length > 0">
        <div class="pds-card-header-highlight flex">
            <span class="pr-3 py-3 pl-4">Verification Checks</span>
        </div>
        <div class="p-2 pb-4 flex flex-wrap">
            <div class="w-12 font-medium text-xl pt-2">
                {{verificationData.decisions[0].customDescriptionTitle}}
            </div>
            <div class="w-12 pt-3">
                {{verificationData.decisions[0].customDescription}}
            </div>
        </div>
        <div class="p-2 flex flex-wrap" *ngIf="haveAtLeastOneVerifOrDoc">
            <div class="w-12 font-medium text-xl pb-4">
                Verify Steps
            </div>

            <div *ngFor="let verification of verificationData.verificaitons; index as i;"
                class="w-12 py-2 flex flex-wrap">
                <div class="w-12 font-medium">
                    {{(i +1)}}. {{verification.verificationDescription}}
                </div>
                <div class="w-12 flex pl-2 pt-2" *ngIf="hasFeature('CSR_CUSTOMER_VERIFICATION_CHECKS')">
                    <span class="px-2">
                        <p3solved-button *ngIf="verification.passShow" id="btnPassVer{{i}}" label="Pass"
                            [class]="verification.status == 'pass' ? '' : 'secondary'"
                            [disabled]="verificationModeExpired"
                            (buttonClick)="updateVerOrDoc(verification.verificationID, 'verification', 'pass')"></p3solved-button>
                    </span>
                    <span class="px-2">
                        <p3solved-button *ngIf="verification.neutralShow" id="btnNeuVer{{i}}" label="Waive"
                            [class]="verification.status == 'neutral' ? '' : 'secondary'"
                            [disabled]="verificationModeExpired"
                            (buttonClick)="updateVerOrDoc(verification.verificationID, 'verification', 'neutral')"></p3solved-button>
                    </span>
                    <span class="px-2">
                        <p3solved-button *ngIf="verification.failShow" id="btnFailVer{{i}}" label="Fail"
                            [class]="verification.status == 'fail' ? 'danger' : 'secondary'"
                            [disabled]="verificationModeExpired"
                            (buttonClick)="updateVerOrDoc(verification.verificationID, 'verification', 'fail')"></p3solved-button>
                    </span>
                </div>
            </div>

            <div *ngFor="let document of verificationData.documents; index as j;" class="w-12 py-2 flex flex-wrap">
                <div class="w-12 font-medium">
                    {{(verificationData.verificaitons.length + j +1)}}. {{document.document}} -
                    {{document.documentDescription}}
                </div>
                <div class="w-12 flex pl-2 pt-2" *ngIf="hasFeature('CSR_CUSTOMER_VERIFICATION_CHECKS')">
                    <span class="px-2">
                        <p3solved-button *ngIf="document.passShow" id="btnPassDoc{{j}}" label="Pass"
                            [class]="document.status == 'pass' ? '' : 'secondary'" [disabled]="verificationModeExpired"
                            (buttonClick)="updateVerOrDoc(document.documentID, 'document', 'pass')"></p3solved-button>
                    </span>
                    <span class="px-2">
                        <p3solved-button *ngIf="document.neutralShow" id="btnNDoc{{j}}" label="Waive"
                            [class]="document.status == 'neutral' ? '' : 'secondary'"
                            [disabled]="verificationModeExpired"
                            (buttonClick)="updateVerOrDoc(document.documentID, 'document', 'neutral')"></p3solved-button>
                    </span>
                    <span class="px-2">
                        <p3solved-button *ngIf="document.failShow" id="btnFailDoc{{j}}" label="Fail"
                            [class]="document.status == 'fail' ? 'danger' : 'secondary'"
                            [disabled]="verificationModeExpired"
                            (buttonClick)="updateVerOrDoc(document.documentID, 'document', 'fail')"></p3solved-button>
                    </span>
                </div>
            </div>

            <div class="w-12 font-medium text-xl py-4">
                Final Decision
            </div>

            <div class="w-12 text-3xl font-bold pb-4" [style.color]="getVerDocFinalDecisionCSS()">
                {{finalStatus}}
            </div>

            <div class="w-12 flex pl-2 pt-2"
                *ngIf="hasFeature('CSR_CUSTOMER_VERIFICATION_CHECKS') && verificationData.decisions[0].decisionType.toUpperCase() != 'FACTA'">
                <span class="px-2">
                    <p3solved-button *ngIf="finalStatus == 'APPROVE'" id="ButtonOKApprove" label="Confirm"
                        [disabled]="verificationModeExpired" (buttonClick)="cvConfirmChangeStatus(1)"></p3solved-button>
                </span>
                <span class="px-2">
                    <p3solved-button *ngIf="finalStatus == 'DECLINE'" id="ButtonOKDecline" label="Confirm"
                        class="danger" [disabled]="verificationModeExpired"
                        (buttonClick)="cvConfirmChangeStatus(0)"></p3solved-button>
                </span>
                <span class="px-2">
                    <p3solved-button *ngIf="finalStatus == 'DECLINE'" id="ButtonForceAPPROVE" label="Override"
                        class="success" [disabled]="verificationModeExpired"
                        (buttonClick)="cvConfirmChangeStatus(2)"></p3solved-button>
                </span>
                <span class="px-2">
                    <p3solved-button *ngIf="finalStatus == 'APPROVE'" id="ButtonForceRFAI" label="Override"
                        class="danger" [disabled]="verificationModeExpired"
                        (buttonClick)="cvConfirmChangeStatus(3)"></p3solved-button>
                </span>
            </div>

            <div class="w-12 flex pl-2 pt-2"
                *ngIf="hasFeature('CSR_CUSTOMER_VERIFICATION_CHECKS') && verificationData.decisions[0].decisionType.toUpperCase() == 'FACTA'">
                <span class="px-2">
                    <p3solved-button *ngIf="finalStatus == 'PASS'" id="btnFactPass" label="Pass"
                        [disabled]="verificationDisabled" (buttonClick)="cvConfirmChangeStatus(11)"></p3solved-button>
                </span>
                <span class="px-2">
                    <p3solved-button *ngIf="finalStatus == 'NEUTRAL'" id="btnFACTANeutral" label="Verify"
                        class="secondary" [disabled]="verificationDisabled"
                        (buttonClick)="cvConfirmChangeStatus(12)"></p3solved-button>
                </span>
                <span class="px-2">
                    <p3solved-button *ngIf="finalStatus == 'FAIL'" id="btnFACTAFail" label="Fail" class="danger"
                        [disabled]="verificationDisabled" (buttonClick)="cvConfirmChangeStatus(13)"></p3solved-button>
                </span>
            </div>

            <div class="w-12 flex pl-2 pt-2"
                *ngIf="hasFeature('CSR_CUSTOMER_VERIFICATION_CHECKS') && docsIncompleteShow">
                <span class="px-2">
                    <p3solved-button id="btnDocsIncomplete" label="Docs Incompleted" class="warn"
                        (buttonClick)="docsIncomplete()"></p3solved-button>
                </span>
            </div>
        </div>
    </div>
</div>

<p-confirmDialog key="{{customerVerificationsConfirmKey}}" [style]="{width: '25vw'}" [baseZIndex]="10000"
    rejectButtonStyleClass="p-button-danger">
    <ng-template pTemplate="header">
        <div class="flex flex-wrap text-center justify-content-center">
            <span class="w-12">
                <i class="pi pi-exclamation-circle text-8xl cds-warn-text"></i>
            </span>
            <span class="text-4xl text-center line-height-2 pt-3">Verification Status Change</span>
        </div>
    </ng-template>
</p-confirmDialog>