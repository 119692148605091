import { Inject, Injectable } from '@angular/core';
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, AccountInfo, RedirectRequest } from '@azure/msal-browser';
import { BehaviorSubject } from 'rxjs';
import { AppStorageKeys } from '../configs/app-storage-keys';
import { IUserGuids } from '../models/user-guids';
import { UserModel } from '../models/user-model';
import { ApiService } from './api.service';
import { SettingService } from './setting.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _userGuid = new BehaviorSubject<IUserGuids | undefined | null>(null);
  private _user = new BehaviorSubject<UserModel>(new UserModel);
  userGuid$ = this._userGuid.asObservable();
  user$ = this._user.asObservable();

  constructor(@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration, 
  private api: ApiService,
  private settings: SettingService,
  private msalService: MsalService) { }

  async logout() { 
    this._user.next(new UserModel);
    this._userGuid.next(null);
    localStorage.removeItem(AppStorageKeys.loggedInuserGuid);
    localStorage.removeItem(AppStorageKeys.selectedCustomerStorageKey);
    localStorage.removeItem(AppStorageKeys.authenticatedUserAccount);

    let params: any = {
      authority: await this.settings.signInPolicy(),
      postLogoutRedirectUri: window.location.origin
    };
    let homeAccountId = localStorage.getItem(AppStorageKeys.homeAccountId);
    if (homeAccountId) {
      params.account = this.msalService.instance.getAccountByHomeId(homeAccountId);
      localStorage.removeItem(AppStorageKeys.homeAccountId);
    }
    this.msalService.logoutRedirect(params);
  }

  login() {
    if (this.msalGuardConfig.authRequest){
      this.msalService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest);
    } else {
      this.msalService.loginRedirect();
    }
  }

  resetPassword(){
    this.msalService.loginRedirect(this.settings.getValue('B2C_ResetPasswordPolicy'));
  }

  authenticated(result: AuthenticationResult){
    var account = result.account;
    let claims: any = result.idTokenClaims;
    this.setUserGuids(account, this.getClaims(claims));
  }

  authenticatedAccount(account: AccountInfo){
    let claims: any = account.idTokenClaims;
    this.setUserGuids(account, this.getClaims(claims));
  }

  private getClaims(claims: any){
    let list: Claim[] = new Array<Claim>();
    Object.keys(claims).forEach(function (k, v) {
      let c = new Claim();
      c.claim = k;
      c.value = claims ? claims[k] : null;
      list.push(c);
    });
    return list;
  }

  private setUserGuids(account: AccountInfo | null, claims: Claim[]){
    if (account){
      var val = this._userGuid.getValue();
      if (!val || val.localAccountGuid !== account.localAccountId){
        var json = JSON.stringify(JSON.stringify(claims));
        var obj = {
          accountId: account.localAccountId,
          email: account.username,
          accountJson: json
        }
        var apiUrl = `app/loggedin-user`;
        this.api.post(apiUrl, JSON.stringify(obj)).subscribe((res) => {
          this._user.next(res);
          let userGuids: IUserGuids = {
            localAccountGuid: account.localAccountId,
            loggedUserGuid: res.userGUID || ''
          }
          localStorage.setItem(AppStorageKeys.loggedInuserGuid, JSON.stringify(userGuids));
          this._userGuid.next(userGuids);
        }, error => {
          console.error(error);
          let userGuids: IUserGuids = {
            localAccountGuid: account.localAccountId,
            loggedUserGuid: ''
          }
          this._userGuid.next(userGuids);
        })
      }
    }
  }
}

export class Claim {
  claim: string | undefined;
  value: string | undefined;
}
