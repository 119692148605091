import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-changes',
  templateUrl: './confirm-changes.component.html',
  styleUrls: ['./confirm-changes.component.scss']
})
export class ConfirmChangesComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ConfirmChangesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: fieldChanged[],
  ) { }

  ngOnInit(): void {
  }

  onClick(action: string){
    this.dialogRef.close(action);
  }

}

export interface fieldChanged {
  field: string,
  from: string,
  to: string
}
