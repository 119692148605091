import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbCarousel, NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest } from 'rxjs';
import { CustomerNotesComponent } from 'src/app/components/customer-notes/customer-notes.component';
import { ActivatedIds } from 'src/app/models/activated-ids';
import { ScheduledPaymentModel } from 'src/app/models/csr/scheduled-payment-model';
import { TargetTransactionHistory } from 'src/app/models/csr/target-transaction-history';
import { AccountServicingUpcomingPayment } from 'src/app/models/csr/upcoming-payment-model';
import { LookupModel } from 'src/app/models/lookup-model';
import { PaymentOptionModel } from 'src/app/models/payment-option';
import { TargetAccountSummary } from 'src/app/models/target-account-summary';
import { ApiService } from 'src/app/services/api.service';
import { CustomerService } from 'src/app/services/customer.service';
import { GuidService } from 'src/app/services/guid.service';
import { MainService } from 'src/app/services/main.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-account-servicing-page',
  templateUrl: './account-servicing-page.component.html',
  styleUrls: ['./account-servicing-page.component.scss'],
  providers: [NgbCarouselConfig]
})
export class AccountServicingPageComponent implements OnInit {
  loading = false;
  showNavigationArrows = false;
  showSchedule = true;
  showScheduleEdit = false;
  showPayments: boolean = false;
  showPayoffs: boolean = false;

  accountSummary: TargetAccountSummary = new TargetAccountSummary();
  scheduledPayments: ScheduledPaymentModel[] = [];
  upcomingPayments: AccountServicingUpcomingPayment[] = [];
  paymentOptions: PaymentOptionModel[] = [];
  transactionHistory: any[] = [];
  processors: LookupModel[] = [];
  ids: ActivatedIds = {};

  lateFees = '';
  serviceOption: string = '';

  @ViewChild('custNotes') custNotes: CustomerNotesComponent = {} as CustomerNotesComponent;
  @ViewChild(NgbCarousel) carousel: NgbCarousel | undefined;

  constructor(
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService,
    private guidService: GuidService,
    private userService: UserService,
    private main: MainService,
    private customerService: CustomerService, 
    config: NgbCarouselConfig
    ) {
    config.interval = 10000;
    config.keyboard = false;
    config.pauseOnHover = false;
    config.showNavigationArrows = false;
    config.showNavigationIndicators = false;
    config.animation = true;
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(async paramMap => {
      let cGuid = paramMap.get('customerGuid')?.toString() ?? '';
      if (this.guidService.isValidGuid(cGuid)) {
        let customerGuid = cGuid;
        this.customerService.initCustomer(customerGuid);
        await this.userService.subscribeAccess();
        let data = this.activatedRoute.snapshot.data;
        if (data && data['pageId']) {
          this.userService.checkPageAccess(data['pageId']);
        }
        this.initPage();
      }
    });
  }

  initPage() {

    let combSub = combineLatest([this.main.activatedIds$, this.customerService.customer$])
    .subscribe({
      next: ([ids, cust]) => {
        if (ids && ids.targetGuid) {
          if (!this.ids || this.ids.targetGuid !== ids.targetGuid) {
            this.ids = ids;
          }
        }
        if (cust) {
          this.serviceOption = "2";//cust.cSAccountServicingOption;
        }
        if (ids && cust) {
            this.getAccountSummary();
            this.getTargetTransactionHistory();
        }        
      },
      error: (err: any) => {
        console.error(err);
      },
      complete: () => { combSub.unsubscribe(); }
    });
    
  }

  ngAfterViewInit() {
  }

  getAccountSummary() {
    if (this.ids.customerGuid && this.ids.campaignGuid && this.ids.targetGuid) {
      // this.main.showLoading();
      this.loading = true;
      this.apiService.get(`csr/target-account-summary/${this.serviceOption}/${this.ids.customerGuid}/${this.ids.campaignGuid}/${this.ids.targetGuid}`)
        .subscribe((res: any) => {
          this.loading = false;
          this.accountSummary = res.accountSummary;
          this.scheduledPayments = res.scheduledPayments || [];
          this.upcomingPayments = res.upcomingPayments || [];
          this.paymentOptions = (res.paymentOptions as PaymentOptionModel[]).filter(p => p.paymentOptionDescription.length > 0) || [];
          // this.tenDaysPayoffs = res.tenDaysPayoffs || [];
          // this.paymentAccounts = res.paymentAccounts || [];
          // var arr = res.processors || [];
          // arr.forEach((item: { processorID: any; processorDescription: any; }) => {
          //   this.processors.push({
          //     id: item.processorID,
          //     desc: item.processorDescription
          //   })
          // });
          if (Number(this.accountSummary.lateFeesBalance) > 0) {
            this.lateFees = this.accountSummary.lateFeesBalance;
          }
          // this.main.dismissLoading();
        },
          (err: any) => {
            console.error(err);
            this.loading = false;
            this.main.dismissLoading();
          }
        );
    }
  }
  
  getTargetTransactionHistory() {
    if (this.ids.customerGuid && this.ids.campaignGuid && this.ids.targetGuid) {
      this.apiService.get(`csr/target-transaction-history/${this.ids.customerGuid}/${this.ids.campaignGuid}/${this.ids.targetGuid}`)
        .subscribe((res: TargetTransactionHistory[]) => {
          this.transactionHistory = res;
        },
          (err: any) => {
            console.error(err);
          }
        );
    }
  }

  refreshData() {
    this.custNotes.reload();
    this.getAccountSummary();
    this.getTargetTransactionHistory();
  }

}
