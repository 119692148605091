<mat-nav-list role="navigation" class="side-nav-list">
    <div *ngFor="let menu of menus">
        <mat-list-item class="nav-item" (click)="menuSelected(menu)"
        [class]="menu.selected ? 'sidenav-item-selected' : 'sidenav-item'">
            <div class="flex align-items-center">
                <div class="flex-shrink-0">
                    <div class="sidenav-min">
                        <mat-icon aria-hidden="false" *ngIf="menu.icon" class="menu-icon material-icons-outlined">{{menu.icon}}</mat-icon>
                        <!-- <fa-icon [icon]="menu.icon" size="lg" [classes]="['mr-2']"></fa-icon> -->
                    </div>
                </div>
                <div class="flex-grow-1 ml-2" *ngIf="expanded">
                    <p class="me-auto mb-1 h6 menu-title">
                        <span>{{menu.title}}</span>
                        <span *ngIf="menu.children.length" class="p-1">
                            <i class="bi bi-chevron-right" aria-hidden="true"></i>
                        </span>
                    </p>
                </div>
              </div>
        </mat-list-item>
    </div>
  </mat-nav-list>
