import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService, SelectItem } from 'primeng/api';
import { LookupModel } from 'src/app/models/lookup-model';
import { UserProfileDetails } from 'src/app/models/user-profile-details';
import { ApiService } from 'src/app/services/api.service';
import { MainService } from 'src/app/services/main.service';

@Component({
  selector: 'app-my-profile-page',
  templateUrl: './my-profile-page.component.html',
  styleUrls: ['./my-profile-page.component.scss']
})
export class MyProfilePageComponent implements OnInit {
  // showSuccess: boolean = false;
  timeZones: LookupModel[] = [];
  //timeZoneItems: SelectItem[] = [];

  toastKey: string = 'payToast';
  toastPos: string = 'bottom-center';
  details: UserProfileDetails = new UserProfileDetails();
  loading = false;
  profileForm: FormGroup = new FormGroup({});
  constructor(private main: MainService, 
    private fb: FormBuilder,
    private apiService: ApiService,
    private messageService: MessageService) { }

  ngOnInit(): void {
    this.main.showHideCsrSidenav(false);
    this.getDetails();
    this.getLookups();
  }

  getDetails(){
    this.loading = true;
    this.apiService.get(`user/get-details`)
    .subscribe((res: any) => {
      this.details = res;
      this.initForm();
    },
      (err: any) => {
        console.error(err);
        this.loading = false;
      }
    );
  }

  getLookups(){
    this.apiService.get(`user/get-settings-lookup`)
    .subscribe((res: any) => {
      this.timeZones = res.timeZones;
      //this.timeZoneItems = this.timeZones.map(t => {return { label: t.desc, value: t.id }; }) as SelectItem[];
    },
      (err: any) => {
        console.error(err);
      }
    );
  }

  initForm(){
    this.profileForm = this.fb.group({
      firstName: [this.details.firstName, Validators.required],
      lastName: [this.details.lastName, Validators.required],
      middleName: [this.details.middleName],
      company: [this.details.company],
      cellPhone: [this.details.mobile],
      workPhone: [this.details.businessPhone],
      email: [this.details.loginEmail, Validators.required],
      timeZoneId: [this.details.timeZoneID],
    });
    this.loading = false;
  }

  submit(){
    if (!this.profileForm.valid){
      this.profileForm.markAllAsTouched();
      return;
    }

    this.main.showLoading();
    var obj = {
      FirstName: this.profileForm.value.firstName,
      LastName: this.profileForm.value.lastName,
      Company: this.profileForm.value.company,
      LoginEmail: this.profileForm.value.email,
      Mobile: this.profileForm.value.cellPhone,
      BusinessPhone: this.profileForm.value.workPhone,
      TimeZoneId: this.profileForm.value.timeZoneId,
    }

    var body = JSON.stringify(obj);
    this.apiService.post(`user/update-details`, body)
      .subscribe((res: any) => {
        this.main.dismissLoading();
        //this.main.showSuccessModal('Profile Updated Successfully', '');
        // this.showSuccess = true;
        this.messageService.add({
          key: this.toastKey,
          severity: 'success',          
          detail: 'Profile updated successfully.'
        });
      },
        (err: any) => {
          this.main.dismissLoading();
          console.error(err);
        }
      );
  }

}
