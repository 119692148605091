import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { AccountServicingPageComponent } from './pages/account-servicing-page/account-servicing-page.component';
import { AdvancedSearchComponent } from './pages/advanced-search/advanced-search.component';
import { AgentToolsPageComponent } from './pages/agent-tools-page/agent-tools-page.component';
import { ApplicationPageComponent } from './pages/application-page/application-page.component';
import { CommunicationsPageComponent } from './pages/communications-page/communications-page.component';
import { CommunicationsQueuePageComponent } from './pages/communications-queue-page/communications-queue-page.component';
import { DashboardPageComponent } from './pages/dashboard-page/dashboard-page.component';
import { DecisionPageComponent } from './pages/decision-page/decision-page.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { LeadsPageComponent } from './pages/leads-page/leads-page.component';
import { MyProfilePageComponent } from './pages/my-profile-page/my-profile-page.component';
import { NoteListPageComponent } from './pages/note-list-page/note-list-page.component';
import { PreApplicationPageComponent } from './pages/pre-application-page/pre-application-page.component';
import { QueueCollectionsPageComponent } from './pages/queue-collections-page/queue-collections-page.component';
import { SystemsPageComponent } from './pages/systems-page/systems-page.component';
import { VerificationQueuePageComponent } from './pages/verification-queue-page/verification-queue-page.component';
import { CourtesyAdjustmentPageComponent } from './pages/courtesy-adjustment-page/courtesy-adjustment-page.component';
import { ManualPaymentsPageComponent } from './pages/manual-payments-page/manual-payments-page.component';
import { LogoutComponent } from './pages/logout/logout.component';
import { NotAuthorizedComponent } from './pages/not-authorized/not-authorized.component';
import { VerificationPageComponent } from './pages/verification-page/verification-page.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { CreditBureauComponent } from './pages/credit-bureau/credit-bureau.component';
import { PaymentCalculatorComponent } from './pages/payment-calculator/payment-calculator.component';
import { CustomerCollectionsComponent } from './pages/customer-collections/customer-collections.component';

const routes: Routes = [
  { path: 'not-authorized', pathMatch: 'full', component: NotAuthorizedComponent },
  { path: '', component: HomePageComponent, canActivate: [MsalGuard]  },
  { path: 'home/:customerGuid', component: HomePageComponent, pathMatch: 'full', canActivate: [MsalGuard]  },
  { path: 'my-profile', component: MyProfilePageComponent, pathMatch: 'full', canActivate: [MsalGuard]  },
  { path: 'dashboard/:customerGuid/:campaignGuid/:targetGuid', component: DashboardPageComponent, pathMatch: 'full', canActivate: [MsalGuard] },
  { path: 'pre-application/:customerGuid/:campaignGuid/:targetGuid', component: PreApplicationPageComponent, pathMatch: 'full', canActivate: [MsalGuard] },
  { path: 'application/:customerGuid/:campaignGuid/:targetGuid', component: ApplicationPageComponent, pathMatch: 'full', canActivate: [MsalGuard] },
  { path: 'decision/:customerGuid/:campaignGuid/:targetGuid', component: DecisionPageComponent, pathMatch: 'full', canActivate: [MsalGuard] },
  { path: 'account-servicing/:customerGuid/:campaignGuid/:targetGuid', component: AccountServicingPageComponent, pathMatch: 'full', canActivate: [MsalGuard] },
  { path: 'communications/:customerGuid/:campaignGuid/:targetGuid', component: CommunicationsPageComponent, pathMatch: 'full', canActivate: [MsalGuard] },
  { path: 'agent-tools/:customerGuid/:campaignGuid/:targetGuid', component: AgentToolsPageComponent, pathMatch: 'full', canActivate: [MsalGuard] },
  { path: 'systems/:customerGuid/:campaignGuid/:targetGuid', component: SystemsPageComponent, pathMatch: 'full', canActivate: [MsalGuard] },
  { path: 'verification/:customerGuid/:campaignGuid/:targetGuid', component: VerificationPageComponent, pathMatch: 'full', canActivate: [MsalGuard] },
  { path: 'advanced-search/:customerGuid', pathMatch: 'full', component: AdvancedSearchComponent, canActivate: [MsalGuard]},
  { path: 'collections-queue/:customerGuid', pathMatch: 'full', component: QueueCollectionsPageComponent, canActivate: [MsalGuard]},
  { path: 'leads/:customerGuid', pathMatch: 'full', component: LeadsPageComponent, canActivate: [MsalGuard]},
  { path: 'verification-queue/:customerGuid', pathMatch: 'full', component: VerificationQueuePageComponent, canActivate: [MsalGuard]},
  { path: 'communications-queue/:customerGuid', pathMatch: 'full', component: CommunicationsQueuePageComponent, canActivate: [MsalGuard]},
  { path: ':customerGuid', component: HomePageComponent, canActivate: [MsalGuard]},
  { path: 'note-list/:customerGuid', component: NoteListPageComponent, canActivate: [MsalGuard]},
  { path: 'courtesy-adjustment/:customerGuid/:campaignGuid/:targetGuid', component: CourtesyAdjustmentPageComponent, canActivate: [MsalGuard]},
  { path: 'manual-payments/:customerGuid/:campaignGuid/:targetGuid', component: ManualPaymentsPageComponent, canActivate: [MsalGuard]},
  { path: 'credit-bureau/:customerGuid/:campaignGuid/:targetGuid', component: CreditBureauComponent, canActivate: [MsalGuard]},
  { path: 'payment-calculator/:customerGuid/:campaignGuid/:targetGuid', component: PaymentCalculatorComponent, canActivate: [MsalGuard]},
  { path: 'customer_collections/:customerGuid/:campaignGuid/:targetGuid', component: CustomerCollectionsComponent, canActivate: [MsalGuard]},
  { path: 'logout', component: LogoutComponent },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: false 
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
