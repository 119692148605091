import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LookupModel } from 'src/app/models/lookup-model';

@Component({
  selector: 'app-confirm-change-modal',
  templateUrl: './confirm-change-modal.component.html',
  styleUrls: ['./confirm-change-modal.component.scss']
})
export class ConfirmChangeModalComponent implements OnInit {
  comment = new FormControl('', Validators.required);
  constructor(
    public dialogRef: MatDialogRef<ConfirmChangeModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmChangeData,
  ) { }

  ngOnInit(): void {
  }

  cancel(): void {
    this.dialogRef.close(null);
  }

  confirm(): void {
    var result = {
      confirmed: true,
      comment: this.comment.value
    }

    if (this.comment.valid || !this.data.needComment){
      this.dialogRef.close(result);
    }else{
      this.comment.markAllAsTouched();
    }
  }
}

export interface ConfirmChangeData {
  opt: LookupModel | null | undefined, 
  title: string,
  subTitle: string,
  needComment: boolean
}
