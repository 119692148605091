import { Component } from '@angular/core';

@Component({
  selector: 'app-credit-card-adjustment',
  templateUrl: './credit-card-adjustment.component.html',
  styleUrls: ['./credit-card-adjustment.component.scss']
})
export class CreditCardAdjustmentComponent {

}
