<div class="card-body">
    <h4 class="card-title">Bank Account Adjustments</h4>
    <div class="form-group mb-2">
        <div class="form-group">
            <label class="form-label clabel">Enter Amount</label>
            <p3solved-input-number class="pl-3" id="manualBankAdjustment"
                [(ngModel)]="manualBankAdjustment"
                (blurEvent)="manualBankAdjBlur=true; validateBankAmount()">
                <div *ngIf="(manualBankAdjBlur && manualBankAdjustment == 0)"
                    class="w-12 my-1 p-error text-xs">
                    <p>Invalid Input</p>
                </div>
            </p3solved-input-number>
        </div>
        <div class="form-group">
            <label class="form-label clabel">Payment Method</label>
            <!-- <p3solved-dropdown id="selectedBankPayment" optionLabel="loanPaymentMethodDescription"
                [items]="courtesyAdjustinfo.paymentMethods" [displayFirst]="false"
                [showClear]="false" [(ngModel)]="selectedMethod"
                (optionSelected)="validateBankAmount()">
            </p3solved-dropdown> -->
        </div>
        <p3solved-button type="button" (click)="confirmBankAdjustment()"
            [disabled]="disableBankPayment">CONFIRM ADJUSTMENT</p3solved-button>
    </div>
</div>