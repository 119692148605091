import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
// import { ActivatedRoute } from '@angular/router';
// import { ApiService } from 'src/app/services/api.service';
// import { CustomerService } from 'src/app/services/customer.service';
// import { MainService } from 'src/app/services/main.service';
import { CustomGridComponent } from 'src/app/components/custom-grid/custom-grid.component';
import { ActivatedIds } from 'src/app/models/activated-ids';
import { CustomerService } from 'src/app/services/customer.service';
import { GuidService } from 'src/app/services/guid.service';
import { MainService } from 'src/app/services/main.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-queue-collections-page',
  templateUrl: './queue-collections-page.component.html',
  styleUrls: ['./queue-collections-page.component.scss']
})
export class QueueCollectionsPageComponent implements OnInit {

  ids: ActivatedIds = {} as ActivatedIds;
  constructor(
    private activatedRoute: ActivatedRoute,
    private main: MainService,
    private userService: UserService,
    private guidService: GuidService,
    private customerService: CustomerService
    ) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(async paramMap => {
      let cGuid = paramMap.get('customerGuid')?.toString() ?? '';
      if (this.guidService.isValidGuid(cGuid)) {
        let customerGuid = cGuid;
        this.customerService.initCustomer(customerGuid);
        // await this.userService.subscribeAccess();
        // let data = this.activatedRoute.snapshot.data;
        // if (data && data['pageId']) {
        //   this.userService.checkPageAccess(data['pageId']);
        // }
        this.main.activatedIds$.subscribe(ids => {
          if (ids && ids.customerGuid) this.ids = ids;
          else {
            this.ids = {} as ActivatedIds;
            this.ids.customerGuid = cGuid;
          }
        });
      }
    });
  }

  onSelected(ev: any){
    var url = `/dashboard/${ev.CustomerGUID}/${ev.CampaignGUID}/${ev.TargetGUID}`;
    window.open(url, "_blank");
  }

}
