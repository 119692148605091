<div class="cds-card p-4">
    <div>
        <p class="h4 my-2">Notifications</p>
        <div *ngIf="!loading" class="pt-2">
            <div *ngFor="let n of notifications" 
                class="px-4 py-3 mb-4 notification-item" 
                [ngClass]="getNotificationClass(n.notificationCardPriorityID)">
                    <p class="mb-3 text-muted"><small>{{n.createDate}}</small><small class="ml-1">-</small><small class="ml-1">{{n.fullName}}</small></p>
                    <p class="mb-1">{{n.note}}</p>
            </div>
        </div>
        <div class="my-2 pt-4">
            <textarea class="form-control border-round p-3"
             id="notificationTextarea" 
             [formControl]="notificationText"
             rows="2" 
             [ngClass]="notificationText.touched && notificationText.invalid ? 'is-invalid' : ''"
             placeholder="Add a notification here and select type of alert"></textarea>
             <div *ngIf="notificationText && notificationText.touched && notificationText.invalid" class="invalid-feedback">
                This field is invalid
              </div>
          </div>
          <div class="pt-3" *ngIf="hasFeature('CSR_CUSTOMER_DASHBOARD_EDIT_NOTIFICATION')" >
            <p3solved-button label="Priority" icon="pi pi-angle-down"
              [matMenuTriggerFor]="notificationMenu" [disabled]="loading || updating">                
              </p3solved-button>
              <mat-menu #notificationMenu="matMenu">
                <button mat-menu-item (click)="add('3')">
                  <span>None</span>
                </button>
                <button mat-menu-item (click)="add('2')">
                  <span>Medium</span>
                </button>
                <button mat-menu-item (click)="add('1')">
                  <span>High</span>
                </button>
              </mat-menu>
        </div>
      </div>
  </div>
