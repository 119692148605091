import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import { LookupModel } from 'src/app/models/lookup-model';
import { LookupService } from 'src/app/services/lookup.service';

@Component({
  selector: 'app-cc-select',
  templateUrl: './cc-select.component.html',
  styleUrls: ['./cc-select.component.scss']
})
export class CcSelectComponent implements OnInit {
  @Input() controlName: string = '';
  @Input() isRequired: boolean = false;
  @Input() id?: string;
  @Input() options: LookupModel[] = [];
  @Input() label: string = "";
  iconRequired = faAsterisk;
  @Input() group: FormGroup = new FormGroup({});
  @Input()
  get type(): string { return this._type; }
  set type(type: string) {
    this._type = (type && type.trim()) || '';
    if (type === 'states'){
      this.options = this.lookupService.getStates();
    }
  }
  private _type = '';
  constructor(private lookupService: LookupService) {
    var d = new Date();
    this.id = d.getTime().toString();
  }

  ngOnInit(): void {
  }

}
