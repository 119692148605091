import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor() { }

  getBoolean(value: any, dflt: boolean = false): boolean {
    if (value == null || value == undefined) return dflt;
    switch (value) {
      case true:
      case "True":
      case "TRUE":
      case "true":
      case 1:
      case "1":
      case "on":
      case "On":
      case "ON":
      case "Yes":
      case "YES":
      case "yes":
        return true;
      default:
        return false;
    }
  }
}
