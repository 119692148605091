import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ConfirmationService, ConfirmEventType } from 'primeng/api';
import { ActivatedIds } from 'src/app/models/activated-ids';
import { ApiService } from 'src/app/services/api.service';
import { MainService } from 'src/app/services/main.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-customer-contact-new',
  templateUrl: './customer-contact-new.component.html',
  styleUrls: ['./customer-contact-new.component.scss']
})
export class CustomerContactNewComponent implements OnInit {
  @Output() optionChanged = new EventEmitter();
  shareNonpublic: boolean = false;
  accountPhoneMe: boolean = false;
  marketingPhoneMe: boolean = false;
  marketingEmailMe: boolean = false;
  marketingTextMe: boolean = false;
  arbitration: boolean = false;
  doNotCallFlag: boolean = false;

  label1: string = 'Please do not share my nonpublic personal information with non-affiliated third parties, except as provided by law.';
  label2: string = 'You may phone me for account related matters (such as reminder calls before my loan comes due) at any number I give you on my application or on the My Account page.';
  label3: string = 'You may phone me for marketing purposes at any number I give you on my application or on the My Account page.';
  label4: string = 'You may email me for marketing purposes.';
  label5: string = 'You may text message me to any number I give you on my application or on the My Account page.';
  label6: string = 'Account is subject to Arbitration.';
  label7: string = 'Is Account Flagged as DO NOT CALL?';

  customerContactNewConfirmKey: string = 'custContConfKey';

  ids: ActivatedIds = {} as ActivatedIds;
  targetInfo: any;

  constructor(
    private mainService: MainService,
    private apiSerivce: ApiService,
    private confirmService: ConfirmationService,
    private toastService: ToastService
  ) { }

  ngOnInit(): void {
    this.mainService.activatedIds$.subscribe(ids => {
      if (ids && ids.customerGuid) {
        this.ids = ids;
        this.getTargetInfo();
      }
    })
  }

  getTargetInfo() {
    let body = {
      customerGuid: this.ids.customerGuid,
      campaignGuid: this.ids.campaignGuid,
      targetGuid: this.ids.targetGuid
    };
    let postSub = this.apiSerivce.post(`campaign/get-target-info`, body)
      .subscribe({
        next: (info: any) => {
          if (info && info.length) {
            this.targetInfo = info[0];
            this.setupChecks();
          }
        },
        error: (err: any) => {
          this.toastService.addError("Unable to get target information. See log for details.")
          console.error(err);
        },
        complete: () => { postSub.unsubscribe(); }
      });
  }

  setupChecks() {
    this.shareNonpublic = this.targetInfo.shareNonpublic.toLowerCase() != "true" && this.targetInfo.shareNonpublic.toLowerCase() != "1";
    this.accountPhoneMe = this.targetInfo.accountPhoneMe.toLowerCase() == "true" || this.targetInfo.accountPhoneMe.toLowerCase() == "1";
    this.marketingPhoneMe = this.targetInfo.marketingPhoneMe.toLowerCase() == "true" || this.targetInfo.marketingPhoneMe.toLowerCase() == "1";
    this.marketingEmailMe = this.targetInfo.marketingEmailMe.toLowerCase() == "true" || this.targetInfo.marketingEmailMe.toLowerCase() == "1";
    this.marketingTextMe = this.targetInfo.marketingTextMe.toLowerCase() == "true" || this.targetInfo.marketingTextMe.toLowerCase() == "1";
    this.arbitration = this.targetInfo.arbitration.toLowerCase() == "true" || this.targetInfo.arbitration.toLowerCase() == "1";
    this.doNotCallFlag = this.targetInfo.doNotCallFlag.toLowerCase() == "true" || this.targetInfo.doNotCallFlag.toLowerCase() == "1";
  }

  checkBoxChanged(event: any, optName: string, saveCode: string) {
    let msg: string = `<div class="flex flex-wrap">
    <div class="w-12 pt-4 text-xl">Confirm the Contact Setting Change</div>
    <div class="w-12 pt-4 text-xl">${optName} <span class="pl-6">${event.checked ? 'YES' : 'NO'}</span></div>
    </div>`;

    this.confirmService.confirm({
      key: this.customerContactNewConfirmKey,
      message: msg,
      rejectLabel: 'Cancel',
      acceptLabel: 'Confirm',
      accept: () => {
        this.saveOption(saveCode, event.checked);
      },
      reject: (type: any) => {
        this.revertChecked(event.checked, saveCode);
        switch (type) {
          case ConfirmEventType.REJECT:
            this.toastService.add({ severity: 'warn', summary: 'Declined', detail: 'Change will not be submitted.' });
            break;
          case ConfirmEventType.CANCEL:
            this.toastService.add({ severity: 'warn', summary: 'Cancelled', detail: 'Operation cancelled.' });
            break;
        }
      }
    });

  }

  revertChecked(checked: boolean, saveCode: string) {
    switch (saveCode) {
      case 'share':
        this.shareNonpublic = !checked;
        break;
      case 'phone_account':
        this.accountPhoneMe = !checked;
        break;
      case 'phone':
        this.marketingPhoneMe = !checked;
        break;
      case 'email':
        this.marketingEmailMe = !checked;
        break;
      case 'text':
        this.marketingTextMe = !checked;
        break;
      case 'arbitration':
        this.arbitration = !checked;
        break;
      case 'do_not_call':
        this.doNotCallFlag = !checked;
        break;
    }
  }

  saveOption(saveCode: string, checked: boolean) {
    if (saveCode == 'share') checked = !checked;

    let body = {
      customerGuid: this.ids.customerGuid,
      campaignGuid: this.ids.campaignGuid,
      targetGuid: this.ids.targetGuid,
      fieldName: saveCode,
      fieldChecked: checked
    };

    let postSub = this.apiSerivce.post(`csr/comm-update`, body)
    .subscribe({
      next: () => {
        this.optionChanged.emit();
        this.toastService.addSuccess('Option successfully updated.');
      },
      error: (err: any) => {
        this.toastService.addError('Unable to save option. See log for details');
        console.error(err);
      },
      complete: () => { postSub.unsubscribe(); }
    });
  }

}
