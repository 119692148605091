import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ActivatedIds } from 'src/app/models/activated-ids';
import { LookupModel } from 'src/app/models/lookup-model';
import { PatientAppDetails } from 'src/app/models/patient-app-details';
import { ApiService } from 'src/app/services/api.service';
import { CustomerService } from 'src/app/services/customer.service';
import { MainService } from 'src/app/services/main.service';

@Component({
  selector: 'app-pre-application-page',
  templateUrl: './pre-application-page.component.html',
  styleUrls: ['./pre-application-page.component.scss']
})
export class PreApplicationPageComponent implements OnInit {
  details: PatientAppDetails = new PatientAppDetails;
  ids: ActivatedIds = {};
  loading = false;
  patientTypes: LookupModel[] = [{ id: 'patient', desc: 'Patient' }, { id: "responsibleParty", desc: 'Responsible Party' }];
  patientType:string | null | undefined = '';
  patientRelationships: LookupModel[] = [];
  
  constructor(private activatedRoute: ActivatedRoute, 
    private main: MainService, 
    private customerService: CustomerService,
    private apiService: ApiService) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(paramMap => {
      var customerGuid = paramMap.get('customerGuid')?.toString();
      this.customerService.initCustomer(customerGuid);
    });
    this.main.activatedIds$.subscribe((ids) => {
      if (ids && ids.targetGuid){
        if (!this.ids || this.ids.targetGuid !== ids.targetGuid){
          this.ids = ids;
          this.getPatientDetails();
          this.getLookups();
        }
      }
    });
  }

  getPatientDetails() {
    if (this.ids.customerGuid && this.ids.campaignGuid && this.ids.targetGuid){
      this.loading = true;
      this.apiService.get(`app/patient-app-details/${this.ids.customerGuid}/${this.ids.campaignGuid}/${this.ids.targetGuid}`)
      .subscribe((res: any) => {
        this.loading = false;
        this.details = res;
        this.details.patLanguage = this.details.patLanguage.toUpperCase();
        this.patientType = this.getPatientType(this.details?.patientRelationship || 'patient');
      },
        (err: any) => {
          console.error(err);
          this.loading = false;
        }
      );
    }
  }

  private getLookups(){
    this.apiService.get(`app/bop-lookups/${this.ids.customerGuid}`)
    .subscribe(
      res => {
        this.patientRelationships = res.patientRelationships || [];
      },
      err => console.error("Error retrieving Bop lookups")
    );
  }

  private getPatientType(patientType: string | null | undefined){
    var type = patientType ? patientType.toLocaleLowerCase() : 'patient';
    if (type === 'self' || type === 'patient' || type === '1' )
     return 'patient';
 
     if (type === '2' || type === 'responsibleparty' || type === 'party' )
    return 'responsibleParty';
 
    return patientType;
   }

}
