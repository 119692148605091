import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
//import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedIds } from 'src/app/models/activated-ids';
import { ApiService } from 'src/app/services/api.service';
import { MainService } from 'src/app/services/main.service';
import { ToastService } from 'src/app/services/toast.service';
import { getFileIcon } from 'src/app/services/util.service';

@Component({
  selector: 'app-upload-attachment',
  templateUrl: './upload-attachment.component.html',
  styleUrls: ['./upload-attachment.component.scss']
})
export class UploadAttachmentComponent implements OnInit {

  @Output() backClicked = new EventEmitter();
  @Output() fileUploaded = new EventEmitter<any>();
  @Output() uploadError = new EventEmitter<string>();

  @Input() msgGuid: string | null = null;

  payload: FormData = new FormData();
  ids: ActivatedIds = {};
  fileName = new FormControl('');
  iconPath = '';
  uploadedFile = '';
  uploading = false;

  @ViewChild('uploader') uploader: ElementRef = {} as ElementRef;

  constructor(
    private toastService: ToastService,
    private main: MainService,
    private api: ApiService
  ) { }

  ngOnInit(): void {

    this.main.activatedIds$.subscribe((ids) => {
      if (ids && ids.targetGuid) {
        if (!this.ids || this.ids.targetGuid !== ids.targetGuid) {
          this.ids = ids;
        }
      }
    });
  }

  uploadFile(evt: any) {
    var file = evt[0];
    this.updateFile(file);
  }

  uploadFileInput(evt: any) {
    var file = evt.target.files[0];
    this.updateFile(file);
  }

  updateFile(file: File) {
    this.payload = new FormData();
    this.payload.append('data', file);
    var ext = file.name.substring(file.name.indexOf('.'));
    var icon = getFileIcon(ext);
    this.iconPath = `/assets/icons/${icon}`;
    if (!this.fileName.value) {
      this.fileName.setValue(file.name);
    }
    this.uploadedFile = file.name;
  }

  back() {

    this.backClicked.emit();
  }

  upload() {
    if (this.uploadedFile) {
      this.uploading = true;
      this.api.postFile(`upload/customer-attachment/${this.ids.customerGuid}`, this.payload)
        .subscribe((res: any) => {

          this.uploader.nativeElement.value = null;
          this.uploadedFile = '';
          this.uploading = false;
          if (res) {
            var result = {
              fileGuid: res,
              filename: this.fileName.value || res
            };
            this.fileUploaded.emit(result);
          }
        },
          (err: any) => {
            this.uploading = false;
            this.toastService.addError('Error occurred uploading file. See log for details.');
            console.error(err);
            var msg = err.error.text || "Error occured uploading file";
            this.uploadError.emit(msg);
          }
        );
    }

  }

}
