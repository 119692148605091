<div [formGroup]="group" class="mb-1">
    <label *ngIf="label">{{label}}</label>
    <mat-radio-group 
    class="cc-radio-group" 
    [formControlName]="controlName" 
    color="primary" [disabled]="disabled">
        <mat-radio-button class="cc-radio-button" *ngFor="let o of options" [value]="o.id">
            {{o.desc}}
        </mat-radio-button>
    </mat-radio-group>
</div>