<p class="text-2xl font-bold m-2">Verification Queue</p>

<app-custom-grid #verifications
    [DataSourceName]="'dsCSRQueueVerifications'" 
    [showCheckbox]="true"
    dataKey="loanNumber"
    [AllowPaging]="false" 
    [showPointer]="true" 
    [rowSelectable]="true"
    (onSelect)="rowSelected($event);"
    [ActivatedIds]="ids"
    [showCaptionRight]="true">
    <div captionRight class="text-right">
        <button pButton icon="pi pi-user" class="p-button-outlined" (click)="opUsers.toggle($event)"></button>
    </div>
</app-custom-grid>


<p-overlayPanel #opUsers [showCloseIcon]="false" [showTransitionOptions]="'0.2s'">
    <ng-template pTemplate="content">
        <div class="p-4 pds-overlay-bg" *ngIf="usersLoaded">
            <p-table #availUsers [value]="availableUsers" styleClass="p-datatable-sm" responsiveLayout="scroll"
                [columns]="userColumns" dataKey="userID" [(selection)]="selectedUser">
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th></th>
                        <th *ngFor="let col of getUserDisplayColumns()">
                            {{col.header}}
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns">
                    <tr>
                        <td><p-tableRadioButton [value]="rowData"></p-tableRadioButton></td>
                        <td *ngFor="let col of getUserDisplayColumns()">
                            {{rowData[col.field]}}
                        </td>
                    </tr>
                </ng-template>
            </p-table>
            <div class="flex justify-content-center">
                <div class="p-2">
                    <p3solved-button id="btnCancel" label="Cancel" class="secondary" (buttonClick)="selectedUser=null;opUsers.hide();"></p3solved-button>
                </div>
                <div class="p-2">
                    <p3solved-button id="btnAssign" label="Assign" (buttonClick)="assignUsers('assign')" [disabled]="!selectedUser"></p3solved-button>
                </div>
                <div class="p-2">
                    <p3solved-button id="btnGrab" label="Grab" class="warn" (buttonClick)="assignUsers('grab')" [disabled]="!selectedUser"></p3solved-button>
                </div>
            </div>
        </div>
    </ng-template>
</p-overlayPanel>