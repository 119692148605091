import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ActivatedIds } from 'src/app/models/activated-ids';
import { LookupModel } from 'src/app/models/lookup-model';
import { TargetInfoModel } from 'src/app/models/target-info-model';
import { ApiService } from 'src/app/services/api.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { MainService } from 'src/app/services/main.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmChangeData, ConfirmChangeModalComponent } from '../confirm-change-modal/confirm-change-modal.component';
import { UserService } from 'src/app/services/user.service';
import { CustomerService } from 'src/app/services/customer.service';
import { CustomerModel } from 'src/app/models/customer-model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastService } from 'src/app/services/toast.service';
import { ConfirmFraudFormGroup } from 'src/app/models/form-groups';

@Component({
  selector: 'app-dashboard-header',
  templateUrl: './dashboard-header.component.html',
  styleUrls: ['./dashboard-header.component.scss']
})
export class DashboardHeaderComponent implements OnInit {
  ids: ActivatedIds = {};
  targetInfo: TargetInfoModel = new TargetInfoModel;
  customer: CustomerModel | null = null;
  payMethods: LookupModel[] = []
  loanStatuses: LookupModel[] = [];
  legalStatuses: LookupModel[] = [];
  cashAdvStatusesMaster: LookupModel[] = [
    { id: '0', desc: 'Disable' }, { id: '1', desc: 'Enable' }
  ];
  naturalDisasterOption: LookupModel = {} as LookupModel;
  legalStatusOption: LookupModel = {} as LookupModel;
  cashAdvStatuses: LookupModel[] = [];
  popItems: string[] = [];

  showCustomerName: boolean = false;
  showAccountNumber: boolean = false;
  showPayStatus: boolean = false;
  showFundType: boolean = false;
  showDpd: boolean = false;
  showAppStatus: boolean = false;
  showLoanStatus: boolean = false;
  showLegalStatus: boolean = false;
  showRewards: boolean = false;
  showCashAdv: boolean = false;
  showCreditFlag: boolean = false;
  showNatDis: boolean = false;
  showNatDis2: boolean = false;
  cyclesNoLoaded: boolean = false;
  showActMil: boolean = false;
  showConfFraud: boolean = false;
  showConfFraud2: boolean = false;
  showConfBankrupt: boolean = false;
  showConfBankrupt2: boolean = false;
  confFraudFormLoaded: boolean = false;
  
  customerNameLabel: string = '';
  accountNumLabel: string = '';
  paystatusLabel: string = '';  
  fundTypeLabel: string = '';
  dpdLabel: string = '';
  appStatusLabel: string = '';
  loanStatusLabel: string = '';
  legalStatusLabel: string = '';
  rewardsLabel: string = '';
  cashAdvLabel: string = '';
  creditFlagLabel: string = '';
  holidayCycleEndDate: string = '';
  holidayNextPaymentDueDate: string = '';

  confFraudForm: FormGroup<ConfirmFraudFormGroup> = {} as FormGroup;
  cyclesNo: FormControl<number|null> = {} as FormControl;
  activeDutyDate: FormControl<Date|null> = new FormControl<Date|null>(null, {nonNullable: true, validators: [Validators.required]});
  caseNumber: FormControl<string|null> = new FormControl<string|null>(null, {nonNullable: true, validators: [Validators.required]});
  attorneyPhone: FormControl<string|null> = new FormControl<string|null>(null, {nonNullable: true, validators: [Validators.required]});

  constructor(
    private mainService: MainService,
    private dashboard: DashboardService,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private customerService: CustomerService, 
    private toastService: ToastService,
    private dialog: MatDialog,
    private apiService: ApiService) { }

  ngOnInit(): void {
    this.customerService.customer$
    .subscribe(cust => {
      this.customer = cust;
      if (this.customer && this.customer.jsonCustomerSupportHeaderBar) {
        this.setLabels(this.customer.jsonCustomerSupportHeaderBar);       
      }
    });
    this.mainService.showHideCsrSidenav(true);
    this.mainService.activatedIds$.subscribe(ids => {
      this.ids = ids;
    });
    this.activatedRoute.paramMap.subscribe(paramMap => {
      this.mainService.updateActivatedIdsMany([
        { propname: 'campaignGuid', propValue: paramMap.get('campaignGuid')?.toString() },
        { propname: 'targetGuid', propValue: paramMap.get('targetGuid')?.toString() },
        { propname: 'customerGuid', propValue: paramMap.get('customerGuid')?.toString() },
      ]);
      this.getTargetInfo();
      this.getLookupData();
    });
  }

  setLabels(jsonCustomerSupportHeaderBar: string) {
    let dashHeaderSettings = JSON.parse(jsonCustomerSupportHeaderBar) as DashBoardHeaderSetting[];

    let dbObj = dashHeaderSettings.find(d => d.value == 'customername');
    this.showCustomerName = dbObj != undefined;
    if (dbObj) this.customerNameLabel = dbObj.label;

    dbObj = dashHeaderSettings.find(d => d.value == 'loannumber');
    this.showAccountNumber = dbObj != undefined;
    if (dbObj) this.accountNumLabel = dbObj.label

    dbObj = dashHeaderSettings.find(d => d.value == 'paystatus');
    this.showPayStatus = dbObj != undefined;
    if (dbObj) this.paystatusLabel = dbObj.label;

    dbObj = dashHeaderSettings.find(d => d.value == 'fundtype');
    this.showFundType = dbObj != undefined;
    if (dbObj) this.fundTypeLabel = dbObj.label;

    dbObj = dashHeaderSettings.find(d => d.value == 'dpd');
    this.showDpd = dbObj != undefined;
    if (dbObj) this.dpdLabel = dbObj.label;

    dbObj = dashHeaderSettings.find(d => d.value == 'appstatus');
    this.showAppStatus = dbObj != undefined;
    if (dbObj) this.appStatusLabel = dbObj.label;

    dbObj = dashHeaderSettings.find(d => d.value == 'loantatus');
    this.showLoanStatus = dbObj != undefined;
    if (dbObj) this.loanStatusLabel = dbObj.label;

    dbObj = dashHeaderSettings.find(d => d.value == 'legalstatus');
    this.showLegalStatus = dbObj != undefined;
    if (dbObj) this.legalStatusLabel = dbObj.label;

    dbObj = dashHeaderSettings.find(d => d.value == 'rewards');
    this.showRewards = dbObj != undefined;
    if (dbObj) this.rewardsLabel = dbObj.label;

    dbObj = dashHeaderSettings.find(d => d.value == 'cashadvance');
    this.showCashAdv = dbObj != undefined;
    if (dbObj) this.cashAdvLabel = dbObj.label;

    dbObj = dashHeaderSettings.find(d => d.value == 'creditflag');
    this.showCreditFlag = dbObj != undefined;
    if (dbObj) this.creditFlagLabel = dbObj.label;

  }

  getTargetInfo() {
    if (this.ids.customerGuid && this.ids.campaignGuid && this.ids.targetGuid){
      this.apiService.get(`csr/campaign-target-info/${this.ids.customerGuid}/${this.ids.campaignGuid}/${this.ids.targetGuid}`)
      .subscribe((res: any) => {
        this.targetInfo = res;
        this.dashboard.setTargetInfo(this.targetInfo);
        this.setPopOvers();
        // this.setLegalStatusOptions();
        if (this.targetInfo && this.targetInfo.cashAdvanceStatusId) {
          this.cashAdvStatuses = this.cashAdvStatusesMaster.filter(c => c.id != this.targetInfo.cashAdvanceStatusId);
        }
      },
        (err: any) => {
          console.error(err);
        }
      );
    }
  }

  getLookupData() {
    if (this.ids.customerGuid){
      this.apiService.get(`csr/lookup-data/${this.ids.customerGuid}`)
      .subscribe((res: any) => {
        this.payMethods = res.payMethods;
        this.loanStatuses = res.loanStatuses;
        this.legalStatuses = res.legalStatuses;
        this.dashboard.setLookup(res);
      },
        (err: any) => {
          console.error(err);
        }
      );
    }
  }

  hasFeature(feature: string, mode: string = 'write') {
    switch (mode) {
      case 'read':
        return this.userService.hasRead(feature);
        break;
      case 'feature': 
        return this.userService.hasFeature(feature);
        break;
      default:
        return this.userService.hasWrite(feature);
        break;
    }
  }

  concessionChange(){
    var msg = this.targetInfo.isConcession  == 'True'
    ? 'Are you sure you want to remove the Concession for this loan?'
    : 'Are you sure you want to set this loan as Concession?';
    const dialogRef = this.dialog.open(ConfirmChangeModalComponent, {
      width: '480px',
      data: this.getStatusChangeDataModel(null, 'Concession Confirmation', msg),
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.confirmed){
         this.updateConcession();
      }
    });
  }

  withdrawShow(){
    const dialogRef = this.dialog.open(ConfirmChangeModalComponent, {
      width: '480px',
      data: this.getStatusChangeDataModel(null, 'Withdraw Loan Confirmation', 'Are you sure you want to Withdraw this loan?'),
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.confirmed){
        this.withdrawLoan();
      }
    });
  }

  cancelShow(){
    const dialogRef = this.dialog.open(ConfirmChangeModalComponent, {
      width: '450px',
      data: this.getStatusChangeDataModel(null, 'Cancel Loan Confirmation', 'Are you sure you want to Cancel this loan?'),
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.confirmed){
        this.cancelLoan();
      }
    });
  }

  payStatusChange(opt: LookupModel): void {
    const dialogRef = this.dialog.open(ConfirmChangeModalComponent, {
      width: '450px',
      data: this.getStatusChangeDataModel(opt, 'Pay Status Change Confirmation', null, true),
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.comment){
        this.updatePaymentMethod(result.comment, opt);
      }
    });
  }

  legalStatusChange(opt: LookupModel): void {
    this.legalStatusOption = opt;

    if (opt.desc.toLowerCase() == 'military - active duty') {
      this.showActMil = true;
    }
    else if (opt.desc.toLowerCase() == 'confirmed fraud') {
      this.confFraudForm = new FormGroup<ConfirmFraudFormGroup>({
        police: new FormControl<boolean>(false, {nonNullable: true, validators: [Validators.requiredTrue]}),
        affidavit: new FormControl<boolean>(false, {nonNullable: true, validators: [Validators.requiredTrue]}),
        address: new FormControl<boolean>(false, {nonNullable: true, validators: [Validators.requiredTrue]}),
        dl: new FormControl<boolean>(false, {nonNullable: true, validators: [Validators.requiredTrue]})
      });
      this.confFraudFormLoaded = true;
      this.showConfFraud = true;
    }
    else if (opt.desc.toLowerCase() == 'confirmed bankruptcy') {
      this.showConfBankrupt = true;
    }    
    else {
      const dialogRef = this.dialog.open(ConfirmChangeModalComponent, {
        width: '450px',
        data: this.getStatusChangeDataModel(opt, 'Legal Status Change Confirmation', null, true),
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result && result.comment){
          this.updateLegalStatus(result.comment, opt);
        }
      });
    }


  }

  cantractStatusChange(opt: LookupModel): void {
    this.cyclesNoLoaded = false;
    this.naturalDisasterOption = {} as LookupModel;
    if (opt.desc.toLowerCase() == 'natural disaster') {
      this.naturalDisasterOption = opt;
      this.cyclesNo = new FormControl<number|null>(null, {nonNullable: true, validators: [Validators.required, Validators.min(1), Validators.max(6)]});
      this.showNatDis = true;
      this.cyclesNoLoaded = true;
    }
    else this.changeContractStatus(opt);

  }

  setCycles() {
    let body = {
      customerGuid: this.ids.customerGuid,
      campaignGuid: this.ids.campaignGuid,
      targetGuid: this.ids.targetGuid,
      cycles: this.cyclesNo.value
    };

    let pSub = this.apiService.post(`csr/disaster-holiday`, body)
    .subscribe({
      next: (data: any) => {        
        if ((data.holidayNextPaymentDueDate as string).indexOf("Error") > -1) {
          this.toastService.addError(data.holidayNextPaymentDueDate);
          console.error(data.holidayNextPaymentDueDate);
        }
        else {
          this.holidayCycleEndDate = data.holidayCycleEndDate;
          this.holidayNextPaymentDueDate = data.holidayNextPaymentDueDate;
          this.showNatDis2 = true;
        }
      },
      error: (err: any) => {
        this.toastService.addError("Unable to set Natural Disaster. See log for details.");
        console.error(err);
      },
      complete: () => { this.showNatDis = false; pSub.unsubscribe(); },
    });
  }

  changeContractStatus(opt: LookupModel) {
    const dialogRef = this.dialog.open(ConfirmChangeModalComponent, {
      width: '450px',
      data: this.getStatusChangeDataModel(opt, 'Contract Status Change Confirmation', null, true),
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.comment){
        this.updateContractStatus(result.comment, opt);
      }
    });
  }

  cashAdvStatusChange(opt: LookupModel): void {
    const dialogRef = this.dialog.open(ConfirmChangeModalComponent, {
      width: '450px',
      data: this.getStatusChangeDataModel(opt, 'Cash Advance Status Change Confirmation', null, true),
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.comment){
        this.updateCashAdvStatus(result.comment, opt);
      }
    });
  }

  updatePaymentMethod(comment: string, opt: LookupModel){
    this.mainService.showLoading();
    var obj = {
      TargetGuid: this.ids.targetGuid,
      CampaignGuid: this.ids.campaignGuid,
      CustomerGuid: this.ids.customerGuid,
      FieldName: 'PAY METHOD STATUS',
      OldValue: this.targetInfo.paymentMethodID,
      NewValue: opt.id,
      Comments: comment
    }
    var json = JSON.stringify(obj);
    this.apiService.post('csr/update-field', json)
    .subscribe((res: any) => {
      this.reloadDashboard();
      this.mainService.dismissLoading();
      if (res){
        this.targetInfo.paymentMethod = opt.desc;
        this.targetInfo.paymentMethodID = opt.id;
        this.mainService.showSuccessModal('Good Job!', 'Pay method successfully updated')
      }
    },
      (err: any) => {
        console.error(err);
        this.mainService.dismissLoading();
      }
    );
  }

  

  cancelLoan(){
    this.mainService.showLoading();
    this.apiService.post(`csr/cancel-loan/${this.ids.customerGuid}/${this.ids.campaignGuid}/${this.ids.targetGuid}`, null)
    .subscribe((res: any) => {
      this.reloadDashboard();
      this.mainService.dismissLoading();
      if (res){
        this.mainService.showSuccessModal('Good Job!', 'Loan Cancel Successfull');
        this.getTargetInfo();
      }else{
        this.mainService.showSuccessModal('Ops!', 'Loan Cancel not available');
      }
    },
      (err: any) => {
        console.error(err);
        this.mainService.dismissLoading();
        this.mainService.showSuccessModal('Ops!', 'Loan Cancel not available');
      }
    );
  }

  withdrawLoan(){
    this.mainService.showLoading();
    this.apiService.post(`csr/withdraw-loan/${this.ids.customerGuid}/${this.ids.campaignGuid}/${this.ids.targetGuid}`, null)
    .subscribe((res: any) => {
      this.reloadDashboard();
      this.mainService.dismissLoading();
      if (res){
        this.mainService.showSuccessModal('Good Job!', 'Loan Withdraw Successfull');
        this.getTargetInfo();
      }else{
        this.mainService.showSuccessModal('Ops!', 'Loan Withdraw not available');
      }
    },
      (err: any) => {
        console.error(err);
        this.mainService.dismissLoading();
        this.mainService.showSuccessModal('Ops!', 'Loan Withdraw not available');
      }
    );
  }

  updateLegalStatus(comment: string, opt: LookupModel){
    this.mainService.showLoading();
    var obj = {
      TargetGuid: this.ids.targetGuid,
      CampaignGuid: this.ids.campaignGuid,
      CustomerGuid: this.ids.customerGuid,
      FieldName: 'LEGAL FLAG',
      OldValue: this.targetInfo.legalStatusID,
      NewValue: opt.id,
      Comments: comment,
      MilitaryActiveDutyDate: this.activeDutyDate.value,
      CyclesNo: this.cyclesNo.value
    }
    var json = JSON.stringify(obj);
    this.apiService.post('csr/update-field', json)
    .subscribe((res: any) => {
      this.reloadDashboard();
      this.mainService.dismissLoading();
      if (res){
        this.targetInfo.legalStatus = opt.desc;
        this.targetInfo.legalStatusID = opt.id;
        this.mainService.showSuccessModal('Good Job!', 'Legal Status updated')
      }
    },
      (err: any) => {
        console.error(err);
        this.mainService.dismissLoading();
      }
    );
  }

  updateContractStatus(comment: string, opt: LookupModel){
    this.mainService.showLoading();
    var obj = {
      TargetGuid: this.ids.targetGuid,
      CampaignGuid: this.ids.campaignGuid,
      CustomerGuid: this.ids.customerGuid,
      FieldName: 'LOAN STATUS',
      OldValue: this.targetInfo.loanStatusID,
      NewValue: opt.id,
      Comments: comment
    }
    var json = JSON.stringify(obj);
    this.apiService.post('csr/update-field', json)
    .subscribe((res: any) => {
      this.reloadDashboard();
      this.mainService.dismissLoading();
      if (res){
        this.targetInfo.loanStatus = opt.desc;
        this.targetInfo.loanStatusID = opt.id;
        this.mainService.showSuccessModal('Good Job!', 'Contract Status Updated.')
      }
    },
      (err: any) => {
        console.error(err);
        this.mainService.dismissLoading();
      }
    );
  }

  updateCashAdvStatus(comment: string, opt: LookupModel){
    this.mainService.showLoading();
    var obj = {
      TargetGuid: this.ids.targetGuid,
      CampaignGuid: this.ids.campaignGuid,
      CustomerGuid: this.ids.customerGuid,
      FieldName: 'CASH ADVANCE STATUS',
      OldValue: this.targetInfo.cashAdvanceStatusId,
      NewValue: opt.id,
      Comments: comment
    }
    var json = JSON.stringify(obj);
    this.apiService.post('csr/update-field', json)
    .subscribe((res: any) => {
      this.reloadDashboard();
      this.mainService.dismissLoading();
      if (res){
        this.targetInfo.loanStatus = opt.desc;
        this.targetInfo.loanStatusID = opt.id;
        this.mainService.showSuccessModal('Good Job!', 'Cash Advance Status Updated.')
      }
    },
      (err: any) => {
        console.error(err);
        this.mainService.dismissLoading();
      }
    );
  }

  updateConcession(){
    this.mainService.showLoading();
    var obj = {
      TargetGuid: this.ids.targetGuid,
      CampaignGuid: this.ids.campaignGuid,
      CustomerGuid: this.ids.customerGuid,
      IsConcession: this.targetInfo.isConcession == 'True'
    }
    var json = JSON.stringify(obj);
    this.apiService.post('csr/set-concession', json)
    .subscribe((res: any) => {
      this.reloadDashboard();
      this.mainService.dismissLoading();
      if (res){
        this.targetInfo.isConcession = this.targetInfo.isConcession == 'True' ? 'False' : 'True';
        this.mainService.showSuccessModal('Good Job!', 'Concession updated');
      }
    },
      (err: any) => {
        console.error(err);
        this.mainService.dismissLoading();
        this.mainService.showSuccessModal('Ops!', 'Error Occured');
      }
    );
  }

  reloadDashboard() {
    this.dashboard.emitReload();
    this.getTargetInfo();
    this.getLookupData();
  }

  setPopOvers(){
    this.popItems = [];
    this.popItems.push(`Loan Number: ${this.targetInfo.loanNumber}`);
    this.popItems.push(`TargetLoanGUID: ${this.ids.targetGuid}`);
    this.popItems.push(`Campaign ID: ${this.targetInfo.campaignID}`);
    this.popItems.push(`Target ID: ${this.targetInfo.targetID}`);
    this.popItems.push(`NLSAcctRefNo: ${this.targetInfo.acctrefno}`);
    this.popItems.push(`Invitation Code: ${this.targetInfo.invitationCode}`);
  }

  ngOnDestroy(){
    this.dashboard.setTargetInfo(new TargetInfoModel);
  }

  private getStatusChangeDataModel(opt: LookupModel | null | undefined, title: any, subTitle: any = null, needComment = false){
    var data: ConfirmChangeData = {
      opt : opt,
      title: title,
      subTitle: subTitle,
      needComment : needComment
    }
    return data;
  }

}

export class DashBoardHeaderSetting {
  label: string = '';
  type: string = '';
  value: string = '';
}
