<div class="cds-card p-3 mb-2">
    <p-tabMenu [model]="tabMenu" [activeItem]="activeItem"></p-tabMenu>
    <div *ngIf="showSummary" class="grid">
        <div class="col-6">
            <div class="flex flex-wrap pt-4">
                <div class="w-12 font-bold pb-2">Account Snapshot {{accountSummary.last4Account}}</div>
                <div class="w-12 pds-divider-top flex py-2">
                    <div class="w-9">Approved Credit Amount</div>
                    <div class="w-3"
                        [innerHTML]="getMoneyWithAsterik(accountSummary.approvedCreditAmount, accountSummary.asterisk_ApprovedCreditAmount)">
                    </div>
                </div>
                <div class="w-12 pds-divider-top flex py-2">
                    <div class="w-9">Current Principal Balance</div>
                    <div class="w-3">{{getMoney(accountSummary.currentPrincipalBalance)}}</div>
                </div>
                <div class="w-12 pds-divider-top flex py-2">
                    <div class="w-9">Current Fees</div>
                    <div class="w-3">{{getMoney(accountSummary.currentFees)}}</div>
                </div>
                <div class="w-12 pds-divider-top flex py-2">
                    <div class="w-9 font-bold">Total Account Balance</div>
                    <div class="w-3 font-bold">{{getMoney(accountSummary.totalAccountBalance)}}</div>
                </div>

                <div *ngIf="showPastDue || showTotalCurrentDue" class="w-12 pt-6 pb-2 flex flex-wrap">
                    <div *ngIf="showPastDue" class="w-9 pds-divider-top py-2"
                        [style.color]="accountSummary.totalPastDueBalanceColor.length ? accountSummary.totalPastDueBalanceColor : ''">
                        Total Past Due</div>
                    <div *ngIf="showPastDue" class="w-3 pds-divider-top py-2"
                        [style.color]="accountSummary.totalPastDueBalanceColor.length ? accountSummary.totalPastDueBalanceColor : ''">
                        {{getMoney(accountSummary.totalPastDueBalance)}}
                        <span class="pl-2 pi pi-info-circle" (mouseenter)="opPastDue.show($event)"
                            (mouseleave)="opPastDue.hide()" (click)="opPastDue.toggle($event)"></span>
                    </div>
                    <div *ngIf="showTotalCurrentDue" class="w-9 pds-divider-top py-2">
                        Total Amount Due</div>
                    <div *ngIf="showTotalCurrentDue" class="w-3 pds-divider-top py-2">
                        {{getMoney(accountSummary.totalCurrentDueBalance)}}
                        <span class="pl-2 pi pi-info-circle" (mouseenter)="opTotalDue.show($event)"
                            (mouseleave)="opTotalDue.hide()" (click)="opTotalDue.toggle($event)"></span>
                    </div>
                </div>

            </div>
        </div>
        <div class="col-6">
            <div class="flex flex-wrap pt-4">
                <div class="w-12 font-bold pb-2">{{accountSummary.scheduledPaymentsHeader}}</div>
                <div *ngFor="let payment of scheduledPayments; index as i" class="w-12 grid py-2">
                    <div class="col-4 pds-divider-top py-2">{{payment.displayDate}}</div>
                    <div class="col-4 pds-divider-top py-2"
                        [style.color]="payment.descriptionColor.length ? payment.descriptionColor : ''">
                        {{payment.description}}</div>
                    <div class="col-4 pds-divider-top py-2 text-right">
                        {{getMoney(payment.totalAmt)}}
                        <span *ngIf="payment.hideDistribution == '0'" class="pl-2 pi pi-info-circle"
                            (mouseenter)="showUpcoming($event, payment)" (mouseleave)="hideUpcoming()"
                            (click)="toggleUpcoming($event, payment)"></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="flex flex-wrap">
                <div class="w-12 font-bold pt-6 pb-2">Request More Cash</div>
                <div class="w-12 pds-divider-top pds-divider-bottom flex py-2">
                    <div class="w-9">Amount Available</div>
                    <div class="w-3">{{getMoney(accountSummary.amountAvailable)}}</div>
                </div>

                <div class="w-12 pt-6 pb-4" *ngIf="accountSummary.asteriskNote_ApprovedCreditAmount.length > 0">
                    <div [innerHTML]="sanitize(accountSummary.asteriskNote_ApprovedCreditAmount)"></div>
                </div>
            </div>
        </div>

        <div class="col-6" *ngIf="upcomingPayments.length > 0">
            <div class="flex flex-wrap">
                <div class="w-12 font-bold pt-6 pb-2">Pending</div>
                <table class="w-12">
                    <tr *ngFor="let payment of upcomingPayments; index as i" class="pds-divider-top">
                        <td>{{formatDate(payment.date)}}</td>
                        <td>{{payment.description}}</td>
                        <td>{{getMoney(payment.amount)}}</td>
                        <td>
                            <p3solved-button id="btnCancelPay{{i}}" label="Cancel"
                                (buttonClick)="confirmCancelUpcomingPayment(payment);"></p3solved-button>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>

    <div *ngIf="showPayment" class="grid">
        <div class="col-2 pt-5 pl-4">
            <div class="pl-4" (click)="setPayment('Date')" [ngClass]="showDate ? 'pay-tab-selected' : 'pay-tab'">
                Payment Date Change
            </div>
            <div class="pl-4" (click)="setPayment('Standard')"
                [ngClass]="showStandard ? 'pay-tab-selected' : 'pay-tab'">Standard
                Payment
            </div>
            <div class="pl-4" (click)="setPayment('Total')" [ngClass]="showTotal ? 'pay-tab-selected' : 'pay-tab'">
                Total Balance
            </div>
            <div class="pl-4" (click)="setPayment('Other')" [ngClass]="showOther ? 'pay-tab-selected' : 'pay-tab'">
                Other Amount
            </div>
        </div>
        <div *ngIf="showDate" class="col-10 pt-5 pl-4 grid">
            <div class="col-10 flex">
                <div class="pr-4">{{getUSDate(accountSummary.minimumPaymentPushDate) | date:'MM/dd/yyyy' }}</div>
                <div class="pr-4">Minimum Payment Due</div>
                <div class="text-right">
                    {{getMoney(accountSummary.minimumPaymentPushAmount)}}
                    <span class="pl-2 pi pi-info-circle" (mouseenter)="opPushPay.show($event)"
                        (mouseleave)="opPushPay.hide()" (click)="opPushPay.toggle($event)"></span>
                </div>                
            </div>
            <div class="col-12" *ngIf="hasPushPayment && hasFeature('CSR_CUSTOMER_ACCOUNT_SERVICING_MAKE_PAYMENT')">
                <div class="w-4">
                    <p3solved-datepicker id="dtPushPay" [formControl]="pushPayDate" 
                        [minDate]="pushPayMinDate" 
                        [maxDate]="pushPayMaxDate"
                        panelStyleClass="dp-max"
                        [showIcon]="true">
                        <div class="w-12 my-1 p-error text-xs"
                            *ngIf="pushPayDate.touched && pushPayDate.invalid">
                            <p>Date is required</p>
                        </div>
                    </p3solved-datepicker>
                </div>
                <br>
                <p3solved-button id="btnPushPay" label="Confirm Date" 
                    [disabled]="!pushPayDate.valid"
                    (buttonClick)="confirmPushPayDate()"></p3solved-button>
            </div>
        </div>
        <div *ngIf="showStandard || showTotal || showOther" class="col-10 pt-5 pl-4 grid">
            <form class="col-12" *ngIf="payFormLoaded" [formGroup]="payForm" (ngSubmit)="confirmSavePayForm()">
                <div class="col-12">
                    <p3solved-input-number *ngIf="!showTotal" id="txtAmount" formControlName="amount"
                        [disabled]="showStandard">
                        <div class="w-12 my-1 p-error text-xs"
                            *ngIf="payForm.controls.amount.touched && payForm.controls.amount.invalid">
                            <p>Amount must be between {{getMoney('0.01')}} and
                                {{getMoney(this.accountSummary.totalAccountBalance)}}</p>
                        </div>
                    </p3solved-input-number>
                    <span *ngIf="showTotal">{{payForm.value.amount | currency}}</span>
                </div>
                <div class="col-12">
                    <p3solved-datepicker id="dtPay" formControlName="date" 
                        [minDate]="today" [maxDate]="maxPayDate" panelStyleClass="dp-max"
                        [showIcon]="true">
                        <div class="w-12 my-1 p-error text-xs"
                            *ngIf="payForm.controls.date.touched && payForm.controls.date.invalid">
                            <p>Date is required</p>
                        </div>
                    </p3solved-datepicker>
                </div>
                <div class="col-12">
                    <p3solved-dropdown id="ddFrom" [items]="paymentOptions" optionLabel="paymentOptionDescription"
                        optionValue="paymentOptionID" formControlName="payType" [displayFirst]="false"
                        (optionSelected)="payTypeChanged($event)">
                        <div class="w-12 my-1 p-error text-xs"
                            *ngIf="payForm.controls.payType.touched && payForm.controls.payType.invalid">
                            <p>Payment Type is required</p>
                        </div>
                    </p3solved-dropdown>
                </div>
                <div class="col-12 grid" *ngIf="payForm.value.payType == '-2'">
                    <div class="col-12">Enter one-time Routing and Account number</div>
                    <div class="col-6">
                        <p3solved-input-number id="txtRoute" placeholder="Routing Number" formControlName="routeNum"
                            [max]="999999999999999999999999" mode="decimal" [useGrouping]="false">
                            <div class="w-12 my-1 p-error text-xs"
                                *ngIf="payForm.controls.routeNum.touched && payForm.controls.routeNum.invalid">
                                <p>Route Number is required and must be less than 24 digits</p>
                            </div>
                        </p3solved-input-number>
                    </div>
                    <div class="col-6">
                        <p3solved-input-number id="txtAcct" placeholder="Bank Account Number"
                            formControlName="accountNum" mode="decimal" [useGrouping]="false"
                            [max]="999999999999999999999999">
                            <div class="w-12 my-1 p-error text-xs"
                                *ngIf="payForm.controls.accountNum.touched && payForm.controls.accountNum.invalid">
                                <p>Bank Account Number is required and must be less than 24 digits</p>
                            </div>
                        </p3solved-input-number>
                    </div>

                </div>
                <div class="col-12 grid" *ngIf="payForm.value.payType == '-3'">
                    <div class="col-12">Enter one-time Card Info</div>
                    <div class="col-6">
                        <p3solved-form-text id="txtCardNumber" placeholder="Card Number" formControlName="debitNum">
                            <div class="w-12 my-1 p-error text-xs"
                                *ngIf="payForm.controls.debitNum.touched && payForm.controls.debitNum.invalid">
                                <p>Card Number is required</p>
                            </div>
                        </p3solved-form-text>
                    </div>
                    <div class="col-2">
                        <p3solved-input-mask id="txtExpDate" placeholder="Exp Date" formControlName="expDate"
                            [autoClear]="true" mask="99/99">
                            <div class="w-12 my-1 p-error text-xs"
                                *ngIf="payForm.controls.expDate.touched && payForm.controls.expDate.invalid">
                                <p>Exp Date is required</p>
                            </div>
                        </p3solved-input-mask>
                    </div>
                    <div class="col-2">
                        <p3solved-form-text id="txtCVV" placeholder="CVV" formControlName="cvv" [maxlength]="3">
                            <div class="w-12 my-1 p-error text-xs"
                                *ngIf="payForm.controls.cvv.touched && payForm.controls.cvv.invalid">
                                <p>CVV is required</p>
                            </div>
                        </p3solved-form-text>
                    </div>
                    <div class="col-2">
                        <p3solved-form-text id="txtZipCode" placeholder="Zip Code" formControlName="zip"
                            [maxlength]="5">
                            <div class="w-12 my-1 p-error text-xs"
                                *ngIf="payForm.controls.zip.touched && payForm.controls.zip.invalid">
                                <p>Zip Code is required</p>
                            </div>
                        </p3solved-form-text>
                    </div>
                </div>
                <div class="col-4">
                    <p3solved-button id="btnSubmit" buttonType="submit" label="Confirm Payment"
                        [disabled]="!payForm.valid"></p3solved-button>
                </div>
            </form>
        </div>

        <div *ngIf="showPayPastDue" class="grid">
            <div class="col-6 pt-4 flex flex-wrap ">
                <div class="w-9 py-2">
                    <span
                        [style.color]="accountSummary.totalPastDueBalanceColor.length > 0 ? accountSummary.totalPastDueBalanceColor : ''">Total
                        Past Due</span>
                </div>
                <div class="w-3 py-2">
                    <span
                        [style.color]="accountSummary.totalPastDueBalanceColor.length > 0 ? accountSummary.totalPastDueBalanceColor : ''">{{getMoney(accountSummary.totalPastDueBalance)}}</span>
                </div>
                <div class="w-9 py-2">Total Amount Due</div>
                <div class="w-3 py-2">{{getMoney(accountSummary.totalCurrentDueBalance)}}</div>
            </div>
        </div>

    </div>

    <div *ngIf="showHistory" class="grid">
        <div class="col-12 pt-4">
            <app-custom-grid styleClass="p-datatable-sm p-datatable-gridlines mr-4 mb-4" [ActivatedIds]="ids"
                [DataSourceName]="transActHistDSName" [GridTitle]="'Transaction History'" [AllowPaging]="true"></app-custom-grid>
        </div>
    </div>

    <div *ngIf="showRewards" class="grid pt-4 pl-4">
        <div class="col-4 pt-6">
            <div class="bg-indigo text-white grid p-4">
                <table class="w-full text-white">
                    <tr>
                        <td>Tier Level:</td>
                        <td class="text-right">{{rewardInfo.rewardsAdjustmentTierLevel}}</td>
                    </tr>
                    <tr>
                        <td>Point Balance:</td>
                        <td class="text-right">{{rewardInfo.rewardsAdjustmentPointBalance}}</td>
                    </tr>
                    <tr>
                        <td>Pending Points:</td>
                        <td class="text-right">{{rewardInfo.rewardsAdjustmentPendingPoints}}</td>
                    </tr>
                    <tr>
                        <td>Points until next Tier:</td>
                        <td class="text-right">
                            <div
                                class="css-bar css-bar-{{percentageNextTier}} css-bar-success-green text-center relative">
                                <div class="absolute z-4 w-full pt-2 flex flex-wrap">
                                    <i class="w-12 mt-2 bi bi-trophy"></i>
                                    <span class="w-12 mt-3 text-sm">{{pointUntilNextTier}}</span>
                                </div>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="col-8 pl-2">
            <app-custom-grid styleClass="p-datatable-sm p-datatable-gridlines mr-4 mb-4" [ActivatedIds]="ids"
                DataSourceName="dsCSRAccountServicingRewards" GridTitle="" [AllowPaging]="true"></app-custom-grid>
        </div>
    </div>

</div>

<div class="cds-card p-3 mb-2" *ngIf="showHistory">
    <div class="grid">
        <div class="col-12 text-2xl pt-4">Statements</div>
        <div class="col-12 pt-2">All dates are statements end dates.</div>
        <div class="col-12 pt-2 flex-column flex-wrap columns-4">
            <div class="flex py-1" *ngFor="let statement of targetLoanStatements; index as i">
                <span class="cds-text-color cursor-pointer no-underline"
                    [innerHTML]="getStatementEndDate(targetLoanStatements[i].stmtEndDateUS, targetLoanStatements[i].pDFUrl)"></span>
                <span class="cds-text-color cursor-pointer pl-2 no-underline"
                    [innerHTML]="getStatementEndDateCSR(targetLoanStatements[i].cSRPDFUrl)"></span>
            </div>

            <!-- 
          <p-table [value]="targetLoanStatements" styleClass="mr-4" [rowHover]="true" [paginator]="true"
              [rowsPerPageOptions]="[15, 30, 50, 100]" [rows]="rows" [(first)]="first" [showCurrentPageReport]="false"
              responsiveLayout="scroll">
              <ng-template pTemplate="body" let-h>
                  <tr>
                      <td [innerHTML]="getStatementEndDate(h.stmtEndDateUS, h.pDFUrl)"></td>
                      <td [innerHTML]="getStatementEndDateCSR(h.cSRPDFUrl)"></td>
                  </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                  <tr>
                      <td [colSpan]="2">No Statements found.</td>
                  </tr>
              </ng-template>
          </p-table> -->
        </div>
    </div>
</div>

<p-overlayPanel #opPastDue [showCloseIcon]="false" [showTransitionOptions]="'0.3s'" styleClass="past-due">
    <ng-template pTemplate>
        <div class="w-12 flex justify-content-center px-2 font-medium">Minimum Payment Details</div>
        <div class="grid">
            <div class="col-10">Principal</div>
            <div class="col-2">{{getMoney(accountSummary.totalPastDue_PPal)}}</div>

            <div class="col-10">Cash Advance Fee</div>
            <div class="col-2">{{getMoney(accountSummary.totalPastDue_CAF)}}</div>

            <div class="col-10">Fixed Finance Charge</div>
            <div class="col-2">{{getMoney(accountSummary.totalPastDue_FFC)}}</div>
        </div>
    </ng-template>
</p-overlayPanel>

<p-overlayPanel #opTotalDue [showCloseIcon]="false" [showTransitionOptions]="'0.3s'" styleClass="past-due">
    <ng-template pTemplate>
        <div class="w-12 flex justify-content-center px-2 font-medium">Minimum Payment Details</div>
        <div class="grid">
            <div class="col-10">Principal</div>
            <div class="col-2">{{getMoney(accountSummary.totalCurrentDue_PPal)}}</div>

            <div class="col-10">Cash Advance Fee</div>
            <div class="col-2">{{getMoney(accountSummary.totalCurrentDue_CAF)}}</div>

            <div class="col-10">Fixed Finance Charge</div>
            <div class="col-2">{{getMoney(accountSummary.totalCurrentDue_FFC)}}</div>
        </div>
    </ng-template>
</p-overlayPanel>

<p-overlayPanel #opUpcoming [showCloseIcon]="false" [showTransitionOptions]="'0.3s'" styleClass="past-due">
    <ng-template pTemplate>
        <div class="w-12 flex justify-content-center px-2 font-medium">Minimum Payment Details</div>
        <div class="grid">
            <div class="col-10">Principal</div>
            <div class="col-2">{{getMoney(upcoming_PPal)}}</div>

            <div class="col-10">Cash Advance Fee</div>
            <div class="col-2">{{getMoney(upcoming_CAF)}}</div>

            <div class="col-10">Fixed Finance Charge</div>
            <div class="col-2">{{getMoney(upcoming_FFC)}}</div>
            <div class="col-12" *ngIf="hintMessage.length > 0">
                {{hintMessage}}
            </div>
        </div>
    </ng-template>
</p-overlayPanel>

<p-overlayPanel #opPushPay [showCloseIcon]="false" [showTransitionOptions]="'0.3s'" styleClass="past-due">
    <ng-template pTemplate>
        <div class="w-12 flex justify-content-center px-2 font-medium">Minimum Payment Details</div>
        <div class="grid">
            <div class="col-10">Principal</div>
            <div class="col-2">{{getMoney(accountSummary.minimumPaymentPushAmountPrincipal)}}</div>

            <div class="col-10">Cash Advance Fee</div>
            <div class="col-2">{{getMoney(accountSummary.minimumPaymentPushAmountCAF)}}</div>

            <div class="col-10">Fixed Finance Charge</div>
            <div class="col-2">{{getMoney(accountSummary.minimumPaymentPushAmountFCC)}}</div>

            <div class="col-12" *ngIf="!pushPaymentAvail">
                <span class="text-error">Cycle has not yet billed.</span>
            </div>
        </div>
    </ng-template>
</p-overlayPanel>


<p-confirmDialog key="{{makePaymentConfirmKey}}" [style]="{width: '25vw'}" [baseZIndex]="10000">
</p-confirmDialog>