<app-dashboard-header></app-dashboard-header>
<div class="col-5 align-self-center">
    <div class="flex flex-column">
        <div class="text-xl pl-3">Payment Calculator</div>
        <p-breadcrumb [model]="bcItems"></p-breadcrumb>
    </div>
</div>
<div class="grid">
    <div class="col-12 lg:col-8">
        <div class="cds-card p-3">
            <div class="grid">
                <div class="col-12 m-2">
                    <p3solved-dropdown id="ddFees" placeholder="Payment Calculator" 
                        [items]="fees" optionLabel="templateName" optionValue="paymentCalculatorIframeSRC"
                        [(ngModel)]="selectedCalc"
                        (optionSelected)="feeCalcChanged($event)">
                    </p3solved-dropdown>
                </div>
                <div class="col-12 h-250rem">
                    <iframe [src]="getSanitizedURL()" frameborder="0" scrolling="yes" style="height: 100%; width: 100%; border: 0"></iframe>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 lg:col-4">
        <app-customer-notes #custNotes></app-customer-notes>
    </div>
</div>
