<div class="cds-card p-3">
    <mat-card-content>
        <p class="h4 ml-2 my-2">Contact</p>
        <form [formGroup]="contactForm" class="p-3">
            <div class="row">
                <div class="col-7">
                    <span>Email Communications</span>
                </div>
                <div class="col-5">
                    <span>OFF   </span>
                    <mat-slide-toggle (change)="showConfirm($event)" color="accent" formControlName="marketingEmailMe" name="email" class="cc-toggle">ON</mat-slide-toggle>
                </div>
            </div>
            <div class="row">
                <div class="col-7">
                    <span>Text Communications</span>
                </div>
                <div class="col-5">
                    <span>OFF   </span>
                    <mat-slide-toggle (change)="showConfirm($event)" color="accent" formControlName="marketingTextMe" name="text" class="cc-toggle">ON</mat-slide-toggle>
                </div>
            </div>
            <div class="row">
                <div class="col-7">
                    <span>Phone Communications</span>
                </div>
                <div class="col-5">
                    <span>OFF   </span>
                    <mat-slide-toggle (change)="showConfirm($event)" color="accent" formControlName="marketingPhoneMe" name="phone" class="cc-toggle">ON</mat-slide-toggle>
                </div>
            </div>
            <div class="row">
                <div class="col-7">
                    <span>Auto-Dial Communications</span>
                </div>
                <div class="col-5">
                    <span>OFF   </span>
                    <mat-slide-toggle (change)="showConfirm($event)" color="accent" formControlName="autoDial" name="auto_dial" class="cc-toggle">ON</mat-slide-toggle>
                </div>
            </div>
            <div class="row">
                <div class="col-7">
                    <span>Opt out of Share</span>
                </div>
                <div class="col-5">
                    <span>OFF   </span>
                    <mat-slide-toggle (change)="showConfirm($event)" color="accent" formControlName="shareNonpublic" name="share" class="cc-toggle">ON</mat-slide-toggle>
                </div>
            </div>
            <div class="row">
                <div class="col-7">
                    <span>Opt out of Arbitration</span>
                </div>
                <div class="col-5">
                    <span>OFF   </span>
                    <mat-slide-toggle (change)="showConfirm($event)" color="accent" formControlName="arbitration" name="arbitration" class="cc-toggle">ON</mat-slide-toggle>
                </div>
            </div>
            <div class="row">
                <div class="col-7">
                    <span>Receive Paper Statement</span>
                </div>
                <div class="col-5">
                    <span>OFF   </span>
                    <mat-slide-toggle (change)="showConfirm($event)" color="accent" formControlName="marketingDirectMailMe" name="direct_mail" class="cc-toggle">ON</mat-slide-toggle>
                </div>
            </div>
        </form>
    </mat-card-content>
</div>

<ng-template #modalContent let-modal>
    <div class="modal-content">
        <div class="row">
            <div class="col-12 text-right">
                <button class="btn float-end" (click)="modal.close('x')">X</button>
            </div>
        </div>
        <hr/>
        <div class="row">
            <h1><strong>Contact Setting Change</strong></h1>
        </div>
        <div class="row">
            <p>Confirm the contact setting change.</p>
        </div>

        <div class="row">
            <div class="col-7 text-right">
                <span class="text-right">{{contactSetting}}:</span>
            </div>
            <div class="col-5">
                <span><strong>{{value}}</strong></span>
            </div>
        </div>
        <div class="center">
            <div class="row">
                <button class="btn btn-success btn-center shadow-none" (click)="modal.close('confirm')"><strong>CONFIRM</strong></button>
            </div>
            <div class="row">
                <button class="btn btn-center" (click)="modal.close('cancel')">Back</button>
            </div>
        </div>
    </div>
</ng-template>



<!-- <mat-card class="mat-card-sm my-2">
    <mat-card-content>
        <p class="h4 ml-2 my-2">Contact</p>
        <form [formGroup]="contactForm">
            <div class="row">
                <div class="col-7">
                    <span>Email Communications</span>
                </div>
                <div class="col-5">
                    <span>OFF   </span>
                    <mat-slide-toggle (change)="showConfirm($event)" color="accent" formControlName="marketingEmailMe" name="email" class="cc-toggle">ON</mat-slide-toggle>
                </div>
            </div>
            <div class="row">
                <div class="col-7">
                    <span>Text Communications</span>
                </div>
                <div class="col-5">
                    <span>OFF   </span>
                    <mat-slide-toggle (change)="showConfirm($event)" color="accent" formControlName="marketingTextMe" name="text" class="cc-toggle">ON</mat-slide-toggle>
                </div>
            </div>
            <div class="row">
                <div class="col-7">
                    <span>Phone Communications</span>
                </div>
                <div class="col-5">
                    <span>OFF   </span>
                    <mat-slide-toggle (change)="showConfirm($event)" color="accent" formControlName="marketingPhoneMe" name="phone" class="cc-toggle">ON</mat-slide-toggle>
                </div>
            </div>
            <div class="row">
                <div class="col-7">
                    <span>Auto-Dial Communications</span>
                </div>
                <div class="col-5">
                    <span>OFF   </span>
                    <mat-slide-toggle (change)="showConfirm($event)" color="accent" formControlName="autoDial" name="auto_dial" class="cc-toggle">ON</mat-slide-toggle>
                </div>
            </div>
            <div class="row">
                <div class="col-7">
                    <span>Opt out of Share</span>
                </div>
                <div class="col-5">
                    <span>OFF   </span>
                    <mat-slide-toggle (change)="showConfirm($event)" color="accent" formControlName="shareNonpublic" name="share" class="cc-toggle">ON</mat-slide-toggle>
                </div>
            </div>
            <div class="row">
                <div class="col-7">
                    <span>Opt out of Arbitration</span>
                </div>
                <div class="col-5">
                    <span>OFF   </span>
                    <mat-slide-toggle (change)="showConfirm($event)" color="accent" formControlName="arbitration" name="arbitration" class="cc-toggle">ON</mat-slide-toggle>
                </div>
            </div>
            <div class="row">
                <div class="col-7">
                    <span>Receive Paper Statement</span>
                </div>
                <div class="col-5">
                    <span>OFF   </span>
                    <mat-slide-toggle (change)="showConfirm($event)" color="accent" formControlName="marketingDirectMailMe" name="direct_mail" class="cc-toggle">ON</mat-slide-toggle>
                </div>
            </div>
        </form>
    </mat-card-content>
  </mat-card>

<ng-template #modalContent let-modal>
    <div class="modal-content">
        <div class="row">
            <div class="col-12 text-right">
                <button class="btn float-end" (click)="modal.close('x')">X</button>
            </div>
        </div>
        <hr/>
        <div class="row">
            <h1><strong>Contact Setting Change</strong></h1>
        </div>
        <div class="row">
            <p>Confirm the contact setting change.</p>
        </div>

        <div class="row">
            <div class="col-7 text-right">
                <span class="text-right">{{contactSetting}}:</span>
            </div>
            <div class="col-5">
                <span><strong>{{value}}</strong></span>
            </div>
        </div>
        <div class="center">
            <div class="row">
                <button class="btn btn-success btn-center shadow-none" (click)="modal.close('confirm')"><strong>CONFIRM</strong></button>
            </div>
            <div class="row">
                <button class="btn btn-center" (click)="modal.close('cancel')">Back</button>
            </div>
        </div>
    </div>
</ng-template> -->
