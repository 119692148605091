<app-dashboard-header></app-dashboard-header>
<div class="col-5 align-self-center">
    <div class="flex flex-column">
        <div class="text-xl pl-3">Credit Bureau</div>
        <p-breadcrumb [model]="bcItems"></p-breadcrumb>
    </div>
</div>
<div class="grid">
    <div class="col-12 lg:col-8">
        <div class="cds-card p-3">
            <form *ngIf="bureauFormLoaded" [formGroup]="bureauForm" (ngSubmit)="confirmBureauInfo()">
                <div class="grid">
                    <div class="col-12">
                        <p3solved-dropdown id="ddCommentCode" placeholder="Special Comment Code"
                            [items]="lookups.commentCodes" optionLabel="specialCommentFull"
                            optionValue="creditBureauSpecialCommentID" appendTo="body"
                            formControlName="commentCode" [showClear]="true"></p3solved-dropdown>
                    </div>
                    <div class="col-12">
                        <p3solved-dropdown id="ddConsumerInfo" placeholder="Consumer Information Indicator"
                            [items]="lookups.consumerInfos" optionLabel="consumerInfoIndicatorFull"
                            optionValue="creditBureauConsumerInfoIndicatorID" appendTo="body"
                            formControlName="infoIndic" [showClear]="true"></p3solved-dropdown>
                        </div>
                    <div class="col-12">
                        <p3solved-dropdown id="ddCompliance" placeholder="Compliance Condition Code"
                            [items]="lookups.compliances" optionLabel="complianceFull"
                            optionValue="creditBureauComplianceID" appendTo="body"
                            formControlName="conditionCode" [showClear]="true"></p3solved-dropdown>
                        </div>
                    <div class="col-12 flex">
                        <span class="pr-6 align-self-center">Exclude?</span>
                        <p-selectButton [options]="excludeOps" optionLabel="label" optionValue="value" 
                        formControlName="exclude"></p-selectButton>
                    </div>
                    <div class="col-12">
                        <span class="w-4">
                        <p3solved-button id="btnCreditBconfirm" label="Confirm Credit Bureau Update" buttonType="submit"></p3solved-button>
                        </span>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="col-12 lg:col-4">
        <app-customer-notes #custNotes></app-customer-notes>
    </div>
</div>

<p3solved-dialog [isVisible]="showConfirm" header="Confirm Credit Bureau Settings Update" headerClass="text-2xl"
    [showHeader]="true" [isModal]="true" [isClosable]="true" (HideClick)="showConfirm=false;"
    [style]="{'width': '25vw'}">
    <div body>
       <ul class="pds-list-disc">
            <li *ngFor="let change of bureauChanges; index as i">{{change}}</li>
       </ul>
    </div>
    <div footer class="flex flex-row-reverse">
        <p3solved-button id="btnDialog1Ok" label="Confirm" (buttonClick)="saveBureauInfo()"></p3solved-button>
        <p3solved-button id="btnDialog1Cancel" label="Back" class="secondary" (buttonClick)="showConfirm=false;"></p3solved-button>
    </div>
</p3solved-dialog>