<div class="flex">
    <div class="text-2xl mb-4">Edit "<i>{{category?.messageCategoryName}}</i>" Category Default Email</div>
    <div class="flex-grow-1 text-right">
        <p3solved-button label="Back" class="secondary" (buttonClick)="onBack.emit()"></p3solved-button>
    </div>
</div>

<form *ngIf="categoryFormLoaded" [formGroup]="categoryForm">
    <div class="grid">
        <div class="col-12">
            <p3solved-form-text id="tName" placeholder="Email Subject" formControlName="subject">
                <div class="w-12 my-1 p-error text-xs"
                    *ngIf="categoryForm.controls.subject.touched && categoryForm.controls.subject.errors">
                    <p>Subject is required</p>
                </div>
            </p3solved-form-text>
        </div>
        <div class="col-12 pt-5">
            Email body:
            <p-editor formControlName="body" [style]="{'height': '20rem'}"></p-editor>
            <div class="w-12 my-1 p-error text-xs"
                *ngIf="categoryForm.controls.body.touched && categoryForm.controls.body.errors">
                <p>Email Body is required</p>
            </div>
        </div>
        <div class="col-12" *ngIf="hasFeature('CSR_QUEUE_COMMUNICATION_EDIT_TEMPLATE')">
            <p3solved-button id="btnSaveTemp" label="Save Template" [disabled]="!categoryForm.valid" (buttonClick)="saveDefault()"></p3solved-button>
        </div>
    </div>
</form>