import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CsrLookupModel } from '../models/csr-lookup-model';
import { CustomerInfoLoanModel, CustomerLoanCardInfo } from '../models/customer-info-loan-model';
import { PaymentMethodModel } from '../models/payment-methods';
import { TargetInfoModel } from '../models/target-info-model';
import { ApiService } from './api.service';
import { CustomerService } from './customer.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  private _loanData = new BehaviorSubject<CustomerInfoLoanModel | null>(null);
  private _cardData = new BehaviorSubject<CustomerLoanCardInfo | null>(null);
  private _paymentMethods = new BehaviorSubject<PaymentMethodModel[]>([]);
  private _targetInfo = new BehaviorSubject<TargetInfoModel>(new TargetInfoModel);
  private _lookups = new BehaviorSubject<CsrLookupModel>(new CsrLookupModel);
  private _reloadData = new BehaviorSubject<boolean|null>(null);

  lookups$ = this._lookups.asObservable();
  loanData$ = this._loanData.asObservable();
  cardData$ = this._cardData.asObservable();
  targetInfo$ = this._targetInfo.asObservable();
  paymentMethods$ = this._paymentMethods.asObservable();
  reloadData$ = this._reloadData.asObservable();
  
  constructor(private custService: CustomerService, private apiService: ApiService) { }

  setLoanData(loanData: CustomerInfoLoanModel){
    this._loanData.next(loanData);
  }

  setCardData(cardInfo: CustomerLoanCardInfo | null) {
    this._cardData.next(cardInfo);
  }

  setPaymentMethods(data: PaymentMethodModel[]){
    this._paymentMethods.next(data);
  }

  setTargetInfo(data: TargetInfoModel){
    this._targetInfo.next(data);
  }

  setLookup(data: CsrLookupModel){
    this._lookups.next(data);
  }

  emitReload() {
    this._reloadData.next(true);
  }

}
