import { DataSource } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, ReplaySubject, Subscription } from 'rxjs';
import { QueueSearchModel } from 'src/app/models/queue-search-model';
import { QueueSearchResultModel } from 'src/app/models/queue-search-result-model';
import { ApiService } from 'src/app/services/api.service';
import { CustomerService } from 'src/app/services/customer.service';
import { GuidService } from 'src/app/services/guid.service';
import { MainService } from 'src/app/services/main.service';
import { PortfolioService } from 'src/app/services/portfolio.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {

  rows: number = 15;
  first: number = 0;

  query: QueueSearchModel | null = null;
  customerGuid: string | null | undefined = "";
  searching = true;
  queried = false;
  queues: QueueSearchResultModel[] = [];
  subscriptions: Subscription[] = [];
  // dataSource: MatTableDataSource<QueueSearchResultModel>;
  dataSource = new QueueDataSource(this.queues);
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort: MatSort = new MatSort;
  searchParams: any = {};
  GridTitle: string = "Verification Queue";

  displayedColumns: string[] = ['accountNumber', 'contractLimit', 'firstName', 'lastName', 'phone', 'city', 'state', 'email', 'contractStatus', 'appStatus'];

  constructor(private activatedRoute: ActivatedRoute,
    private mainService: MainService,
    private router: Router,
    private portfolioService: PortfolioService,
    private apiService: ApiService,
    private userService: UserService,
    private guidService: GuidService,
    private customerService: CustomerService) {
    this.portfolioService.setPortfolios();
  }

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe(async paramMap => {
      let cGuid = paramMap.get('customerGuid')?.toString() ?? '';
      if (this.guidService.isValidGuid(cGuid)) {
        this.customerGuid = cGuid;
        this.customerService.initCustomer(this.customerGuid);
        await this.userService.subscribeAccess();
        let data = this.activatedRoute.snapshot.data;
        if (data && data['pageId']) {
          this.userService.checkPageAccess(data['pageId']);
        }
        this.initPage();
      }
    });
  }

  initPage() {
    this.customerService.customerGuid$.subscribe(cust => {
      if (this.customerGuid !== cust)
        this.customerGuid = cust;
    });
    this.mainService.queueSimpleSearchQuery$.subscribe((q: QueueSearchModel | null) => {
      this.query = q;
      if (this.query) {
        this.queried = true;
        setTimeout(() => {
          this.performSearch();
        }, 300);
      }
    });
    setTimeout(() => {
      if (!this.queried && this.searching) {
        this.queried = true;
        this.searching = false;
      }
    }, 2000);
  }

  performSearch() {
    // if (this.searching) return;
    var obj = this.apiService.getUpperCaseModel(this.query);
    this.searching = true;
    if (this.customerGuid) {
      this.apiService.post(`csr/simple-queue-search/${this.customerGuid}`, obj)
        .subscribe((res: any) => {
          this.searching = false;
          this.queues = res || [];
          this.dataSource.setData(this.queues);
        },
          (err: any) => {
            console.error(err);
            this.searching = false;
          }
        );
    }

  }

  selectRow(row: QueueSearchResultModel) {
    var url = `/dashboard/${this.customerGuid}/${row.campaignGuid}/${row.targetGuid}`;
    window.open(url, "_blank");
  }

}

class QueueDataSource extends DataSource<QueueSearchResultModel> {
  private _dataStream = new ReplaySubject<QueueSearchResultModel[]>();

  constructor(initialData: QueueSearchResultModel[]) {
    super();
    this.setData(initialData);
  }

  connect(): Observable<QueueSearchResultModel[]> {
    return this._dataStream;
  }

  disconnect() { }

  setData(data: QueueSearchResultModel[]) {
    this._dataStream.next(data);
  }
}