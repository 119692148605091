import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedIds } from 'src/app/models/activated-ids';
import { LookupModel } from 'src/app/models/lookup-model';
import { GuidService } from 'src/app/services/guid.service';
import { MainService } from 'src/app/services/main.service';

@Component({
  selector: 'app-add-payment-method-modal',
  templateUrl: './add-payment-method-modal.component.html',
  styleUrls: ['./add-payment-method-modal.component.scss']
})
export class AddPaymentMethodModalComponent implements OnInit {

  paymentTypes: LookupModel[] = [{ id: 'debit', desc: 'Debit Card' }, { id: 'credit', desc: 'Credit Card' }, { id: 'ach', desc: 'Bank Account (ACH)' }];
  paymentType = 'debit';
  cardForm: FormGroup = new FormGroup({});
  achForm: FormGroup = new FormGroup({});
  ids: ActivatedIds = {};
  constructor(
    public dialogRef: MatDialogRef<AddPaymentMethodModalComponent>,
    private fb: FormBuilder,
    private guidService: GuidService,
    private main: MainService
  ) {
    this.initForms();
  }

  ngOnInit(): void {

    this.main.activatedIds$.subscribe((ids) => {
      if (ids && ids.targetGuid) {
        if (!this.ids || this.ids.targetGuid !== ids.targetGuid) {
          this.ids = ids;
        }
      }
    });
  }

  initForms() {
    this.cardForm = this.fb.group({
      number: [null, Validators.required],
      exp: [null, Validators.required],
      cvv: [null, Validators.required]
    });
    this.achForm = this.fb.group({
      account: [null, Validators.required],
      routing: [null, Validators.required]
    });
  }

  back() {
    this.dialogRef.close(null);
  }

  confirm() {
    if (this.paymentType == 'debit' || this.paymentType == 'credit') {
      if (!this.cardForm.valid) {
        this.cardForm.markAllAsTouched();
        return;
      }
    }
    if (this.paymentType == 'ach') {
      if (!this.achForm.valid) {
        this.achForm.markAllAsTouched();
        return;
      }
    }

    var model = {
      CustomerGuid: this.ids.customerGuid,
      CampaignGuid: this.ids.campaignGuid,
      TargetGuid: this.ids.targetGuid,
      PaymentMethodGuid: this.guidService.newGuid(),
      PaymentMethodType: this.paymentType,
      AccountNumber: this.achForm.value.account || '0',
      RoutingNumber: this.achForm.value.routing || '0',
      CardNumber: this.cardForm.value.number || '0',
      CardExp: this.cardForm.value.exp || '0',
      CardCvv: this.cardForm.value.cvv || '0'
    }

    this.dialogRef.close(model);
  }

}
