import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-full-screen-loading',
  templateUrl: './full-screen-loading.component.html',
  styleUrls: ['./full-screen-loading.component.scss']
})
export class FullScreenLoadingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
