<div class="cds-card p-3">
  <div *ngIf="loading">
    <p class="text-2xl ml-3 pt-3">Note List</p>
    <p3solved-progress-bar [style]="{ height: '0.5rem' }"></p3solved-progress-bar>
  </div>
  <div class="w-full pt-4" *ngIf="!loading">
    <p-table #dtNotes [value]="notes" styleClass="m-2" [columns]="columns"
      [globalFilterFields]="displayedColumns" [paginator]="true" [rowsPerPageOptions]="[15, 30, 50, 100]"
      [showCurrentPageReport]="true" currentPageReportTemplate="{totalRecords} total records" [(first)]="first"
      responsiveLayout="scroll" [rows]="100" [rowHover]="true">
      <ng-template pTemplate="caption">
        <div class="flex">
          <div class="flex justify-content-start">
            <span class="text-2xl font-medium">Note List</span>
          </div>
          <div class="flex flex-grow-1 justify-content-end">
            <button pButton class="p-button p-button-outlined" 
              (click)="opSearch.toggle($event)">
                <i class="pi pi-search" aria-hidden="true"></i>
            </button>
            <p3solved-button id="btnDownload" class="outline"
              (buttonClick)="dtNotes.paginator=false;dtNotes.exportCSV();dtNotes.paginator=true" icon="pi pi-download">
            </p3solved-button>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <ng-template ngFor let-col [ngForOf]="columns" let-i="index">
            <th *ngIf="col.show && col.sortable" [pSortableColumn]="col.field" class="font-medium" scope="col">
              {{col.header }}
              <p-sortIcon [field]="col.field"></p-sortIcon>
            </th>
            <th *ngIf="col.show && !col.sortable" scope="col">{{ col.header }}</th>
          </ng-template>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-note>
        <tr class="cursor-pointer" (click)="goToApp(note)">
          <td>{{ note.createDate | date: 'MM/dd/yyyy h:mm:ss a'}}</td>
          <td>{{ note.loanNumber }}</td>
          <td>{{ note.customerFullName }}</td>
          <td>{{ note.noteCategoryDescription }}</td>
          <td>{{ note.note }}</td>
          <td>{{ note.userFullName }}</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td [colSpan]="6">No notes found.</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<p-overlayPanel #opSearch [showCloseIcon]="false" [showTransitionOptions]="'0.2s'">
  <ng-template pTemplate="content">
    <div class="flex flex-wrap note-search p-4 max-w-30rem">
      <div class="w-12">
        <div class="note-title-bg py-2 pl-2 font-bold">Search</div>
      </div>
      <div class="w-12 pt-4">Date Range</div>
      <div class="w-12 flex">
        <div class="w-5">
          <p3solved-datepicker id="dtFrom" [(ngModel)]="noteSearchFrom" [disabledDays]="[]"></p3solved-datepicker>
        </div>
        <div class="w-2 align-self-center text-center">
          <span class="mx-2 p-2 note-title-bg">TO</span>
        </div>
        <div class="w-5">
          <p3solved-datepicker id="dtTo" [(ngModel)]="noteSearchTo" [disabledDays]="[]"></p3solved-datepicker>
        </div>
      </div>
      <div class="w-12">
        <p3solved-dropdown id="ddSearchAgt" placeholder="Agent" [items]="agents"
          optionLabel="desc" optionValue="id"
          [(ngModel)]="noteSearchAgent" [showClear]="true"></p3solved-dropdown>
      </div>
      <div class="w-12">
        <p3solved-form-text id="keyword" placeholder="Keyword" [(ngModel)]="noteSearchKeyword"></p3solved-form-text>
      </div>
      <div>
        <p3solved-button id="btnSearch" label="Search" icon="pi pi-search" iconPos="left"
          (buttonClick)="opSearch.hide();searchNotes();"></p3solved-button>
      </div>
    </div>        
  </ng-template>
</p-overlayPanel>