<div class="flex">
    <div class="flex">
        <p class="my-3 text-2xl">{{messageSubject}}
            <span *ngIf="showReplyButton" class="pl-3">
                <p3solved-button class="outline" icon="pi pi-reply" title="Reply" (buttonClick)="replyToMsg();"></p3solved-button>
            </span>
        </p>
    </div>
    <div class="flex flex-grow-1 flex-row-reverse">
        <p3solved-button class="secondary" label="Back"
            (buttonClick)="back()">            
        </p3solved-button>
    </div>
</div>
<div>
    <div class="p-4 flex-auto">
        <div class="flex align-items-center">
            <div class="flex-shrink-0">
                <i class="bi bi-person-circle text-5xl text-blue-900" aria-hidden="true"></i>
            </div>
            <div class="flex-grow-1 ml-3">
                <p class="m-0">
                    <strong>
                        {{fromFullName}}
                    </strong>
                    <span>
                        ({{fromEmailOrNumber}})
                    </span>
                </p>
                <p class="m-0">
                    to {{toEmailOrNumber}}
                </p>
            </div>
        </div>
    </div>
    <div class="p-3 flex-auto">
        <div [innerHTML]="emailBody">
        </div>
        <div *ngIf="hasAttachments" class="flex flex-wrap border-top-1 pt-4">
            <span class="w-12 pb-3 text-2xl font-bold"><i class="pi pi-link"></i> Attachments ({{attachmentCount}})</span>
            <div *ngFor="let att of attachments; index as i"
                class="w-12 lg:w-3 m-2 flex cursor-pointer" (click)="getAttachment(att)">
                <img class="attachment-icon" alt="{{att}}" [src]="getAttachemntIcon(att)">
                <span class="attachment-text">{{att}}</span>
            </div>
        </div>
    </div>
</div>