import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { ActivatedIds } from 'src/app/models/activated-ids';
import { CustomerModel } from 'src/app/models/customer-model';
import { ApiService } from 'src/app/services/api.service';
import { CustomerService } from 'src/app/services/customer.service';
import { GuidService } from 'src/app/services/guid.service';
import { HelperService } from 'src/app/services/helper.service';
import { MainService } from 'src/app/services/main.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-communications-page',
  templateUrl: './communications-page.component.html',
  styleUrls: ['./communications-page.component.scss']
})
export class CommunicationsPageComponent implements OnInit {
  
  showCommunications: boolean = true;
  showDocuments: boolean = false;
  tabMenuItems: MenuItem[] = [
    {
      label: 'Communication',      
      command: () => { this.showDocuments = false; this.showCommunications = true; }
    },
    {
      label: 'Documents',
      command: () => { this.showCommunications = false; this.showDocuments = true; }
    }
  ];
  activeItem: MenuItem = {} as MenuItem;
  ids: ActivatedIds = {};
  customer: CustomerModel | null = null;


  constructor(private activatedRoute: ActivatedRoute, 
    private main: MainService,
    private guidService: GuidService,
    private userService: UserService,
    private helperService: HelperService,
    private api: ApiService,
    private customerService: CustomerService) { }

  ngOnInit(): void {
    this.activeItem = this.tabMenuItems[0];
    this.activatedRoute.paramMap.subscribe(async paramMap => {
      let cGuid = paramMap.get('customerGuid')?.toString() ?? '';
      if (this.guidService.isValidGuid(cGuid)) {
        let customerGuid = cGuid;
        this.customerService.initCustomer(customerGuid);
        await this.userService.subscribeAccess();
        let data = this.activatedRoute.snapshot.data;
        if (data && data['pageId']) {
          this.userService.checkPageAccess(data['pageId']);
        }
        this.customerService.customer$.subscribe(cust => {
          this.customer = cust;
          if (cust) {
            this.initPage();
          }
        })
      }
    });
  }

  hasFeature(feature: string, mode: string = 'write') {
    switch (mode) {
      case 'read':
        return this.userService.hasRead(feature);
        break;
      case 'feature': 
        return this.userService.hasFeature(feature);
        break;
      default:
        return this.userService.hasWrite(feature);
        break;
    }
  }

  initPage() {    
    this.main.activatedIds$.subscribe((ids) => {
      if (ids && ids.targetGuid) {
        if (!this.ids || this.ids.targetGuid !== ids.targetGuid) {
          this.ids = ids;
        }
      }
    });
  }

  getBoolean(value: any) {
    return this.helperService.getBoolean(value);
  }


}

export interface MailboxCount {
  inbox: number;
  draft: number;
}
