import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ConfirmationService, ConfirmEventType, MenuItem } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel';
import { ActivatedIds } from 'src/app/models/activated-ids';
import { CSRRewardTierInfo } from 'src/app/models/csr/reward-tier-info';
import { ScheduledPaymentModel } from 'src/app/models/csr/scheduled-payment-model';
import { CSRTargetLoanStatement } from 'src/app/models/csr/target-loan-statement';
import { AccountServicingUpcomingPayment } from 'src/app/models/csr/upcoming-payment-model';
import { PayFormGroup } from 'src/app/models/form-groups';
import { PaymentOptionModel } from 'src/app/models/payment-option';
import { TargetAccountSummary } from 'src/app/models/target-account-summary';
import { ApiService } from 'src/app/services/api.service';
import { HelperService } from 'src/app/services/helper.service';
import { MainService } from 'src/app/services/main.service';
import { ToastService } from 'src/app/services/toast.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-account-servicing-option-two',
  templateUrl: './account-servicing-option-two.component.html',
  styleUrls: ['./account-servicing-option-two.component.scss']
})
export class AccountServicingOptionTwoComponent implements OnInit, OnChanges {

  @Input() accountSummary: TargetAccountSummary = new TargetAccountSummary();
  @Input() scheduledPayments: ScheduledPaymentModel[] = [];
  @Input() paymentOptions: PaymentOptionModel[] = [];
  @Input() upcomingPayments: AccountServicingUpcomingPayment[] = [];
  @Output() refreshData = new EventEmitter();

  @ViewChild('opUpcoming') opUpcoming: OverlayPanel = {} as OverlayPanel;

  tabMenu: MenuItem[] = [];
  activeItem: MenuItem = {} as MenuItem;
  payTabMenu: MenuItem[] = [];
  payActiveItem: MenuItem = {} as MenuItem;
  today: Date = new Date();
  pushPayDate: FormControl<Date | null> = new FormControl<Date | null>(null, { nonNullable: true, validators: [Validators.required] });
  pushPayMinDate: Date = new Date();
  pushPayMaxDate: Date = new Date();
  maxPayDate: Date | null = null;

  hasPushPayment: boolean = false;
  achEnabled: boolean = false;
  showSummary: boolean = false;
  showPayment: boolean = false;
  showHistory: boolean = false;
  showRewards: boolean = false;
  showTotalCurrentDue: boolean = false;
  showPastDue: boolean = false;
  pushPaymentAvail: boolean = false;
  showPayPastDue: boolean = false;
  showDate: boolean = false;
  showStandard: boolean = false;
  showTotal: boolean = false;
  showOther: boolean = false;
  payFormLoaded: boolean = false;

  showConfirmPayment: boolean = false;
  showConfirmPayment1: boolean = false;

  payForm: FormGroup<PayFormGroup> = {} as FormGroup;

  transActHistDSName: string = 'dsMBLCSRAccountServicingTransactionHistory';
  upcoming_PPal: string = '';
  upcoming_CAF: string = '';
  upcoming_FFC: string = '';
  hintMessage: string = '';
  makePaymentConfirmKey: string = 'csMakePayConfirmKey';

  ids: ActivatedIds = {};
  rewardInfo: CSRRewardTierInfo = {} as CSRRewardTierInfo;
  pointUntilNextTier: number = 0;
  targetLoanStatements: CSRTargetLoanStatement[] = [];

  rows: number = 15;
  first: number = 0;
  activeIndex: number = 0;
  percentageNextTier: number = 0;

  private parser = new DOMParser();

  currencyPipe = new CurrencyPipe('en-US');
  datePipe = new DatePipe('en-US');
  changesRun: boolean = true;
  constructor(
    private sanitizer: DomSanitizer,
    private main: MainService,
    private apiService: ApiService,
    private confirmService: ConfirmationService,
    private toastService: ToastService,
    private helperService: HelperService,
    private userService: UserService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.accountSummary.firstChange || !changes.paymentOptions.firstChange
      || !changes.scheduledPayments.firstChange || !changes.upcomingPayments.firstChange) {
      this.changesRun = false;
      setTimeout(() => {
        if (!this.changesRun) {
          this.changesRun = true;
          this.getHistoryStatements();
          this.getRewardInfo();

          let payTab = this.showStandard ? 'Standard' :
            this.showTotal ? 'Total' :
              this.showOther ? 'Other' : 'Date';

          let tab = this.showPayment ? 'Payment' :
            this.showHistory ? 'History' :
              this.showRewards ? 'Rewards' : 'Summary';

          if (tab.length > 0) this.showTab(tab);
          if (payTab.length > 0) this.setPayment(payTab);
        }
      }, 500);
    }
  }

  ngOnInit(): void {
    this.hasPushPayment = this.helperService.getBoolean(this.accountSummary.pushPaymentAvailable);
    this.pushPayMinDate = new Date(this.accountSummary.pushPaymentMinDate);
    this.pushPayMaxDate = new Date(this.accountSummary.pushPaymentMaxDate);
    this.achEnabled = this.helperService.getBoolean(this.accountSummary.aCHEnabled);
    this.main.activatedIds$.subscribe((ids) => {
      if (ids && ids.targetGuid) {
        if (!this.ids || this.ids.targetGuid !== ids.targetGuid) {
          this.ids = ids;
          this.getHistoryStatements();
          this.getRewardInfo();
        }
      }
    }, (err) => { console.error(err) });

    this.tabMenu = [
      { label: 'AccountSummary', command: () => { this.showTab('Summary'); } },
      { label: 'Make a Payment', command: () => { this.showTab('Payment'); } },
      { label: 'Transaction History', command: () => { this.showTab('History'); } },
      { label: 'Rewards', command: () => { this.showTab('Rewards'); } }
    ];

    this.payActiveItem = this.payTabMenu[0];
    this.activeItem = this.tabMenu[0];
    this.showSummary = true;

    this.showPastDue = this.accountSummary.hideTotalPastDueSection == "0" || this.accountSummary.hideTotalPastDueSection.toLowerCase() == 'false';
    this.showTotalCurrentDue = this.accountSummary.hideTotalCurrentDueSection == "0" || this.accountSummary.hideTotalCurrentDueSection.toLowerCase() == 'false';
    this.pushPaymentAvail = this.accountSummary.pushPaymentAvailable == "1";
    this.showPayPastDue = this.accountSummary.daysPastDue.length > 0 ? +this.accountSummary.daysPastDue > 0 : false;

    this.showConfirmPayment = this.accountSummary.showConfirmPayment == "1" || this.accountSummary.showConfirmPayment.toLowerCase() == 'true';
    this.showConfirmPayment1 = this.accountSummary.showConfirmPayment1 == "1" || this.accountSummary.showConfirmPayment1.toLowerCase() == 'true';

  }

  showTab(tabName: string) {
    this.activeIndex = 0;
    this.showSummary = false;
    this.showPayment = false;
    this.showHistory = false;
    this.showRewards = false;
    switch (tabName) {
      case 'Summary':
        this.showSummary = true;
        this.payFormLoaded = false;
        break;
      case 'Payment':
        this.showPayment = true;
        this.setPayment('Date');
        this.payFormLoaded = false;
        break;
      case 'History':
        this.showHistory = true;
        this.payFormLoaded = false;
        break;
      case 'Rewards':
        this.showRewards = true;
        this.payFormLoaded = false;
        break;
    }
  }

  setPayment(tab: string) {
    this.payFormLoaded = false;
    this.showDate = false;
    this.showStandard = false;
    this.showTotal = false;
    this.showOther = false;
    this.initPayForm(tab);
    switch (tab) {
      case 'Date':
        this.showDate = true;
        break;
      case 'Standard':
        this.showStandard = true;
        this.maxPayDate = new Date(this.accountSummary.standardPaymentMaxDate);
        this.payForm.patchValue({
          amount: this.accountSummary.minimumPaymentAmount.length > 0 ? +this.accountSummary.minimumPaymentAmount : 0
        });
        break;
      case 'Total':
        this.showTotal = true;
        this.maxPayDate = new Date(this.accountSummary.totalBalancePaymentMaxDate);
        this.payForm.patchValue({
          amount: this.accountSummary.totalAccountBalance.length > 0 ? +this.accountSummary.totalAccountBalance : 0
        });
        break;
      case 'Other':
        this.showOther = true;
        this.maxPayDate = new Date(this.accountSummary.otherAmountPaymentMaxDate);
        let maxAmt = this.accountSummary.totalAccountBalance.length > 0 ? +this.accountSummary.totalAccountBalance : 0
        this.payForm.controls.amount.addValidators([Validators.max(maxAmt)]);
        break;
    }
  }

  initPayForm(tab: string) {
    if (tab == 'Date') {
      this.payForm = {} as FormGroup;
      return;
    }

    this.payForm = new FormGroup<PayFormGroup>({
      amount: new FormControl<number>(0, { nonNullable: true, validators: [Validators.required, Validators.min(0.01)] }),
      date: new FormControl<Date | null>(null, { nonNullable: true, validators: [Validators.required] }),
      payType: new FormControl<string | null>(null, { nonNullable: true, validators: [Validators.required] }),
      routeNum: new FormControl<string | null>(null, { nonNullable: true }),
      accountNum: new FormControl<string | null>(null, { nonNullable: true }),
      debitNum: new FormControl<string>('', { nonNullable: true }),
      expDate: new FormControl<string>('', { nonNullable: true }),
      cvv: new FormControl<string>('', { nonNullable: true }),
      zip: new FormControl<string>('', { nonNullable: true }),
    });

    this.payFormLoaded = true;
  }

  getHistoryStatements() {
    if (this.ids.customerGuid && this.ids.campaignGuid && this.ids.targetGuid) {
      this.apiService.get(`csr/target-loan-statements/${this.ids.customerGuid}/${this.ids.campaignGuid}/${this.ids.targetGuid}`)
        .subscribe((res: CSRTargetLoanStatement[]) => {

          this.targetLoanStatements = res;
        },
          (err: any) => {
            console.error(err);
          }
        );
    }
  }

  getRewardInfo() {
    if (this.ids.customerGuid && this.ids.campaignGuid && this.ids.targetGuid) {
      this.apiService.get(`csr/reward-info/${this.ids.customerGuid}/${this.ids.campaignGuid}/${this.ids.targetGuid}`)
        .subscribe((res: CSRRewardTierInfo) => {
          this.rewardInfo = res;
          let currTierPoints = isNaN(+res.rewardsAdjustmentCurrentTierPoints) ? 0 : +res.rewardsAdjustmentCurrentTierPoints;
          let nextTierPoints = isNaN(+res.rewardsAdjustmentNextTierPoints) ? 0 : +res.rewardsAdjustmentNextTierPoints;
          let adjustPointsBalance = isNaN(+res.rewardsAdjustmentPointBalance) ? 0 : +res.rewardsAdjustmentPointBalance;
          let pointUntilNextTier = nextTierPoints - adjustPointsBalance;

          this.percentageNextTier = 100;

          if (nextTierPoints > currTierPoints) {
            let tmp = Math.floor(1 * (adjustPointsBalance - currTierPoints) / (nextTierPoints - currTierPoints)) * 100;
            this.percentageNextTier = Math.floor(tmp / 5) * 5;
            this.percentageNextTier = this.percentageNextTier <= 100 ? this.percentageNextTier : 100;
          }
          else {
            pointUntilNextTier = 0;
          }
          this.pointUntilNextTier = pointUntilNextTier;

        },
          (err: any) => {
            console.error(err);
          }
        );
    }
  }

  getUSDate(dt: string): Date {
    let tempDt: Date = new Date();
    if (dt && dt.length > 0) {
      tempDt = new Date(dt);
    }
    return tempDt;
  }

  formatDate(dt: string): string {
    let tempDt: Date = new Date(dt);
    let fmtDt = this.datePipe.transform(tempDt, 'MM/dd/yyy');
    return fmtDt ?? '';
  }

  getStatementEndDate(endDate: string, pdf: string) {
    if (pdf && pdf.length > 0) {
      let link = `<a href="${pdf}" class="no-underline" target="_blank">${endDate}</a>`;
      return this.sanitizer.bypassSecurityTrustHtml(link);
    }
    else {
      return endDate;
    }
  }

  getStatementEndDateCSR(pdf: string) {
    if (pdf && pdf.length > 0) {
      let link = `<a href="${pdf}" class="no-underline ml-1" target="_blank" title="Download PDF"><i class="pi pi-download"></i></a>`;
      return this.sanitizer.bypassSecurityTrustHtml(link);
    }
    else return "";
  }

  getMoney(value: string): string {
    let retVal: string = "";
    let amt: number = 0;
    if (value && value.length > 0 && !isNaN(+value)) {
      amt = +value;
    }
    retVal = amt.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    return retVal;
  }

  getMoneyWithAsterik(value: string, asterik: string): string | SafeHtml {
    let retVal: string = this.getMoney(value);
    if (asterik.length > 0) {
      retVal += ` ${asterik}`;
    }
    return this.sanitize(retVal);
  }

  sanitize(value: string): string | SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }

  hideUpcoming() {
    if (this.opUpcoming) {
      this.opUpcoming.hide();
    }
  }

  toggleUpcoming($event: MouseEvent, pmt: ScheduledPaymentModel) {
    this.hintMessage = pmt.hintMessage.length ? pmt.hintMessage : '';
    this.upcoming_CAF = pmt.cAF;
    this.upcoming_FFC = pmt.fCC;
    this.upcoming_PPal = pmt.principal;
    if (this.opUpcoming) {
      this.opUpcoming.toggle($event);
    }
  }

  showUpcoming($event: MouseEvent, pmt: ScheduledPaymentModel) {
    this.hintMessage = pmt.hintMessage.length ? pmt.hintMessage : '';
    this.upcoming_CAF = pmt.cAF;
    this.upcoming_FFC = pmt.fCC;
    this.upcoming_PPal = pmt.principal;
    if (this.opUpcoming) {
      this.opUpcoming.show($event);
    }
  }

  payTypeChanged(payTypeId: string) {
    this.payForm.controls.routeNum.clearValidators();
    this.payForm.controls.accountNum.clearValidators();
    this.payForm.controls.debitNum.clearValidators();
    this.payForm.controls.expDate.clearValidators();
    this.payForm.controls.cvv.clearValidators();
    this.payForm.controls.zip.clearValidators();

    this.payForm.controls.routeNum.markAsUntouched();
    this.payForm.controls.accountNum.markAsUntouched();
    this.payForm.controls.debitNum.markAsUntouched();
    this.payForm.controls.expDate.markAsUntouched();
    this.payForm.controls.cvv.markAsUntouched();
    this.payForm.controls.zip.markAsUntouched();

    this.payForm.controls.routeNum.markAsPristine();
    this.payForm.controls.accountNum.markAsPristine();
    this.payForm.controls.debitNum.markAsPristine();
    this.payForm.controls.expDate.markAsPristine();
    this.payForm.controls.cvv.markAsPristine();
    this.payForm.controls.zip.markAsPristine();

    switch (payTypeId) {
      case '-2':  // Other Bank Account
        this.payForm.controls.routeNum.setValidators([Validators.required]);
        this.payForm.controls.accountNum.setValidators([Validators.required]);
        break;

      case '-3': //  Other Debit Card
        this.payForm.controls.debitNum.setValidators([Validators.required]);
        this.payForm.controls.expDate.setValidators([Validators.required]);
        this.payForm.controls.cvv.setValidators([Validators.required, Validators.minLength(3), Validators.maxLength(3)]);
        this.payForm.controls.zip.setValidators([Validators.required, Validators.minLength(5), Validators.maxLength(5)]);
        break;

      default:
        break;
    }
    this.payForm.patchValue({
      routeNum: null,
      accountNum: null,
      debitNum: '',
      expDate: '',
      cvv: '',
      zip: ''
    });
    this.payForm.updateValueAndValidity();

  }

  async confirmSavePayForm() {
    let amount = this.payForm.value.amount ?? 0;
    let today = new Date();
    let selDate = this.payForm.value.date;

    let displayAmount = this.currencyPipe.transform(amount);
    let displayDate = this.datePipe.transform(selDate, 'MM/dd/yyyy');

    let minPayment = this.accountSummary.minimumPaymentAmount.length > 0 ? +this.accountSummary.minimumPaymentAmount : 0;

    if (this.showOther) {
      if (minPayment > amount) {
        let confirmPartialPay = await this.showPartialPayModal();
        if (!confirmPartialPay) return;
      }
    }

    let title = this.showStandard ? this.accountSummary.standardPaymentMyModalTitle :
      this.showTotal ? this.accountSummary.totalBalancePaymentMyModalTitle :
        this.accountSummary.otherAmountPaymentPartialMyModalTitle;

    let msg = this.showStandard ? this.accountSummary.standardPaymentMyModalDescription :
      this.showTotal ? this.accountSummary.totalBalancePaymentMyModalDescription :
        this.accountSummary.otherAmountPaymentPartialMyModalDescription;

    let doc = this.parser.parseFromString(msg, 'text/html');

    doc.querySelectorAll('.page_amount_selector')
      .forEach(node => {
        node.innerHTML = displayAmount ?? '';
      });
    doc.querySelectorAll('.page_date_selector')
      .forEach(node => {
        node.innerHTML = displayDate ?? '';
      });
    if (this.payForm.value.accountNum && this.payForm.value.accountNum?.length > 0) {
      let bankAcctNum = this.payForm.value.accountNum;
      if (bankAcctNum.length > 4) bankAcctNum = bankAcctNum.substring(bankAcctNum.length - 4);
      doc.querySelectorAll('.page_bankaccount_selector')
        .forEach(node => {
          node.innerHTML = bankAcctNum ?? '';
        });
    }
    if (this.payForm.value.debitNum && this.payForm.value.debitNum?.length > 0) {
      let cardNum = this.payForm.value.debitNum;
      if (cardNum.length > 4) cardNum = cardNum.substring(cardNum.length - 4);
      doc.querySelectorAll('.page_card_selector')
        .forEach(node => {
          node.innerHTML = cardNum ?? '';
        });
    }
    let currDtSel = doc.querySelector('.current_date_selector');
    let pgDtSel = doc.querySelector('.page_date_selector');
    if (currDtSel && pgDtSel && currDtSel.innerHTML == pgDtSel.innerHTML) {
      doc.querySelector('.div_future_date_selector')?.remove();
    }
    else {
      doc.querySelector('.div_current_date_selector')?.remove();
    }

    let confirmPayModal: boolean = false;
    if (this.showStandard) {
      if (this.showConfirmPayment) {
        confirmPayModal = await this.showConfirmPaymentModal();
        if (!confirmPayModal) return;
      }
    }
    else {
      if (this.showConfirmPayment1) {
        confirmPayModal = await this.showConfirmPaymentModal1();
        if (!confirmPayModal) return;
      }
      else if (this.showConfirmPayment) {
        confirmPayModal = await this.showConfirmPaymentModal();
        if (!confirmPayModal) return;
      }

    }

    const serializer = new XMLSerializer();
    let confMsg = serializer.serializeToString(doc);
    this.confirmService.confirm({
      key: this.makePaymentConfirmKey,
      message: confMsg,
      header: title,
      rejectLabel: 'Cancel',
      rejectButtonStyleClass: 'p-button-outlined',
      acceptLabel: 'Confirm',
      accept: () => {
        this.savePayForm();
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.toastService.add({ severity: 'warn', summary: 'Declined', detail: 'Payment will not be submitted.' });
            break;
          case ConfirmEventType.CANCEL:
            this.toastService.add({ severity: 'warn', summary: 'Cancelled', detail: 'Operation cancelled.' });
            break;
        }
      }
    });
  }

  async showPartialPayModal(): Promise<boolean> {
    return new Promise<boolean>(async resolve => {
      this.confirmService.confirm({
        key: this.makePaymentConfirmKey,
        message: this.getPartialPaymentConfirmMsg(),
        header: this.accountSummary.otherAmountPaymentPartialMyModalTitle, //'Partial Payment',
        rejectLabel: 'Back',
        rejectButtonStyleClass: 'p-button-outlined',
        acceptLabel: 'Confirm',
        accept: () => {
          resolve(true);
        },
        reject: (type: any) => {
          switch (type) {
            case ConfirmEventType.REJECT:
              this.toastService.add({ severity: 'warn', summary: 'Declined', detail: 'Payment will not be submitted.' });
              break;
            case ConfirmEventType.CANCEL:
              this.toastService.add({ severity: 'warn', summary: 'Cancelled', detail: 'Operation cancelled.' });
              break;
          }
          resolve(false);
        }
      });
    });
  }

  async showConfirmPaymentModal(): Promise<boolean> {
    return new Promise<boolean>(async resolve => {
      this.confirmService.confirm({
        key: this.makePaymentConfirmKey,
        message: this.accountSummary.myModal_AutoACHConfirmPaymentDescription,
        header: this.accountSummary.myModal_AutoACHConfirmPaymentTitle,
        rejectLabel: 'Back',
        rejectButtonStyleClass: 'p-button-outlined',
        acceptLabel: 'Confirm',
        accept: () => {
          resolve(true);
        },
        reject: (type: any) => {
          switch (type) {
            case ConfirmEventType.REJECT:
              this.toastService.add({ severity: 'warn', summary: 'Declined', detail: 'Payment will not be submitted.' });
              break;
            case ConfirmEventType.CANCEL:
              this.toastService.add({ severity: 'warn', summary: 'Cancelled', detail: 'Operation cancelled.' });
              break;
          }
          resolve(false);
        }
      });
    });
  }

  async showConfirmPaymentModal1(): Promise<boolean> {
    return new Promise<boolean>(async resolve => {
      this.confirmService.confirm({
        key: this.makePaymentConfirmKey,
        message: this.accountSummary.myModal_AutoACHConfirmPaymentDescription1,
        header: this.accountSummary.myModal_AutoACHConfirmPaymentTitle1,
        rejectLabel: 'Back',
        rejectButtonStyleClass: 'p-button-outlined',
        acceptLabel: 'Confirm',
        accept: () => {
          resolve(true);
        },
        reject: (type: any) => {
          switch (type) {
            case ConfirmEventType.REJECT:
              this.toastService.add({ severity: 'warn', summary: 'Declined', detail: 'Payment will not be submitted.' });
              break;
            case ConfirmEventType.CANCEL:
              this.toastService.add({ severity: 'warn', summary: 'Cancelled', detail: 'Operation cancelled.' });
              break;
          }
          resolve(false);
        }
      });
    });
  }

  getPartialPaymentConfirmMsg(): string | undefined {
    let rawMsg = this.parser.parseFromString(this.accountSummary.otherAmountPaymentPartialMyModalDescription, 'text/html');
    let nodeAmt = rawMsg.querySelector('.page_amount_selector');
    if (nodeAmt) {
      let amount = this.currencyPipe.transform(this.payForm.value.amount);
      if (!amount) amount = '';
      nodeAmt.innerHTML = amount;
    }

    let nodeDt = rawMsg.querySelector('.page_date_selector');
    if (nodeDt) {
      let currDt = this.datePipe.transform(this.payForm.value.date, 'MM/dd/yyyy');
      if (!currDt) currDt = '';
      nodeDt.innerHTML = currDt;
    }

    const serializer = new XMLSerializer();
    return serializer.serializeToString(rawMsg);
  }

  confirmCancelUpcomingPayment(payment: AccountServicingUpcomingPayment) {
    let title: string = '';
    let msg: string = '';

    if (payment.isRepresentment == '1') {
      title = 'Cancel Representment';
      msg = `<p>You are requesting to CANCEL the pending Representment. Please confirm below?</p>`;
    }
    else if (payment.isDeposit == '1') {
      title = 'Cancel Deposit';
      msg = `<p>You are requesting to cancel the draw of ${this.getMoney(payment.amount)}. Please confirm below?</p>`;
    }
    else {
      title = 'Cancel Pending Payment';
      msg = `<p>You are requesting to cancel the previously authorized payment of ${this.getMoney(payment.amount)} scheduled to be paid on ${this.formatDate(payment.date)}. Do you wish to continue?</p>`;
    }

    this.confirmService.confirm({
      key: this.makePaymentConfirmKey,
      message: msg,
      header: title,
      rejectLabel: 'Cancel',
      rejectButtonStyleClass: 'p-button-outlined',
      acceptLabel: 'Confirm',
      accept: () => {
        this.cancelUpcomingPayment(payment);
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.toastService.add({ severity: 'warn', summary: 'Declined', detail: 'Payment will not be cancelled.' });
            break;
          case ConfirmEventType.CANCEL:
            this.toastService.add({ severity: 'warn', summary: 'Cancelled', detail: 'Operation cancelled.' });
            break;
        }
      }
    });
  }

  cancelUpcomingPayment(payment: AccountServicingUpcomingPayment) {
    let rowId = payment.row_id.length > 0 ? +payment.row_id : -1;

    if (rowId < 0) {
      this.toastService.addError('Invalid data. Please refresh and try again.');
      return;
    }

    let type: string = '';
    let transType: string = 'payment';
    if (payment.isRepresentment == '1') {
      type = 'Representment';
      transType = type.toLowerCase();
    }
    else if (payment.isDeposit == '1') {
      type = 'Deposit';
      transType = type.toLowerCase();
    }
    else {
      type = 'Payment';
    }

    if (payment.isCardPayment == '1') transType = 'payment_card';

    let body = {
      customerGuid: this.ids.customerGuid ?? '',
      targetGuid: this.ids.targetGuid ?? '',
      campaignGuid: this.ids.campaignGuid ?? '',
      rowId: rowId,
      checkTargetGuid: payment.targetGuid,
      checkCampaignGuid: payment.campaignGuid,
      recordType: payment.isCardPayment == '1' ? 'card' : 'ach'
    };

    let postSub = this.apiService.post(`csr/account-servicing/cancel-upcoming`, body)
      .subscribe({
        next: () => {
          this.refreshData.emit();
          let msg = `We've completed the cancelation of the payment. You should receive an email notification regarding this cancelation.`
          if (transType == 'representment') msg = `We've completed the cancelation of the representment. You should receive an email notification regarding this cancelation.`;
          else if (transType == 'deposit') msg = `We've completed your request to cancel the draw. You should receive an email notification regarding this change.`;
          this.toastService.addSuccess(msg);
        },
        error: (err: any) => {
          this.toastService.addError(`Unable to cancel ${type.toLowerCase()}. See logs for details.`);
          console.error(err);
        },
        complete: () => { postSub.unsubscribe(); }
      });
  }

  savePayForm() {
    let body = {
      customerGuid: this.ids.customerGuid ?? '',
      targetGuid: this.ids.targetGuid ?? '',
      campaignGuid: this.ids.campaignGuid ?? '',
      paymentAmount: this.payForm.value.amount ?? 0,
      paymentDate: this.payForm.value.date ?? null,
      routingNumber: this.payForm.value.routeNum?.toString() ?? '',
      accountNumber: this.payForm.value.accountNum?.toString() ?? '',
      loanCardId: this.payForm.value.payType && +this.payForm.value.payType > 0 ? +this.payForm.value.payType : -1,
      cardNumber: this.payForm.value.debitNum ?? '',
      cardExpDate: this.payForm.value.expDate ?? '',
      cardCVV: this.payForm.value.cvv ?? '',
      cardZipCode: this.payForm.value.zip ?? '',
      nextPaymentAmount: this.accountSummary.nextPaymentAmount.length > 0 ? +this.accountSummary.nextPaymentAmount.length : null,
      nextPaymentDate: this.accountSummary.nextPaymentDate.length > 0 ? new Date(this.accountSummary.nextPaymentDate) : null,
      csrAction: true,
      pageUrl: window.location.href,
    };

    let postSub = this.apiService.post(`csr/account-servicing/make-payment`, body)
      .subscribe({
        next: () => {
          this.refreshData.emit();
          this.showTab('Summary');
          this.toastService.addSuccess('Payment successfully processed.');
        },
        error: (err: any) => {
          this.toastService.addError('Unable to process payment. See logs for details.');
          console.error(err);
        },
        complete: () => { postSub.unsubscribe(); }
      });

  }

  async confirmPushPayDate() {
    let confirmModal1 = await this.showPushPayModal1();
    if (confirmModal1) {
      let confirmModal2 = await this.showPushPayModal2();
      if (confirmModal2) {
        if (this.achEnabled) this.pushPayment();
        else this.showPushPayModal3();
      }
    }
  }

  async showPushPayModal1(): Promise<boolean> {
    return new Promise<boolean>(async resolve => {
      this.confirmService.confirm({
        key: this.makePaymentConfirmKey,
        message: this.getPushPaymentConfirmMsg(this.accountSummary.pushPaymentMyModalDescription1),
        header: this.accountSummary.pushPaymentMyModalTitle1, //'Partial Payment',
        rejectLabel: 'Cancel',
        rejectButtonStyleClass: 'p-button-secondary',
        acceptLabel: 'Next',
        accept: () => {
          resolve(true);
        },
        reject: (type: any) => {
          switch (type) {
            case ConfirmEventType.REJECT:
              this.toastService.add({ severity: 'warn', summary: 'Declined', detail: 'Payment Date will not be updated.' });
              break;
            case ConfirmEventType.CANCEL:
              this.toastService.add({ severity: 'warn', summary: 'Cancelled', detail: 'Operation cancelled.' });
              break;
          }
          resolve(false);
        }
      });
    });
  }

  async showPushPayModal2(): Promise<boolean> {
    return new Promise<boolean>(async resolve => {
      this.confirmService.confirm({
        key: this.makePaymentConfirmKey,
        message: this.getPushPaymentConfirmMsg(this.accountSummary.pushPaymentMyModalDescription2),
        header: this.accountSummary.pushPaymentMyModalTitle2, //'Partial Payment',
        rejectLabel: 'Cancel',
        rejectButtonStyleClass: 'p-button-secondary',
        acceptLabel: 'OK',
        accept: () => {
          resolve(true);
        },
        reject: (type: any) => {
          switch (type) {
            case ConfirmEventType.REJECT:
              this.toastService.add({ severity: 'warn', summary: 'Declined', detail: 'Payment Date will not be updated.' });
              break;
            case ConfirmEventType.CANCEL:
              this.toastService.add({ severity: 'warn', summary: 'Cancelled', detail: 'Operation cancelled.' });
              break;
          }
          resolve(false);
        }
      });
    });
  }

  showPushPayModal3() {
    this.confirmService.confirm({
      key: this.makePaymentConfirmKey,
      message: this.getPushPaymentConfirmMsg(this.accountSummary.pushPaymentMyModalDescription3),
      header: this.accountSummary.pushPaymentMyModalTitle3, //'Partial Payment',
      rejectLabel: 'Cancel',
      rejectButtonStyleClass: 'p-button-secondary',
      acceptLabel: 'OK',
      accept: () => {
        this.pushPayment();
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.toastService.add({ severity: 'warn', summary: 'Declined', detail: 'Payment Date will not be updated.' });
            break;
          case ConfirmEventType.CANCEL:
            this.toastService.add({ severity: 'warn', summary: 'Cancelled', detail: 'Operation cancelled.' });
            break;
        }
      }
    });
  }

  getPushPaymentConfirmMsg(description: string): string | undefined {
    let rawMsg = this.parser.parseFromString(description, 'text/html');
    let nodeDt = rawMsg.querySelector('.page_date_selector');
    if (nodeDt) {
      let currDt = this.datePipe.transform(this.pushPayDate.value, 'MM/dd/yyyy');
      if (!currDt) currDt = '';
      nodeDt.innerHTML = currDt;
    }

    const serializer = new XMLSerializer();
    return serializer.serializeToString(rawMsg);
  }

  pushPayment() {
    let body = {
      customerGuid: this.ids.customerGuid,
      campaignGuid: this.ids.campaignGuid,
      targetGuid: this.ids.targetGuid,
      paymentAmount: +this.accountSummary.minimumPaymentPushAmount,
      paymentDate: new Date(this.accountSummary.minimumPaymentPushDate),
      pushDate: this.pushPayDate.value,
      pageUrl: window.location.href,
    };

    let postSub = this.apiService.post(`csr/push-payment`, body)
      .subscribe({
        next: () => {
          this.refreshData.emit();
          this.toastService.addSuccess("Payment Date successfully pushed.");
        },
        error: (err: any) => {
          this.toastService.addError("Unable to push payment date. See log for details.");
          console.error(err);
        },
        complete: () => { postSub.unsubscribe(); }
      });
  }

  hasFeature(feature: string, mode: string = 'write'): boolean {
    switch (mode) {
      case 'read':
        return this.userService.hasRead(feature);
        break;
      case 'feature':
        return this.userService.hasFeature(feature);
        break;
      default:
        return this.userService.hasWrite(feature);
        break;
    }
  }


}
