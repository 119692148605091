
<div class="bg-indigo text-white grid p-4">
    <table class="w-full text-white">
        <tr>
            <td>Tier Level:</td>
            <td class="text-right">{{rewardInfo.rewardsAdjustmentTierLevel}}</td>
        </tr>
        <tr>
            <td>Point Balance:</td>
            <td class="text-right">{{rewardInfo.rewardsAdjustmentPointBalance}}</td>
        </tr>
        <tr>
            <td>Pending Points:</td>
            <td class="text-right">{{rewardInfo.rewardsAdjustmentPendingPoints}}</td>
        </tr>
        <tr>
            <td>Points until next Tier:</td>
            <td class="text-right">
                <div
                    class="css-bar css-bar-{{percentageUntilNextTier}} css-bar-success-green text-center relative">
                    <div class="absolute z-4 w-full pt-2 flex flex-wrap">
                        <i class="w-12 mt-2 bi bi-trophy"></i>
                        <span class="w-12 mt-3 text-sm">{{rewardsAdjustmentPointsUntilNextTier}}</span>
                    </div>
                </div>
            </td>
        </tr>
    </table>
</div>
