import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { CustomerNotesComponent } from 'src/app/components/customer-notes/customer-notes.component';
import { PaymentFeeCalculator } from 'src/app/models/csr/agent-tools';
import { CustomerModel } from 'src/app/models/customer-model';
import { ApiService } from 'src/app/services/api.service';
import { CustomerService } from 'src/app/services/customer.service';
import { GuidService } from 'src/app/services/guid.service';
import { ToastService } from 'src/app/services/toast.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-payment-calculator',
  templateUrl: './payment-calculator.component.html',
  styleUrls: ['./payment-calculator.component.scss']
})
export class PaymentCalculatorComponent implements OnInit {


  customerGuid: string | null = null;
  campaignGuid: string | null = null;
  targetGuid: string | null = null;

  customer: CustomerModel | null = null;
  
  bcItems: MenuItem[] = [];
  fees: PaymentFeeCalculator[] = [];
  selectedCalc: string | null = null;

  @ViewChild('custNotes') custNotes: CustomerNotesComponent = {} as CustomerNotesComponent;

  constructor(
    private sanitizer: DomSanitizer,
    private activatedRoute: ActivatedRoute, 
    private customerService: CustomerService,
    private guidService: GuidService,
    private userService: UserService,
    private toastService: ToastService,
    private apiService: ApiService
    ) { }

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe(async paramMap => {
      let cGuid = paramMap.get('customerGuid')?.toString() ?? '';
      if (this.guidService.isValidGuid(cGuid)) {
        this.customerGuid = cGuid;
        this.customerService.initCustomer(this.customerGuid);
        await this.userService.subscribeAccess();
        let data = this.activatedRoute.snapshot.data;
        if (data && data['pageId']) {
          this.userService.checkPageAccess(data['pageId']);
        }        
      }
      let campGuid = paramMap.get('campaignGuid')?.toString() ?? '';
      let tGuid = paramMap.get('targetGuid')?.toString() ?? '';
      if (this.guidService.isValidGuid(campGuid)) this.campaignGuid = campGuid;
      if (this.guidService.isValidGuid(tGuid)) this.targetGuid = tGuid;
      this.customerService.customer$.subscribe(cust => {
        this.customer = cust;        
      })
      this.bcItems = [
        { label: 'Agent Tools', routerLink: [`/agent-tools/${this.customerGuid}/${this.campaignGuid}/${this.targetGuid}`]},
        { label: 'Payment Calculator' }
      ];
      this.getFees();
    });

  }

  getFees() {
    let feeSub = this.apiService.get(`agenttools/payment-calculator/fee-templates/${this.customerGuid}/${this.targetGuid}`)
    .subscribe({
      next: (data: PaymentFeeCalculator[]) => {
        this.fees = data;
        if (this.fees && this.fees.length > 0) this.selectedCalc = this.fees[0].paymentCalculatorIframeSRC;
      },
      error: (err: any) => {
        this.toastService.addError("Unable to get Payment Calculator Fee versions. See log for details.");
        console.error(err);
      },
      complete: () => { feeSub.unsubscribe(); }
    });
  }

  feeCalcChanged(fee: string) {
    this.selectedCalc = fee;
  }

  getSanitizedURL() {
    if (this.selectedCalc) return this.sanitizer.bypassSecurityTrustResourceUrl(this.selectedCalc);
    else return 'about:blank';
  }

}
