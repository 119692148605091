import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CourtesyAdjustmentInfo } from 'src/app/models/courtesy-adjustment-info';
import { TransactionHistoryFees } from 'src/app/models/csr/agent-tools';
import { OptionValue } from 'src/app/models/csr/options';
import { Bank2AdjustFormGroup } from 'src/app/models/form-groups';
import { TargetAccountSummary } from 'src/app/models/target-account-summary';
import { ApiService } from 'src/app/services/api.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-bank-adjustment-option-two',
  templateUrl: './bank-adjustment-option-two.component.html',
  styleUrls: ['./bank-adjustment-option-two.component.scss']
})
export class BankAdjustmentOptionTwoComponent implements OnInit {
  @Output() reload = new EventEmitter();
  @Input() transactionHistoryFees: TransactionHistoryFees = {} as TransactionHistoryFees;
  @Input() courtesyAdjustinfo: CourtesyAdjustmentInfo = new CourtesyAdjustmentInfo();
  @Input() accountSummary: TargetAccountSummary = {} as TargetAccountSummary;
  @Input() customerGuid: any;
  @Input() targetGuid: any;
  @Input() campaignGuid: any;

  bankAdjustFormLoaded: boolean = false;
  amountReadOnly: boolean = false;
  showBankAcctReasonModal: boolean = false;
  showBankAcctModal: boolean = false;
  submitting: boolean = false;

  suspenseAmount: number = 0;
  manualBankAdjustment: number = 0;

  reasons: OptionValue[] = [
    { label: 'Suspense Refund', value: 1}, { label: 'Courtesy Adjustment - Other', value: 2 }
  ];

  effDtMinDate: Date = new Date();
  effDtMaxDate: Date = new Date();

  bankAdjustForm: FormGroup<Bank2AdjustFormGroup> = {} as FormGroup;

  constructor(
    private apiService: ApiService,
    private toastService: ToastService
  ) { }

  ngOnInit(): void {
    
  }

  initPage() {
    setTimeout(() => {
      if (this.transactionHistoryFees.suspense.suspenseAmount) {
        this.suspenseAmount = this.transactionHistoryFees.suspense.suspenseAmount;
      }
      this.loadForm();
      if (this.courtesyAdjustinfo.courtesyAdjustmentMinDate) this.effDtMinDate = new Date(this.courtesyAdjustinfo.courtesyAdjustmentMinDate);
      if (this.courtesyAdjustinfo.courtesyAdjustmentMaxDate) this.effDtMaxDate = new Date(this.courtesyAdjustinfo.courtesyAdjustmentMaxDate);
    });
  }

  loadForm() {
    this.bankAdjustForm = new FormGroup<Bank2AdjustFormGroup>({
      reason: new FormControl<OptionValue|null>(null, {nonNullable: true, validators: [Validators.required]}),
      amount: new FormControl<number|null>(null, {nonNullable: true, validators: [Validators.required, Validators.min(0)]}),
      effDate: new FormControl<Date|null>(null, {nonNullable: true, validators: [Validators.required]}),
    });
    this.bankAdjustFormLoaded = true;

    this.bankAdjustForm.controls.reason.valueChanges.subscribe(val => {
      if (val && val.value == 1) {
        this.amountReadOnly = true;
        this.bankAdjustForm.controls.amount.patchValue(this.suspenseAmount);        
      }
      else {
        this.amountReadOnly = false;
        this.bankAdjustForm.controls.amount.patchValue(null);
        this.bankAdjustForm.controls.amount.markAsPristine();
      }
      this.bankAdjustForm.updateValueAndValidity();
    })
  }

  confirmBankAdjust() {
    this.showBankAcctReasonModal = true;
  }

  confirmReason() {
    this.showBankAcctReasonModal = false;
    this.showBankAcctModal = true;
  }

  postBankAdjustment() {
    this.submitting = true;

    let body = {
      customerGuid: this.customerGuid,
      campaignGuid: this.campaignGuid,
      targetGuid: this.targetGuid,
      courtesyAdjustmentType: 'bank',
      cafRowId: 0,
      ffcRowId: 0,
      cafAmount: 0,
      ffcAmount: 0,
      principalAmount: 0,
      bankAccountAmount: this.bankAdjustForm.value.amount ?? 0,
      usaepayTransactionQueueId: -1,
      suspenseRefund: this.bankAdjustForm.value.reason?.value == 1
    };

    let pSub = this.apiService.post(`agenttools/account-adjustment/courtesy-adjustment`, body)
      .subscribe({
        next: () => {
          this.reload.emit();
          this.submitting = false;
          this.bankAdjustForm.reset();
          this.bankAdjustForm.updateValueAndValidity();
          this.toastService.addSuccess("Courtesy Adjustment successfully created.");
        },
        error: (err: any) => {
          this.submitting = false;
          this.toastService.addError("Unable to create Courtesy Adjustment. See log for details.");
          console.error(err);
        },
        complete: () => { pSub.unsubscribe(); }
      });

  }

}
