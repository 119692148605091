<div *ngIf="!loading">
    <p class="mb-3">Personal Info</p>
    <div class="grid">
      <div class="col-6 md:col-4">
          <p class="clabel">First Name</p>
          <p class="cvalue">{{customerInfo.firstName}}</p>
      </div>
      <div class="col-6 md:col-4">
          <p class="clabel">Middle Name</p>
          <p class="cvalue">{{customerInfo.middleName}}</p>
      </div>
      <div class="col-6 md:col-4">
          <p class="clabel">Last Name</p>
          <p class="cvalue">{{customerInfo.lastName}}</p>
      </div>
      <div class="col-12 md:col-6">
          <p class="clabel">Date of Birth</p>
          <p class="cvalue">{{customerInfo.dOB}}</p>
      </div>
      <div class="col-12 md:col-6">
          <p class="clabel">Email Address</p>
          <p class="cvalue">{{customerInfo.emailAddress}}</p>
      </div>
      <div class="col-12">
          <p class="clabel">Address</p>
          <p class="cvalue"><span>{{customerInfo.address1}}</span><span class="ml-1">{{customerInfo.address2}}</span></p>
      </div>
      <div class="col-12 md:col-6">
          <p class="clabel">City</p>
          <p class="cvalue">{{customerInfo.city}}</p>
      </div>
      <div class="col-12 md:col-6">
          <p class="clabel">State</p>
          <p class="cvalue">{{customerInfo.state}}</p>
      </div>
      <div class="col-12 md:col-6">
          <p class="clabel">Postal Code</p>
          <p class="cvalue">{{customerInfo.zipCode}}</p>
      </div>
      <div class="col-12 md:col-6">
          <p class="clabel">Country</p>
          <p class="cvalue">{{customerInfo.country}}</p>
      </div>
      <div class="col-12 md:col-6">
          <p class="clabel">Home Phone</p>
          <p class="cvalue">{{customerInfo.phone}}</p>
      </div>
      <div class="col-12 md:col-6">
          <p class="clabel">Cell Phone</p>
          <p class="cvalue">{{customerInfo.cellPhone}}</p>
      </div>
      <div class="col-12">
          <p class="clabel">Social Security Number</p>
          <p class="cvalue">{{customerInfo.sSNMasked}}</p>
      </div>
    </div>
    <p class="mb-3 pt-3 pds-divider-top">Other</p>
    <div class="grid">
        <div class="col-12 md:col-6">
            <p class="clabel">Routing Number</p>
            <p class="cvalue">{{customerInfo.routingNumber}}</p>
        </div>
        <div class="col-12 md:col-6">
            <p class="clabel">Bank Account Number</p>
            <p class="cvalue">{{customerInfo.accountNumber}}</p>
        </div>
        <div class="col-12 md:col-6">
            <p class="clabel">IP Address</p>
            <p class="cvalue">{{customerInfo.iPAddress}}</p>
        </div>
        <div class="col-12 md:col-6">
            <p class="clabel">Bank Name</p>
            <p class="cvalue">{{customerInfo.bankName}}</p>
        </div>
        <div class="col-12 md:col-6">
            <p class="clabel">Card Number</p>
            <p class="cvalue">{{hyphenForEmpty(cardInfo.cardDescription)}}</p>
        </div>
        <div class="col-12 md:col-6">
            <p class="clabel">Card Expiration</p>
            <p class="cvalue">{{hyphenForEmpty(cardInfo.cardExpiration)}}</p>
        </div>
        <div class="col-12 md:col-6">
            <p class="clabel">Reference 1</p>
            <p class="cvalue">{{hyphenForEmpty(customerInfo.reference1FirstName)}}</p>
        </div>
        <div class="col-12 md:col-6">
            <p class="clabel"></p>
            <p class="cvalue">{{hyphenForEmpty(customerInfo.reference1LastName)}}</p>
        </div>
        <div class="col-12 md:col-6">
            <p class="clabel">Reference 2</p>
            <p class="cvalue">{{hyphenForEmpty(customerInfo.reference2FirstName)}}</p>
        </div>
        <div class="col-12 md:col-6">
            <p class="clabel"></p>
            <p class="cvalue">{{hyphenForEmpty(customerInfo.reference2LastName)}}</p>
        </div>
    </div>
    <p class="mb-3 pt-3 pds-divider-top">Income Info</p>
    <div class="grid">
        <div class="col-12">
            <p class="clabel">Income Type</p>
            <p class="cvalue">{{customerInfo.employerIncomeSource}}</p>
        </div>
        <div class="col-12 md:col-6">
            <p class="clabel">Employer Name</p>
            <p class="cvalue">{{customerInfo.employerName}}</p>
        </div>
        <div class="col-12 md:col-6">
            <p class="clabel">Job Title</p>
            <p class="cvalue">{{customerInfo.positionOrDepartment}}</p>
        </div>
        <div class="col-12 md:col-6">
            <p class="clabel">Work Phone</p>
            <p class="cvalue">{{customerInfo.workPhone}}</p>
        </div>
        <div class="col-12 md:col-6">
            <p class="clabel">Employer Phone</p>
            <p class="cvalue">{{customerInfo.employerPhoneNumber}}</p>
        </div>
        <div class="col-12 md:col-6">
            <p class="clabel">Amount From Check</p>
            <p class="cvalue">{{getMoney(customerInfo.homePayLastCheck)}}</p>
        </div>
        <div class="col-12 md:col-6">
            <p class="clabel">Pay Frequency</p>
            <p class="cvalue">{{customerInfo.payFrequency}}</p>
        </div>
        <div class="col-12 md:col-6">
            <p class="clabel">Additional Monthly Income</p>
            <p class="cvalue">{{getMoney(customerInfo.additionalMonthlyIncome)}}</p>
        </div>
        <div class="col-12 md:col-6">
            <p class="clabel">Additional Job Income Type</p>
            <p class="cvalue">{{customerInfo.additionalJobIncomeType}}</p>
        </div>
    </div>
</div>
