<div class="flex">
    <div class="text-2xl mb-4">{{action}} Template</div>
    <div class="flex-grow-1 text-right">
        <p3solved-button label="Back" class="secondary" (buttonClick)="onBack.emit()"></p3solved-button>
    </div>
</div>
<form *ngIf="templateFormLoaded" [formGroup]="templateForm">
    <div class="grid">
        <div class="col-12">
            <p3solved-dropdown id="ddCats" placeholder="Category" formControlName="category" [showClear]="true"
                [items]="categories" optionLabel="messageCategoryName" optionValue="messageCategoryID">
                <div class="w-12 my-1 p-error text-xs"
                    *ngIf="templateForm.controls.category.touched && templateForm.controls.category.errors">
                    <p>Template Category is required</p>
                </div>
            </p3solved-dropdown>
        </div>
        <div class="col-12">
            <p3solved-form-text id="tName" placeholder="Template Name" formControlName="name">
                <div class="w-12 my-1 p-error text-xs"
                    *ngIf="templateForm.controls.name.touched && templateForm.controls.name.errors">
                    <p>Template Name is required</p>
                </div>
            </p3solved-form-text>
        </div>
        <div class="col-12 pt-5" *ngIf="messageTypeId == 4">
            <p3solved-form-textarea id="body" placeholder="SMS Text Body" formControlName="body" [rows]="5">
                <div class="w-12 my-1 p-error text-xs"
                    *ngIf="templateForm.controls.body.touched && templateForm.controls.body.errors">
                    <p>SMS Body is required</p>
                </div>
            </p3solved-form-textarea>
        </div>
        <div class="col-12 pt-5" *ngIf="messageTypeId == 2">
            Email body:
            <p-editor formControlName="body" [style]="{'height': '20rem'}"></p-editor>
            <div class="w-12 my-1 p-error text-xs"
                *ngIf="templateForm.controls.body.touched && templateForm.controls.body.errors">
                <p>Email Body is required</p>
            </div>
        </div>
        <div class="col-12" *ngIf="hasFeature('CSR_QUEUE_COMMUNICATION_EDIT_TEMPLATE')">
            <p3solved-button id="btnSaveTemp" label="Save Template" [disabled]="!templateForm.valid" (buttonClick)="saveTemplate()"></p3solved-button>
        </div>
    </div>
</form>