import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { DebitCardPayment } from 'src/app/models/card-payment';
import { TargetAccountSummary } from 'src/app/models/target-account-summary';
import { ApiService } from 'src/app/services/api.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-debit-card-adjustment',
  templateUrl: './debit-card-adjustment.component.html',
  styleUrls: ['./debit-card-adjustment.component.scss']
})
export class DebitCardAdjustmentComponent implements OnInit {
  @Output() reload = new EventEmitter();

  @Input() accountSummary: TargetAccountSummary = new TargetAccountSummary();
  @Input() debitCardPayments: DebitCardPayment[] = [];
  @Input() customerGuid: any;
  @Input() targetGuid: any;
  @Input() campaignGuid: any;

  showDebitCardModal: boolean = false;
  submitting: boolean = false;

  manualRefundAmount: number = 0;

  selectedPayment: FormControl<DebitCardPayment | null> = new FormControl<DebitCardPayment | null>(null, { nonNullable: true, validators: [Validators.required] });

  constructor(
    private apiService: ApiService,
    private toastService: ToastService
  ) { }

  ngOnInit(): void {

  }

  adjustDebitPayment() {
    if (this.selectedPayment.value?.description) {
      let desc = this.selectedPayment.value.description;
      let temp = desc.substring(1, desc.indexOf(' ') - 1);
      if (temp && temp.length > 0 && !isNaN(+temp)) {
        this.manualRefundAmount = +temp;
        this.showDebitCardModal = true;
      }
      else this.toastService.addWarn('Unabled to determine adjustment amount.');
    }
    else this.toastService.addWarn('Unabled to determine adjustment amount.');
  }

  postCardAdjustment() {
    this.submitting = true;

    let body = {
      customerGuid: this.customerGuid,
      campaignGuid: this.campaignGuid,
      targetGuid: this.targetGuid,
      courtesyAdjustmentType: 'card',
      cafRowId: 0,
      ffcRowId: 0,
      cafAmount: 0,
      ffcAmount: 0,
      principalAmount: 0,
      bankAccountAmount: 0,
      usaepayTransactionQueueId: this.selectedPayment.value?.transactionQueueID ?? -1,
      suspenseRefund: false
    };

    let pSub = this.apiService.post(`agenttools/account-adjustment/courtesy-adjustment`, body)
      .subscribe({
        next: () => {
          this.reload.emit();
          this.submitting = false;
          this.selectedPayment.reset();
          this.selectedPayment.updateValueAndValidity();
          this.toastService.addSuccess("Card Adjustment successfully created.");
        },
        error: (err: any) => {
          this.submitting = false;
          this.toastService.addError("Unable to create Card Adjustment. See log for details.");
          console.error(err);
        },
        complete: () => { pSub.unsubscribe(); }
      });
  }


}

