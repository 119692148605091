<app-dashboard-header></app-dashboard-header>
<div class="row">
    <div class="col-12 lg:col-4">
        <div class="cds-card p-4">

            <div class="pt-3 pds-divider-bottom">
                <div class="flex pb-2">
                    <div class="flex-grow-1">
                        <p class="h4 m-0">Customer Info</p>
                    </div>
                    <div class="p-1 mx-1" *ngIf="hasFeature('CSR_DELETE_CUSTOMER')">
                        <p3solved-button id="btnDelCust" label="Delete" class="danger"
                            (buttonClick)="deleteCustomerInfo()"></p3solved-button>
                    </div>
                    <div class="p-1 ml-1" *ngIf="hasFeature('CSR_CUSTOMER_VERIFICATION_EDIT_INFO') && !showEditCusomer">
                        <p3solved-button id="btnEditCust" label="Edit" (buttonClick)="editCustomerInfo()"></p3solved-button>
                    </div>
                    <div class="p-1 mx-1" *ngIf="hasFeature('CSR_CUSTOMER_VERIFICATION_EDIT_INFO') && showEditCusomer">
                        <p3solved-button id="btnCancelCust" label="Cancel" class="secondary"
                            (buttonClick)="cancelEditCustomerInfo()"></p3solved-button>
                    </div>
                    <div class="p-1 ml-1" *ngIf="hasFeature('CSR_CUSTOMER_VERIFICATION_EDIT_INFO') && showEditCusomer">
                        <p3solved-button id="btnSaveCust" label="Save" (buttonClick)="saveCustomerInfo()"></p3solved-button>
                    </div>
                </div>                
            </div>
            <div class="pt-3">
                <app-customer-info *ngIf="!showEditCusomer"
                    (customerInfoUpdated)="customerInfoUpdated($event)"
                    (cardInfoUpdated)="cardInfoUpdated($event)">
                </app-customer-info>
                <app-customer-info-edit #editCustomer *ngIf="showEditCusomer"
                    [customerInfo]="customerInfo"
                    [cardInfo]="cardInfo"
                    (updated)="customerUpdated($event)">
                </app-customer-info-edit>
            </div>
        </div>
    </div>
    <div class="col-12 lg:col-4">
        <app-add-customer-notification></app-add-customer-notification>
        <!-- <app-customer-contact *ngIf="contactEnabled"></app-customer-contact> -->
        <app-customer-contact-new (optionChanged)="reloadNotes()"></app-customer-contact-new>
    </div>
    <div class="col-12 lg:col-4">
        <app-customer-notes #customerNotes></app-customer-notes>
    </div>
</div>