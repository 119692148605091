import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationService, ConfirmEventType, MenuItem } from 'primeng/api';
import { forkJoin } from 'rxjs';
import { SettlementInfo } from 'src/app/models/csr/agent-tools';
import { CustomerModel } from 'src/app/models/customer-model';
import { LookupModel } from 'src/app/models/lookup-model';
import { TargetAccountSummary } from 'src/app/models/target-account-summary';
import { ApiService } from 'src/app/services/api.service';
import { CustomerService } from 'src/app/services/customer.service';
import { GuidService } from 'src/app/services/guid.service';
import { HelperService } from 'src/app/services/helper.service';
import { LookupService } from 'src/app/services/lookup.service';
import { ToastService } from 'src/app/services/toast.service';
import { UserService } from 'src/app/services/user.service';
import { PayCalendarFormComponent } from '../pay-calendar-form/pay-calendar-form.component';

@Component({
  selector: 'app-collection-settlement',
  templateUrl: './collection-settlement.component.html',
  styleUrls: ['./collection-settlement.component.scss']
})
export class CollectionSettlementComponent implements OnInit {
  @Output() reload = new EventEmitter();

  //  Steps
  showOneTime: boolean = false;
  showNoWrite: boolean = false;
  showSettle: boolean = false;
  oneTimeStep1Active: boolean = false;
  oneTimeStep1Completed: boolean = false;
  oneTimeStep2Active: boolean = false;
  oneTimeStep2Completed: boolean = false;
  noWriteStep1Active: boolean = false;
  noWriteStep1Completed: boolean = false;
  noWriteStep2Active: boolean = false;
  noWriteStep2Completed: boolean = false;
  settleStep1Active: boolean = false;
  settleStep1Completed: boolean = false;
  settleStep2Active: boolean = false;
  settleStep2Completed: boolean = false;

  showSubTtl: boolean = true;
  showTabs: boolean = true;
  showCompleted: boolean = false;
  showNoWriteOffComp: boolean = false;
  showWithWriteOffComp: boolean = false;

  tabMenu: MenuItem[] = [];
  activeItem: MenuItem = {} as MenuItem;

  payFrequencies: LookupModel[] = [];
  daysOfWeek: LookupModel[] = [];
  semiMonthDays: LookupModel[] = [];
  twoMonthOther1: LookupModel[] = [];
  twoMonthOther2: LookupModel[] = [];
  twoMonthOtherWhen: LookupModel[] = [];
  days: LookupModel[] = [];
  monthWhens: LookupModel[] = [];
  noWriteNextPayDates: LookupModel[] = [];
  settleNextPayDates: LookupModel[] = [];
  autoPayOps: any[] = [{ label: 'Yes', value: true }, { label: 'No', value: false }];

  customerGuid: string | null = null;
  campaignGuid: string | null = null;
  targetGuid: string | null = null;

  customer: CustomerModel | null = null;
  accountSummary: TargetAccountSummary = new TargetAccountSummary();
  settlementReasons: LookupModel[] = [];
  settlementInfo: SettlementInfo = {} as SettlementInfo;
  noWritePayForm: FormGroup = new FormGroup({});
  settlePayForm: FormGroup = new FormGroup({});

  freezeMinDate: Date = new Date();
  freezeMaxDate: Date = new Date();

  payDates: string[] = [];
  serviceOption: string = '';
  collSettConfKey: string = 'collectionSettConfirmKey';
  payFrequency: string = '';
  dayOfWeek: string = '';
  nextPayDate: string = '';

  principalBalance: number = 0;
  cafBalance: number = 0;
  ffcBalance: number = 0;
  totalAccountBalance: number = 0;
  paymentAmount: number = 0;

  //Form Controls
  oneTimePpal: FormControl<number | null> = new FormControl<number | null>(null, { nonNullable: true });
  oneTimeCAF: FormControl<number | null> = new FormControl<number | null>(null, { nonNullable: true });
  oneTimeFFC: FormControl<number | null> = new FormControl<number | null>(null, { nonNullable: true });
  oneTimeRemainBal: FormControl<number | null> = new FormControl<number | null>(null, { nonNullable: true });

  noWriteEffDate: FormControl<Date | null> = new FormControl<Date | null>(null, { nonNullable: true });
  noWriteSettleAmount: FormControl<number | null> = new FormControl<number | null>(null, { nonNullable: true });
  noWriteNoPayments: FormControl<number | null> = new FormControl<number | null>(null, { nonNullable: true });
  noWritePayAmount: FormControl<number | null> = new FormControl<number | null>(null, { nonNullable: true });
  noWriteHowOften: FormControl<string | null> = new FormControl<string | null>(null, { nonNullable: true });
  noWriteDayOfWeek: FormControl<string | null> = new FormControl<string | null>(null, { nonNullable: true });
  noWriteSemiMonthDay: FormControl<string | null> = new FormControl<string | null>(null, { nonNullable: true });

  noWriteFirstDueDate: FormControl<string | null> = new FormControl<string | null>(null, { nonNullable: true });
  noWriteReason: FormControl<string | null> = new FormControl<string | null>(null, { nonNullable: true });
  noWriteAutoPay: FormControl<boolean> = new FormControl<boolean>(false, { nonNullable: true });

  settlePpal: FormControl<number | null> = new FormControl<number | null>(null, { nonNullable: true });
  settleCAF: FormControl<number | null> = new FormControl<number | null>(null, { nonNullable: true });
  settleFFC: FormControl<number | null> = new FormControl<number | null>(null, { nonNullable: true });
  settleNoPayments: FormControl<number | null> = new FormControl<number | null>(null, { nonNullable: true });
  settlePayAmount: FormControl<number | null> = new FormControl<number | null>(null, { nonNullable: true });
  settleHowOften: FormControl<string | null> = new FormControl<string | null>(null, { nonNullable: true });
  settleDayOfWeek: FormControl<string | null> = new FormControl<string | null>(null, { nonNullable: true });
  settleSemiMonthDay: FormControl<string | null> = new FormControl<string | null>(null, { nonNullable: true });

  settleFirstDueDate: FormControl<string | null> = new FormControl<string | null>(null, { nonNullable: true });
  settleReason: FormControl<string | null> = new FormControl<string | null>(null, { nonNullable: true });
  settleAutoPay: FormControl<boolean> = new FormControl<boolean>(false, { nonNullable: true });

  private parser = new DOMParser();
  currencyPipe = new CurrencyPipe('en-US');
  datePipe = new DatePipe('en-US');

  @ViewChild('noWritePayCalendar') noWritePayCalendar: PayCalendarFormComponent = {} as PayCalendarFormComponent;
  @ViewChild('settlePayCalendar') settlePayCalendar: PayCalendarFormComponent = {} as PayCalendarFormComponent;
  constructor(
    private activatedRoute: ActivatedRoute,
    private customerService: CustomerService,
    private confirmService: ConfirmationService,
    private lookupService: LookupService,
    private helperService: HelperService,
    private guidService: GuidService,
    private userService: UserService,
    private toastService: ToastService,
    private apiService: ApiService
  ) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(async paramMap => {
      let cGuid = paramMap.get('customerGuid')?.toString() ?? '';
      if (this.guidService.isValidGuid(cGuid)) {
        this.customerGuid = cGuid;
        this.customerService.initCustomer(this.customerGuid);
        await this.userService.subscribeAccess();
        let data = this.activatedRoute.snapshot.data;
        if (data && data['pageId']) {
          this.userService.checkPageAccess(data['pageId']);
        }
      }
      let campGuid = paramMap.get('campaignGuid')?.toString() ?? '';
      let tGuid = paramMap.get('targetGuid')?.toString() ?? '';
      if (this.guidService.isValidGuid(campGuid)) this.campaignGuid = campGuid;
      if (this.guidService.isValidGuid(tGuid)) this.targetGuid = tGuid;
      this.customerService.customer$.subscribe(cust => {
        this.customer = cust;
        if (cust) {
          this.serviceOption = "2";//cust.cSAccountServicingOption;
          this.getData();
        }
      });
    });
    this.tabMenu = [
      { label: 'One Time', command: () => { this.showTab('oneTime'); } },
      { label: 'No Write-Off', command: () => { this.showTab('noWriteOff'); } },
      { label: 'Settle with Write-Off', command: () => { this.showTab('settleWriteOff'); } }
    ];
    this.activeItem = this.tabMenu[0];
    this.showTab('oneTime');
  }

  getData() {
    const fSub = forkJoin({
      acctSumm: this.apiService.get(`csr/target-account-summary/${this.serviceOption}/${this.customerGuid}/${this.campaignGuid}/${this.targetGuid}`),
      settleReas: this.apiService.post(`settlement/reasons/${this.customerGuid}`, {}),
      settleInfo: this.apiService.get(`agenttools/settlement-info/${this.customerGuid}/${this.campaignGuid}/${this.targetGuid}`)
    })
      .subscribe({
        next: (data: any) => {
          this.accountSummary = data.acctSumm.accountSummary;
          this.settlementReasons = data.settleReas;
          this.settlementInfo = data.settleInfo;
          this.initPage();
        },
        error: (err: any) => {
          this.toastService.addError("Unable to get initial Settlement data. See log for details.");
          console.error(err);
        },
        complete: () => { fSub.unsubscribe(); }
      });
  }

  initPage() {
    let today = new Date();
    this.freezeMinDate.setDate(today.getDate() - 30);
    this.freezeMaxDate.setDate(today.getDate() + 7);
    this.principalBalance = +this.accountSummary.currentPrincipalBalance;
    this.cafBalance = +this.accountSummary.cAFBalance;
    this.ffcBalance = +this.accountSummary.fFCBalance;
    this.totalAccountBalance = +this.accountSummary.totalAccountBalance;

    let noPayments: number = 0;
    //  Settlement No WriteOff - completed
    if (this.settlementInfo.noWriteOff && this.settlementInfo.noWriteOff.freezeFees && this.settlementInfo.noWriteOff.settlementPlan) {
      this.showSubTtl = false;
      this.showTabs = false;
      this.showCompleted = true;
      this.showNoWriteOffComp = true;
      this.payDates = this.settlementInfo.noWriteOff.payDates ? this.settlementInfo.noWriteOff.payDates.split('|') : [];
      noPayments = this.settlementInfo.noWriteOff.noOfPayments ?? 0;

      if (noPayments < this.payDates.length) {
        this.payDates.splice(noPayments);
      }

    } //Settlement With WriteOff - completed
    else if (this.settlementInfo.withWriteOff && this.settlementInfo.withWriteOff.writeOff && this.settlementInfo.withWriteOff.settlementPlan) {
      this.showSubTtl = false;
      this.showTabs = false;
      this.showCompleted = true;
      this.showWithWriteOffComp = true;
      this.payDates = this.settlementInfo.withWriteOff.payDates ? this.settlementInfo.withWriteOff.payDates.split('|') : [];
      noPayments = this.settlementInfo.withWriteOff.noOfPayments ?? 0;

      if (noPayments < this.payDates.length) {
        this.payDates.splice(noPayments);
      }

    }
    else this.setupPayTabs();

  }

  setupPayTabs() {

    this.removeValidators();

    //  One Time
    let step1Status = this.settlementInfo.oneTime.writeOffStatus.toLowerCase();
    let step2Status = this.settlementInfo.oneTime.makeAPaymentStatus.toLowerCase();
    this.oneTimeStep1Active = false;
    this.oneTimeStep1Completed = false;
    this.oneTimeStep2Active = false;
    this.oneTimeStep2Completed = false;
    if (step1Status == 'active') {
      this.oneTimeStep1Active = true;
      this.oneTimePpal.setValidators([Validators.max(this.principalBalance), Validators.min(0.01)]);
      this.oneTimeCAF.setValidators([Validators.max(this.cafBalance), Validators.min(0.01)]);
      this.oneTimeFFC.setValidators([Validators.max(this.ffcBalance), Validators.min(0.01)]);
      this.oneTimePpal.updateValueAndValidity();
      this.oneTimeCAF.updateValueAndValidity();
      this.oneTimeFFC.updateValueAndValidity();
    }
    else if (step1Status == 'completed') {
      this.oneTimeStep1Completed = true;
    }

    if (step2Status == 'active') {
      this.oneTimeStep2Active = true;
      this.oneTimeRemainBal.setValidators([Validators.min(0.1), Validators.max(this.totalAccountBalance)]);
      this.oneTimeRemainBal.updateValueAndValidity();
    }
    else if (step2Status == 'completed') {
      this.oneTimeStep2Completed = true;
    }

    //  No Write Off
    step1Status = this.settlementInfo.noWriteOff.freezeFeesStatus.toLowerCase();
    step2Status = this.settlementInfo.noWriteOff.settlementPlanStatus.toLowerCase();
    this.noWriteStep1Active = false;
    this.noWriteStep1Completed = false;
    this.noWriteStep2Active = false;
    this.noWriteStep2Completed = false;
    if (step1Status == 'active') {
      this.noWriteStep1Active = true;
      this.noWriteEffDate.setValidators([Validators.required]);
      this.noWriteEffDate.updateValueAndValidity();
    }
    else if (step1Status == 'completed') {
      this.noWriteStep1Completed = true;
    }

    if (step2Status == 'active') {
      this.noWriteStep2Active = true;
      this.noWriteNoPayments.setValidators([Validators.required, Validators.min(1)]);
      this.noWriteNoPayments.updateValueAndValidity();
      this.noWriteFirstDueDate.setValidators([Validators.required]);
      this.noWriteFirstDueDate.updateValueAndValidity();
      this.noWriteReason.setValidators([Validators.required]);
      this.noWriteReason.updateValueAndValidity();
    }
    else if (step2Status == 'completed') {
      this.noWriteStep2Completed = true;
    }

    //  With Write-Off Settlement
    step1Status = this.settlementInfo.withWriteOff.writeOffStatus.toLowerCase();
    step2Status = this.settlementInfo.withWriteOff.settlementPlanStatus.toLowerCase();
    this.settleStep1Active = false;
    this.settleStep1Completed = false;
    this.settleStep2Active = false;
    this.settleStep2Completed = false;
    if (step1Status == 'active') {
      this.settleStep1Active = true;
      this.settlePpal.setValidators([Validators.max(this.principalBalance), Validators.min(0.01)]);
      this.settleCAF.setValidators([Validators.max(this.cafBalance), Validators.min(0.01)]);
      this.settleFFC.setValidators([Validators.max(this.ffcBalance), Validators.min(0.01)]);
      this.settlePpal.updateValueAndValidity();
      this.settleCAF.updateValueAndValidity();
      this.settleFFC.updateValueAndValidity();
    }
    else if (step1Status == 'completed') {
      this.settleStep1Completed = true;
    }

    if (step2Status == 'active') {
      this.settleStep2Active = true;
    }
    else if (step2Status == 'completed') {
      this.settleStep2Completed = true;
    }
  }

  removeValidators() {
    this.oneTimePpal.clearValidators();
    this.oneTimePpal.updateValueAndValidity();
    this.oneTimeCAF.clearValidators();
    this.oneTimeCAF.updateValueAndValidity();
    this.oneTimeFFC.clearValidators();
    this.oneTimeFFC.updateValueAndValidity();
    this.oneTimeRemainBal.clearValidators();
    this.oneTimeRemainBal.updateValueAndValidity();
    this.noWriteEffDate.clearValidators();
    this.noWriteEffDate.updateValueAndValidity();
    this.noWriteNoPayments.clearValidators();
    this.noWriteNoPayments.updateValueAndValidity();
    this.noWriteFirstDueDate.clearValidators();
    this.noWriteFirstDueDate.updateValueAndValidity();
    this.noWriteReason.clearValidators();
    this.noWriteReason.updateValueAndValidity();
    this.settlePpal.clearValidators();
    this.settleCAF.clearValidators();
    this.settleFFC.clearValidators();
    this.settlePpal.updateValueAndValidity();
    this.settleCAF.updateValueAndValidity();
    this.settleFFC.updateValueAndValidity();
  }

  noPaymentsChange(noPayments: number | null) {
    if (noPayments) {
      this.paymentAmount = this.totalAccountBalance / noPayments;
    }

  }

  showTab(tabItm: string) {
    this.showOneTime = false;
    this.showNoWrite = false;
    this.showSettle = false;
    switch (tabItm) {
      case 'oneTime':
        this.showOneTime = true;
        break;

      case 'noWriteOff':
        this.showNoWrite = true;
        break;

      case 'settleWriteOff':
        this.showSettle = true;
        break;

      default:
        break;
    }

  }

  oneTimeStep1Valid(): boolean {
    let sum: number = 0;
    if (this.oneTimePpal.valid && this.oneTimePpal.value) sum += this.oneTimePpal.value;
    if (this.oneTimeCAF.valid && this.oneTimeCAF.value) sum += this.oneTimeCAF.value;
    if (this.oneTimeFFC.valid && this.oneTimeFFC.value) sum += this.oneTimeFFC.value;
    return sum > 0;
  }

  validateOneTimeWriteOff() {
    let msg = `
    <p>Write off the following:</p>
    <div class="grid">
      <div class="col-10">Principal Write-Off</div>
      <div class="col-2 text-right">${this.currencyPipe.transform(this.oneTimePpal.value ?? 0)}</div>
      <div class="col-10">Cash Advance Fee Write-Off</div>
      <div class="col-2 text-right">${this.currencyPipe.transform(this.oneTimeCAF.value ?? 0)}</div>
      <div class="col-10">Fixed Finance Charges Write-Off</div>
      <div class="col-2 text-right">${this.currencyPipe.transform(this.oneTimeFFC.value ?? 0)}</div>
    </div>`;
    let doc = this.parser.parseFromString(msg, 'text/html');
    const serializer = new XMLSerializer();
    let confMsg = serializer.serializeToString(doc);
    this.confirmService.confirm({
      key: this.collSettConfKey,
      message: confMsg,
      header: 'Write-Off Confirmation',
      rejectLabel: 'Back',
      rejectButtonStyleClass: 'p-button-outlined',
      acceptLabel: 'Confirm',
      accept: () => {
        this.saveOneTimeWriteOff();
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.toastService.add({ severity: 'warn', summary: 'Declined', detail: 'One Time Write-off aborted.' });
            break;
          case ConfirmEventType.CANCEL:
            this.toastService.add({ severity: 'warn', summary: 'Cancelled', detail: 'Operation cancelled.' });
            break;
        }
      }
    });
  }

  saveOneTimeWriteOff() {
    let body = {
      customerGuid: this.customerGuid,
      campaignGuid: this.campaignGuid,
      targetGuid: this.targetGuid,
      principalAmount: this.oneTimePpal.value ?? 0,
      cafAmount: this.oneTimeCAF.value ?? 0,
      ffcAmount: this.oneTimeFFC.value ?? 0
    };

    let pSub = this.apiService.post(`agenttools/one-time/write-off`, body)
      .subscribe({
        next: () => {
          this.reload.emit();
          this.toastService.addSuccess("One Time Write-Off successfully saved.");
          this.getData();
        },
        error: (err: any) => {
          this.toastService.addError("Unable to save One Time Write Off. See log for details.");
          console.error(err);
        },
        complete: () => { pSub.unsubscribe(); }
      });
  }

  validateOneTimePayment() {
    let title = this.accountSummary.standardPaymentMyModalTitle;
    let msg = this.accountSummary.standardPaymentMyModalDescription;

    let doc = this.parser.parseFromString(msg, 'text/html');

    doc.querySelectorAll('.page_amount_selector')
      .forEach(node => {
        node.innerHTML = this.currencyPipe.transform(this.oneTimeRemainBal.value) ?? '';
      });
    doc.querySelectorAll('.page_date_selector')
      .forEach(node => {
        node.innerHTML = this.datePipe.transform(new Date(), 'MM/dd/yyyy') ?? '';
      });

    const serializer = new XMLSerializer();
    let confMsg = serializer.serializeToString(doc);
    this.confirmService.confirm({
      key: this.collSettConfKey,
      message: confMsg,
      header: title,
      rejectLabel: 'Back',
      rejectButtonStyleClass: 'p-button-outlined',
      acceptLabel: 'Confirm',
      accept: () => {
        this.saveOneTimePayment();
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.toastService.add({ severity: 'warn', summary: 'Declined', detail: 'Payment will not be processed.' });
            break;
          case ConfirmEventType.CANCEL:
            this.toastService.add({ severity: 'warn', summary: 'Cancelled', detail: 'Operation cancelled.' });
            break;
        }
      }
    });
  }

  saveOneTimePayment() {
    let body = {
      customerGuid: this.customerGuid,
      campaignGuid: this.campaignGuid,
      targetGuid: this.targetGuid,
      paymentAmount: this.oneTimeRemainBal.value ?? 0,
      paymentDate: new Date(),
      nextPaymentAmount: +this.accountSummary.nextPaymentAmount ?? 0,
      nextPaymentDate: new Date(this.accountSummary.nextPaymentDate),
      csrAction: true,
      loanCardId: 0,
      pageUrl: window.location.href
    };

    let pSub = this.apiService.post(`csr/account-servicing/make-payment`, body)
      .subscribe({
        next: () => {
          this.reload.emit();
          this.toastService.addSuccess("One Time Payment successfully created.");
          this.getData();
        },
        error: (err: any) => {
          this.toastService.addError("Unable to save One Time Payment. See log for details.");
          console.error(err);
        },
        complete: () => { pSub.unsubscribe(); }
      });
  }

  validateNoWriteFreeze() {
    let msg = `
    <div>
      <p><strong>Account will freeze fees effective:</strong></p>
      <span>${this.datePipe.transform(this.noWriteEffDate.value, 'MM/dd/yyyy')}</span>
    </div>`;
    let doc = this.parser.parseFromString(msg, 'text/html');
    const serializer = new XMLSerializer();
    let confMsg = serializer.serializeToString(doc);
    this.confirmService.confirm({
      key: this.collSettConfKey,
      message: confMsg,
      header: 'Freeze Fees',
      rejectLabel: 'Back',
      rejectButtonStyleClass: 'p-button-outlined',
      acceptLabel: 'Confirm',
      accept: () => {
        this.saveNoWriteOffFreezeDate();
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.toastService.add({ severity: 'warn', summary: 'Declined', detail: 'No Write-Off Freeze aborted.' });
            break;
          case ConfirmEventType.CANCEL:
            this.toastService.add({ severity: 'warn', summary: 'Cancelled', detail: 'Operation cancelled.' });
            break;
        }
      }
    });
  }

  saveNoWriteOffFreezeDate() {
    let body = {
      customerGuid: this.customerGuid,
      campaignGuid: this.campaignGuid,
      targetGuid: this.targetGuid,
      value: this.datePipe.transform(this.noWriteEffDate.value, 'MM/dd/yyyy')
    };
    let pSub = this.apiService.post(`settlement/freeze-fees`, body)
      .subscribe({
        next: () => {
          this.reload.emit();
          this.toastService.addSuccess("Fees frozen.");
          this.getData();
        },
        error: (err: any) => {
          this.toastService.addError("Unable freeze fees. See log for details.");
          console.error(err);
        },
        complete: () => { pSub.unsubscribe(); }
      });
  }


  settleStep1Valid(): boolean {
    let sum: number = 0;
    if (this.settlePpal.valid && this.settlePpal.value) sum += this.settlePpal.value;
    if (this.settleCAF.valid && this.settleCAF.value) sum += this.settleCAF.value;
    if (this.settleFFC.valid && this.settleFFC.value) sum += this.settleFFC.value;
    return sum > 0;
  }

  validateSettleWriteOff() {
    let msg = `
    <p>Write-off the following:</p>
    <div class="grid">
      <div class="col-10">Principal Write-Off</div>
      <div class="col-2 text-right">${this.currencyPipe.transform(this.settlePpal.value ?? 0)}</div>
      <div class="col-10">Cash Advance Fee Write-Off</div>
      <div class="col-2 text-right">${this.currencyPipe.transform(this.settleCAF.value ?? 0)}</div>
      <div class="col-10">Fixed Finance Charges Write-Off</div>
      <div class="col-2 text-right">${this.currencyPipe.transform(this.settleFFC.value ?? 0)}</div>
    </div>`;
    let doc = this.parser.parseFromString(msg, 'text/html');
    const serializer = new XMLSerializer();
    let confMsg = serializer.serializeToString(doc);
    this.confirmService.confirm({
      key: this.collSettConfKey,
      message: confMsg,
      header: 'Write-Off Confirmation',
      rejectLabel: 'Back',
      rejectButtonStyleClass: 'p-button-outlined',
      acceptLabel: 'Confirm',
      accept: () => {
        this.saveSettleWriteOff();
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.toastService.add({ severity: 'warn', summary: 'Declined', detail: 'One Time Write-off aborted.' });
            break;
          case ConfirmEventType.CANCEL:
            this.toastService.add({ severity: 'warn', summary: 'Cancelled', detail: 'Operation cancelled.' });
            break;
        }
      }
    });
  }

  saveSettleWriteOff() {
    let body = {
      customerGuid: this.customerGuid,
      campaignGuid: this.campaignGuid,
      targetGuid: this.targetGuid,
      principalAmount: this.settlePpal.value ?? 0,
      cafAmount: this.settleCAF.value ?? 0,
      ffcAmount: this.settleFFC.value ?? 0
    };

    let pSub = this.apiService.post(`agenttools/settlement-with-write-off/write-off`, body)
      .subscribe({
        next: () => {
          this.reload.emit();
          this.toastService.addSuccess("Write-Off successfully completed.");
          this.getData();
        },
        error: (err: any) => {
          this.toastService.addError("Unable to save Write-Off. See log for details.");
          console.error(err);
        },
        complete: () => { pSub.unsubscribe(); }
      });
  }


  getDate(dt: string) {
    return new Date(dt);
  }

  noWriteDatesCalculated(dts: Date[]) {
    this.noWriteNextPayDates = [];
    if (dts && dts.length > 0) {
      dts.forEach(dt => {
        this.noWriteNextPayDates.push({ id: dt.getTime().toString(), desc: this.datePipe.transform(dt, 'longDate') ?? 'Calculation Error' });
      });
      this.noWriteFirstDueDate.reset();
      this.noWriteFirstDueDate.patchValue(this.noWriteNextPayDates[0].id);
      setTimeout(() => {
        this.noWriteFirstDueDate.updateValueAndValidity();
      });
    }
    else {
      this.noWriteFirstDueDate.reset();
      this.noWriteFirstDueDate.updateValueAndValidity();
    }
  }

  noWriteAutoPayChanged(event: any) {
    if (event.value) {
      let msg = ` <p>Mr./Ms. ${this.accountSummary.lastName}, you are re-authorizing us to enable AutoPay for account ending in ${this.accountSummary.last4Account}. Please say "I agree" to confirm.</p>`;
    let doc = this.parser.parseFromString(msg, 'text/html');
    const serializer = new XMLSerializer();
    let confMsg = serializer.serializeToString(doc);
    this.confirmService.confirm({
      key: this.collSettConfKey,
      message: confMsg,
      header: 'AutoPay',
      rejectLabel: 'Back',
      rejectButtonStyleClass: 'p-button-outlined',
      acceptLabel: 'Next',
      accept: () => {
        this.toastService.addSuccess('An email will be sent to the email address on file.  Please follow the instructions within the email to finalize the re-authorization for AutoPay.');
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.toastService.add({ severity: 'warn', summary: 'Declined', detail: 'One Time Write-off aborted.' });
            break;
          case ConfirmEventType.CANCEL:
            this.toastService.add({ severity: 'warn', summary: 'Cancelled', detail: 'Operation cancelled.' });
            break;
        }
        this.noWriteAutoPay.patchValue(false);
        this.noWriteAutoPay.updateValueAndValidity();
      }
    });
    }
  }

  noWriteBtnSaveValid(): boolean {
    return this.noWriteNoPayments.valid && this.noWriteFirstDueDate.valid && this.noWriteReason.valid
  }

  validateNoWriteSettlement() {
    let msg = `<p><strong>Do you confirm the settlement plan?</strong></p>`;
    let doc = this.parser.parseFromString(msg, 'text/html');
    const serializer = new XMLSerializer();
    let confMsg = serializer.serializeToString(doc);
    this.confirmService.confirm({
      key: this.collSettConfKey,
      message: confMsg,
      header: 'Settlement Plan',
      rejectLabel: 'Back',
      rejectButtonStyleClass: 'p-button-outlined',
      acceptLabel: 'Confirm',
      accept: () => {
        this.saveNoWriteSettlementPlan();
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.toastService.add({ severity: 'warn', summary: 'Declined', detail: 'One Time Write-off aborted.' });
            break;
          case ConfirmEventType.CANCEL:
            this.toastService.add({ severity: 'warn', summary: 'Cancelled', detail: 'Operation cancelled.' });
            break;
        }
      }
    });
  }

  saveNoWriteSettlementPlan() {
    let payDts = this.noWriteNextPayDates.filter(f => new Date(f.id) >= new Date(this.noWriteFirstDueDate.value ?? '')).map(p => this.datePipe.transform(new Date(p.desc), 'yyyy-MM-dd'));

    let body = {
      customerGuid: this.customerGuid,
      campaignGuid: this.campaignGuid,
      targetGuid: this.targetGuid,
      settlementAmount: this.totalAccountBalance,
      noOfPayments: this.noWriteNoPayments.value ?? 0,
      paymentAmount: this.paymentAmount,
      payFrequency: this.noWritePayCalendar.getPayFrequencyString(),
      payDates: payDts.join('|'),
      settlementReasonId: this.noWriteReason.value,
      autoPay: this.noWriteAutoPay.value
    };

    let pSub = this.apiService.post(`agenttools/no-write-off/settlement-plan`, body)
    .subscribe({
      next: () => {
        this.reload.emit();
        this.toastService.addSuccess("No Write-Off Settlement Plan successfully completed.");
        this.getData();
      },
      error: (err: any) => {
        this.toastService.addError("Unable to save Write-Off Settlement Plan. See log for details.");
        console.error(err);
      },
      complete: () => { pSub.unsubscribe(); }
    });


  }

  

  settleDatesCalculated(dts: Date[]) {
    this.settleNextPayDates = [];
    if (dts && dts.length > 0) {
      dts.forEach(dt => {
        this.settleNextPayDates.push({ id: dt.getTime().toString(), desc: this.datePipe.transform(dt, 'longDate') ?? 'Calculation Error' });
      });
      this.settleFirstDueDate.reset();
      this.settleFirstDueDate.patchValue(this.settleNextPayDates[0].id);
      setTimeout(() => {
        this.settleFirstDueDate.updateValueAndValidity();
      });
    }
    else {
      this.settleFirstDueDate.reset();
      this.settleFirstDueDate.updateValueAndValidity();
    }
  }

  settleAutoPayChanged(event: any) {
    if (event.value) {
      let msg = ` <p>Mr./Ms. ${this.accountSummary.lastName}, you are re-authorizing us to enable AutoPay for account ending in ${this.accountSummary.last4Account}. Please say "I agree" to confirm.</p>`;
    let doc = this.parser.parseFromString(msg, 'text/html');
    const serializer = new XMLSerializer();
    let confMsg = serializer.serializeToString(doc);
    this.confirmService.confirm({
      key: this.collSettConfKey,
      message: confMsg,
      header: 'AutoPay',
      rejectLabel: 'Back',
      rejectButtonStyleClass: 'p-button-outlined',
      acceptLabel: 'Next',
      accept: () => {
        this.toastService.addSuccess('An email will be sent to the email address on file.  Please follow the instructions within the email to finalize the re-authorization for AutoPay.');
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.toastService.add({ severity: 'warn', summary: 'Declined', detail: 'One Time Write-off aborted.' });
            break;
          case ConfirmEventType.CANCEL:
            this.toastService.add({ severity: 'warn', summary: 'Cancelled', detail: 'Operation cancelled.' });
            break;
        }
        this.settleAutoPay.patchValue(false);
        this.settleAutoPay.updateValueAndValidity();
      }
    });
    }
  }

  settleBtnSaveValid(): boolean {
    return this.settleNoPayments.valid && this.settleFirstDueDate.valid && this.settleReason.valid
  }

  validateSettleSettlement() {
    let msg = `<p><strong>Do you confirm the settlement plan?</strong></p>`;
    let doc = this.parser.parseFromString(msg, 'text/html');
    const serializer = new XMLSerializer();
    let confMsg = serializer.serializeToString(doc);
    this.confirmService.confirm({
      key: this.collSettConfKey,
      message: confMsg,
      header: 'Settlement Plan',
      rejectLabel: 'Back',
      rejectButtonStyleClass: 'p-button-outlined',
      acceptLabel: 'Confirm',
      accept: () => {
        this.saveSettleSettlementPlan();
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.toastService.add({ severity: 'warn', summary: 'Declined', detail: 'One Time Write-off aborted.' });
            break;
          case ConfirmEventType.CANCEL:
            this.toastService.add({ severity: 'warn', summary: 'Cancelled', detail: 'Operation cancelled.' });
            break;
        }
      }
    });
  }

  saveSettleSettlementPlan() {
    let payDts = this.noWriteNextPayDates.filter(f => new Date(f.id) >= new Date(this.noWriteFirstDueDate.value ?? '')).map(p => this.datePipe.transform(new Date(p.desc), 'yyyy-MM-dd'));

    let body = {
      customerGuid: this.customerGuid,
      campaignGuid: this.campaignGuid,
      targetGuid: this.targetGuid,
      settlementAmount: this.totalAccountBalance,
      noOfPayments: this.settleNoPayments.value ?? 0,
      paymentAmount: this.paymentAmount,
      payFrequency: this.settlePayCalendar.getPayFrequencyString(),
      payDates: payDts.join('|'),
      settlementReasonId: this.settleReason.value,
      autoPay: this.settleAutoPay.value
    };

    let pSub = this.apiService.post(`agenttools/with-write-off/settlement-plan`, body)
    .subscribe({
      next: () => {
        this.reload.emit();
        this.toastService.addSuccess("With Write-Off Settlement Plan successfully completed.");
        this.getData();
      },
      error: (err: any) => {
        this.toastService.addError("Unable to save With Write-Off Settlement Plan. See log for details.");
        console.error(err);
      },
      complete: () => { pSub.unsubscribe(); }
    });


  }

}
