<div [formGroup]="group" class="mb-2">
    <label *ngIf="label" [for]="id" class="form-label clabel">{{label}}</label>
    <textarea class="form-control border-round p-3" [id]="id" [placeholder]="placeholder" formControlName="text" [rows]="rows" 
        [ngClass]="group && group.controls[controlName].touched && !group.controls[controlName].valid ? 'is-invalid' : ''">
    </textarea>
<div *ngIf="group && group.controls[controlName].touched && group.controls[controlName]?.invalid" class="invalid-feedback">
    This field is invalid
  </div>
  </div>

  <!-- <div [formGroup]="group" class="mb-2">
    <label *ngIf="label" [for]="id" class="form-label clabel">{{label}}</label>
    <textarea 
    class="form-control"
    [id]="id" 
    [placeholder]="placeholder"
    formControlName="text"
    [rows]="rows" 
    [ngClass]="group && group.controls[controlName].touched && group.controls[controlName]?.invalid ? 'is-invalid' : ''">
</textarea>
<div *ngIf="group && group.controls[controlName].touched && group.controls[controlName]?.invalid" class="invalid-feedback">
    This field is invalid
  </div>
  </div> -->