<h1 mat-dialog-title>Customer Email Changes</h1>
<div mat-dialog-content>
    <div class="mb-3">
        <label for="emailInput" class="form-label">Confirm Code</label>
        <input type="text" class="form-control"
        [ngClass]="confirmCode && confirmCode.touched && confirmCode.invalid ? 'is-invalid' : ''" 
        id="emailInput" 
        style="text-transform:uppercase"
        placeholder="Code" [formControl]="confirmCode">
        <div *ngIf="confirmCode && confirmCode.touched && confirmCode.invalid" class="invalid-feedback">
            Confirm Code is required
          </div>
      </div>
</div>
<div mat-dialog-actions>
    <button pButton (click)="onClick('back')" class="p-button p-button-secondary" >Back</button>
    <span class="px-2"></span>
    <button pButton (click)="onClick('confirmed')" class="p-button">CONFIRM</button>
</div>