<p class="text-2xl font-bold m-2">Communications Queue</p>
<div class="cds-card p-4" [ngClass]="loadingMsgs ? 'pds-div-disabled' : ''">
    <div class="flex flex-auto flex-wrap">

        <!-- left side -->
        <div class="w-12 lg:w-2">
            <div class="flex flex-wrap">
                <div class="w-12 flex">
                    <span class="w-12" *ngIf="hasFeature('CSR_QUEUE_COMMUNICATION_COMPOSE')">
                        <button pButton class="p-button px-8 bg-orange-500 border-orange-500 text-center"
                            label="Compose" (click)="customerSMS ? opCompose.toggle($event) : loadEmail(true);"
                            [icon]="customerSMS ? 'pi pi-angle-down' : ''" iconPos="right"></button>
                        <p-overlayPanel #opCompose [showCloseIcon]="false" [showTransitionOptions]="'0.2s'"
                            styleClass="p-2">
                            <ng-template pTemplate="content">
                                <div class="h-auto flex flex-wrap">
                                    <div class="flex comm-filter-item" (click)="opCompose.hide();clearSMS();loadSMS();">
                                        Free Form SMS</div>
                                    <div class="flex comm-filter-item" (click)="opCompose.hide();loadEmail(true);">Free
                                        Form Email</div>
                                </div>
                            </ng-template>
                        </p-overlayPanel>
                    </span>
                </div>
                <div *ngFor="let cat of categories; index as i" class="w-10 py-3 flex flex-wrap">
                    <div class="w-12 font-bold">{{cat.messageCategoryName}}</div>
                    <div class="commQueueBox" (click)="loadCategory('inbox', cat.messageCategoryID)"
                        [ngClass]="getQueueBoxClass(cat.messageCategoryID, 'inbox')">
                        <span class="w-5">Inbox</span>
                        <div class="w-3 text-right">
                            <span class="commCategorySelected px-2 text-sm">{{counts[cat.messageCategoryID +
                                '_inbox_cnt']}}</span>
                        </div>
                    </div>
                    <div class="commQueueBox" (click)="loadCategory('unknown', cat.messageCategoryID)"
                        [ngClass]="getQueueBoxClass(cat.messageCategoryID, 'unknown')">
                        <span class="w-5">Unknown</span>
                        <div class="w-3 text-right">
                            <span class="commCategorySelected px-2 text-sm">{{counts[cat.messageCategoryID +
                                '_unknown_cnt']}}</span>
                        </div>
                    </div>
                    <div class="commQueueBox" *ngIf="hasFeature('CSR_QUEUE_COMMUNICATION_SHOW_SENT')"
                        (click)="loadCategory('sent', cat.messageCategoryID)"
                        [ngClass]="getQueueBoxClass(cat.messageCategoryID, 'sent')">
                        <span class="w-5">Sent</span>
                        <div class="w-3 text-right">
                            <span class="commCategorySelected px-2 text-sm">{{counts[cat.messageCategoryID +
                                '_sent_cnt']}}</span>
                        </div>
                    </div>
                </div>
                <div class="w-10 py-3 flex flex-wrap" *ngIf="counts">
                    <div class="w-12 font-bold">Unknown</div>
                    <div class="commQueueBox" (click)="loadCategory('inbox', '9999')"
                        [ngClass]="getQueueBoxClass('9999', 'inbox')">
                        <span class="w-5">Replied</span>
                        <div class="w-3 text-right">
                            <span class="commCategorySelected px-2 text-sm">{{counts['unknownCount']}}</span>
                        </div>
                    </div>
                    <div class="commQueueBox" *ngIf="hasFeature('CSR_QUEUE_COMMUNICATION_SHOW_SENT')"
                        (click)="loadCategory('sent', '9999')" [ngClass]="getQueueBoxClass('9999', 'sent')">
                        <span class="w-5">Sent</span>
                        <div class="w-3 text-right">
                            <span class="commCategorySelected px-2 text-sm">{{counts['unknownSentCount']}}</span>
                        </div>
                    </div>
                </div>

                <div class="w-10 py-4 flex flex-wrap border-top-1 border-black-alpha-20"
                    *ngIf="hasFeature('CSR_QUEUE_COMMUNICATION_SHOW_SPAM')">
                    <div class="w-12 py-2 pl-2 mr-4 flex cursor-pointer align-items-center"
                        [ngClass]="messageCategoryId == -1 ? 'commCategorySelected': ''" (click)="toggleView('spam')">
                        <i class="pi pi-exclamation-circle"></i>
                        <span class="pl-4">Spam List</span>
                    </div>
                </div>


                <div class="w-10 py-4 flex flex-wrap border-top-1 border-black-alpha-20 align-contents-center cursor-pointer h-max-content"
                    (click)="showTemplateCategories = !showTemplateCategories;">
                    <i class="pi pi-folder text-xl" aria-hidden="true"></i>
                    <span class="mx-3">Templates</span>
                    <i class="pi pi-angle-down" aria-hidden="true"></i>
                </div>
                <div *ngIf="showTemplateCategories" class="w-12 flex flex-wrap animate__animated animate__fadeIn">
                    <span class="w-12 py-2 text-sm flex" *ngFor="let category of categories; index as i">
                        <p3solved-button id="btnCat{{i}}" label="{{category.messageCategoryName}}" class="text"
                            (buttonClick)="loadTemplates(category)"></p3solved-button>
                        <i class="pi pi-pencil cursor-pointer text-primary flex-grow-1 align-self-center text-end mr-4"
                            (click)="editTemplateDefault(category)"></i>
                    </span>
                </div>
            </div>
        </div>

        <!-- right side -->
        <div class="w-12 pt-4 lg:w-10 border-top-1 lg:border-top-none lg:-ml-4 lg:pt-0">

            <div *ngIf="showMailboxes">
                <div class="flex">
                    <div class="w-6 flex flex-wrap">
                        <div class="w-12 font-bold">{{categoryName}}</div>
                        <div class="w-12">
                            <span class="font-bold text-primary capitalize">{{selectedMailbox}}</span>
                            <span> - {{messageTypeFilter == 0 ? 'Email and SMS' : messageTypeFilter == 1 ? 'Emails Only'
                                :
                                'SMS-s Only'}}</span>
                        </div>
                    </div>
                    <div class="w-6 flex flex-row-reverse">
                        <span class="p-input-icon-left" *ngIf="inbound">
                            <i class="pi pi-search" aria-hidden="true"></i>
                            <input #txtSearch type="text" class="w-15rem" pInputText placeholder="Search"
                                (input)="dt.filterGlobal(txtSearch.value, 'contains')" />
                        </span>
                    </div>
                </div>
                <p-table #dt [value]="messages" dataKey="messageGUID" styleClass="mr-2 my-2" responsiveLayout="scroll"
                    [globalFilterFields]="messageFilterFields" [paginator]="true"
                    [rowsPerPageOptions]="[15, 30, 50, 100]" [showCurrentPageReport]="true"
                    currentPageReportTemplate="{totalRecords} total records" [(first)]="first" [rows]="100"
                    [rowHover]="true" [(selection)]="selectedMsgs">
                    <ng-template pTemplate="header">
                        <tr *ngIf="inbound">
                            <th colspan="2" class="pl-3 py-2 border-right-none">
                                <p-tableHeaderCheckbox></p-tableHeaderCheckbox><span class="px-2">Check All</span>
                            </th>
                            <th [colSpan]="needAttach ? '2' : '5'" class="text-right border-left-none">
                                <span>
                                    <p3solved-button icon="pi pi-refresh" class="large outlined py-3 px-4"
                                        (buttonClick)="getQueue()"></p3solved-button>
                                </span>
                                <span *ngIf="customerSMS">
                                    <button pButton icon="pi pi-filter"
                                        class="p-button-large p-button-outlined py-3 px-4 mx-3"
                                        (click)="opTypeFilter.toggle($event)"></button>
                                    <p-overlayPanel #opTypeFilter [showCloseIcon]="false"
                                        [showTransitionOptions]="'0.2s'" styleClass="p-2">
                                        <ng-template pTemplate="content">
                                            <div class="h-auto flex flex-wrap">
                                                <div class="flex comm-filter-item"
                                                    (click)="filterComms(0);opTypeFilter.hide();">All</div>
                                                <div class="flex comm-filter-item"
                                                    (click)="filterComms(1);opTypeFilter.hide();">
                                                    <i pBadge severity="danger"></i>
                                                    <span class="pl-2">Email</span>
                                                </div>
                                                <div class="flex comm-filter-item"
                                                    (click)="filterComms(3);opTypeFilter.hide();">
                                                    <i pBadge severity="success"></i>
                                                    <span class="pl-2">SMS</span>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </p-overlayPanel>
                                </span>
                                <span *ngIf="hasFeature('CSR_QUEUE_COMMUNICATION_DELETE_MESSAGE')">
                                    <p3solved-button icon="pi pi-trash" class="large outlined py-3 px-4"
                                        (buttonClick)="deleteMessages()"></p3solved-button>
                                </span>
                                <span *ngIf="hasFeature('CSR_QUEUE_COMMUNICATION_COMPLETE_MESSAGE')">
                                    <p3solved-button icon="pi pi-link" class="large outlined py-3 px-4"
                                        (buttonClick)="confirmCompleteMessages()"></p3solved-button>
                                </span>
                                <span *ngIf="needAttach">
                                    <button pButton icon="pi pi-folder"
                                        class="p-button-large p-button-outlined py-3 px-4 mx-3"
                                        (click)="opSearch.toggle($event);toggleSearchForm($event);"></button>
                                    <p-overlayPanel #opSearch [showCloseIcon]="false" [showTransitionOptions]="'0.2s'"
                                        styleClass="w-4 shadow-2">
                                        <ng-template pTemplate="content">
                                            <div *ngIf="showSearchForm" class="p-2">
                                                <form *ngIf="searchFormLoaded" [formGroup]="searchForm">
                                                    <div class="grid">
                                                        <div class="col-12">
                                                            <p3solved-form-text id="srchFN" placeholder="First Name"
                                                                formControlName="firstName"></p3solved-form-text>
                                                        </div>
                                                        <div class="col-12">
                                                            <p3solved-form-text id="srchLN" placeholder="Last Name"
                                                                formControlName="lastName"></p3solved-form-text>
                                                        </div>
                                                        <div class="col-12">
                                                            <p3solved-form-text id="srchPh" placeholder="Phone Number"
                                                                formControlName="phone"></p3solved-form-text>
                                                        </div>
                                                        <div class="col-12">
                                                            <p3solved-form-text id="srchLoan" placeholder="Loan Number"
                                                                formControlName="loanNumber"></p3solved-form-text>
                                                        </div>
                                                        <div class="col-12">
                                                            <p3solved-form-text id="srchEmail"
                                                                placeholder="Email Address"
                                                                formControlName="email"></p3solved-form-text>
                                                        </div>
                                                    </div>
                                                </form>
                                                <span class="px-2">
                                                    <p3solved-button id="btnSearch" label="Search" icon="pi pi-search"
                                                        iconPos="left"
                                                        (buttonClick)="searchMessages()"></p3solved-button>
                                                </span>
                                                <span class="px-2">
                                                    <p3solved-button id="btnSearchCncl" label="Cancel" class="secondary"
                                                        (buttonClick)="opSearch.hide();"></p3solved-button>
                                                </span>
                                            </div>

                                            <div *ngIf="showSearchResults" class="p-2 pds-overlay-bg">
                                                <p-table #availUsers [value]="availableUsers"
                                                    styleClass="p-datatable-sm" responsiveLayout="scroll"
                                                    [columns]="userColumns" dataKey="targetID"
                                                    [(selection)]="selectedUser">
                                                    <ng-template pTemplate="header" let-columns>
                                                        <tr>
                                                            <th></th>
                                                            <th *ngFor="let col of getUserDisplayColumns()">
                                                                {{col.header}}
                                                            </th>
                                                        </tr>
                                                    </ng-template>
                                                    <ng-template pTemplate="body" let-rowData let-columns="columns">
                                                        <tr>
                                                            <td><p-tableRadioButton [value]="rowData"></p-tableRadioButton></td>
                                                            <td *ngFor="let col of getUserDisplayColumns()">
                                                                {{rowData[col.field]}}
                                                            </td>
                                                        </tr>
                                                    </ng-template>
                                                    <ng-template pTemplate="emptymessage">
                                                        <tr>
                                                            <td colspan="6">No matching Accounts.</td>
                                                        </tr>
                                                    </ng-template>
                                                </p-table>
                                                <div class="flex my-4">
                                                    <span class="px-2">
                                                        <p3solved-button id="btnBack" label="Back" class="secondary"
                                                            (buttonClick)="showSearchResults=false;showSearchForm=true;selectedUser=null;">
                                                        </p3solved-button>
                                                    </span>
                                                    <span class="px-2">
                                                        <p3solved-button id="btnCanc" label="Cancel" class="secondary" (buttonClick)="opSearch.hide();selectedUser=null;">
                                                        </p3solved-button>
                                                    </span>
                                                    <span class="px-2">
                                                        <p3solved-button id="btnMove" label="Move" *ngIf="hasFeature('CSR_QUEUE_COMMUNICATION_ATTACH_MESSAGE')"
                                                            [disabled]="!selectedUser || selectedMsgs.length == 0" (buttonClick)="moveMessages()">
                                                        </p3solved-button>
                                                    </span>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </p-overlayPanel>
                                </span>
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-msg>
                        <tr>
                            <td *ngIf="inbound"><p-tableCheckbox [value]="msg"></p-tableCheckbox></td>
                            <td class="cursor-pointer flex align-items-center"
                                [ngClass]="inbound ? 'border-x-none border-top-none' : ''" (click)="viewMessage(msg)">
                                <span class="px-2">
                                    <i pBadge
                                        [severity]="msg.messageTypeID == '1' || msg.messageTypeID == '2' ? 'danger' : 'success'"></i>
                                </span>
                                <span class="px-1">{{msg.messageSubject}}</span>
                                <span class="px-1 font-bold">({{msg.messageFrom}})</span>
                                <span class="px-1 cds-placeholder-color">{{formatMsgBody(msg.messageBody,
                                    msg.accountNumber)}}</span>
                            </td>
                            <td *ngIf="!needAttach">{{msg.accountNumber}}</td>
                            <td *ngIf="!needAttach">{{msg.firstName}}</td>
                            <td *ngIf="!needAttach">{{msg.lastName}}</td>
                            <td>
                                <i class="pi pi-paperclip" *ngIf="msg.attachmentList.length > 0"></i>
                            </td>
                            <td class="text-right">
                                {{msg.sendReceivedDate}} CT
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td [colSpan]="7">No messages found.</td>
                        </tr>
                    </ng-template>
                </p-table>

            </div>

            <div *ngIf="showSpamBox">
                <div class="w-12 flex mb-4">
                    <span class="font-bold">Spam List</span>
                    <span class="font-bold text-primary cursor-pointer ml-2" (click)="addSpamEmail()">Add Email</span>
                </div>

                <table class="w-full" style="border-collapse: collapse;">
                    <tr>
                        <th class="p-3 border-1 border-black-alpha-20">Email</th>
                        <th class="p-3 border-1 border-black-alpha-20">Subject</th>
                        <th class="p-3 border-1 border-black-alpha-20">Body</th>
                        <th class="p-3 border-1 border-black-alpha-20"></th>
                    </tr>
                    <tr *ngFor="let sp of spamEmails; index as i">
                        <td class="p-3 border-1 border-black-alpha-20">{{sp.fromEmail}}</td>
                        <td class="p-3 border-1 border-black-alpha-20">{{sp.subject}}</td>
                        <td class="p-3 border-1 border-black-alpha-20">{{sp.body}}</td>
                        <td class="p-3 border-1 border-black-alpha-20 text-center">
                            <i class="pi pi-pencil text-primary cursor-pointer" title="Edit" (click)="editSpam(sp)"></i>
                            <i class="ml-3 pi pi-trash text-primary cursor-pointer" title="Delete"
                                (click)="confirmDeleteSpam(sp)"></i>
                        </td>
                    </tr>
                </table>
            </div>

            <div *ngIf="showTemplates">
                <div class="w-12 flex">
                    <div class="text-2xl mb-4">{{templateCategoryName}} Templates</div>
                    <div class="flex-grow-1 text-end">
                        <p3solved-button *ngIf="hasFeature('CSR_QUEUE_COMMUNICATION_EDIT_TEMPLATE')" label="Add Template"
                            icon="pi pi-envelope" iconPos="left" class="px-2"
                            (buttonClick)="addTemplate(2)"></p3solved-button>
                        <p3solved-button *ngIf="customerSMS" label="Add Template" icon="pi pi-mobile" iconPos="left"
                            class="px-2" (buttonClick)="addTemplate(4)"></p3solved-button>

                    </div>
                </div>
                <div *ngFor="let t of catTemplates" class="template-row">
                    <div class="flex py-2">
                        <div class="flex align-items-center cursor-pointer" (click)="loadTemplate(t)">
                            <i class="p-badge p-badge-dot"
                                [ngClass]="t.messageTypeID == '2' ? 'bg-orange-500' : 'sucess'"></i>
                            <span class="pl-2">{{ t.subject }}</span>
                        </div>
                        <div class="flex-grow-1 text-end" *ngIf="hasFeature('CSR_QUEUE_COMMUNICATION_EDIT_TEMPLATE')">
                            <p3solved-button label="Edit" class="small warn px-2"
                                (buttonClick)="editTemplate(t)"></p3solved-button>
                            <p3solved-button label="Delete" class="small secondary px-2"
                                (buttonClick)="confirmDeleteTemplate(t)"></p3solved-button>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="showTemplateDefault">
                <app-comm-template-default [customerGuid]="customerGuid" [category]="editTemplateCategory"
                    (onBack)="toggleView('templates');" (onSave)="loadData(); toggleView('templates');">
                </app-comm-template-default>
            </div>

            <div *ngIf="showTemplateEdit">
                <app-comm-template-edit [customerGuid]="customerGuid" [categories]="categories"
                    [template]="selectedTemplate" [messageTypeId]="templateMessageTypeId" (onBack)="toggleView('templates')"
                    (onSave)="loadData(); toggleView('templates');">
                </app-comm-template-edit>
            </div>

            <div *ngIf="showMailboxItem">
                <app-comm-queue-item 
                    [message]="selectedMessage" 
                    [customer]="customer"
                    [showReplyButton]="hasFeature('CSR_QUEUE_COMMUNICATION_COMPOSE') && (needAttach || false) && (inbound || false)"
                    (onBack)="toggleView('mailbox')"
                    (onReply)="replyToMsg($event)">
                </app-comm-queue-item>
            </div>

            <div *ngIf="showNewSMS">
                <app-comm-queue-sms
                    [messageTemplateGuid]="messageTemplateGuid"
                    [replyMessage]="replyToMessage"
                    [customerGuid]="customerGuid"
                    (onRefresh)="loadData()"
                    (onBack)="backFromSMS()">
                </app-comm-queue-sms>
            </div>

            <div *ngIf="showNewEmail">
                <app-comm-queue-email
                [messageTemplateGuid]="messageTemplateGuid"
                [replyMessage]="replyToMessage"
                [customerGuid]="customerGuid"
                (onRefresh)="loadData()"
                (onBack)="backFromSMS()">
            </app-comm-queue-email>
            </div>

        </div>
    </div>

</div>
<div class="pds-div-spinner" *ngIf="loadingMsgs">
    <p-progressSpinner></p-progressSpinner>
</div>



<p3solved-dialog [isVisible]="showEditSpamModal" header="{{spamEmailAction}} Spam Email" headerClass="text-2xl"
    [showHeader]="true" [isModal]="true" [isClosable]="true" (HideClick)="showEditSpamModal=false;"
    [style]="{'width': '25vw'}">
    <div body>
        <form *ngIf="spamEditFormLoaded" [formGroup]="spamEditForm">
            <div class="grid">
                <div class="col-12">
                    <p3solved-form-text id="txtSPFrom" placeholder="From Email"
                        formControlName="from"></p3solved-form-text>
                </div>
                <div class="col-12">
                    <p3solved-form-text id="txtSPSubj" placeholder="Subject"
                        formControlName="subject"></p3solved-form-text>
                </div>
                <div class="col-12">
                    <p3solved-form-textarea id="txtSPBody" placeholder="Body" formControlName="body">
                    </p3solved-form-textarea>
                </div>
            </div>
        </form>
    </div>
    <div footer class="justify-content-end">
        <p3solved-button id="btnDialog1Ok" label="Save" class="w-3"
            (buttonClick)="showEditSpamModal=false;saveSpamEmail();"></p3solved-button>
        <p3solved-button id="btnDialog1Cancel" label="Close" class="p-button-secondary w-3"
            (buttonClick)="showEditSpamModal=false;"></p3solved-button>
    </div>
</p3solved-dialog>

<p-confirmDialog [key]="commQueueConfirmKey" [style]="{width: '25vw'}" [baseZIndex]="10000">
</p-confirmDialog>