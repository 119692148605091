import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatMoney'
})
export class FormatMoneyPipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): unknown {
    if (!value) return value;

    let trimmed = value.toString().replace(/\s+/g, '');
    trimmed = Number(trimmed).toFixed(2).toString();
    var beforeDecimal = trimmed;
    var afterDecimal = '00';
    var hasDecimal = trimmed.indexOf('.') !== -1;
    if (hasDecimal){
      beforeDecimal = trimmed.substring(0, trimmed.indexOf('.'));
      afterDecimal = trimmed.substring(trimmed.indexOf('.') + 1);
    }
    beforeDecimal = beforeDecimal.replace(/[^0-9]*/g, '');
    beforeDecimal = beforeDecimal.replace(/-/g, '');
    if (beforeDecimal.length <= 2) {
      return `${beforeDecimal}.${afterDecimal}`;
    }
    // var scale = trimmed.substring(trimmed.length - 2);
    // var nums = trimmed.substring(0, trimmed.length - 2);
    var nums = beforeDecimal;
    var numSet = [];
    let numbers = [];
    for (var i = nums.length - 1; i >= 0; i--) {
      numSet.unshift(nums[i]);
      if (numSet.length == 3) {
        numbers.unshift(numSet.join(''));
        numSet = [];
      }
    }
    if (numSet.length)
      numbers.unshift(numSet.join(''));
    var result = `${numbers.join(',')}`;
    return `${result}.${afterDecimal}`;
  }

}
