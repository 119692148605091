export class UserProfileDetails {
    businessAddress1 = "";
    businessAddress2 = "";
    businessCity = "";
    businessCountry = "";
    businessEmail = "";
    businessFax = "";
    businessPhone = "";
    businessState = "";
    businessZip = "";
    company = "";
    customerID = "";
    firstName = "";
    homeAddress1 = "";
    homeAddress2 = "";
    homeCity = "";
    homeCountry = "";
    homeEmail = "";
    homeFax = "";
    homePhone = "";
    homeState = "";
    homeZip = "";
    jobTitle = "";
    lastName = "";
    loginEmail = "";
    middleName = "";
    mobile = "";
    suffix = "";
    timeZoneAbb = "";
    timeZoneDescription = "";
    timeZoneID = "";
    title = "";
    titleCompany = "";
    userTypeID = "";
    userTypeName = "";
}