
<div class="relative">
<div class="card-body" [ngClass]="submitting ? 'pds-div-disabled' : ''">
    <h4 class="card-title">
        Account Adjustments
    </h4>
    <div class="form-group mb-2">
        <form [formGroup]="accountAdjustments" *ngIf="accountAdjustmentsLoaded">
            <div class="grid">
                <div class="col-12 p-1">
                    <p3solved-dropdown id="ddCAF" placeholder="Cash Advance Fee Adjustment"
                        [items]="transactionHistoryFees.caf" [showClear]="true"
                        optionLabel="displayDescription" optionValue="row_id"
                        formControlName="caf"></p3solved-dropdown>

                    <div *ngIf="accountAdjustments.value.caf" class="py-2 flex flex-row-reverse">                                           
                        <div class="w-6">
                            <p3solved-input-number id="cafAmt" placeholder="CAF Adjustment Amount"
                                formControlName="cafAmt">
                                <div class="w-12 my-1 p-error text-xs"
                                    *ngIf="accountAdjustments.controls.cafAmt.touched && accountAdjustments.controls.cafAmt.errors">
                                    <p>Invalid amount.</p>
                                </div>
                            </p3solved-input-number>
                        </div>
                    </div>

                </div>
                <div class="col-12 p-1">
                    <p3solved-dropdown id="ddFFC" placeholder="Fixed Finance Charge Adjustment"
                        [items]="transactionHistoryFees.ffc" [showClear]="true"
                        optionLabel="displayDescription" optionValue="row_id"
                        formControlName="ffc">
                    </p3solved-dropdown>

                        <div *ngIf="accountAdjustments.value.ffc" class="py-2 flex flex-row-reverse">                                           
                            <div class="w-6">
                                <p3solved-input-number id="ffcAmt" placeholder="FFC Adjustment Amount"
                                    formControlName="ffcAmt">
                                    <div class="w-12 my-1 p-error text-xs"
                                        *ngIf="accountAdjustments.controls.ffcAmt.touched && accountAdjustments.controls.ffcAmt.errors">
                                        <p>Invalid amount.</p>
                                    </div>
                                </p3solved-input-number>
                            </div>
                        </div>

                </div>
                <div class="col-12 p-1">
                    <p3solved-input-number formControlName="princ" id="princ"
                        placeholder="Principal Balance Adjustment" [required]="true"
                        (blurEvent)="updateTotalAdjustment();">
                        <div class="w-12 my-1 p-error text-xs"
                            *ngIf="accountAdjustments.controls.princ.touched && accountAdjustments.controls.princ.errors">
                            <p>Must be between $0 and {{principalBalance | currency}}</p>
                        </div>
                    </p3solved-input-number>
                </div>
                <div class="col-12 p-1">
                    <p3solved-datepicker id="dtEffect" formControlName="effDt" 
                        [minDate]="effDtMinDate" 
                        [maxDate]="effDtMaxDate"
                        panelStyleClass="dp-max"
                        [showIcon]="true">
                        <div class="w-12 my-1 p-error text-xs"
                            *ngIf="accountAdjustments.controls.effDt.touched && accountAdjustments.controls.effDt.errors">
                            <p>Date is required</p>
                        </div>
                    </p3solved-datepicker>
                </div>
            </div>
        </form>
        <div class="row">
            <div class="col-6 text-muted">Total Fee(s) Adjustment</div>
            <div class="col-6 text-end"><strong>{{totalAdjustment | currency}}</strong></div>
        </div>
        <p3solved-button buttonType="submit" (click)="showAdjustConfirmModal()"
            [disabled]="totalAdjustment == 0 || accountAdjustments.invalid">Confirm Account Adjustment</p3solved-button>

    </div>
</div>
<div class="pds-div-spinner" *ngIf="submitting">
    <p-progressSpinner></p-progressSpinner>
</div>
</div>



<p3solved-dialog [isVisible]="showAcctAdjModal" header="Account Adjustment" headerClass="text-2xl" [showHeader]="true"
    [isModal]="true" [isClosable]="true" (HideClick)="showAcctAdjModal=false;" [style]="{'width': '25vw'}">
    <div body>
        <div class="row">
            <div class="text-center">
                <span>Mr/Ms. {{ accountSummary.lastName }} we are processing the amount of ${{ totalAdjustment }}. You
                    should see this credit reflected in your account the next day.</span>
            </div>
        </div>
    </div>
    <div footer class="justify-content-around">
        <p3solved-button id="btnDialog1Cancel" label="Close" class="p-button-outlined w-3"
            (buttonClick)="showAcctAdjModal=false;"></p3solved-button>
        <p3solved-button id="btnDialog1Ok" label="Save" class="w-3"
            (buttonClick)="showAcctAdjModal=false;postAccountAdjustment();"></p3solved-button>
    </div>
</p3solved-dialog>
