<div class="w-full">
    <form *ngIf="payFormLoaded" [formGroup]="payForm">
        <div class="grid">
            <div class="col-12">
                <p3solved-dropdown id="ddHowOften" placeholder="How often are you paid?" appendTo="body"
                    [items]="payFrequencies" optionLabel="desc" optionValue="id" formControlName="payFrequency"
                    (optionSelected)="payFrequencyChanged($event)"></p3solved-dropdown>
            </div>

            <div class="col-12 flex flex-wrap"
                *ngIf="payForm.value.payFrequency == 'week' || payForm.value.payFrequency == '2week'">
                <div class="w-12">
                    <p3solved-dropdown id="ddDayOfWeek" placeholder="Which day of the week are you paid?"
                        appendTo="body" [items]="daysOfWeek" optionLabel="desc" optionValue="id"
                        (optionSelected)="dayOfWeekChanged($event)" formControlName="dayOfWeek">
                    </p3solved-dropdown>

                </div>
                <div class="w-12"
                    *ngIf="payForm.value.payFrequency == '2week' && next2WeekPayDates.length > 0">
                    <div class="w-12 pb-2">Which day is your next pay date?</div>
                    <div *ngFor="let nextPayDt of next2WeekPayDates; index as i;"
                        class="w-12 pb-3 flex align-items-center">
                        <span class="pr-2">
                            <p-radioButton name="twoWeekNext" value="{{nextPayDt.id}}" inputId="noWrite2WeekNext{{i}}"
                                formControlName="twoWeekNext"></p-radioButton>
                        </span>
                        <span>{{nextPayDt.desc}}</span>
                    </div>
                </div>
            </div>

            <div class="col-12" *ngIf="payForm.value.payFrequency == '2month'">
                <p3solved-dropdown id="ddDayOfWeek2" placeholder="Which day of the week are you paid?" appendTo="body"
                    [items]="semiMonthDays" optionLabel="desc" optionValue="id" formControlName="semiMonthDay"
                    (optionSelected)="semiMonthDayChanged($event)">
                </p3solved-dropdown>
            </div>

            <div class="col-12 flex flex-wrap"
                *ngIf="payForm.value.payFrequency == '2month' && payForm.value.semiMonthDay == '2monthother'">
                <div class="w-12 flex align-items-center">
                    <span class="pr-2">
                        <p-radioButton inputId="rdo2monthother1" name="twoMonthOther" value="1"
                            formControlName="twoMonthOther"></p-radioButton>
                    </span>
                    <span class="pr-2">The</span>
                    <span class="pr-2">
                        <p3solved-dropdown id="dd2MonthOther1" appendTo="body" [items]="twoMonthOther1"
                            optionLabel="desc" optionValue="id" formControlName="twoMonthOtherDate1"
                            (optionSelected)="twoMonthOther1Changed($event)"></p3solved-dropdown>
                    </span>
                    <span class="pr-2">and</span>
                    <span class="pr-2">
                        <p3solved-dropdown id="ddMonthOther2" appendTo="body" [items]="twoMonthOther2"
                            optionLabel="desc" optionValue="id"
                            formControlName="twoMonthOtherDate2"
                            (optionSelected)="twoMonthOtherChanged()"></p3solved-dropdown>
                    </span>
                    <span>of each month</span>

                </div>
                <div class="w-12 flex align-items-center">
                    <span class="pr-2">
                        <p-radioButton inputId="rdo2monthother2" name="twoMonthOther" value="2"
                            formControlName="twoMonthOther" class="mr-2"></p-radioButton>
                    </span>
                    <span class="pr-2">The</span>
                    <span class="pr-2">
                        <p3solved-dropdown id="dd2MonthOtherWhen" appendTo="body" [items]="twoMonthOtherWhen"
                            optionLabel="desc" optionValue="id" formControlName="twoMonthOtherWhen"
                            (optionSelected)="twoMonthOther2Changed()"></p3solved-dropdown>
                    </span>
                    <span class="pr-2">
                        <p3solved-dropdown id="dd2MODayOfWeek" appendTo="body" [items]="daysOfWeek" optionLabel="desc"
                            optionValue="id" formControlName="twoMonthOtherDay"
                            (optionSelected)="twoMonthOther2Changed()"></p3solved-dropdown>
                    </span>
                    <span>of each month</span>
                </div>
            </div>

            <div class="col-12 flex flex-wrap" *ngIf="payForm.value.payFrequency == 'month'">
                <div class="w-12"><span>Which day are you paid each month?</span></div>
                <div class="w-12 flex align-items-center">
                    <span class="pr-2">
                        <p-radioButton inputId="rdo2monthother1" name="radioMonth" value="1"
                            formControlName="radioMonth"></p-radioButton>
                    </span>
                    <span class="pr-2">The</span>
                    <span class="pr-2">
                        <p3solved-dropdown id="ddMonthDay" appendTo="body" [items]="days" optionLabel="desc"
                            optionValue="id" formControlName="monthDay"
                            (optionSelected)="monthDayChanged()"></p3solved-dropdown>
                    </span>
                    <span class="pr-2">of each month</span>
                </div>
                <div class="w-12 flex align-items-center">
                    <span class="pr-2">
                        <p-radioButton inputId="rdo2monthother2" name="radioMonth" value="2"
                            formControlName="radioMonth" class="mr-2"></p-radioButton>
                    </span>
                    <span class="pr-2">The</span>
                    <span class="pr-2">
                        <p3solved-dropdown id="ddMonthWhen" appendTo="body" [items]="monthWhens" optionLabel="desc"
                            optionValue="id" formControlName="monthWhen"
                            (optionSelected)="monthDay2Changed()"></p3solved-dropdown>
                    </span>
                    <span class="pr-2">
                        <p3solved-dropdown id="dd2MODayOfWeek" appendTo="body" [items]="daysOfWeek" optionLabel="desc"
                            optionValue="id" formControlName="monthWhenWeekday"
                            (optionSelected)="monthDay2Changed()"></p3solved-dropdown>
                    </span>
                    <span>of each month</span>
                </div>

            </div>
        </div>
    </form>
</div>