<app-dashboard-header></app-dashboard-header>
<p class="text-2xl pt-5">Account Servicing</p>
<div *ngIf="!loading">
  <div class="row">
    <div class="md:col-12 lg:col-9">
      <app-account-servicing-option-one
        *ngIf="serviceOption == '1'"
        [accountSummary]="accountSummary"
        [scheduledPayments]="scheduledPayments"
        [lateFees]="lateFees"
        [ids]="ids"
        [paymentOptions]="paymentOptions"
        [processors]="processors"
        [transactionHistory]="transactionHistory"
        [upcomingPayments]="upcomingPayments"
        (getAccountSumm)="getAccountSummary()"
      ></app-account-servicing-option-one>
      <app-account-servicing-option-two
        *ngIf="serviceOption == '2'"
        [accountSummary]="accountSummary"
        [scheduledPayments]="scheduledPayments"
        [paymentOptions]="paymentOptions"
        [upcomingPayments]="upcomingPayments"
        (refreshData)="refreshData()"
      ></app-account-servicing-option-two>
    </div>
    <div class="md:col-12 lg:col-3">
      <app-customer-notes #custNotes></app-customer-notes>
    </div>
  </div>
</div>
