<h1 mat-dialog-title>Customer Email Change</h1>
<div>
    <div class="mb-3">
        <label for="emailInput" class="form-label">New Email</label>
        <p3solved-form-text type="email" class="form-control"
            [ngClass]="email && email.touched && email.invalid ? 'is-invalid' : ''" 
            id="emailInput" 
            placeholder="name@example.com" [formControl]="email"></p3solved-form-text>
        <div *ngIf="email && email.touched && email.invalid" class="invalid-feedback">
            Valid email is required
          </div>
      </div>
</div>
<div mat-dialog-actions>
    <p3solved-button class="secondary pl-3 pr-3" (click)="onClick('back')">Back</p3solved-button>
    <p3solved-button class="primary" (click)="onClick('confirmed')">CONFIRM</p3solved-button>
</div>

<!-- <h1 mat-dialog-title>Customer Email Change</h1>
<div mat-dialog-content>
    <div class="mb-3">
        <label for="emailInput" class="form-label">New Email</label>
        <input type="email" class="form-control"
        [ngClass]="email && email.touched && email.invalid ? 'is-invalid' : ''" 
        id="emailInput" 
        placeholder="name@example.com" [formControl]="email">
        <div *ngIf="email && email.touched && email.invalid" class="invalid-feedback">
            Valid email is required
          </div>
      </div>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="onClick('back')" color="primary">Back</button>
    <button mat-flat-button (click)="onClick('confirmed')" color="primary">CONFIRM</button>
</div> -->