import { Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { SitePageAccess, UserSiteFeature } from '../models/user-site-features';
import { ApiService } from './api.service';
import { CustomerService } from './customer.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private _siteFeatures = new BehaviorSubject<UserSiteFeature[]>([]);
  private userAdminPages = new BehaviorSubject<SitePageAccess[] | null>([]);
  siteFeatures$ = this._siteFeatures.asObservable();
  userAdminPages$ = this.userAdminPages.asObservable();

  custSub: Subscription | null = null;
  customerGuid: string | null = null;
  customerGuidUpdated: boolean = false;
  pageAccessLoaded: boolean = false;
  siteFeaturesLoaded: boolean = false;
  constructor(
    private customerService: CustomerService,
    private apiService: ApiService,
    private router: Router
  ) { }

  initCustSub() {
    this.custSub = this.customerService.customerGuid$.subscribe({
      next: async (c: string) => {
        if (!this.customerGuid && c && this.customerGuid != c) {
          this.customerGuid = c;
          this.customerGuidUpdated = true;
        }
      },
      error: (err: any) => {
        this.customerGuid = null;
        console.error('UserService.initCustSub error: ', err);        
      }
    })
  }

  async subscribeAccess() {
    if (this.customerGuid && this.customerGuidUpdated) {
      this.pageAccessLoaded = false;
      this.siteFeaturesLoaded = false;
      await this.loadUserFeatures();
      await this.loadUserAdminPages();
      this.customerGuidUpdated = false;
    }
    else {
      console.error('UserService.subscribeAccess customerGuid not set!');
    }

  }

  async loadUserFeatures(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.apiService.get(`csr/user-site-features/${this.customerGuid}`).toPromise()
      .then((res: UserSiteFeature[]) => {
        this._siteFeatures.next(res);
        this.siteFeaturesLoaded = true;
        resolve(true);      
      }, (err: any) => {
        console.error('UserService.loadUserFeatures error: ', err);
        reject(false);
      }).catch((ex: any) => {
        console.error('UserService.loadUserFeatures exception: ', ex);
        reject(false);
      });
    });
  }

  async loadUserAdminPages(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.apiService.get(`admin/get-pages/${this.customerGuid}`).toPromise()
      .then((res: SitePageAccess[]) => {
        this.userAdminPages.next(res || []);
        this.pageAccessLoaded = true;
        resolve(true);      
      }, (err: any) => {
        console.error('UserService.loadUserAdminPages error: ', err);
        reject(false);
      }).catch((ex: any) => {
        console.error('UserService.loadUserAdminPages exception: ', ex);
        reject(false);
      });
    });
  }

  // hasPageAccess(pageId: number): boolean {
  //   if (!pageId) return false;

  //   let pages = this.userAdminPages.getValue();
  //   let item = pages?.find(item => item.sitePageID === pageId);
  //   if (!item) return false;

  //   return item.allowAccess;
  // }

  // checkPageAccess(pageId: number) {
  //   let hasAccess: boolean = false;
  //   if (!pageId) hasAccess = false;

  //   let pages = this.userAdminPages.getValue();
  //   let item = pages?.find(item => item.sitePageID === pageId);
  //   if (!item) hasAccess = false;
  //   else hasAccess = item.allowAccess;
  //   if (!hasAccess) this.router.navigate(['not-authorized']);
  // }

  // hasFeature(feature: string): boolean {
  //     if (!feature) return false;
  //     var features = this._siteFeatures.getValue();
  //     if (!features || !features.length) return false;
  //     var item = features.find(item => item.code === feature);
  //     if (!item) return false;

  //     return item.allowRead || item.allowWrite;
  // }

  // hasRead(feature: string): boolean {
  //     if (!feature) return false;
  //     var features = this._siteFeatures.getValue();
  //     if (!features || !features.length) return false;
  //     var item = features.find(item => item.code === feature);
  //     if (!item) return false;

  //     return item.allowRead;
  // }

  // hasWrite(feature: string, attempts: number = 0): boolean {
  //     if (!feature) return false;
  //     var features = this._siteFeatures.getValue();
  //     if (!features || !features.length) return false;
  //     var item = features.find(item => item.code === feature);
  //     if (!item) return false;

  //     return item.allowWrite;
  //   }








    hasPageAccess(pageId: number): boolean {
      return true;
    }

  checkPageAccess(pageId: number) {
    return true;
  }

  hasFeature(feature: string): boolean {
    return true;
  }

  hasRead(feature: string): boolean {
    return true;
  }

  hasWrite(feature: string, attempts: number = 0): boolean {
    return true;
    }


  testFormValidators(form: FormGroup | FormArray): void {
    console.log('Test Form Validity start');
    Object.keys(form.controls).forEach(key => {
      const control = form.get(key) as FormControl | FormGroup | FormArray;
      if (control instanceof FormControl) {
        if (control.errors) {
          for (const err in control.errors) {
            console.log(`${key} has error: ${err}`);
          }
        }
      } else if (control instanceof FormGroup || control instanceof FormArray) {
        this.testFormValidators(control);
      } else {
        console.log("ignoring control: ", key)
      }
    });
    console.log('Test Form Validity complete');
  }


}
