<app-dashboard-header></app-dashboard-header>
<div class="py-4 pl-3">
    <h4 class="page-title">Agent Tools</h4>
</div>
<div class="container-fluid">
    <div class="grid">
        <div class="col-12 lg:col-8">
            <div class="flex flex-wrap">
                <div *ngFor="let button of agentButtons; index as i"
                    class="w-12 lg:w-4 px-2 my-2 h-6rem">
                    <div class="w-12 p-2 grid cursor-pointer h-6rem" [style.background-color]="button['bg-color']"
                        (click)="agentToolsItemClick(button.url)" [style.color]="button.color">
                        <div class="col-2 align-self-center">
                            <i *ngIf="button.icon.length > 0" class="w-1 text-4xl {{button.icon}}"></i>
                        </div>
                        <div class="col-10 align-self-center text-center pr-6">
                            <span class="text-2xl py-4">{{button.btn}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 lg:col-4">
            <app-customer-notes></app-customer-notes>
        </div>
    </div>
</div>