<div class="relative">
    <div class="card-body">
        <h4 class="card-title">Bank Account Adjustments</h4>
        <form *ngIf="bankAdjustFormLoaded" [formGroup]="bankAdjustForm">
            <div class="grid">
                <div class="col-12">
                    <p3solved-dropdown id="ddSusRef" placeholder="Reason" [items]="reasons" [showClear]="true"
                        formControlName="reason">
                        <div class="w-12 my-1 p-error text-xs"
                            *ngIf="bankAdjustForm.controls.reason.touched && bankAdjustForm.controls.reason.errors">
                            <p>Reason is required</p>
                        </div>
                    </p3solved-dropdown>
                </div>
                <div class="col-12">
                    <p3solved-input-number id="txtAmount" placeholder="Enter Amount" formControlName="amount"
                        [disabled]="amountReadOnly">
                        <div class="w-12 my-1 p-error text-xs"
                            *ngIf="bankAdjustForm.controls.amount.touched && bankAdjustForm.controls.amount.errors">
                            <p>Amount is required</p>
                        </div>
                    </p3solved-input-number>
                </div>
                <div class="col-12">
                    <p3solved-datepicker id="dtEffect" formControlName="effDt" [minDate]="effDtMinDate"
                        [maxDate]="effDtMaxDate" panelStyleClass="dp-max" [showIcon]="true" formControlName="effDate">
                        <div class="w-12 my-1 p-error text-xs"
                            *ngIf="bankAdjustForm.controls.effDate.touched && bankAdjustForm.controls.effDate.errors">
                            <p>Date is required</p>
                        </div>
                    </p3solved-datepicker>
                </div>
                <div class="col-12">
                    <p3solved-button id="btnConf" label="Confirm Bank Account Adjustment"
                        [disabled]="!bankAdjustForm.valid" (buttonClick)="confirmBankAdjust()"></p3solved-button>
                </div>

            </div>
        </form>


    </div>
    <div class="pds-div-spinner" *ngIf="submitting">
        <p-progressSpinner></p-progressSpinner>
    </div>
</div>

<p3solved-dialog [isVisible]="showBankAcctReasonModal" header="Bank Account Adjustment - Verify Reason"
    headerClass="text-2xl" [showHeader]="true" [isModal]="true" [isClosable]="true"
    (HideClick)="showBankAcctReasonModal=false;" [style]="{'width': '35vw'}">
    <div body>
        <div class="row">
            <div class="text-center">
                <span>Please verify reason before proceeding with Bank Account Adjustment:</span>
                <p *ngIf="bankAdjustForm.value"><strong>{{bankAdjustForm.value.reason?.label}}</strong></p>
            </div>
        </div>
    </div>
    <div footer class="justify-content-around">
        <p3solved-button id="btnDialog1Cancel" label="Close" class="p-button-outlined w-3"
            (buttonClick)="showBankAcctReasonModal=false;"></p3solved-button>
        <p3solved-button id="btnDialog1Ok" label="Confirm" class="w-3"
            (buttonClick)="confirmReason()"></p3solved-button>
    </div>
</p3solved-dialog>

<p3solved-dialog [isVisible]="showBankAcctModal" header="Bank Account Adjustment" headerClass="text-2xl"
    [showHeader]="true" [isModal]="true" [isClosable]="true" (HideClick)="showBankAcctModal=false;"
    [style]="{'width': '25vw'}">
    <div body>
        <div class="row">
            <div class="text-center">
                <span *ngIf="bankAdjustForm.value">Mr/Ms. {{ accountSummary.lastName }} we are processing the amount of
                    {{ bankAdjustForm.value.amount | currency }}.
                    You should see this credit reflected in your account the next day.</span>
            </div>
        </div>
    </div>
    <div footer class="justify-content-around">
        <p3solved-button id="btnDialog1Cancel" label="Back" class="p-button-outlined w-3"
            (buttonClick)="showBankAcctModal=false;"></p3solved-button>
        <p3solved-button id="btnDialog1Ok" label="Confirm" class="w-3"
            (buttonClick)="showBankAcctModal=false;postBankAdjustment();"></p3solved-button>
    </div>
</p3solved-dialog>