<div class="form-group mb-2">
    <form [formGroup]="accountAdjustments">
        <div class="p-grid p-fluid">
        <div class="p-field p-col-12 p-md-1">
            <p3solved-input-number formControlName="principal" id="principal" placeholder="Principal Adjustment" [required]="true" (blurEvent)="updateTotalAdjustment($event);">
                <div class="w-12 my-1 p-error text-xs"
                        *ngIf="!accountAdjustments.controls['principal'].pristine && accountAdjustments.controls['principal'].errors">   
                    <p *ngIf="accountAdjustments.controls['principal'].errors?.required">Required</p>
                    <p *ngIf="accountAdjustments.controls['principal'].errors?.min">Invalid input.</p>   
                    <p *ngIf="accountAdjustments.controls['principal'].errors?.max">Invalid Input ( Max = ${{this.accountSummary.principalBalance}})</p>  
                </div>
            </p3solved-input-number>
        </div>
        <div class="p-field p-col-12 p-md-1">
            <p3solved-input-number formControlName="interest" id="interest" placeholder="Interest Adjustment" [required]="true" (blurEvent)="updateTotalAdjustment($event);">
                <div class="w-12 my-1 p-error text-xs"
                        *ngIf="!accountAdjustments.controls['interest'].pristine && accountAdjustments.controls['interest'].errors">
                    <p *ngIf="accountAdjustments.controls['interest'].errors?.required">Required</p>
                    <p *ngIf="accountAdjustments.controls['interest'].errors?.min">Invalid input.</p>   
                    <p *ngIf="accountAdjustments.controls['interest'].errors?.max">Invalid Input ( Max = ${{this.accountSummary.interestBalance}})</p>
                </div>
            </p3solved-input-number>
        </div>
        <div class="p-field p-col-12 p-md-1" >
              <p3solved-input-number formControlName="lateFee" id="lateFee" placeholder="Late Fee Adjustment" [required]="true" (blurEvent)="updateTotalAdjustment($event);">
                <div class="w-12 my-1 p-error text-xs"
                        *ngIf="!accountAdjustments.controls['lateFee'].pristine && accountAdjustments.controls['lateFee'].errors">
                    <p *ngIf="accountAdjustments.controls['lateFee'].errors?.required">Required</p>
                    <p *ngIf="accountAdjustments.controls['lateFee'].errors?.min">Invalid input.</p>   
                    <p *ngIf="accountAdjustments.controls['lateFee'].errors?.max">Invalid Input ( Max = ${{this.accountSummary.feesBalance}})</p>
                </div>
            </p3solved-input-number>
        </div>
    </div>
    </form>
    <div class="row">
        <div class="col-6 text-muted">Total Fee(s) Adjustment</div>
        <div class="col-6 text-end"><strong>{{totalAdjustment | currency}}</strong></div>
    </div>
    <p3solved-button buttonType="submit" (click)="confirmAccountAdjustment()" [disabled]="accountAdjustments.invalid">Confirm Account Adjustment</p3solved-button>

</div>
