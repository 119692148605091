<div class="cds-card p-4 flex flex-wrap text-base">
  <span class="w-12 font-medium text-xl">Note: By unchecking or not checking any of the below options, you are opted
    OUT of that communication type.</span>
  <span class="w-12 font-bold text-lg mt-5">Information Sharing Preference</span>

  <span class="w-12 pt-4">
    <p-checkbox
      [(ngModel)]="shareNonpublic"
      [binary]="true"
      [label]="label1"
      labelStyleClass="line-height-3"
      styleClass="align-self-baseline"
      (onChange)="checkBoxChanged($event, 'Share Opt Out', 'share')">
    </p-checkbox>
  </span>

  <span class="w-12 font-bold text-lg mt-5">Contact Preference</span>

  <span class="w-12 pt-4 flex">
    <p-checkbox
      [(ngModel)]="accountPhoneMe"
      [binary]="true"
      [label]="label2"
      labelStyleClass="line-height-3"
      styleClass="align-self-baseline"
      (onChange)="checkBoxChanged($event, 'Phone Account Opt In', 'phone_account')">
    </p-checkbox>
  </span>

  <span class="w-12 pt-4">
    <p-checkbox
      [(ngModel)]="marketingPhoneMe"
      [binary]="true"
      [label]="label3"
      labelStyleClass="line-height-3"
      styleClass="align-self-baseline"
      (onChange)="checkBoxChanged($event, 'Phone Opt In', 'phone')">
    </p-checkbox>
  </span>

  <span class="w-12 pt-4">
    <p-checkbox
      [(ngModel)]="marketingEmailMe"
      [binary]="true"
      [label]="label4"
      labelStyleClass="line-height-3"
      styleClass="align-self-baseline"
      (onChange)="checkBoxChanged($event, 'Email Opt In', 'email')">
    </p-checkbox>
  </span>

  <span class="w-12 pt-4">
    <p-checkbox
      [(ngModel)]="marketingTextMe"
      [binary]="true"
      [label]="label5"
      labelStyleClass="line-height-3"
      styleClass="align-self-baseline"
      (onChange)="checkBoxChanged($event, 'Text Opt In', 'text')">
    </p-checkbox>
  </span>

  <span class="w-12 font-bold text-lg mt-5">Arbitration Agreement</span>

  <span class="w-12 pt-4">
    <p-checkbox
      [(ngModel)]="arbitration"
      [binary]="true"
      [label]="label6"
      labelStyleClass="line-height-3"
      styleClass="align-self-baseline"
      (onChange)="checkBoxChanged($event, 'Account is subject to Arbitration?', 'arbitration')">
    </p-checkbox>
  </span>

  <span class="w-12 font-bold text-lg mt-5">Other</span>

  <span class="w-12 pt-4">
    <p-checkbox
      [(ngModel)]="doNotCallFlag"
      [binary]="true"
      [label]="label7"
      labelStyleClass="line-height-3"
      styleClass="align-self-baseline"
      (onChange)="checkBoxChanged($event, 'DO NOT CALL Flag', 'do_not_call')">
    </p-checkbox>
  </span>

  <div class="w-12 pt-6 pb-4">
    <p>
      <strong>Important:</strong> By unchecking or not checking any of the above
      options, you are opted out of that communication type. Please note that
      opting out of text messages may take up to 24 hours to take effect and
      update in the system.
    </p>
  </div>
</div>

<p-confirmDialog key="{{ customerContactNewConfirmKey }}" [style]="{ width: '25vw' }" [baseZIndex]="10000" rejectButtonStyleClass="p-button-danger">
  <ng-template pTemplate="header">
    <span class="text-4xl text-center line-height-2">Contact Setting Change Confirmation</span>
  </ng-template>
</p-confirmDialog>
