import { Component, OnInit } from '@angular/core';
import { ActivatedIds } from 'src/app/models/activated-ids';
import { ContactDocumentModel } from 'src/app/models/csr/document-model';
import { ApiService } from 'src/app/services/api.service';
import { MainService } from 'src/app/services/main.service';

@Component({
  selector: 'app-contract-documents',
  templateUrl: './contract-documents.component.html',
  styleUrls: ['./contract-documents.component.scss']
})
export class ContractDocumentsComponent implements OnInit {
  ids: ActivatedIds = new ActivatedIds;
  loading = false;
  docs: ContactDocumentModel[] = [];

  constructor(private main: MainService, 
    private api: ApiService) { }

  ngOnInit(): void {
    this.main.activatedIds$.subscribe(id => {
      if (id && this.ids.targetGuid !== id.targetGuid){
        this.ids = id;
        this.getDocuments();
      }
    });
  }

  getDocuments(){
    this.loading = true;
    var model = this.getTargetModel('');
    var body = JSON.stringify(model);
    this.api.post(`csr/customer-pdfs`, body)
      .subscribe((res: any) => {
        this.loading = false;
        this.docs = res;
      },
        (err: any) => {
          this.loading = false;
          console.error(err);
        }
      );
  }

  private getTargetModel(val: string){
    return {
      CustomerGuid: this.ids.customerGuid,
      CampaignGuid: this.ids.campaignGuid,
      TargetGuid: this.ids.targetGuid,
      Value: val
    }
  }

}
