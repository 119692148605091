import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-email-code-modal',
  templateUrl: './confirm-email-code-modal.component.html',
  styleUrls: ['./confirm-email-code-modal.component.scss']
})
export class ConfirmEmailCodeModalComponent implements OnInit {

  confirmCode = new FormControl('', [Validators.required]);
  
  constructor(
    public dialogRef: MatDialogRef<ConfirmEmailCodeModalComponent>,
  ) { }

  ngOnInit(): void {
  }

  onClick(action: string){
    if (action === 'confirmed'){
      if (this.confirmCode.valid){
        this.dialogRef.close(this.confirmCode.value);
      }else{
        this.confirmCode.markAllAsTouched();
      }
    }else{
      this.dialogRef.close(action);
    }
  }

}
