
<div mat-dialog-content>
  <div class="p-4 text-center modal-container">
      <mat-icon class="rerun-icon m-2">error_outline</mat-icon>
    <!-- <fa-icon [icon]="iconCheck" size="5x" [classes]="['m-3 text-warning']"></fa-icon> -->
    <p class="h3"><strong>Rerun Rules</strong></p>
    <div class="m-2">
        <select class="form-select"  [formControl]="runAction">
            <option value="save">Save Current Results</option>
            <option value="reset">Reset Current Results</option>
          </select>
    </div>

      <div class="flex justify-content-center">
          <div class="m-1">
            <button type="button" class="btn btn-success" (click)="onClick('yes')">Rerun</button>

          </div>
          <div class="m-1">
            <button type="button" class="btn btn-danger" (click)="onClick('no')">Cancel</button>

          </div>
      </div>
  </div>
</div>