import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TemplateFormGroup } from 'src/app/models/form-groups';
import { MessageCategory, MessageTemplate } from 'src/app/models/message-template';
import { ApiService } from 'src/app/services/api.service';
import { CustomerService } from 'src/app/services/customer.service';
import { HelperService } from 'src/app/services/helper.service';
import { ToastService } from 'src/app/services/toast.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-comm-template-edit',
  templateUrl: './comm-template-edit.component.html',
  styleUrls: ['./comm-template-edit.component.scss']
})
export class CommTemplateEditComponent implements OnInit {

  @Output() onBack = new EventEmitter();
  @Output() onSave = new EventEmitter();
  @Input() customerGuid: string | null = null;
  @Input() categories: MessageCategory[] = [];
  @Input() template: MessageTemplate | null = null;
  @Input() messageTypeId: number = 2; //2 = Email; 4 = SMS

  action: string = 'Edit';

  templateFormLoaded: boolean = false;

  templateForm: FormGroup<TemplateFormGroup> = {} as FormGroup;
  
  constructor(
    private userService: UserService,
    private customerService: CustomerService,
    private toastService: ToastService,
    private apiService: ApiService
    ) { }

  ngOnInit(): void {
    if (!this.template) {
      this.action = 'New';
    }
    else {
      this.action = 'Edit';
    }
    setTimeout(() => {
      this.initForm();      
    });
  }

  initForm() {
    this.templateForm = new FormGroup<TemplateFormGroup>({
      category: new FormControl<string|null>(null, {nonNullable: true, validators: [Validators.required]}),
      name: new FormControl<string>('', {nonNullable: true, validators: [Validators.required]}),
      body: new FormControl<string>('', {nonNullable: true, validators: [Validators.required]})
    });
    
    
    if (this.template) {
      let cSub = this.customerService.getMessageTemplate(this.template.messageTemplateGUID)
      .subscribe({
        next: (details: any) => {
          this.templateForm.patchValue({
            category: details.messageCategoryID ?? null,
            name: details.subject ?? '',
            body: details.body ?? ''
          });
          this.templateForm.updateValueAndValidity();
        },
        error: (err: any) => {
          this.toastService.addError("Unable to get Template details. See log for more information.");
          console.error(err);        
        },
        complete: () => { cSub.unsubscribe(); }
      });
    }

    this.templateFormLoaded = true;
  }

  hasFeature(feature: string, mode: string = 'write') {
    switch (mode) {
      case 'read':
        return this.userService.hasRead(feature);
        break;
      case 'feature':
        return this.userService.hasFeature(feature);
        break;
      default:
        return this.userService.hasWrite(feature);
        break;
    }
  }

  saveTemplate() {
    let body = {
      customerGuid: this.customerGuid,
      templateGuid: this.template?.messageTemplateGUID ?? null,
      messageTypeId: this.messageTypeId,
      messageCategoryId: +(this.templateForm.value.category ?? 0),
      description: this.templateForm.value.name,
      subject: this.templateForm.value.name,
      body: this.templateForm.value.body,
    };

    let pSub = this.apiService.post(`communications/message-template-post`, body)
    .subscribe({
      next: () => {
        this.toastService.addSuccess("Template saved.");
        this.templateFormLoaded = false;
        this.templateForm = {} as FormGroup;
        this.onSave.emit();
      },
      error: (err: any) => {
        this.toastService.addError("Unexpected Error. Unable to save Template. See log for details.");
        console.error(err);
      },
      complete: () => { pSub.unsubscribe(); }
    });
  }

}
