export class PaymentMethodModel {
    bankAccountNumberLast4 = ""
    bankInstitutionName = ""
    canUse = ""
    expired = ""
    invalid = ""
    isPrimary = ""
    isSecondary = ""
    loanPaymentMethodDescription = ""
    loanPaymentMethodGUID = ""
    loanPaymentMethodGUID_Combined = ""
    loanPaymentMethodType = ""
    paylianceCardExpirationFormatted = ""
    paylianceCardLastFour = ""
    paylianceCardNetwork = ""

}