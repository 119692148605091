<app-dashboard-header></app-dashboard-header>
<div class="col-5 align-self-center">
    <div class="flex flex-column">
        <div class="text-xl pl-3">Courtesy Adjustment</div>
        <p-breadcrumb [model]="bcItems"></p-breadcrumb>
    </div>
</div>

<div class="w-full px-1 mx-auto">
    <div class="flex">
        <div class="col-8 grid">
            <div class="col-6">
                <div class="cds-card">
                    <div *ngIf="serviceOption == '1'">
                        <app-account-adjustment-option-one></app-account-adjustment-option-one>
                    </div>
                    <div *ngIf="serviceOption == '2'">
                        <app-account-adjustment-option-two #acctAdjustOptTwo [customerGuid]="customerGuid"
                            [targetGuid]="targetGuid" [campaignGuid]="campaignGuid"
                            [transactionHistoryFees]="transactionHistoryFees" [courtesyAdjustinfo]="courtesyAdjustinfo"
                            [accountSummary]="accountSummary" (reload)="reloadData()">
                        </app-account-adjustment-option-two>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="cds-card">
                    <div *ngIf="serviceOption == '1'">
                        <app-credit-card-adjustment></app-credit-card-adjustment>
                    </div>
                    <div *ngIf="serviceOption == '2'">
                        <app-debit-card-adjustment [customerGuid]="customerGuid" [targetGuid]="targetGuid"
                            [campaignGuid]="campaignGuid" [accountSummary]="accountSummary"
                            [debitCardPayments]="debitCardPayments" (reload)="reloadData()">
                        </app-debit-card-adjustment>
                    </div>
                </div>
                <div class="cds-card">
                    <div *ngIf="serviceOption == '1'">
                        <app-bank-adjustment-option-one></app-bank-adjustment-option-one>
                    </div>
                    <div *ngIf="serviceOption == '2'">
                        <app-bank-adjustment-option-two #bankAdjustOpt2 [customerGuid]="customerGuid"
                            [targetGuid]="targetGuid" [campaignGuid]="campaignGuid"
                            [transactionHistoryFees]="transactionHistoryFees" [accountSummary]="accountSummary"
                            [courtesyAdjustinfo]="courtesyAdjustinfo" (reload)="reloadData()">>
                        </app-bank-adjustment-option-two>
                    </div>
                </div>
            </div>
            <div class="col-12" *ngIf="serviceOption == '2'">
                <div class="cds-card">
                    <div class="card-body">
                        <h4 class="card-title">
                            Rewards Adjustment
                        </h4>
                        <div class="grid py-4">
                            <div class="col-5 mx-2">
                                <app-customer-reward-info #rewards [rewardInfo]="rewardInfo"></app-customer-reward-info>
                            </div>
                            <div class="col-6 mx-2">
                                <form *ngIf="rewardAdjustFormLoaded" [formGroup]="rewardAdjustForm">
                                    <div class="grid">
                                        <div class="col-12">
                                            <p3solved-dropdown id="ddRewardAdj" placeholder="Point Adjustment"
                                                [items]="pointAdjustments" optionLabel="desc" optionValue="desc"
                                                formControlName="points" [showClear]="true">
                                                <div class="w-12 my-1 p-error text-xs"
                                                    *ngIf="rewardAdjustForm.controls.points.touched && rewardAdjustForm.controls.points.errors">
                                                    <p>Points is required</p>
                                                </div>
                                            </p3solved-dropdown>
                                        </div>
                                        <div class="col-12">
                                            <p3solved-form-textarea id="txtComments"
                                                placeholder="Comments"
                                                formControlName="comments">
                                                <div class="w-12 my-1 p-error text-xs"
                                                    *ngIf="rewardAdjustForm.controls.comments.touched && rewardAdjustForm.controls.comments.errors">
                                                    <p>Comments are required</p>
                                                </div>
                                            </p3solved-form-textarea>
                                        </div>
                                        <div class="col-12">
                                            <p3solved-button id="btnSubRew"
                                                label="Confirm Rewards Adjustment" [disabled]="!rewardAdjustForm.valid"
                                                (buttonClick)="confirmRewardAdjust()"></p3solved-button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-4">
            <app-customer-notes #custNotes></app-customer-notes>
        </div>
    </div>
</div>


<p3solved-dialog [isVisible]="showAcctAdjModal" header="Account Adjustment" headerClass="text-2xl" [showHeader]="true"
    [isModal]="true" [isClosable]="true" (HideClick)="showAcctAdjModal=false;" [style]="{'width': '25vw'}">
    <div body>
        <div class="row">
            <div class="text-center">
                <span>Mr/Ms. {{ accountSummary.lastName }} we are processing the amount of ${{ totalAdjustment }}. You
                    should see this credit reflected in your account the next day.</span>
            </div>
        </div>
    </div>
    <div footer class="justify-content-around">
        <p3solved-button id="btnDialog1Cancel" label="Back" class="p-button-outlined w-3"
            (buttonClick)="showAcctAdjModal=false;"></p3solved-button>
        <p3solved-button id="btnDialog1Ok" label="Confirm" class="w-3"
            (buttonClick)="showAcctAdjModal=false;postAccountAdjustment();"></p3solved-button>
    </div>
</p3solved-dialog>

<p3solved-dialog [isVisible]="showRewardAjust" header=" Rewards Adjustment" headerClass="text-2xl" [showHeader]="true"
    [isModal]="true" [isClosable]="true" (HideClick)="showRewardAjust=false;" [style]="{'width': '25vw'}">
    <div body>
        <div class="row">
            <div class="text-center">
                <p *ngIf="rewardAdjustForm.value">Mr/Ms. {{ accountSummary.lastName }} we are processing the Rewards adjustment of an additional {{rewardAdjustForm.value.points}}. 
                    You should see this reflected into your Rewards History the next day.</p>
            </div>
        </div>
    </div>
    <div footer class="justify-content-around">
        <p3solved-button id="btnDialog1Cancel" label="Close" class="p-button-outlined w-3"
            (buttonClick)="showRewardAjust=false;"></p3solved-button>
        <p3solved-button id="btnDialog1Ok" label="Save" class="w-3"
            (buttonClick)="showRewardAjust=false;postRewardAdjustment();"></p3solved-button>
    </div>