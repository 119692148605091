<div class="row">
  <div class="md:col-12 lg:col-6">
    <div class="cds-card p-3 mb-2">
      <div class="card-body">
        <div class="text-center">
          <p class="h5 mt-2 mb-0">Account Snapshot</p>
          <p class="mb-2">#{{ accountSummary.loanNumber }}</p>
        </div>
        <div class="flex mb-0 pds-divider-top">
          <div class="p-2">Contract Amount</div>
          <div class="ms-auto p-2">${{ accountSummary.loanAmount }}</div>
        </div>
        <div class="flex mb-0 pds-divider-top">
          <div class="p-2">Contract Balance</div>
          <div class="ms-auto p-2">
            ${{ accountSummary.currentPrincipalBalance }}
          </div>
        </div>
        <div class="flex mb-0 pds-divider-top">
          <div class="p-2">Finance Charges</div>
          <div class="ms-auto p-2">${{ accountSummary.interestBalance }}</div>
        </div>
        <div *ngIf="lateFees">
          <div class="flex mb-0 pds-divider-top">
            <div class="p-2">Late Charge</div>
            <div class="ms-auto p-2">${{ lateFees }}</div>
          </div>
        </div>
        <div class="flex mb-0 pds-divider-top">
          <div class="p-2"><strong>Total Account Balance</strong></div>
          <div class="ms-auto p-2">
            ${{ accountSummary.totalAccountBalance }}
          </div>
        </div>
      </div>
    </div>
    <div class="cds-card mb-2">
      <div class="card-body">
        <div class="flex justify-content-center">
          <div class="m-1">
            <p3solved-button type="button" class="p-button-text p-button-text" (click)="showPayments = true"
              label="Upcoming Payments"></p3solved-button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="md:col-12 lg:col-6">
    <mat-accordion class="payment-panel mb-3">
      <mat-expansion-panel hideToggle [expanded]="!showScheduleEdit" (opened)="showScheduleEdit = false" class="chris">
        <div class="">
          <div class="card-body">
            <p class="text-center h5 my-2">Payments</p>
            <div *ngIf="
                accountSummary.upcomingPaymentScheduledPopup === '1' ||
                accountSummary.upcomingPaymentScheduledPopup === 'True'
              " class="alert alert-info flex align-items-center alert-purple" role="alert">
              <i class="pi pi-exclamation-triangle" style="font-size: 2rem" aria-hidden="true"></i>
              <div>
                {{ accountSummary.upcomingPaymentScheduledMessage }}
              </div>
            </div>
            <div class="m-1">
              <div class="p-2"><strong>Scheduled Payment</strong></div>
              <div *ngIf="scheduledPayments && scheduledPayments.length">
                <div *ngFor="let p of scheduledPayments">
                  <div class="flex mb-0 pds-divider-top">
                    <div class="p-2" [ngStyle]="{ color: p.descriptionColor }" [ngClass]="
                        p.pushedPayment == '1' || p.pushedPayment == 'True'
                          ? 'text-muted'
                          : ''
                      ">
                      {{ p.description }}
                    </div>
                    <div class="ms-auto p-2" [ngClass]="
                        p.pushedPayment == '1' || p.pushedPayment == 'True'
                          ? 'text-muted'
                          : ''
                      ">
                      ${{ p.totalAmt | formatMoney }}
                    </div>
                  </div>
                  <div class="flex mb-0 pds-divider-top">
                    <div class="p-2">Payment Due Date</div>
                    <div class="ms-auto p-2">{{ p.dateDueFormatted }}</div>
                  </div>
                </div>
              </div>
              <div class="flex mb-0 pds-divider-top">
                <div class="p-2"><strong>Pay in Full Amount</strong></div>
                <div class="ms-auto p-2">
                  <strong>${{
                    accountSummary.totalAccountBalance | formatMoney
                    }}</strong>
                </div>
              </div>
            </div>
            <div class="m-1">
              <div class="p-2"><strong>Quick Pay</strong></div>
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-sm-4">
                      <p3solved-button type="button" (click)="copyLink()" label="Get Link"
                        class="p-button-text p-button-text" [cdkCopyToClipboard]="
                          accountSummary.firstTimePaymentURL
                        "></p3solved-button>
                    </div>
                    <div class="col-sm-4">
                      <p3solved-button type="button" (click)="sendSms()" label="Send SMS"
                        class="p-button-text p-button-text"></p3solved-button>
                    </div>
                    <div class="col-sm-4">
                      <p3solved-button type="button" (click)="sendEmail()" label="Send Email"
                        class="p-button-text p-button-text"></p3solved-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="m-2 py-4">
              <div class="text-center">
                <p3solved-button class="p-button-lg px-8 py-2" label="SCHEDULE A PAYMENT" icon="pi pi-search"
                  (click)="makePayment()"></p3solved-button>
              </div>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel hideToggle [expanded]="showScheduleEdit" (opened)="showScheduleEdit = true" class="chris2">
        <app-schedule-payment-form (onBack)="onBack($event)" [paymentAccounts]="paymentOptions"
          [processors]="processors" [accSummary]="accountSummary">
        </app-schedule-payment-form>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
<div class="cds-card">
  <div class="card-body">
    <p class="text-center h5 my-2">Transaction History</p>

    <p-table [value]="transactionHistory" styleClass="p-datatable mr-4" [rowHover]="true" [paginator]="true"
      [rowsPerPageOptions]="[15, 30, 50, 100]" [rows]="rows" [(first)]="first" [showCurrentPageReport]="false"
      responsiveLayout="scroll">
      <ng-template pTemplate="header">
        <tr>
          <th class="font-medium" scope="col">Date</th>
          <th class="font-medium" scope="col">Description</th>
          <th class="font-medium" scope="col">Pmt Amt</th>
          <th class="font-medium" scope="col">Prin</th>
          <th class="font-medium" scope="col">Finance Charges</th>
          <th class="font-medium" scope="col">Contract Bal</th>
          <th class="font-medium" scope="col">Balance</th>
          <th class="font-medium" scope="col">DPD</th>
          <th class="font-medium" scope="col">Type</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-h>
        <tr class="cursor-pointer">
          <td>{{ h.tranDate }}</td>
          <td>{{ h.tranDescription }}</td>
          <td>{{ h.payment }}</td>
          <td>{{ h.principal }}</td>
          <td>{{ h.fees }}</td>
          <td>{{ h.balance }}</td>
          <td>{{ h.principalBalance }}</td>
          <td>{{ h.dPD }}</td>
          <td>{{ h.tender }}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<p-dialog [modal]="true" [(visible)]="showPayments" [closable]="true">
  <app-upcoming-payments [payments]="upcomingPayments" (btnClick)="showPayments = false"></app-upcoming-payments>
</p-dialog>