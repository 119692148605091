<app-dashboard-header></app-dashboard-header>
<div class="col-5 align-self-center">
    <div class="flex flex-column">        
        <div class="text-xl pl-3">Manual Payments</div>
        <p-breadcrumb [model]="bcItems"></p-breadcrumb>        
    </div>
</div>

<div class="w-full px-1 mx-auto">
    <div class="flex">
        <div class="col-4">
            <div class="cds-card">
                <div class="flex-auto p-4">
                    <div class="text-xl font-bold mb-2">
                        Manual Payments
                    </div>
                    <div class="mb-3">
                        <form [formGroup]="manualPaymentForm" *ngIf="manualPaymentFormLoaded">

                            <div class="pb-2">
                                <p3solved-input-number formControlName="paymentAmount" id="payAmt" placeholder="Enter Payment Amount" [required]="true">
                                <div class="w-12 my-1 p-error text-xs"
                                    *ngIf="!manualPaymentForm.controls.paymentAmount.pristine && manualPaymentForm.controls.paymentAmount.errors">
                                    <p *ngIf="manualPaymentForm.controls.paymentAmount.errors?.['required']">
                                        Payment Amount is required</p>
                                </div>
                            </p3solved-input-number>
                            </div>
                            <div class="pb-2">
                                    <p3solved-dropdown id="payType" placeholder="Select Payment Type" formControlName="paymentType"
                                        [items]="paymentOptions" optionLabel="paymentMethod" optionValue="paymentMethodNo"
                                        [displayFirst]="false" [showClear]="true" [required]="true">
                                    <div class="w-12 my-1 p-error text-xs"
                                        *ngIf="manualPaymentForm.controls.paymentType.touched && manualPaymentForm.controls.paymentType.invalid">
                                        <p>Payment Type is required</p>
                                    </div>
                                </p3solved-dropdown>
                            </div>
                            <div class="pb-2">
                                    <p3solved-form-text id="refCode" placeholder="Payment Reference Code"
                                        formControlName="refCode" [required]="true">
                                        <div class="w-12 my-1 p-error text-xs"
                                            *ngIf="!manualPaymentForm.controls.refCode.pristine && manualPaymentForm.controls.refCode.errors">
                                            <p *ngIf="manualPaymentForm.controls.refCode.errors?.['required']">
                                                Reference Code is required</p>
                                        </div>
                                    </p3solved-form-text>
                            </div>
                            <div class="pb-4"> 
                                <p3solved-datepicker id="dtPayment" formControlName="paymentDate" placeholder="Payment Date" [required]="true"
                                    [minDate]="minDate" [maxDate]="maxDate">
                                    <div class="w-12 my-1 p-error text-xs"
                                    *ngIf="!manualPaymentForm.controls.paymentDate.pristine && manualPaymentForm.controls.paymentDate.errors">
                                    <p *ngIf="manualPaymentForm.controls['paymentDate'].errors?.['required']">
                                        Payment Date is required</p>
                                </div>
                                </p3solved-datepicker>
                            </div>
                            <p3solved-button buttonType="submit" (click)="confirmManualPayment()" [disabled]="manualPaymentForm.invalid">APPLY PAYMENT
                            </p3solved-button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-4">
            <div class="cds-card" *ngIf="showPaperCheckFunding">

                <div class="flex-auto p-4">
                    <div class="text-xl font-bold mb-2">
                        Paper Check Funding
                    </div>

                    <div class="pb-2">
                        <p3solved-input-number id="checkFundAmt" placeholder="Amount" [(ngModel)]="paperCheckFundAmount" [disabled]="true">
                        </p3solved-input-number>
                    </div>
                    <p3solved-button (click)="confirmPaperFunding()" label="Initiate Paper Check Funding">
                    </p3solved-button>
                </div>
            </div>
        </div>
        <div class="col-4">
            <app-customer-notes></app-customer-notes>
        </div>
    </div>
</div>

<p3solved-dialog [isVisible]="showPaymentModal" header="Manual Payment Review" headerClass="text-2xl"
    [showHeader]="true" [isModal]="true" [isClosable]="true" (HideClick)="showPaymentModal=false;"
    [style]="{'width': '25vw'}">
    <div body>
        <div class="grid">
            <div class="col-7 text-center">
                <span>Payment Amount: </span>
            </div>
            <div class="col-5 font-bold">
                <span>{{manualPaymentForm.value['paymentAmount'] | currency}}</span>
            </div>
            <div class="col-7 text-center">
                <span>Payment Method: </span>
            </div>
            <div class="col-5 font-bold">
                <span>{{getPaymentMethodDescription()}}</span>
            </div>
            <div class="col-7 text-center">
                <span>Reference Code: </span>
            </div>
            <div class="col-5 font-bold">
                <span>{{manualPaymentForm.value['refCode']}}</span>
            </div>
            <div class="col-7 text-center">
                <span>Payment Date: </span>
            </div>
            <div class="col-5 font-bold">
                <span>{{manualPaymentForm.value['paymentDate'] | date}}</span>
            </div>
        </div>
    </div>
    <div footer class="col-offset-5 col-7 justify-content-around">
        <p3solved-button id="btnDialog1Cancel" label="Back" class="p-button-outlined w-3"
            (buttonClick)="showPaymentModal=false; abortManPayment();"></p3solved-button>
        <p3solved-button id="btnDialog1Ok" label="Confirm" class="w-6" (buttonClick)="showPaymentModal=false;postManualPayment();"></p3solved-button>
    </div>
</p3solved-dialog>

<p-confirmDialog key="{{paperFundConfirmKey}}" [style]="{width: '25vw'}" [baseZIndex]="10000">
</p-confirmDialog>