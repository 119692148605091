import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { forkJoin } from 'rxjs';
import { CustomerNotesComponent } from 'src/app/components/customer-notes/customer-notes.component';
import { CreditBureauInfo, CreditBureauLookups } from 'src/app/models/csr/agent-tools';
import { CustomerModel } from 'src/app/models/customer-model';
import { CreditBureauFormGroup } from 'src/app/models/form-groups';
import { ApiService } from 'src/app/services/api.service';
import { CustomerService } from 'src/app/services/customer.service';
import { GuidService } from 'src/app/services/guid.service';
import { ToastService } from 'src/app/services/toast.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-credit-bureau',
  templateUrl: './credit-bureau.component.html',
  styleUrls: ['./credit-bureau.component.scss']
})
export class CreditBureauComponent implements OnInit {

  customerGuid: string | null = null;
  campaignGuid: string | null = null;
  targetGuid: string | null = null;

  customer: CustomerModel | null = null;
  
  bcItems: MenuItem[] = [];
  excludeOps: any[] = [{label: 'Yes', value: true}, {label: 'No', value: false}];
  bureauChanges: string[] = [];

  showConfirm: boolean = false;
  bureauFormLoaded: boolean = false;

  bureauForm: FormGroup<CreditBureauFormGroup> = {} as FormGroup;
  lookups: CreditBureauLookups = {} as CreditBureauLookups;
  bureauInfo: CreditBureauInfo | null = null;

  @ViewChild('custNotes') custNotes: CustomerNotesComponent = {} as CustomerNotesComponent;
  
  constructor(
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute, 
    private customerService: CustomerService,
    private guidService: GuidService,
    private userService: UserService,
    private toastService: ToastService,
    private apiService: ApiService
    ) { }

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe(async paramMap => {
      let cGuid = paramMap.get('customerGuid')?.toString() ?? '';
      if (this.guidService.isValidGuid(cGuid)) {
        this.customerGuid = cGuid;
        this.customerService.initCustomer(this.customerGuid);
        await this.userService.subscribeAccess();
        let data = this.activatedRoute.snapshot.data;
        if (data && data['pageId']) {
          this.userService.checkPageAccess(data['pageId']);
        }        
      }
      let campGuid = paramMap.get('campaignGuid')?.toString() ?? '';
      let tGuid = paramMap.get('targetGuid')?.toString() ?? '';
      if (this.guidService.isValidGuid(campGuid)) this.campaignGuid = campGuid;
      if (this.guidService.isValidGuid(tGuid)) this.targetGuid = tGuid;
      this.customerService.customer$.subscribe(cust => {
        this.customer = cust;        
      })
      this.bcItems = [
        { label: 'Agent Tools', routerLink: [`/agent-tools/${this.customerGuid}/${this.campaignGuid}/${this.targetGuid}`]},
        { label: 'Credit Bureau' }
      ];
      this.getAllData();
    });

  }

  getAllData() {
    const apiTags = forkJoin({
      lookups: this.apiService.get(`agenttools/credit-bureau-lookups/${this.customerGuid}`),
      info: this.apiService.get(`agenttools/credit-bureau-info/${this.customerGuid}/${this.campaignGuid}/${this.targetGuid}`)
    }).subscribe(data => {
      this.bureauInfo = data.info;
      this.lookups = data.lookups;
      this.initForm();
    }, (err: any) => {
      this.toastService.addError('Unable to get Credit Bureau Lookups and/or Info. See log for details.');
      console.error(err);
    });
  }

  initForm() {
    let cc = this.bureauInfo?.creditBureauSpecialCommentID && this.bureauInfo.creditBureauSpecialCommentID > 0 ?
      this.bureauInfo.creditBureauSpecialCommentID : null;
    let ci = this.bureauInfo?.creditBureauConsumerInfoIndicatorID && this.bureauInfo.creditBureauConsumerInfoIndicatorID > 0 ?
      this.bureauInfo.creditBureauConsumerInfoIndicatorID : null;
    let exc = this.bureauInfo?.creditBureauExclusion ?? true;
    let comp = this.bureauInfo?.creditBureauComplianceID && this.bureauInfo.creditBureauComplianceID > 0 ?
    this.bureauInfo.creditBureauComplianceID : null;

    this.bureauForm = this.fb.group<CreditBureauFormGroup>({
      commentCode: new FormControl<number|null>(cc, {nonNullable: true}),
      infoIndic: new FormControl<number|null>(ci, {nonNullable: true}),
      conditionCode: new FormControl<number|null>(comp, {nonNullable: true}),
      exclude: new FormControl<boolean>(exc, {nonNullable: true}),
    });
    this.bureauFormLoaded = true;
  }

  confirmBureauInfo() {
    let updated: boolean = false;
    this.bureauChanges = [];

    if (this.bureauForm.value.commentCode && this.bureauForm.value.commentCode != this.bureauInfo?.creditBureauSpecialCommentID) {
      updated = true;
      let ccFrom: string = 'None';
      let ccTo: string = 'None';
      let ccFromIdx = this.lookups.commentCodes.find(cc => cc.creditBureauSpecialCommentID == this.bureauInfo?.creditBureauSpecialCommentID);
      if (ccFromIdx) ccFrom = ccFromIdx.specialCommentFull;
      let cctoIdx = this.lookups.commentCodes.find(cc => cc.creditBureauSpecialCommentID == this.bureauForm.value.commentCode);
      if (cctoIdx) ccTo = cctoIdx.specialCommentFull;

      this.bureauChanges.push(`${ccFrom} to ${ccTo}`);
    }

    if (this.bureauForm.value.infoIndic && this.bureauForm.value.infoIndic != this.bureauInfo?.creditBureauConsumerInfoIndicatorID) {
      updated = true;
      let ciFrom: string = 'None';
      let ciTo: string = 'None';
      let ciFromIdx = this.lookups.consumerInfos.find(ci => ci.creditBureauConsumerInfoIndicatorID == this.bureauInfo?.creditBureauConsumerInfoIndicatorID);
      if (ciFromIdx) ciFrom = ciFromIdx.consumerInfoIndicatorFull;
      let citoIdx = this.lookups.consumerInfos.find(ci => ci.creditBureauConsumerInfoIndicatorID == this.bureauForm.value.infoIndic);
      if (citoIdx) ciTo = citoIdx.consumerInfoIndicatorFull;

      this.bureauChanges.push(`${ciFrom} to ${ciTo}`);
    }
    
    if (this.bureauForm.value.conditionCode && this.bureauForm.value.conditionCode != this.bureauInfo?.creditBureauComplianceID) {
      updated = true;
      let ccFrom: string = 'None';
      let ccTo: string = 'None';
      let ccFromIdx = this.lookups.compliances.find(cc => cc.creditBureauComplianceID == this.bureauInfo?.creditBureauComplianceID);
      if (ccFromIdx) ccFrom = ccFromIdx.complianceFull;
      let cctoIdx = this.lookups.compliances.find(cc => cc.creditBureauComplianceID == this.bureauForm.value.conditionCode);
      if (cctoIdx) ccTo = cctoIdx.complianceFull;

      this.bureauChanges.push(`${ccFrom} to ${ccTo}`);
    }
    
    if (this.bureauForm.value.exclude != this.bureauInfo?.creditBureauExclusion) {
      updated = true;
      let exFrom: string = this.bureauInfo?.creditBureauExclusion ? 'Exclude' : 'None';
      let exTo: string = this.bureauForm.value.exclude ? 'Exclude' : 'None';
      this.bureauChanges.push(`${exFrom} to ${exTo}`);
    }

    if (!updated) {
      this.toastService.addWarn('No changes detected to Credit Bureau settings.');
      return;
    }

    this.showConfirm = true;

  }
  
  saveBureauInfo() {
    this.showConfirm = false;
    let body = {
      customerGuid: this.customerGuid, 
      campaignGuid: this.campaignGuid, 
      targetGuid: this.targetGuid,
      specialCommentCode: this.bureauForm.value.commentCode ?? -1, 
      consumerInformationIndicator: this.bureauForm.value.infoIndic ?? -1, 
      complianceConditionCode: this.bureauForm.value.conditionCode ?? -1, 
      exclude: this.bureauForm.value.exclude ?? false
    };

    let saveSub = this.apiService.post(`agenttools/save-credit-bureau`, body)
    .subscribe({
      next: () => { 
        this.toastService.addSuccess('Credit Bureau Info successfully saved.');
        this.custNotes.reload();
      },
      error: (err: any) => { 
        this.toastService.addError('Unable to save Credit Bureau Info. See log for details');
        console.error(err);
     },
      complete: () => { saveSub.unsubscribe(); },
    });
  }


}
