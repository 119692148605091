import { PaymentMethodModel } from "./payment-methods";

export class CourtesyAdjustmentInfo {
    accountAdjustmentBlocked: boolean = false;
    bankAccountAdjustmentBlocked: boolean = false;
    cardAdjustmentBlocked: boolean = false;
    accountAdjustmentBlockedMessage: string = '';
    bankAccountAdjustmentBlockedMessage: string = '';
    cardAdjustmentBlockedMessage: string = '';
    courtesyAdjustmentMinDate: Date = new Date();
    courtesyAdjustmentMaxDate: Date = new Date();
}