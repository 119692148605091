export class SendCommMessageModel {
    customerGuid: string | null = null;
    campaignGuid: string | null = null;
    targetGuid: string | null = null;
    messageTypeId = 0;
    messageCategoryId = 0;
    fileGuid: string | null = null;;
    fileName = '';
    fileDesc = '';
    messageGuid: string | null = null;
    emailFrom: string | null = null;
    emailSubject = '';
    emailBody = '';
    emailTo = '';
    emailCc = '';
    emailBcc = '';
    emailMsgGuidFolder = '';
    attachmentList = '';
    isDraft = false;
    smsPhoneNumber = '';
    smsMessage = '';
}

export class PostCommunicationMessageModel {
    messageGuid: string | null = null;
    targetGuid: string | null = null;
    campaignGuid: string | null = null;
    customerGuid: string | null = null;
    messageTypeId: number = 0;
    messageCategoryId: number = 0;
    massActionId: number | null = null;
    isDraft: boolean = false;
    smsMessage: string = '';
    smsPhoneNumber: string = '';
    emailFrom: string = '';
    emailSentTo: string = '';
    emailCc: string = '';
    emailBcc: string = '';
    emailSubject: string = '';
    emailBody: string = '';
    emailMsgGuidFolder: string = '';
    emailAttachments: string = '';
    docFileName: string = '';
    docFileGuid: string | null = null;
    docFileDescription: string = '';
    replyToMessageGuid: string | null = null;
}