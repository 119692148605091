
<div class="app-delete text-center">
    <fa-icon [icon]="iconExclam" size="5x" [classes]="['m-3 text-warning']"></fa-icon>
    <p class="h3 mb-3">{{data.title}}</p>
    <p class="text-xl font-light mb-4 text-danger">{{data.msg}}</p>
  </div>
  <div class="flex justify-content-center">
      <div class="p-1">
        <button type="button" class="btn btn-success" (click)="ok('confirm')">Confirm!</button>
      </div>
      <div class="p-1">
        <button type="button" class="btn btn-danger" (click)="ok('cancel')">Cancel</button>
      </div>
  </div>