<div class="pt-3">
    <div class="flex pb-3">
        <div class="flex flex-wrap">
            <span class="text-2xl font-medium col-12">Compose</span>
            <div class="text-base col-12">New SMS
                <!-- <span *ngIf="isTemplate">(<span class="font-medium">{{ templateName }}</span> Template)</span> -->
            </div>            
        </div>
        <div class="flex flex-grow-1 flex-row-reverse">
            <p3solved-button id="btnBack" label="Back" class="secondary" (buttonClick)="back()"></p3solved-button>
        </div>
    </div>
    <div class="p-4 pds-overlay-bg">
        <div>
            <p3solved-form-text id="txtTo" placeholder="To:" [(ngModel)]="smsTo"></p3solved-form-text>
        </div>
        <div class="pt-5">
            <span class="p-float-label">
                <textarea pInputTextarea id="sms-msg" rows="5" cols="30" style="width: 100%;" [(ngModel)]="smsMsg"></textarea>
                <label for="sms-msg" class="cds-placeholder-color">SMS Text:</label>
            </span>
        </div>
        <div class="flex pt-3" *ngIf="hasFeature('CSR_QUEUE_COMMUNICATION_COMPOSE')">
            <span class="pr-2">
                <p3solved-button id="btnSend" label="Send" icon="pi pi-mobile" iconPos="left" 
                    [disabled]="!smsMsg || !smsTo" (buttonClick)="sendSMS()">
                </p3solved-button>
            </span>
            <span>
                <p3solved-button id="btnCancel" label="Discard" class="secondary" (buttonClick)="discard()"></p3solved-button>
            </span>
        </div>
    </div>
</div>

