import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { DaysPayoffModel } from 'src/app/models/csr/days-payoff-model';

@Component({
  selector: 'app-ten-day-payoff',
  templateUrl: './ten-day-payoff.component.html',
  styleUrls: ['./ten-day-payoff.component.scss']
})
export class TenDayPayoffComponent implements OnInit {
   @Output() btnClick = new EventEmitter();
    @Input() payments: DaysPayoffModel[] = [];  
    @Input() payoffBalance: string = '';
    todayDate = (new Date()).toLocaleDateString('en-US');
    rows: number = 15;  
    first: number = 0;

  constructor(

  ) { }

  ngOnInit(): void {
  }
}
