<div class="card profile-card">
    <div class="flex align-items-center bg-secondary text-white">
        <div class="flex-shrink-0">
            <i class="bi bi-person-circle m-2" style="font-size: 2rem;" aria-hidden="true"></i>
        </div>
        <div class="flex-grow-1 m-2">
            <p class="h4 mb-1">{{user.firstName}} {{user.lastName}}</p>
            <p class="mb-2">{{user.loginEmail}}</p>
        </div>
    </div>
    <ul class="list-group profile-menu">
        <li class="list-group-item list-group-item-action" (click)="doUserMenu('profile')">
            <fa-icon [icon]="iconUserCircle" [classes]="['mr-2']"></fa-icon> My Profile
        </li>
        <!-- <li class="list-group-item list-group-item-action" (click)="doUserMenu('providers')">
            <fa-icon [icon]="iconBars" [classes]="['mr-2 far']"></fa-icon> All Providers
        </li> -->
        <li class="list-group-item list-group-item-action" (click)="doUserMenu('admin')">
            <fa-icon [icon]="iconTable" [classes]="['mr-2 far']"></fa-icon> Admin
        </li>
        <li class="list-group-item list-group-item-action" (click)="doUserMenu('cs')">
            <fa-icon [icon]="iconThLarge" [classes]="['mr-2']"></fa-icon> CS
        </li>
        <!-- <li class="list-group-item list-group-item-action" (click)="doUserMenu('bop')">
            <fa-icon [icon]="iconTh" [classes]="['mr-2']"></fa-icon> BOP
        </li> -->
        <li class="list-group-item list-group-item-action" (click)="doUserMenu('logout')">
            <fa-icon [icon]="iconPowerOff" [classes]="['mr-2']"></fa-icon> Logout
        </li>
    </ul>
</div>
