import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-address-correction',
  templateUrl: './address-correction.component.html',
  styleUrls: ['./address-correction.component.scss']
})
export class AddressCorrectionComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<AddressCorrectionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AddressDialogData,
  ) { }

  ngOnInit(): void {
  }

  onClick(action: string){
    this.dialogRef.close(action);
  }

}

export interface AddressDialogData {
  addressEntered: string;
  correction: string;
}
