import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommQueueMessage } from 'src/app/models/communications/message-model';
import { CustomerModel } from 'src/app/models/customer-model';
import { AppCustomerSettings } from 'src/app/models/customer-settings';
import { ApiService } from 'src/app/services/api.service';
import { GuidService } from 'src/app/services/guid.service';

@Component({
  selector: 'app-comm-queue-item',
  templateUrl: './comm-queue-item.component.html',
  styleUrls: ['./comm-queue-item.component.scss']
})
export class CommQueueItemComponent implements OnInit {
  @Output() onBack = new EventEmitter<boolean>();
  @Output() onReply = new EventEmitter<any>();
  @Input() message:CommQueueMessage | null = null;
  @Input() customer: CustomerModel | null = null;
  @Input() showReplyButton: boolean = false;
  
  loading = false;
  hasAttachments: boolean = false;
  attachmentCount: number = 0;
  attachments: string[] = [];
  customerSettings: AppCustomerSettings = {} as AppCustomerSettings;
  emailBody: any;
  messageSubject: string = '';
  fromFullName: string = '';
  fromEmailOrNumber: string = '';
  toEmailOrNumber: string = '';
  rawMessage: any;

  constructor(
    private api: ApiService,
    private guidService: GuidService
  ) { }

  ngOnInit(): void {
    this.api.get(`app/customer-settings2/${this.customer?.customerGUID}/${this.guidService.newGuid()}`)
    .subscribe(settings => {
      this.customerSettings = settings;
      this.getDetails();
    })
  }

  getDetails(){
    this.loading = true;
    var model = {
      customerGuid: this.customer?.customerGUID,     
      campaignGuid: this.guidService.emptyGuid(),
      targetGuid: this.guidService.emptyGuid(),
      value: this.message?.messageGUID
    }
    var body = JSON.stringify(model);
    this.api.post(`csr/target-message-details`, body)
      .subscribe((res: any) => {  
        this.rawMessage = res;
        this.emailBody = res.emailBody + res.sMSMessage;
        if (res.messageTypeID === '4' || res.messageTypeID === '2') {
          this.fromFullName = `${res.createUserFirstName} `;
          if (res.createUserMiddleName.length > 0) this.fromFullName += `${res.createUserMiddleName} `;
          this.fromFullName += `${res.createUserLastName}`;

          if (res.messageTypeID === '2') {
            this.fromEmailOrNumber = res.emailFrom;
            this.messageSubject = res.emailSubject;
            this.toEmailOrNumber = res.emailSentTo;
          }
          else {
            this.fromEmailOrNumber = 'System SMS Number';
            this.messageSubject = 'SMS';
            this.toEmailOrNumber = res.sMSPhoneNumber;
          }


        }
        else if (res.messageTypeID === '1' || res.messageTypeID === '3') {
          this.fromFullName = `${res.targetFirstName} ${res.targetLastName}`;

          if (res.messageTypeID == '1') {
            this.fromEmailOrNumber = res.emailFrom;
            this.messageSubject = res.emailSubject;
            this.toEmailOrNumber = res.emailSentTo;
          }
          else {
            this.fromEmailOrNumber = res.sMSPhoneNumber;
            this.messageSubject = 'SMS';
            this.toEmailOrNumber = 'System SMS Number';
          }

        }

        let attachments = res.emailAttachments + res.sMSAttachments;
        if (attachments != '' && attachments.length > 0) {
          this.hasAttachments = true;
          this.attachments = (attachments as string).split(',');
          this.attachmentCount = this.attachments.length;          
        }
        this.loading = false;

      },
        (err: any) => {          
          console.error(err);
          this.loading = false;
        }
      );
  }

  getAttachemntIcon(attachment: string): string {
    let lastIdx = attachment.lastIndexOf('.');
    let extension: string = 'file';
    if (lastIdx > 0) {
      extension = attachment.substring(lastIdx + 1);
    }
    let file: string = 'blank.png';
    switch (extension) {
      case 'csv':
          file = 'csv.png';
        break;
      
      case 'doc':
      case 'docx':
        file = 'doc.png';
        break;

      // case 'gif':
      //   file = 'gif.png';
      //   break;

      case 'jpg':
        file = 'jpg.png';
        break;

      case 'pdf': 
        file = 'pdf.png';
        break;

      case 'png':
        file = 'png.png';
        break;

      case 'svg':
        file = 'svg.png';
        break;

      case 'txt':
        file = 'txt.png';
        break;

      case 'xml':
        file = 'xml.png';
        break;

      case 'zip':
        file = 'zip.png';
        break;

    }
    return `assets/icons/${file}`;
  }

  getAttachment(attachment: string) {
    let url: string = '';
    if (this.rawMessage.messageTypeID == '1') {
      url = this.customerSettings.AzureBlobFilesRootURL;
      url += url.endsWith('/') ? this.customerSettings.AzureBlobFolderForEmailAttsInbound : `/${this.customerSettings.AzureBlobFolderForEmailAttsInbound}`;
      url += url.endsWith('/') ? this.rawMessage.msgGuidFolder : `/${this.rawMessage.msgGuidFolder}`;
      url += url.endsWith('/') ? attachment : `/${attachment}`;
    }
    else if (this.rawMessage.messageTypeID == '2') {
      url = this.customerSettings.AzureBlobFilesRootURL;
      url += url.endsWith('/') ? this.customerSettings.AzureBlobFolderForEmailAttsOutbound : `/${this.customerSettings.AzureBlobFolderForEmailAttsOutbound}`;
      url += url.endsWith('/') ? this.rawMessage.msgGuidFolder : `/${this.rawMessage.msgGuidFolder}`;
      url += url.endsWith('/') ? attachment : `/${attachment}`;
    }
    else if (this.rawMessage.messageTypeID == '3') {
      url = this.customerSettings.AzureBlobFilesRootURL;
      url += url.endsWith('/') ? this.customerSettings.AzureBlobFolderForSMSAttsInbound : `/${this.customerSettings.AzureBlobFolderForSMSAttsInbound}`;
      url += url.endsWith('/') ? attachment : `/${attachment}`;
    }  

    window.open(url, '_blank');
  }

  replyToMsg() {
    this.onReply.emit(this.rawMessage);
  }

  back(){
    this.onBack.emit(true);
  }

}
