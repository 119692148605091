import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MsalRedirectComponent } from '@azure/msal-angular';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppMaterialModule } from './material-module';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { SideNavComponent } from './components/layout/side-nav/side-nav.component';
import { ProfileMenuComponent } from './components/layout/profile-menu/profile-menu.component';
import { NavHeaderComponent } from './components/layout/nav-header/nav-header.component';
import { CcInputComponent } from './components/form-controls/cc-input/cc-input.component';
import { DashboardHeaderComponent } from './components/dashboard-header/dashboard-header.component';
import { PreApplicationPageComponent } from './pages/pre-application-page/pre-application-page.component';
import { ApplicationPageComponent } from './pages/application-page/application-page.component';
import { DecisionPageComponent } from './pages/decision-page/decision-page.component';
import { AccountServicingPageComponent } from './pages/account-servicing-page/account-servicing-page.component';
import { CommunicationsPageComponent } from './pages/communications-page/communications-page.component';
import { AgentToolsPageComponent } from './pages/agent-tools-page/agent-tools-page.component';
import { SystemsPageComponent } from './pages/systems-page/systems-page.component';
import { DashboardPageComponent } from './pages/dashboard-page/dashboard-page.component';
import { CustomerInfoComponent } from './components/customer-info/customer-info.component';
import { CustomerInfoEditComponent } from './components/customer/customer-info-edit/customer-info-edit.component';
import { CustomerNotesComponent } from './components/customer-notes/customer-notes.component';
import { AddCustomerNotificationComponent } from './components/add-customer-notification/add-customer-notification.component';
import { CustomerContactComponent } from './components/customer-contact/customer-contact.component';
import { CcSelectComponent } from './components/form-controls/cc-select/cc-select.component';
import { CcTextareaComponent } from './components/form-controls/cc-textarea/cc-textarea.component';
import { PaymentMethodInfoComponent } from './components/payment-method-info/payment-method-info.component';
import { CustomGridComponent } from './components/custom-grid/custom-grid.component';
import { AdvancedSearchComponent } from './pages/advanced-search/advanced-search.component';
import { MatTableFilterModule } from 'mat-table-filter';
import { QueueCollectionsPageComponent } from './pages/queue-collections-page/queue-collections-page.component';
import { LeadsPageComponent } from './pages/leads-page/leads-page.component';
import { VerificationQueuePageComponent } from './pages/verification-queue-page/verification-queue-page.component'; 
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommunicationsQueuePageComponent } from './pages/communications-queue-page/communications-queue-page.component';
import { FullScreenLoadingComponent } from './components/full-screen-loading/full-screen-loading.component';
import { SuccessModalComponent } from './components/success-modal/success-modal.component';
import { MyProfilePageComponent } from './pages/my-profile-page/my-profile-page.component';
import { ConfirmChangeModalComponent } from './components/confirm-change-modal/confirm-change-modal.component';
import { NoteListPageComponent } from './pages/note-list-page/note-list-page.component';
import { FormatMoneyPipe } from './pipes/format-money.pipe';
import { YesNoPopupComponent } from './components/shared/yes-no-popup/yes-no-popup.component';
import { TenDayPayoffComponent } from './components/account-servicing/ten-day-payoff/ten-day-payoff.component';
import { SchedulePaymentFormComponent } from './components/account-servicing/schedule-payment-form/schedule-payment-form.component';
import { CcRadioComponent } from './components/form-controls/cc-radio/cc-radio.component'; 
import { UpcomingPaymentsComponent } from './components/account-servicing/upcoming-payments/upcoming-payments.component'; 
import { CourtesyAdjustmentPageComponent } from './pages/courtesy-adjustment-page/courtesy-adjustment-page.component';
import { ErrorModalComponent } from './components/error-modal/error-modal.component';
import { ManualPaymentsPageComponent } from './pages/manual-payments-page/manual-payments-page.component'; 
import { ReRunRulesComponent } from './components/decision/re-run-rules/re-run-rules.component';
import { CommMailboxComponent } from './components/communications/comm-mailbox/comm-mailbox.component';
import { CommTabComponent } from './components/communications/comm-tab/comm-tab.component';
import { CommMailboxItemComponent } from './components/communications/comm-mailbox-item/comm-mailbox-item.component';
import { ConfirmDeleteModalComponent } from './components/shared/confirm-delete-modal/confirm-delete-modal.component';
import { AddressCorrectionComponent } from './components/customer/address-correction/address-correction.component';
import { ConfirmChangesComponent } from './components/customer/confirm-changes/confirm-changes.component';
import { EditEmailModalComponent } from './components/customer/edit-email-modal/edit-email-modal.component';
import { ConfirmEmailCodeModalComponent } from './components/customer/confirm-email-code-modal/confirm-email-code-modal.component';
import { AddPaymentMethodModalComponent } from './components/customer/add-payment-method-modal/add-payment-method-modal.component';
import { ContractDocumentsComponent } from './components/communications/contract-documents/contract-documents.component';
import { CustomerAttachmentsComponent } from './components/communications/customer-attachments/customer-attachments.component';
import { UploadAttachmentComponent } from './components/communications/upload-attachment/upload-attachment.component';
import { UploadDirective } from './directives/upload.directive'; 

import { P3SolvedLibModule } from '@p3solved/p3solved-ui';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog'
import { InputTextModule } from 'primeng/inputtext';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TabMenuModule } from 'primeng/tabmenu';
import { BadgeModule } from 'primeng/badge';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { EditorModule } from 'primeng/editor';
import { TabViewModule } from 'primeng/tabview';
import { CheckboxModule } from 'primeng/checkbox';
import { SelectButtonModule } from 'primeng/selectbutton';
import { AvatarModule } from 'primeng/avatar';
import {ProgressSpinnerModule} from 'primeng/progressspinner';

import { SuccessComponent } from './components/success/success.component';
import { CommSmsComponent } from './components/communications/comm-sms/comm-sms.component';
import { CommEmailComponent } from './components/communications/comm-email/comm-email.component';
import { LogoutComponent } from './pages/logout/logout.component';
import { MsalApplicationModule } from './msal-application.module';
import { ProviderMenuComponent } from './components/provider-menu/provider-menu.component';
import { NotAuthorizedComponent } from './pages/not-authorized/not-authorized.component';
import { AccountServicingOptionOneComponent } from './components/account-servicing-options/account-servicing-option-one/account-servicing-option-one.component';
import { AccountServicingOptionTwoComponent } from './components/account-servicing-options/account-servicing-option-two/account-servicing-option-two.component';
import { VerificationPageComponent } from './pages/verification-page/verification-page.component';
import { CustomerContactNewComponent } from './components/customer-contact-new/customer-contact-new.component';
import { CustomerVerificationsComponent } from './components/customer-verifications/customer-verifications.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { CreditBureauComponent } from './pages/credit-bureau/credit-bureau.component';
import { PaymentCalculatorComponent } from './pages/payment-calculator/payment-calculator.component';
import { IframeAutoHeightDirective } from './directives/iframe-auto-height.directive';
import { CustomerCollectionsComponent } from './pages/customer-collections/customer-collections.component';
import { CollectionSettlementComponent } from './components/collection-settlement/collection-settlement.component';
import { PayCalendarFormComponent } from './components/pay-calendar-form/pay-calendar-form.component';
import { AccountAdjustmentOptionTwoComponent } from './components/account-adjustment-options/account-adjustment-option-two/account-adjustment-option-two.component';
import { AccountAdjustmentOptionOneComponent } from './components/account-adjustment-options/account-adjustment-option-one/account-adjustment-option-one.component';
import { CreditCardAdjustmentComponent } from './components/card-adjustment-options/credit-card-adjustment/credit-card-adjustment.component';
import { DebitCardAdjustmentComponent } from './components/card-adjustment-options/debit-card-adjustment/debit-card-adjustment.component';
import { BankAdjustmentOptionOneComponent } from './components/bank-adjustment-options/bank-adjustment-option-one/bank-adjustment-option-one.component';
import { BankAdjustmentOptionTwoComponent } from './components/bank-adjustment-options/bank-adjustment-option-two/bank-adjustment-option-two.component';
import { CustomerRewardInfoComponent } from './components/customer-reward-info/customer-reward-info.component';
import { CommTemplateEditComponent } from './components/communications/comm-template-edit/comm-template-edit.component';
import { CommTemplateDefaultComponent } from './components/communications/comm-template-default/comm-template-default.component';
import { CommQueueItemComponent } from './components/communications/comm-queue-item/comm-queue-item.component';
import { CommQueueEmailComponent } from './components/communications/comm-queue-email/comm-queue-email.component';
import { CommQueueSmsComponent } from './components/communications/comm-queue-sms/comm-queue-sms.component';

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    SideNavComponent,
    ProfileMenuComponent,
    NavHeaderComponent,
    CcInputComponent,
    DashboardHeaderComponent,
    PreApplicationPageComponent,
    ApplicationPageComponent,
    DecisionPageComponent,
    AccountServicingPageComponent,
    CommunicationsPageComponent,
    AgentToolsPageComponent,
    SystemsPageComponent,
    DashboardPageComponent,
    PageNotFoundComponent,
    CustomerInfoComponent,
    CustomerInfoEditComponent,
    CustomerNotesComponent,
    AddCustomerNotificationComponent,
    CustomerContactComponent,
    CcSelectComponent,
    CcTextareaComponent,
    PaymentMethodInfoComponent,
    CustomGridComponent,
    SystemsPageComponent,
    AdvancedSearchComponent,
    QueueCollectionsPageComponent,
    LeadsPageComponent,
    VerificationQueuePageComponent,
    CommunicationsQueuePageComponent,
    FullScreenLoadingComponent,
    SuccessModalComponent,
    MyProfilePageComponent,
    ConfirmChangeModalComponent,
    NoteListPageComponent,
    FormatMoneyPipe,
    YesNoPopupComponent,
    TenDayPayoffComponent,
    UpcomingPaymentsComponent,
    SchedulePaymentFormComponent,
    CcRadioComponent,
    CourtesyAdjustmentPageComponent,
    ReRunRulesComponent,
    ErrorModalComponent,
    ManualPaymentsPageComponent,
    CommMailboxComponent,
    CommTabComponent,
    CommMailboxItemComponent,
    ConfirmDeleteModalComponent,
    AddressCorrectionComponent,
    ConfirmChangesComponent,
    EditEmailModalComponent,
    ConfirmEmailCodeModalComponent,
    AddPaymentMethodModalComponent, 
    ContractDocumentsComponent,
    CustomerAttachmentsComponent,
    UploadAttachmentComponent,
    UploadDirective,
    SuccessComponent,
    CommSmsComponent,
    CommEmailComponent,
    LogoutComponent,
    ProviderMenuComponent,
    NotAuthorizedComponent,
    AccountServicingOptionOneComponent,
    AccountServicingOptionTwoComponent,
    VerificationPageComponent,
    CustomerContactNewComponent,
    CustomerVerificationsComponent,
    CreditBureauComponent,
    PaymentCalculatorComponent,
    IframeAutoHeightDirective,
    CustomerCollectionsComponent,
    CollectionSettlementComponent,
    PayCalendarFormComponent,
    AccountAdjustmentOptionTwoComponent,
    AccountAdjustmentOptionOneComponent,
    CreditCardAdjustmentComponent,
    DebitCardAdjustmentComponent,
    BankAdjustmentOptionOneComponent,
    BankAdjustmentOptionTwoComponent,
    CustomerRewardInfoComponent,
    CommTemplateEditComponent,
    CommTemplateDefaultComponent,
    CommQueueItemComponent,
    CommQueueEmailComponent,
    CommQueueSmsComponent
  ],
  imports: [
    BrowserModule,
    MatTableFilterModule,
    AppRoutingModule,
    AppMaterialModule,
    P3SolvedLibModule,
    TableModule,
    ButtonModule,
    RadioButtonModule,
    InputTextModule,
    InputTextareaModule,
    BreadcrumbModule,
    OverlayPanelModule,
    ConfirmDialogModule,
    DialogModule,
    TabMenuModule,
    TabViewModule,
    BadgeModule,
    ProgressSpinnerModule,
    AvatarModule,
    CheckboxModule,
    SelectButtonModule,
    EditorModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MsalApplicationModule.forRoot(),
    FontAwesomeModule,
    NgbModule,
  ],
  providers: [
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: MsalInterceptor,
    //   multi: true
    // },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: ApiInterceptor,
    //   multi: true
    // },
    // MsalGuard
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
