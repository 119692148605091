
import { LogLevel, Configuration, BrowserCacheLocation } from '@azure/msal-browser';
const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export function getMsalConfig(settings: any){
    var msalConfig: Configuration = {
        auth: {
            clientId: settings['AD_AppReg_ClientId'],
            authority: settings['B2C_SignInPolicy'],
            knownAuthorities: [settings['B2C_AuthorityDomain']],
            redirectUri: settings['URL_CS'], 
        },
       cache: {
           cacheLocation: BrowserCacheLocation.LocalStorage,
           storeAuthStateInCookie: true
       },
       system: {
           loggerOptions: {
               loggerCallback(logLevel: LogLevel, message: string){
                   // console.log(message);
               },
               logLevel: LogLevel.Error,
               piiLoggingEnabled: false
           }
       }
    }

    return msalConfig;
}