import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerInfoEditComponent } from 'src/app/components/customer/customer-info-edit/customer-info-edit.component';
import { CustomerNotesComponent } from 'src/app/components/customer-notes/customer-notes.component';
import { ActivatedIds } from 'src/app/models/activated-ids';
import { CustomerInfoLoanModel, CustomerLoanCardInfo } from 'src/app/models/customer-info-loan-model';
import { ApiService } from 'src/app/services/api.service';
import { CustomerService } from 'src/app/services/customer.service';
import { GuidService } from 'src/app/services/guid.service';
import { MainService } from 'src/app/services/main.service';
import { UserService } from 'src/app/services/user.service';


@Component({
  selector: 'app-dashboard-page',
  templateUrl: './dashboard-page.component.html',
  styleUrls: ['./dashboard-page.component.scss']
})
export class DashboardPageComponent implements OnInit {

  @ViewChild('editCustomer') editCustomer: CustomerInfoEditComponent = {} as CustomerInfoEditComponent;
  @ViewChild('customerNotes') customerNotes: CustomerNotesComponent = {} as CustomerNotesComponent;
  
  customerInfo: CustomerInfoLoanModel = new CustomerInfoLoanModel;
  cardInfo: CustomerLoanCardInfo = new CustomerLoanCardInfo;

  showEditCusomer: boolean = false;
  contactEnabled: boolean = false;

  ids: ActivatedIds = {};


  constructor(private main: MainService, 
    private userService: UserService,
    private guidService: GuidService,
    private activatedRoute: ActivatedRoute, 
    private router: Router,
    private customerService: CustomerService, 
    private api: ApiService) {
     }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(async paramMap => {
      let cGuid = paramMap.get('customerGuid')?.toString() ?? '';
      if (this.guidService.isValidGuid(cGuid)) {
        let customerGuid = cGuid;
        this.customerService.initCustomer(customerGuid);
        await this.userService.subscribeAccess();
        let data = this.activatedRoute.snapshot.data;
        if (data && data['pageId']) {
          this.userService.checkPageAccess(data['pageId']);
        }
        this.initPage();
      }
    });
  }

  initPage() {
    this.customerService.customer$
    .subscribe(cust => {
      if (cust) {
        this.contactEnabled = cust.isCSContactEnabled == "1";
      }
    });
    this.main.activatedIds$.subscribe((ids) => {
      if (ids && ids.targetGuid) {
        if (!this.ids || this.ids.targetGuid !== ids.targetGuid) {
          this.ids = ids;
        }
      }
    });
  }

  hasFeature(feature: string, mode: string = 'write') {
    switch (mode) {
      case 'read':
        return this.userService.hasRead(feature);
        break;
      case 'feature': 
        return this.userService.hasFeature(feature);
        break;
      default:
        return this.userService.hasWrite(feature);
        break;
    }
  }

  ngOnDestroy(): void {
    this.main.showHideCsrSidenav(false);
  }

  editCustomerInfo(){
    this.showEditCusomer = true;
  }

  deleteCustomerInfo(){
    this.main.confirmDelete("Delete Customer", 'Are you sure that you want to delete this Customer?', '500px').afterClosed().subscribe(result => {
      if (result && result == 'confirm') {
        this.confirmedDeleteCustomer();
      }
    });
  }

  customerInfoUpdated(info: CustomerInfoLoanModel) {
    this.customerInfo = info;
  }

  cardInfoUpdated(card: CustomerLoanCardInfo) {
    this.cardInfo = card;
  }

  cancelEditCustomerInfo(){
    this.showEditCusomer = false;

  }

  customerUpdated(val: boolean){
    if (val && this.ids.customerGuid){
      this.showEditCusomer = false;
      this.customerService.infoChanged.next('changed');
    }
  }

  reloadNotes() {
    this.customerNotes.reload();
  }

  saveCustomerInfo(){
    this.editCustomer.saveInfo();
  }

  confirmedDeleteCustomer(){
    this.main.showLoading();
    var model = {
      CustomerGuid: this.ids.customerGuid,
      CampaignGuid: this.ids.campaignGuid,
      TargetGuid: this.ids.targetGuid
    }
    var body = JSON.stringify(model);
    this.api.post(`csr/delete-target`, body)
      .subscribe((res: any) => {
        this.main.dismissLoading();
        if (res && res === true){
          this.main.showSuccessModal("Success", "Customer Deleted!");
          this.router.navigate([`/collections-queue/${this.ids.customerGuid}`])
        }else{
          this.main.showErrorModal("Oops...", "Customer could not be deleted");
        }
      },
        (err: any) => {
          this.main.dismissLoading();
          this.main.showErrorModal("Oops...", "Customer could not be deleted");
        }
      );
  }

}
