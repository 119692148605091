import { Component, Input, OnInit } from '@angular/core';
import { CSRRewardTierInfo } from 'src/app/models/csr/reward-tier-info';

@Component({
  selector: 'app-customer-reward-info',
  templateUrl: './customer-reward-info.component.html',
  styleUrls: ['./customer-reward-info.component.scss']
})
export class CustomerRewardInfoComponent implements OnInit {
  @Input() rewardInfo: CSRRewardTierInfo = {} as CSRRewardTierInfo;

  percentageUntilNextTier: number = 100;
  rewardsAdjustmentPointsUntilNextTier: number = 0;

  constructor() { }

  ngOnInit(): void {

  }

  initPage() {
    setTimeout(() => {
      let rewardsAdjustmentPointBalance: number = isNaN(+this.rewardInfo.rewardsAdjustmentPointBalance) ? 0 : +this.rewardInfo.rewardsAdjustmentPointBalance;
      let rewardsAdjustmentPendingPoints: number = isNaN(+this.rewardInfo.rewardsAdjustmentPendingPoints) ? 0 : +this.rewardInfo.rewardsAdjustmentPendingPoints;
      let rewardsAdjustmentNextTierPoints: number = isNaN(+this.rewardInfo.rewardsAdjustmentNextTierPoints) ? 0 : +this.rewardInfo.rewardsAdjustmentNextTierPoints;
      let rewardsAdjustmentCurrentTierPoints: number = isNaN(+this.rewardInfo.rewardsAdjustmentCurrentTierPoints) ? 0 : +this.rewardInfo.rewardsAdjustmentCurrentTierPoints;
      this.rewardsAdjustmentPointsUntilNextTier = rewardsAdjustmentNextTierPoints - rewardsAdjustmentPointBalance;

      if (rewardsAdjustmentNextTierPoints > rewardsAdjustmentCurrentTierPoints) {
        //if there is a next tier level
        let tmp1 = Math.floor((1.0 * (rewardsAdjustmentPointBalance - rewardsAdjustmentCurrentTierPoints) / (rewardsAdjustmentNextTierPoints - rewardsAdjustmentCurrentTierPoints)) * 100);
        this.percentageUntilNextTier = Math.floor(tmp1 / 5) * 5;
        this.percentageUntilNextTier = this.percentageUntilNextTier <= 100 ? this.percentageUntilNextTier : 100;
      }
      else {
        //if there is NO next tier level, then set to full (100) with 0 points remaining
        this.percentageUntilNextTier = 100;
        this.rewardsAdjustmentPointsUntilNextTier = 0;
      }
    });

  }
}
