import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CommQueueMessage } from 'src/app/models/communications/message-model';
import { PostCommunicationMessageModel, SendCommMessageModel } from 'src/app/models/communications/send-message-model';
import { MessageFromTemplate } from 'src/app/models/message-template';
import { ApiService } from 'src/app/services/api.service';
import { CustomerService } from 'src/app/services/customer.service';
import { GuidService } from 'src/app/services/guid.service';
import { ToastService } from 'src/app/services/toast.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-comm-queue-email',
  templateUrl: './comm-queue-email.component.html',
  styleUrls: ['./comm-queue-email.component.scss']
})
export class CommQueueEmailComponent implements OnInit, OnChanges, OnDestroy {

  @Output() onBack = new EventEmitter();
  @Output() onRefresh = new EventEmitter();
  @Input() messageTemplateGuid: string | null = null;
  @Input() replyMessage: any | null = null;
  @Input() customerGuid: string | null = null;


  emailForm: FormGroup = {} as FormGroup;

  fromItems: MessageFromTemplate[] = [];
  myFiles: File[] = [];
  isTemplate: boolean = false;
  showAttach: boolean = false;
  templateName: string = '';
  defaultMessageTypeId: number = 2;
  emailState: string = 'New';

  constructor(
    private customerService: CustomerService,
    private fb: FormBuilder,
    private apiService: ApiService,
    private toastService: ToastService,
    private guidService: GuidService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.initPage();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes['messageTemplateGuid'] && !changes['messageTemplateGuid'].isFirstChange())
      || (changes['replyMessage'] && !changes['replyMessage'].isFirstChange())) {
      this.initPage();
    }
  }

  ngOnDestroy(): void {
    this.messageTemplateGuid = null;
    this.replyMessage = null;
  }

  initPage() {
    if (this.replyMessage) this.emailState = 'Reply to'
    this.emailForm = this.fb.group({
      emailTo: [null, [Validators.required]],
      emailFrom: [null, [Validators.required]],
      emailSubject: [null, Validators.required],
      emailBody: [null, Validators.required]
    });
    
    let fromSub = this.apiService.get(`communications/message-category-readall/${this.customerGuid}`)
      .subscribe({
        next: (res: any) => {
          if (res && res.length > 0) {
            this.fromItems = res;
            for (let i = 0; i < res.length; i++) {
              let item = this.fromItems[i];
              this.fromItems[i].ddLabel = `${item.messageCategoryName} (${item.profileName})`;
            }
          }
          this.checkGetMessageTemplate();
        },
        error: (err: any) => { console.error(err); },
        complete: () => { fromSub.unsubscribe(); }
      });

  }

  hasFeature(feature: string, mode: string = 'write') {
    switch (mode) {
      case 'read':
        return this.userService.hasRead(feature);
        break;
      case 'feature':
        return this.userService.hasFeature(feature);
        break;
      default:
        return this.userService.hasWrite(feature);
        break;
    }
  }

  checkGetMessageTemplate() {

    if (this.replyMessage) {
      this.emailForm.patchValue({
        emailTo: this.replyMessage.emailFrom,
        emailSubject: `RE: ${this.replyMessage.emailSubject}`
      });
      this.emailForm.updateValueAndValidity();
    }
    else if (this.messageTemplateGuid) {
      let custSub = this.customerService.getMessageTemplate(this.messageTemplateGuid)
        .subscribe({
          next: (res: any) => {
            if (res) {
              this.isTemplate = true;
              this.templateName = res.description;
              if (res.subject) {
                this.emailForm.patchValue({
                  emailSubject: res.subject
                });
                this.emailForm.updateValueAndValidity();
              }
              if (res.body) {
                this.emailForm.patchValue({
                  emailBody: res.body
                });
                this.emailForm.updateValueAndValidity();
              }
            }
          },
          error: (err: any) => { console.error(err); },
          complete: () => { custSub.unsubscribe(); }
        })
    }
  }

  resetForm() {
    if (this.emailForm) this.emailForm.reset();
  }

  back() {
    this.onBack.emit();
  }

  onFileChange(event: any) {
    for (var i = 0; i < event.target.files.length; i++) {
      this.myFiles.push(event.target.files[i]);
    }
  }

  deleteFile(file: File) {
    let idx = this.myFiles.findIndex(f => f.name == file.name);
    if (idx > -1) this.myFiles.splice(idx, 1);
  }

  discard() {  
    this.resetForm();
    this.back();
  }

  sendEmail() {

    let commMsg = new PostCommunicationMessageModel();
    commMsg.targetGuid = this.guidService.emptyGuid();
    commMsg.campaignGuid = this.guidService.emptyGuid();
    commMsg.customerGuid = this.customerGuid;
    commMsg.messageTypeId = this.defaultMessageTypeId;
    commMsg.isDraft = false;
    commMsg.messageCategoryId = this.emailForm.value.emailFrom.messageCategoryID;
    commMsg.emailBody = this.emailForm.value.emailBody;
    commMsg.emailSubject = this.emailForm.value.emailSubject;
    commMsg.emailSentTo = this.emailForm.value.emailTo;

    let msgFolderGuid: string = this.guidService.newGuid();
    let attachList: string[] = [];
    
    //upload attachements
    if (this.myFiles.length > 0) {
      this.myFiles.forEach(mF => {
        attachList.push(mF.name);
        let payload = new FormData();
        payload.append('data', mF);
        let fileSub = this.apiService.postFile(`upload/email-attachment/${this.customerGuid}/${msgFolderGuid}`, payload)
        .subscribe({
          next: () => { },
          error: (err: any) => { console.error(err); },
          complete: () => { fileSub.unsubscribe(); }
        });
      })
    }
    else msgFolderGuid = '';

    commMsg.emailMsgGuidFolder = msgFolderGuid;
    commMsg.emailAttachments = attachList.length > 0 ? attachList.join(',') : '';


    let postUrl: string = `communications/message-post`;
    if (this.replyMessage) {
      postUrl = `communications/message-reply`;
      commMsg.replyToMessageGuid = this.replyMessage.messageGUID;
    }

    let apiPost = this.apiService.post(postUrl, commMsg)
    .subscribe({
      next: () => {             
        this.toastService.addSuccess("Message will be sent shortly");
        this.onRefresh.emit();
        this.back();
      },
      error: (err: any) => {
        console.error(err);
        this.toastService.addError('Error sending message!');
        console.error(err);
      },
      complete: () => { apiPost.unsubscribe(); }
    });

  }
}
