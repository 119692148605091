<div class="cds-card p-4">
    <div class="flex pb-2">
      <p class="h4">Notes</p>
      <div class="px-4">
        <a class="text-base" [routerLink]="noteListPageUrl">(View All)</a>
      </div>
      <div class="ml-auto pr-1">
        <button pButton class="p-button p-button-outlined" (click)="reload()">reload</button>
      </div>
    </div>

    <form [formGroup]="noteForm">
      <div class="m-1">
        <p3solved-form-textarea id="note" formControlName="text"></p3solved-form-textarea>        
      </div>
      <div class="m-1 flex">
        <div class="w-10 align-self-center">
          <p3solved-dropdown id="ddCat" [items]="noteCategories" [showClear]="true"
            optionLabel="desc" optionValue="id" formControlName="categoryId">
          </p3solved-dropdown>
        </div>
        <div class="w-2 align-self-center" *ngIf="noteForm.valid">
          <p3solved-button id="btnSubNote" label="Post" class="m-2" [disabled]="noteForm.invalid" (buttonClick)="addNote()">           
          </p3solved-button>
        </div>
      </div>
    </form>
    <div *ngIf="!loading" class="m-1">
      <div *ngFor="let n of notes">
        <div class="text-muted pt-2 mb-0 pds-divider-top">
          <div class="flex">
            <small class="mr-1">{{ n.firstName }}</small>
            <small class="mr-1">{{ n.lastName }}</small>
            <span class="flex flex-grow-1 justify-content-end">
              <small class="mr-1">{{ n.createDate }}</small>
            </span>
          </div>
          <div class="flex">
            <small class="mr-1">{{n.loanNumber}}</small>
          </div>
        </div>
        <p class="mb-0">
          <span class="mr-1 text-base">{{ n.note }}</span>
          <button pButton (click)="deleteNote(n)" class="p-button-sm p-button-danger p-button-text vertical-align-middle"
            *ngIf="hasFeature('CSR_CUSTOMER_DELETE_NOTES')">
            Delete
          </button>
        </p>
      </div>
    </div>
</div>
