import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TableColumn } from '@p3solved/p3solved-ui';
import { ConfirmEventType, ConfirmationService } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Subscription, combineLatest, forkJoin } from 'rxjs';
import { ActivatedIds } from 'src/app/models/activated-ids';
import { CommQueueMessage } from 'src/app/models/communications/message-model';
import { CustomerModel } from 'src/app/models/customer-model';
import { MessageSearchFormGroup, SpamEditFormGroup } from 'src/app/models/form-groups';
import { LookupModel } from 'src/app/models/lookup-model';
import { BogusEmail, MessageCategory, MessageTemplate } from 'src/app/models/message-template';
import { ApiService } from 'src/app/services/api.service';
import { CustomerService } from 'src/app/services/customer.service';
import { GuidService } from 'src/app/services/guid.service';
import { HelperService } from 'src/app/services/helper.service';
import { MainService } from 'src/app/services/main.service';
import { ToastService } from 'src/app/services/toast.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-communications-queue-page',
  templateUrl: './communications-queue-page.component.html',
  styleUrls: ['./communications-queue-page.component.scss']
})
export class CommunicationsQueuePageComponent implements OnInit, OnDestroy {

  customerSMS: boolean = false;
  loadingMsgs: boolean = false;
  showMailboxes: boolean = true;
  showSpamBox: boolean = false;
  showNewEmail: boolean = false;
  showNewSMS: boolean = false;
  showEditSpamModal: boolean = false;
  spamEditFormLoaded: boolean = false;
  showTemplateCategories: boolean = false;
  showTemplates: boolean = false;
  showTemplateDefault: boolean = false;
  showTemplateEdit: boolean = false;
  showSearchForm: boolean = false;
  showSearchResults: boolean = false;
  searchFormLoaded: boolean = false;
  showMailboxItem: boolean = false;

  customerGuid: string | null = null;
  ids: ActivatedIds | null = null;
  customer: CustomerModel | null = null;
  selectedTemplate: MessageTemplate | null = null;
  editTemplateCategory: MessageCategory | null = null;
  selectedMessage: CommQueueMessage | null = null;
  counts: any;
  
  subscriptions: Subscription[] = [];
  categories: MessageCategory[] = [];
  templates: MessageTemplate[] = [];
  catTemplates: MessageTemplate[] = [];
  templateCategories: LookupModel[] = [];
  spamEmails: BogusEmail[] = [];
  messages: CommQueueMessage[] = [];
  messagesShow: CommQueueMessage[] = [];
  messageFilterFields: string[] = [
    'accountNumber', 'attachmentList', 'campaignGUID', 'firstName', 'lastName', 'messageBody', 
    'messageFrom', 'messageGUID', 'messageSubject', 'msgGuidFolder', 'sentTo', 'targetGuid'    
  ];
  selectedMsgs: CommQueueMessage[] = [];
  userColumns: TableColumn[] = [];
  availableUsers: any[] = [];
  selectedUser: any;
  replyToMessage: any | null = null;

  messageTypeFilter: number = 1;
  messageTypeId: number | null = 1;
  messageCategoryId: number | null = null;
  needAttach: boolean | null = false;
  inbound: boolean | null = true;
  columnSortOrder: string | null = null;
  keySearch: string | null = null;

  commQueueConfirmKey: string = 'commqueueConfirmMsgKey';
  serviceOption: string = '';
  categoryName: string = '';
  selectedMailbox: string = 'Inbox';
  spamEmailAction: string = 'Add';
  templateCategoryName: string = '';
  messageTemplateGuid: string | null = null;

  messageMaxLen: number = 250;
  first: number = 0;
  templateMessageTypeId: number = 0;

  spamEditForm: FormGroup<SpamEditFormGroup> = {} as FormGroup;
  searchForm: FormGroup<MessageSearchFormGroup> = {} as FormGroup;

  @ViewChild('opSearch') opSearch: OverlayPanel = {} as OverlayPanel;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private apiService: ApiService,
    private helperService: HelperService,
    private main: MainService,
    private userService: UserService,
    private toastService: ToastService,
    private guidService: GuidService,
    private confirmService: ConfirmationService,
    private customerService: CustomerService) { }

  ngOnInit(): void {
    this.subscriptions.push(
      this.activatedRoute.paramMap.subscribe(async paramMap => {
        let cGuid = paramMap.get('customerGuid')?.toString() ?? '';
        if (this.guidService.isValidGuid(cGuid)) {
          this.customerGuid = cGuid;
          this.customerService.initCustomer(this.customerGuid);
          // await this.userService.subscribeAccess();
          // let data = this.activatedRoute.snapshot.data;
          // if (data && data['pageId']) {
          //   this.userService.checkPageAccess(data['pageId']);
          // }

          this.initPage();
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => { sub.unsubscribe(); });
  }

  initPage() {
    this.subscriptions.push(
      combineLatest([this.main.activatedIds$, this.customerService.customer$])
        .subscribe({
          next: ([ids, cust]) => {
            if (ids && ids.targetGuid) {
              if (!this.ids || this.ids.targetGuid !== ids.targetGuid) {
                this.ids = ids;
              }
            }
            if (cust) {
              this.customer = cust;
              this.customerSMS = this.helperService.getBoolean(this.customer.communicationShowSMS);
              this.serviceOption = "2";//cust.cSAccountServicingOption;
              if (this.customerSMS) this.messageTypeFilter = 0;
            }
            if (ids && cust) {
              this.loadData();
            }
          },
          error: (err: any) => {
            console.error(err);
          }
        })
    );

  }

  loadData() {
    const fSub = forkJoin({
      categories: this.apiService.get(`communications/message-category-readall/${this.customerGuid}`),
      templates: this.apiService.get(`communications/message-template-readall/${this.customerGuid}`),
      bogusMsgs: this.apiService.get(`communications/bogus-message-selectall/${this.customerGuid}`),
      counts: this.apiService.get(`communications/message-queue-counts/${this.customerGuid}`)
    })
      .subscribe({
        next: (data: any) => {

          this.categories = data.categories;
          this.templates = data.templates;
          this.spamEmails = data.bogusMsgs;
          this.counts = data.counts;

          if (this.categories && this.categories.length > 0) {
            this.messageCategoryId = +this.categories[0].messageCategoryID;
            this.categoryName = this.categories[0].messageCategoryName;
          }


          this.getQueue();


          // this.initPage();
        },
        error: (err: any) => {
          this.toastService.addError("Unable to get initial data. See log for details.");
          console.error(err);
        },
        complete: () => { fSub.unsubscribe(); }
      });
  }

  hasFeature(feature: string, mode: string = 'write') {
    switch (mode) {
      case 'read':
        return this.userService.hasRead(feature);
        break;
      case 'feature':
        return this.userService.hasFeature(feature);
        break;
      default:
        return this.userService.hasWrite(feature);
        break;
    }
  }

  toggleView(view: string) {
    this.showMailboxes = false;
    this.showSpamBox = false;
    this.showNewEmail = false;
    this.showNewSMS = false;
    this.showTemplateEdit = false;
    this.showTemplateDefault = false;
    this.showTemplates = false;
    this.showTemplateCategories = false;
    this.showMailboxItem = false;

    switch (view.toLowerCase()) {
      case 'mailbox':
        this.selectedMessage = {} as CommQueueMessage;
        this.showMailboxes = true;
        break;

      case 'spam':
        this.messageCategoryId = -1;
        this.showSpamBox = true;
        break;

      case 'templates':
        this.messageCategoryId = -2;
        this.showTemplateCategories = true;
        this.showTemplates = true;
        break;

      case 'edittemplate':
        this.showTemplateEdit = true;
        this.showTemplateCategories = true;
        break;

      case 'templatedefault':
        this.showTemplateDefault = true;
        this.showTemplateCategories = true;
        break;

      case 'mailboxitem':
        this.showMailboxItem = true;
        break;

      case 'newmsg':
        this.showNewEmail = true;
        break;

      case 'newsms':
        this.showNewSMS = true;
        break;

      default:
        break;
    }
  }

  getQueue() {

    let msgBody = {
      customerGuid: this.customerGuid,
      messageTypeId: this.messageTypeId,
      messageCategoryId: this.messageCategoryId,
      attachTarget: this.needAttach,
      inbound: this.inbound,
      columnSortOrder: this.columnSortOrder,
      keySearch: this.keySearch,
      unknown: this.messageCategoryId == 9999
    };
    this.loadingMsgs = true;
    const fSub = this.apiService.post(`communications/message-queues`, msgBody)
      .subscribe({
        next: (data: any) => {
          this.messages = data;
          this.loadingMsgs = false;
        },
        error: (err: any) => {
          this.loadingMsgs = false;
          this.toastService.addError("Unable to get queue data. See log for details.");
          console.error(err);
        },
        complete: () => { fSub.unsubscribe(); }
      });
  }

  formatMsgBody(html: string, accountNumber: string): string {

    try {
      let div = document.createElement('div');
      div.innerHTML = html;
      let body: string = div.textContent || div.innerText || '';
      if (!body || body.length == 0) {
        body = accountNumber;
      }
      if (body && body.length > this.messageMaxLen) {
        body = body.substring(0, this.messageMaxLen) + '...';
      }

      return body;

    } catch (error) {
      console.error(error);
      return '';
    }
  }

  loadCategory(type: string, category: string) {
    if (!this.showMailboxes) this.toggleView('mailbox');

    this.selectedMailbox = type;
    if (category == '9999') {
      this.categoryName = 'Unknown';
    }
    else {
      let cat = this.categories.find(c => c.messageCategoryID == category);
      this.categoryName = cat?.messageCategoryName ?? 'Unknown';
    }
    this.messageCategoryId = +category;
    this.needAttach = type != 'inbox';
    this.inbound = type != 'sent';
    if (!this.inbound) this.messageTypeId = null;
    this.getQueue();
  }

  filterComms(typeId: number) {
    this.messageTypeFilter = typeId;
    this.messageTypeId = typeId;
    this.getQueue();
  }

  deleteMessages() {
    if (this.selectedMsgs.length == 0) {
      this.toastService.addWarn("Select one or more messages first");
      return;
    }

    let guids = this.selectedMsgs.map(s => s.messageGUID).join(',');

    let pSub = this.apiService.post(`communications/message-delete/${this.customerGuid}`, JSON.stringify(guids))
      .subscribe({
        next: () => {
          this.toastService.addSuccess("Message(s) successfully deleted.");
          this.selectedMsgs = [];
          this.loadData();
        },
        error: (err: any) => {
          this.toastService.addError("Unexpected Error. Unable to delete messages. See log for details.");
          console.error(err);
        },
        complete: () => { pSub.unsubscribe(); }
      });
  }

  confirmCompleteMessages() {
    if (this.selectedMsgs.length == 0) {
      this.toastService.addWarn("Select one or more messages first");
      return;
    }
    this.confirmService.confirm({
      key: this.commQueueConfirmKey,
      message: `<p><strong>Are you sure you want to mark item(s) as completed?</strong></p>`,
      header: '',
      rejectLabel: 'Cancel',
      rejectButtonStyleClass: 'p-button-danger',
      acceptLabel: 'Complete',
      accept: () => {
        this.completeMessages();
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.toastService.add({ severity: 'warn', summary: 'Declined', detail: 'Item(s) will not be completed.' });
            break;
          case ConfirmEventType.CANCEL:
            this.toastService.add({ severity: 'warn', summary: 'Cancelled', detail: 'Operation cancelled.' });
            break;
        }
      }
    });
  }

  completeMessages() {
    let guids = this.selectedMsgs.map(s => s.messageGUID).join(',');
    let body = {
      customerGuid: this.customerGuid,
      messageGuids: guids,
      comments: ''
    };

    let pSub = this.apiService.post(`communications/message-complete`, body)
      .subscribe({
        next: () => {
          this.toastService.addSuccess("Item(s) completed.");
          this.selectedMsgs = [];
          this.loadData();
        },
        error: (err: any) => {
          this.toastService.addError("Unexpected Error. Unable to complete item(s). See log for details.");
          console.error(err);
        },
        complete: () => { pSub.unsubscribe(); }
      });
  }

  moveMessages() {
    let targetGuid = this.selectedUser.targetGUID as string;
    let messageGuids = this.selectedMsgs.map(s => s.messageGUID).join(',');
    let body = {
      customerGuid: this.customerGuid,
      targetGuid,
      messageGuids
    };

    let pSub = this.apiService.post(`csr/attach-to-target`, body)
      .subscribe({
        next: () => {
          this.toastService.addSuccess("Message(s) successfully moved.");
          this.loadData();
        },
        error: (err: any) => {
          this.toastService.addError("Unable to move message(s). See log for details.");
          console.error(err);
        },
        complete: () => { pSub.unsubscribe(); }
      });
  }

  getQueueBoxClass(catId: string, type: string): string {
    let qClass: string = '';
    if (+catId == this.messageCategoryId) {
      if (type == 'inbox' && !this.needAttach && this.inbound) {
        qClass = 'commCategorySelected';
      }
      else if (type == 'sent' && this.needAttach && !this.inbound) {
        qClass = 'commCategorySelected';
      }
      else if (type == 'unknown' && this.needAttach && this.inbound) {
        qClass = 'commCategorySelected';
      }
    }
    return qClass;
  }

  /* #region Templates */

  editTemplateDefault(category: MessageCategory) {
    this.loadTemplates(category);
    this.editTemplateCategory = category;
    if (!this.showTemplateDefault) this.toggleView('templatedefault');
  }

  loadTemplates(category: MessageCategory) {
    if (!this.showTemplates) this.toggleView('templates');
    this.catTemplates = this.templates.filter(m => m.messageCategoryID == category.messageCategoryID);
    this.templateCategoryName = category.messageCategoryName;
    window.scrollTo(0, 0);
  }

  loadTemplate(template: MessageTemplate) {
    this.selectedMessage = null;
    this.replyToMessage = null;
    this.messageTemplateGuid = template.messageTemplateGUID;
    let typeId = +template.messageTypeID;
    this.showDraftBasedOnType(typeId);
  }

  editTemplate(template: MessageTemplate) {
    this.selectedTemplate = template;
    this.templateMessageTypeId = +template.messageTypeID;
    if (!this.showTemplateEdit) this.toggleView('editTemplate');
  }

  addTemplate(typeId: number) {
    this.selectedTemplate = null;
    this.templateMessageTypeId = typeId;
    if (!this.showTemplateEdit) this.toggleView('editTemplate');
  }

  confirmDeleteTemplate(template: MessageTemplate) {
    this.confirmService.confirm({
      key: this.commQueueConfirmKey,
      message: `<p>Are you sure you want to delete the <i>"${template.messageCategoryName}"</i> template?</p>`,
      header: '',
      rejectLabel: 'Cancel',
      rejectButtonStyleClass: 'p-button-secondary',
      acceptLabel: 'Confirm',
      accept: () => {
        this.deleteTemplate(template);
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.toastService.add({ severity: 'warn', summary: 'Declined', detail: 'Template will not be deleted.' });
            break;
          case ConfirmEventType.CANCEL:
            this.toastService.add({ severity: 'warn', summary: 'Cancelled', detail: 'Operation cancelled.' });
            break;
        }
      }
    });
  }

  deleteTemplate(template: MessageTemplate) {
    let body = {
      customerGuid: this.customerGuid,
      templateGuid: template.messageTemplateGUID
    };

    let pSub = this.apiService.post(`communications/message-template-delete`, body)
      .subscribe({
        next: () => {
          this.toastService.addSuccess("Template successfully deleted.");
          this.loadData();
        },
        error: (err: any) => {
          this.toastService.addError("Unable to delete template. See log for details.");
          console.error(err);
        },
        complete: () => { pSub.unsubscribe(); }
      });
  }

  /* #endregion */

  /* #region Spam */

  confirmDeleteSpam(spam: BogusEmail) {
    this.confirmService.confirm({
      key: this.commQueueConfirmKey,
      message: `<p>Are you sure you want to delete <strong>${spam.fromEmail}</strong></p>`,
      header: '',
      rejectLabel: 'Cancel',
      rejectButtonStyleClass: 'p-button-secondary',
      acceptButtonStyleClass: 'p-button-danger',
      acceptLabel: 'Delete',
      accept: () => {
        this.deleteSpam(spam);
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.toastService.add({ severity: 'warn', summary: 'Declined', detail: 'Spam email will not be deleted.' });
            break;
          case ConfirmEventType.CANCEL:
            this.toastService.add({ severity: 'warn', summary: 'Cancelled', detail: 'Operation cancelled.' });
            break;
        }
      }
    });
  }

  deleteSpam(spam: BogusEmail) {
    let body = {
      customerGuid: this.customerGuid,
      emailId: spam.bogusEmailID
    };
    let pSub = this.apiService.post(`communications/spam-delete`, body)
      .subscribe({
        next: () => {
          this.toastService.addSuccess("Spam email successfully deleted.");
          this.loadData();
        },
        error: (err: any) => {
          this.toastService.addError("Unable to delete spam. See log for details.");
          console.error(err);
        },
        complete: () => { pSub.unsubscribe(); }
      });
  }

  editSpam(spam: BogusEmail) {
    this.spamEmailAction = 'Edit';
    this.spamEditForm = new FormGroup<SpamEditFormGroup>({
      emailId: new FormControl<number>(+spam.bogusEmailID, { nonNullable: true }),
      from: new FormControl<string | null>(spam.fromEmail, { nonNullable: true }),
      subject: new FormControl<string | null>(spam.subject, { nonNullable: true }),
      body: new FormControl<string | null>(spam.body, { nonNullable: true }),
    });
    this.spamEditFormLoaded = true;
    this.showEditSpamModal = true;
  }

  addSpamEmail() {
    this.spamEmailAction = 'Add';
    this.spamEditForm = new FormGroup<SpamEditFormGroup>({
      emailId: new FormControl<number>(0, { nonNullable: true }),
      from: new FormControl<string | null>(null, { nonNullable: true }),
      subject: new FormControl<string | null>(null, { nonNullable: true }),
      body: new FormControl<string | null>(null, { nonNullable: true }),
    });
    this.spamEditFormLoaded = true;
    this.showEditSpamModal = true;
  }

  saveSpamEmail() {
    let body = {
      customerGuid: this.customerGuid,
      ...this.spamEditForm.value
    };
    let pSub = this.apiService.post(`communications/spam-update`, body)
      .subscribe({
        next: () => {
          this.toastService.addSuccess("Spam email successfully added/updated.");
          this.loadData();
        },
        error: (err: any) => {
          this.toastService.addError("Unable to add/update spam. See log for details.");
          console.error(err);
        },
        complete: () => {
          this.showEditSpamModal = false;
          this.spamEditFormLoaded = false;
          pSub.unsubscribe();
        }
      });
  }

  /* #endregion */

  toggleSearchForm(event: any) {
    if (this.opSearch.overlayVisible) {
      this.showSearchForm = true;
      this.showSearchResults = false;
      this.initSearchForm();
    }
    else {
      this.searchFormLoaded = false;
      this.searchForm = {} as FormGroup;
      this.userColumns = [];
    }
  }

  initSearchForm() {
    this.searchForm = new FormGroup<MessageSearchFormGroup>({
      firstName: new FormControl<string>('', { nonNullable: true }),
      lastName: new FormControl<string>('', { nonNullable: true }),
      phone: new FormControl<string>('', { nonNullable: true }),
      loanNumber: new FormControl<string>('', { nonNullable: true }),
      email: new FormControl<string>('', { nonNullable: true })
    });
    this.searchFormLoaded = true;
  }

  searchMessages() {
    let doc = document.implementation.createDocument('', 'filter');
    let filter = doc.querySelector('filter');

    let childNode = doc.createElement('customer_guid');
    childNode.innerHTML = this.customerGuid ?? '';
    filter?.appendChild(childNode);

    childNode = doc.createElement('mobile_number');
    childNode.innerHTML = this.searchForm.value.phone ?? '';
    filter?.appendChild(childNode);

    childNode = doc.createElement('last_name');
    childNode.innerHTML = this.searchForm.value.lastName ?? '';
    filter?.appendChild(childNode);

    childNode = doc.createElement('first_name');
    childNode.innerHTML = this.searchForm.value.firstName ?? '';
    filter?.appendChild(childNode);

    childNode = doc.createElement('loan_number');
    childNode.innerHTML = this.searchForm.value.loanNumber ?? '';
    filter?.appendChild(childNode);

    childNode = doc.createElement('email_address');
    childNode.innerHTML = this.searchForm.value.email ?? '';
    filter?.appendChild(childNode);
 
    const serializer = new XMLSerializer();      
    let filterXml = serializer.serializeToString(doc);

    let body = {
      customerGuid: this.customerGuid,
      campaignGuid: this.guidService.emptyGuid(),
      filterXml,
      cryptPass: ''
    };

    let pSub = this.apiService.post(`csr/target-user-filter`, body)
    .subscribe({
      next: (users: any[]) => {
        this.setupUserTable();
        this.availableUsers = users;
        this.showSearchForm = false;
        this.showSearchResults = true;
      },
      error: (err: any) => {
        this.toastService.addError("Unexpected Error. Unable to run search filter. See log for details.");
        console.error(err);
      },
      complete: () => { pSub.unsubscribe(); }
    });

  }

  setupUserTable() {
    this.userColumns = [
      {field: 'loanNumber', header: 'Account Number', class: null, sortable: false, show: true, clickable: false},
      {field: 'lastName', header: 'Last Name', class: null, sortable: false, show: true, clickable: false},
      {field: 'firstname', header: 'First Name', class: null, sortable: false, show: true, clickable: false},
      {field: 'mobileNumber', header: 'Mobile Number', class: null, sortable: false, show: true, clickable: false},
      {field: 'emailAddress', header: 'Email', class: null, sortable: false, show: true, clickable: false},
      {field: 'targetGUID', header: 'Target Guid', class: null, sortable: false, show: false, clickable: false}
    ];
  }

  getUserDisplayColumns() {
    return this.userColumns.filter(f => f.show);
  }

  viewMessage(msg: CommQueueMessage) {
    if (this.needAttach) {    // Unknown or Sent
      this.selectedMessage = msg;
      this.toggleView('mailboxitem');
    }
    else {      //  Inbox - send to target comms

      let url = `communications/${this.customerGuid}/${msg.campaignGUID}/${msg.targetGuid}`;
      
      this.router.navigate([url], {
        queryParams: {messageGuid: msg.messageGUID}
      });
    }

  }

  showDraftBasedOnType(typeId: number) {
    if (typeId == 1 || typeId == 2) this.toggleView('newmsg');
    else if (typeId == 3 || typeId == 4) this.toggleView('newsms');
    else this.toastService.addError('Unable to determine message type for Draft/Reply.');
  }

  replyToMsg(rawMessage: any) {
    this.messageTemplateGuid = null;
    this.selectedMessage = null;
    this.replyToMessage = rawMessage;
    let typeId = +(this.replyToMessage.messageTypeID || 0);
    this.showDraftBasedOnType(typeId);
  }

  loadEmail(clearPrev: boolean = false) {    
    this.messageTemplateGuid = null;
    this.selectedMessage = null;
    this.replyToMessage = null;
    this.toggleView('newmsg');
  }

  clearSMS() {
    this.messageTemplateGuid = null;
    this.replyToMessage = null;
    this.selectedMessage = null;
   }

  loadSMS() { 
    this.toggleView('newsms');
  }

  backFromSMS() {
    this.clearSMS();
    this.toggleView('mailbox');
  }

}
