export class TargetInfoModel {
    accountPhoneMe = "False";
    acctrefno = "";
    arbitration = "False";
    autoDial = "False";
    cSRAggentPass = "True";
    campaignDomain = "dev.apply.p3solved.com";
    campaignDomainProtocol = "";
    campaignFolder = "";
    campaignID = "1029";
    campaignName = "";
    cancelShow = "False";
    cashAdvanceStatus: string = '';
    cashAdvanceStatusId: string = '0';
    creditBureauExclusion: string = '';
    daysPastDue = "0";
    docsIncompleteShow = "False";
    emailAddress = "";
    firstName = "";
    fullName = "";
    fundingType = "";
    inVerificationMode = "True";
    invitationCode = "";
    isConcession = "";
    lastName = "test";
    legalStatus = "None";
    legalStatusID = "1";
    loanAppDate = "";
    loanAppStatus = "";
    loanNumber = "";
    loanOrgDate = "";
    loanStatus = "Active";
    loanStatusID = "2";
    marketingDirectMailMe = "False";
    marketingEmailMe = "False";
    marketingPhoneMe = "False";
    marketingTextMe = "False";
    paperStatements = "False";
    paymentMethod = "";
    paymentMethodID = "8";
    promiseToPay = "False";
    promiseToPayDate = "";
    reward: string = '';
    rewardId: string = '';
    shareNonpublic = "False";
    showCSRFundingBankAccount = "False";
    targetID = "";
    verificationHistory = "False";
    verificationModeExpired = "False";
    verificationModeExpiredText = "";
    withdrawShow = "False";
}