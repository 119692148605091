
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedIds } from 'src/app/models/activated-ids';
import { TargetInfoModel } from 'src/app/models/target-info-model';
import { ApiService } from 'src/app/services/api.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { MainService } from 'src/app/services/main.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-customer-contact',
  templateUrl: './customer-contact.component.html',
  styleUrls: ['./customer-contact.component.scss']
})
export class CustomerContactComponent implements OnInit {
  ids: ActivatedIds = new ActivatedIds;
  loading = true;
  targetInfo: TargetInfoModel = new TargetInfoModel;
  contactForm:any;
  contactSetting: string = "";
  value: string = "";


  @ViewChild('modalContent') confirm!: any;

  constructor(private main: MainService,
    private dashboardService: DashboardService,
    private userService: UserService,
    private fb: FormBuilder,
    private apiService: ApiService,
    private modal: NgbModal) { }

  ngOnInit(): void {
    this.main.activatedIds$.subscribe(id => {
      if (id && this.ids.targetGuid !== id.targetGuid){
        this.ids = id;
      }
    });

    this.dashboardService.targetInfo$.subscribe(info => {
      this.targetInfo = info;
      this.initForm();
    });
  }

  hasFeature(feature: string, mode: string = 'write') {
    switch (mode) {
      case 'read':
        return this.userService.hasRead(feature);
        break;
      case 'feature': 
        return this.userService.hasFeature(feature);
        break;
      default:
        return this.userService.hasWrite(feature);
        break;
    }
  }

  initForm(){
    this.contactForm = this.fb.group({
      marketingEmailMe: [this.getBoolVal(this.targetInfo.marketingEmailMe)],
      marketingTextMe: [this.getBoolVal(this.targetInfo.marketingTextMe)],
      marketingPhoneMe: [this.getBoolVal(this.targetInfo.marketingPhoneMe)],
      marketingDirectMailMe: [this.getBoolVal(this.targetInfo.marketingDirectMailMe)],
      autoDial: [this.getBoolVal(this.targetInfo.autoDial)],
      shareNonpublic: [this.getBoolVal(this.targetInfo.shareNonpublic)],
      arbitration: [this.getBoolVal(this.targetInfo.arbitration)],
    })
  }

  getBoolVal(val: string){
    return val === 'True' ? true : false;
  }

  persistContactSave(name: string, value: boolean){
    let obj = {
      fieldName: name, 
      newValue: '' + value,
      oldValue: '' + !value,
      targetGuid: this.ids.targetGuid,
      campaignGuid: this.ids.campaignGuid,
      customerGuid: this.ids.customerGuid,
      comments: null
    }
    this.apiService.post(`csr/contact-option/${this.ids.customerGuid}`, obj)
      .subscribe((r) => {
      }, (err) => {
        console.error(err);
      });
  }

  showConfirm(event: any){
    this.contactSetting = event.source._elementRef.nativeElement.parentElement.parentElement.children[0].children[0].innerText;
    this.value = event.source.checked ? "YES" : "NO";
    this.modal.open(this.confirm).result.then((r) => {
      if (r == 'confirm'){
        this.persistContactSave(event.source.name, event.source.checked);
      } else {
        event.source.toggle();
      }
    }, () => {
      event.source.toggle();
    });
  }
}
