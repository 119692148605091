import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Message } from 'primeng/api';
import { ActivatedIds } from 'src/app/models/activated-ids';
import { TargetMessageDetails, TargetMessageModel } from 'src/app/models/communications/message-model';
import { PostCommunicationMessageModel, SendCommMessageModel } from 'src/app/models/communications/send-message-model';
import { CustomerInfoLoanModel } from 'src/app/models/customer-info-loan-model';
import { MessageFromTemplate } from 'src/app/models/message-template';
import { ApiService } from 'src/app/services/api.service';
import { CustomerService } from 'src/app/services/customer.service';
import { GuidService } from 'src/app/services/guid.service';
import { ToastService } from 'src/app/services/toast.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-comm-email',
  templateUrl: './comm-email.component.html',
  styleUrls: ['./comm-email.component.scss']
})
export class CommEmailComponent implements OnInit, OnChanges, OnDestroy {

  @Output() onBack = new EventEmitter();
  @Output() loadMsg = new EventEmitter<Message>();
  @Output() refresh = new EventEmitter();
  @Input() ids: ActivatedIds = new ActivatedIds;
  @Input() customerInfo: CustomerInfoLoanModel = {} as CustomerInfoLoanModel;
  @Input() messageTemplateGuid: any = null;
  @Input() draftMessage: TargetMessageModel | null = null;

  emailForm: FormGroup = {} as FormGroup;

  fromItems: MessageFromTemplate[] = [];
  myFiles: File[] = [];
  disableTo: boolean = false;
  isTemplate: boolean = false;
  showAttach: boolean = false;
  templateName: string = '';
  defaultMessageTypeId: string = '2';
  replyGuid: string | null = null;

  constructor(
    private customerService: CustomerService,
    private fb: FormBuilder,
    private apiService: ApiService,
    private toastService: ToastService,
    private guidService: GuidService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.emailForm = this.fb.group({
      emailTo: [null, [Validators.required]],
      emailFrom: [null, [Validators.required]],
      emailSubject: [null, Validators.required],
      emailBody: [null, Validators.required]
    });

    if (this.customerInfo) {
      this.emailForm.patchValue({ emailTo: `${this.customerInfo.emailAddress} (${this.customerInfo.firstName} ${this.customerInfo.lastName})` });
      this.disableTo = true;

    }

    let fromSub = this.apiService.get(`communications/message-category-readall/${this.ids.customerGuid}`)
      .subscribe({
        next: (res: any) => {
          if (res && res.length > 0) {
            this.fromItems = res;
            for (let i = 0; i < res.length; i++) {
              let item = this.fromItems[i];
              this.fromItems[i].ddLabel = `${item.messageCategoryName} (${item.profileName})`;
            }
          }
          this.checkGetMessageTemplate();
        },
        error: (err: any) => { console.error(err); },
        complete: () => { fromSub.unsubscribe(); }
      });


  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['messageTemplateGuid']) {
      // this.smsMsg = null;
      // this.checkGetMessageTemplate();
    }
  }

  ngOnDestroy(): void {
    this.messageTemplateGuid = null;
  }

  hasFeature(feature: string, mode: string = 'write') {
    switch (mode) {
      case 'read':
        return this.userService.hasRead(feature);
        break;
      case 'feature':
        return this.userService.hasFeature(feature);
        break;
      default:
        return this.userService.hasWrite(feature);
        break;
    }
  }

  checkGetMessageTemplate() {

    if (this.messageTemplateGuid) {
      let custSub = this.customerService.getMessageTemplate(this.messageTemplateGuid)
        .subscribe({
          next: (res: any) => {
            if (res) {
              this.isTemplate = true;
              this.templateName = res.description;
              if (res.subject) {
                this.emailForm.patchValue({
                  emailSubject: res.subject
                });
                this.emailForm.updateValueAndValidity();
              }
              if (res.body) {
                this.emailForm.patchValue({
                  emailBody: res.body
                });
                this.emailForm.updateValueAndValidity();
              }
            }
          },
          error: (err: any) => { console.error(err); },
          complete: () => { custSub.unsubscribe(); }
        })
    }
    else if (this.draftMessage) {
      this.loadDraft();
    }
  }

  loadDraft() {

    let body = {
      customerGuid: this.ids.customerGuid,
      campaignGuid: this.ids.campaignGuid,
      targetGuid: this.ids.targetGuid,
      value: this.draftMessage?.messageGUID ?? ''
    };
    this.apiService.post(`csr/target-message-details`, body)
      .subscribe((res: any) => {  
        this.emailForm.patchValue({
          emailFrom: this.fromItems.find(i => i.profileName == res.emailFrom),
          emailSubject: res.emailSubject,
          emailBody: res.emailBody
        });
        this.emailForm.updateValueAndValidity();       

      },
        (err: any) => {
          this.toastService.addError('Unable to load draft message. See log for details.');
          console.error(err);
        }
      );
  }

  resetForm() {
    if (this.emailForm) this.emailForm.reset();
    this.replyGuid = null;
  }


  setupReply(replyMsg: TargetMessageDetails) {
    this.replyGuid = replyMsg.messageGUID;
    if (this.emailForm) {
      this.emailForm.patchValue({
        emailTo: replyMsg.emailFrom,
        emailSubject: `RE: ${replyMsg.emailSubject}`
      });
      this.emailForm.updateValueAndValidity();
    }
  }

  back() {
    this.replyGuid = null;
    this.onBack.emit();
  }

  onFileChange(event: any) {
    for (var i = 0; i < event.target.files.length; i++) {
      this.myFiles.push(event.target.files[i]);
    }
  }

  deleteFile(file: File) {
    let idx = this.myFiles.findIndex(f => f.name == file.name);
    if (idx > -1) this.myFiles.splice(idx, 1);
  }

  discard() {
    if (this.draftMessage) {      
      let draftSub = this.apiService.post(`communications/message-delete/${this.ids.customerGuid}`, JSON.stringify(this.draftMessage.messageGUID))
      .subscribe({
        next: () => {
          this.toastService.addSuccess('Draft message successfully discarded.');          
        },
        error: (err: any) => {
          this.toastService.addError('Unable to discard draft message. See log for details.');
          console.error(err);
        },
        complete: () => { draftSub.unsubscribe(); }
      });
    }
    this.replyGuid = null;
    this.refresh.emit();
  }

  sendEmail(isDraft: boolean = false) {
    let msgFolderGuid: string = this.draftMessage?.msgGuidFolder ?? this.guidService.newGuid();
    let attachList: string[] = [];
    if (this.draftMessage?.attachmentList) {
      (this.draftMessage.attachmentList as string).split(',').forEach(att => { attachList.push(att); });
    }
    //upload attachements
    if (this.myFiles.length > 0) {
      this.myFiles.forEach(mF => {
        attachList.push(mF.name);
        let payload = new FormData();
        payload.append('data', mF);
        let fileSub = this.apiService.postFile(`upload/email-attachment/${this.ids.customerGuid}/${msgFolderGuid}`, payload)
        .subscribe({
          next: () => { },
          error: (err: any) => { console.error(err); },
          complete: () => { fileSub.unsubscribe(); }
        });
      })
    }
    else msgFolderGuid = '';

    let commMsg = new PostCommunicationMessageModel();
    commMsg.targetGuid = this.ids.targetGuid ?? null;
    commMsg.campaignGuid = this.ids.campaignGuid ?? null;
    commMsg.customerGuid = this.ids.customerGuid ?? null;
    commMsg.messageGuid = this.draftMessage?.messageGUID ?? this.guidService.newGuid();
    commMsg.messageTypeId = +this.defaultMessageTypeId;
    commMsg.isDraft = isDraft;
    commMsg.messageCategoryId = this.emailForm.value.emailFrom.messageCategoryID;
    commMsg.emailFrom = this.emailForm.value.emailFrom.profileName;
    commMsg.emailBody = this.emailForm.value.emailBody;
    commMsg.emailSubject = this.emailForm.value.emailSubject;
    commMsg.emailSentTo = this.disableTo && !this.replyGuid ? this.customerInfo.emailAddress : this.emailForm.value.emailTo;
    commMsg.emailMsgGuidFolder = msgFolderGuid;
    commMsg.emailAttachments = attachList.length > 0 ? attachList.join(',') : '';
    
    let postUrl: string = 'communications/message-post';
    if (this.replyGuid) {
      commMsg.replyToMessageGuid = this.replyGuid;
      if (!isDraft) {
        postUrl = 'communications/message-reply';
      }
    }

    let msgSub = this.apiService.post(postUrl, commMsg)
    .subscribe({
      next: () => {
        if (isDraft) this.toastService.addSuccess('Draft Message successfully saved.');
        else this.toastService.addSuccess('Message will be sent shortly.');
        this.replyGuid = null;
        this.refresh.emit();
        },
      error: (err: any) => { 
        this.toastService.addError('Unable to send/save message. See log for details.');
        console.error(err);
      },
      complete: () => { msgSub.unsubscribe(); }
    });

  }

}