<div class="app-success text-center">
  <fa-icon
    [icon]="iconCheck"
    size="5x"
    [classes]="['m-3 text-success']"
  ></fa-icon>
  <p class="h3 mb-3">{{ header }}</p>
  <p class="text-l font-light mb-4">{{ message }}</p>
  <div class="col-12 mb-5 align-content-center">
    <p3solved-button
      label="OK"
      class="p-button-lg w-5"
      (buttonClick)="okClick.emit()"
    ></p3solved-button>
  </div>
</div>
