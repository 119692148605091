export class TargetAccountSummary {
    firstName: string = ""; 
    lastName: string = ""; 
    emailAddress: string = ""; 
    city: string = ""; 
    state: string = ""; 
    zipCode: string = ""; 
    acctRefNo: string = ""; 
    loanNumber: string = ""; 
    last4Account: string = ""; 
    last4BankAccount: string = ""; 
    approvedCreditAmount: string = "";
    asterisk_ApprovedCreditAmount: string = "";
    asteriskNote_ApprovedCreditAmount: string = "";
    bankAccountNumber: string = ""; 
    bankRoutingNumber: string = ""; 
    bankAccountNumberPayments: string = ""; 
    bankRoutingNumberPayments: string = ""; 
    paydayInterval: string = ""; 
    fundingMethod: string = ""; 
    fundingMethodDescription: string = ""; 
    loanMonthlyPaymentMidTerm: string = ""; 
    loanSliderOptions: string = ""; 
    midTermDrawAmount: string = ""; 
    loanAmount: string = ""; 
    currentPrincipalBalance: string = ""; 
    currentPrincipalBalanceNoPending: string = '';
    currentFees: string = ""; 
    principalBalance: string = ""; 
    interestBalance: string = ""; 
    feesBalance: string = ""; 
    lateFeesBalance: string = ""; 
    totalAccountBalance: string = ""; 
    amountAvailable: string = ""; 
    showRequestCash: string = ""; 
    daysPastDue: string = ""; 
    totalPastDueBalance: string = "";
    cAFBalance: string = ""; 
    fFCBalance: string = "";
    hideTotalCurrentDueSection: string = "";
    totalCurrentDueBalance: string = ""; 
    totalCurrentDue_PPal: string = ""; 
    totalCurrentDue_CAF: string = ""; 
    totalCurrentDue_FFC: string = ""; 
    hideTotalPastDueSection: string = "";
    totalPastDueBalanceColor: string = ""; 
    totalPastDue_CAF: string = "";
    totalPastDue_FFC: string = "";
    totalPastDue_PPal: string = "";
    minimumPaymentDate: string = ""; 
    minimumPaymentAmount: string = ""; 
    minimumPaymentAmountPrincipal: string = ""; 
    minimumPaymentAmountInterest: string = ""; 
    minimumPaymentAmountFees: string = ""; 
    minimumPaymentPushFromDate: string = ""; 
    minimumPaymentPushAmountCAF: string = '';
    minimumPaymentPushAmountFCC: string = '';
    minimumPaymentPushDate: string = ""; 
    minimumPaymentPushAmount: string = ""; 
    minimumPaymentPushCreateDate: string = ""; 
    minimumPaymentPushAmountPrincipal: string = ""; 
    minimumPaymentPushAmountInterest: string = ""; 
    minimumPaymentPushAmountFees: string = ""; 
    myModal_AutoACHConfirmPaymentDescription: string = ""; 
    myModal_AutoACHConfirmPaymentDescription1: string = ""; 
    myModal_AutoACHConfirmPaymentTitle: string = ""; 
    myModal_AutoACHConfirmPaymentTitle1: string = ""; 
    pushPaymentAvailable: string = ""; 
    pushPaymentAvailableMessage: string = ""; 
    pendingTransaction: string = ""; 
    nextPaymentDate: string = ""; 
    nextPaymentAmount: string = ""; 
    nextPaymentAmountPrincipal: string = ""; 
    nextPaymentAmountInterest: string = ""; 
    nextPaymentAmountFees: string = ""; 
    makePaymentDisabled: string = ""; 
    makePaymentDisabledMessage: string = ""; 
    aCHEnabled: string = ""; 
    debitCardLastFour: string = ""; 
    debitCardExpirationDate: string = ""; 
    pushPaymentMinDate: string = ""; 
    pushPaymentMaxDate: string = ""; 
    pushPaymentMyModalTitle1: string = ""; 
    pushPaymentMyModalDescription1: string = ""; 
    pushPaymentMyModalTitle2: string = ""; 
    pushPaymentMyModalDescription2: string = ""; 
    pushPaymentMyModalTitle3: string = ""; 
    pushPaymentMyModalDescription3: string = ""; 
    standardPaymentMinDate: string = ""; 
    standardPaymentMaxDate: string = ""; 
    standardPaymentAmount: string = ""; 
    standardPaymentMyModalTitle1: string = ""; 
    standardPaymentMyModalDescription1: string = ""; 
    standardPaymentMyModalTitle2: string = ""; 
    standardPaymentMyModalDescription2: string = ""; 
    standardPaymentMyModalTitle3: string = ""; 
    standardPaymentMyModalDescription3: string = ""; 
    standardPaymentCardMyModalTitle1: string = ""; 
    standardPaymentCardMyModalDescription1: string = ""; 
    standardPaymentCardMyModalTitle2: string = ""; 
    standardPaymentCardMyModalDescription2: string = ""; 
    standardPaymentCardMyModalTitle3: string = ""; 
    standardPaymentCardMyModalDescription3: string = ""; 
    standardPaymentMyModalTitle: string = ""; 
    standardPaymentMyModalDescription: string = ""; 
    standardPaymentCardMyModalDescription: string = "";
    otherAmountPaymentMinDate: string = ""; 
    otherAmountPaymentMaxDate: string = ""; 
    otherAmountPaymentMyModalTitle1: string = ""; 
    otherAmountPaymentMyModalDescription1: string = ""; 
    otherAmountPaymentMyModalTitle2: string = ""; 
    otherAmountPaymentMyModalDescription2: string = ""; 
    otherAmountPaymentMyModalTitle3: string = ""; 
    otherAmountPaymentMyModalDescription3: string = ""; 
    otherAmountPaymentCardMyModalTitle1: string = ""; 
    otherAmountPaymentCardMyModalDescription1: string = ""; 
    otherAmountPaymentCardMyModalTitle2: string = ""; 
    otherAmountPaymentCardMyModalDescription2: string = ""; 
    otherAmountPaymentCardMyModalTitle3: string = ""; 
    otherAmountPaymentCardMyModalDescription3: string = ""; 
    otherAmountPaymentPartialMyModalDescription: string = "";
    otherAmountPaymentPartialMyModalTitle: string = "";
    showConfirmPayment: string = "";
    showConfirmPayment1: string = "";
    totalBalancePaymentMinDate: string = ""; 
    totalBalancePaymentMaxDate: string = ""; 
    totalBalancePaymentMyModalTitle: string = ""; 
    totalBalancePaymentMyModalDescription: string = "";
    totalBalancePaymentMyModalTitle1: string = ""; 
    totalBalancePaymentMyModalDescription1: string = ""; 
    totalBalancePaymentMyModalTitle2: string = ""; 
    totalBalancePaymentMyModalDescription2: string = ""; 
    totalBalancePaymentMyModalTitle3: string = ""; 
    totalBalancePaymentMyModalDescription3: string = ""; 
    totalBalancePaymentCardMyModalTitle1: string = ""; 
    totalBalancePaymentCardMyModalDescription1: string = ""; 
    totalBalancePaymentCardMyModalTitle2: string = ""; 
    totalBalancePaymentCardMyModalDescription2: string = ""; 
    totalBalancePaymentCardMyModalTitle3: string = ""; 
    totalBalancePaymentCardMyModalDescription3: string = ""; 
    debitCardPaymentMyModalTitle1: string = ""; 
    debitCardPaymentMyModalDescription1: string = ""; 
    debitCardPaymentMyModalTitle2: string = ""; 
    debitCardPaymentMyModalDescription2: string = ""; 
    debitCardPaymentMyModalTitle3: string = ""; 
    debitCardPaymentMyModalDescription3: string = ""; 
    loanAgreementPdfUrl: string = ""; 
    welcomePacketPDFUrl: string = ""; 
    welcomePacketPDFName: string = ""; 
    partialPaymentMyModalTitle: string = ""; 
    partialPaymentMyModalDescription: string = ""; 
    payInFullAmount: string = ""; 
    upcomingPaymentScheduledPopup: string = ""; 
    upcomingPaymentScheduledMessage: string = ""; 
    scheduledPaymentsHeader: string = "";
    schedulePaymentConfirmationPopup: string = ""; 
    schedulePaymentConfirmationMessage: string = ""; 
    schedulePaymentCalendarDateConfirmationMinDate: string = ""; 
    schedulePaymentCalendarDateConfirmationMessage: string = ""; 
    firstTimePaymentURL: string = "";
}