
<div class="card-body">
    <h4 class="card-title pb-3">Credit Card Adjustments</h4>
    <div class="form-group mb-2">
        <!-- <div class="form-group">
            <p3solved-dropdown id="selectedPayment" optionLabel="description"
                placeholder="Choose Refund Card Payments" [items]="cardPayments"
                [displayFirst]="false" [showClear]="false" [(ngModel)]="selectedPayment"
                (optionSelected)="setPaymentRefund($event)">
            </p3solved-dropdown>

        </div>
        <div class="form-group py-3" *ngIf="selectedPayment">
            <label class="form-label clabel pl-3">Amount to refund (Partial adjustments will not be
                reflected in NLS)</label>
            <p3solved-input-number class="pl-3" id="paymentRefund" [(ngModel)]="manualRefundAmount"
                (blurEvent)="validateCreditCard()">
                <div *ngIf="(selectedPayment && (manualRefundAmount > selectedPayment.amount))"
                    class="w-12 my-1 p-error text-xs">
                    <p>Invalid Input</p>
                </div>
            </p3solved-input-number>
        </div>
        <p3solved-button type="button" (click)="confirmCardAdjustment()"
            [disabled]="disableCreditCardPayment">CONFIRM ADJUSTMENT</p3solved-button> -->
    </div>
</div>
