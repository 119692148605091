import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { combineLatest } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ActivatedIds } from 'src/app/models/activated-ids';
import { TargetMessageDetails, TargetMessageModel } from 'src/app/models/communications/message-model';
import { MailboxCount } from 'src/app/pages/communications-page/communications-page.component';
import { ApiService } from 'src/app/services/api.service';
import { HelperService } from 'src/app/services/helper.service';
import { MainService } from 'src/app/services/main.service';

export interface messageTyp {
  class: string,
  text: string,
  code: string
}

@Component({
  selector: 'app-comm-mailbox',
  templateUrl: './comm-mailbox.component.html',
  styleUrls: ['./comm-mailbox.component.scss']
})
export class CommMailboxComponent implements OnInit, OnChanges {

  @Output() setCount = new EventEmitter<MailboxCount>();
  @Output() loadDraft = new EventEmitter<TargetMessageModel>();
  @Output() replyEvent = new EventEmitter<TargetMessageDetails|null>();

  @Input() mailbox: 'inbox' | 'draft' = 'inbox';
  @Input() mailboxLabel: string = 'Mailbox';
  @Input() customerSMS: boolean = false;

  label: string = 'All Messages';
  messageGuid: string | null = null;

  rawMessages: TargetMessageModel[] = [];
  messages: TargetMessageModel[] = [];
  filteredMessages: TargetMessageModel[] = [];
  selectedMessage: TargetMessageModel | null = null;
  loading = false;
  ids: ActivatedIds = {};
  searchCtrl = new FormControl('');

  selectedFilterType: messageTyp | undefined = undefined;
  searchStr: string | null = null;

  filters: messageTyp[] = [
    { class: '', text: 'All', code: '0' },
    { class: 'bg-orange-500', text: 'Email - Inbound', code: '1000' },
    { class: 'bg-cyan-500', text: 'Email - Out System', code: '1001' },
    { class: 'bg-yellow-500', text: 'Email - Out Agent', code: '1002' }
  ];


  readonly allMsgsStr: string = "All Messages";

  constructor(
    private actRouter: ActivatedRoute,
    private main: MainService,
    private api: ApiService,
    private helperService: HelperService
  ) { }

  ngOnInit(): void {
    if (this.customerSMS) {
      this.filters.push(
        { class: 'bg-primary', text: 'SMS - In', code: '2000' },
        { class: 'surface-500', text: 'SMS - Out System', code: '2001' },
        { class: 'bg-orange-300', text: 'SMS - Out Agent', code: '2002' },
        { class: 'bg-blue-500', text: 'Text to Pay', code: '3000' }
      )
    }

    this.searchCtrl.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged()
      )
      .subscribe(val => {
        this.filterInbox();
      });

      let combSub = combineLatest([this.main.activatedIds$, this.actRouter.queryParams])
      .subscribe({
        next: ([ids, params]) => {
          if (params && params.messageGuid) {
            this.messageGuid = params.messageGuid;
          }
          if (ids && ids.targetGuid) {
            if (!this.ids || this.ids.targetGuid !== ids.targetGuid) {
              this.ids = ids;
              this.getInbox();
            }
          }

        },
        error: (err: any) => {
          console.error(err);
        },
        complete: () => { combSub.unsubscribe(); }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['mailbox']) {
      this.updateMailbox();
    }
  }

  getInbox() {
    this.loading = true;
    this.main.showLoading();
    var model = {
      CustomerGuid: this.ids.customerGuid,
      CampaignGuid: this.ids.campaignGuid,
      TargetGuid: this.ids.targetGuid,
      Value: ""
    }
    var body = JSON.stringify(model);
    this.api.post(`csr/target-communications`, body)
      .subscribe((res: any) => {
        this.rawMessages = res.messages || [];
        this.rawMessages.forEach(msg => {
          msg.messageSubject = msg.messageSubject.replace('<i>', '').replace('</i>', '');
          msg.messageSubject = msg.messageSubject.trim();
          if (msg.messageSubject[msg.messageSubject.length - 1] === '-') {
            msg.messageSubject = msg.messageSubject.substring(0, msg.messageSubject.length - 1);
            msg.messageSubject = msg.messageSubject.trim();
          }
        })
        let count: MailboxCount = {
          inbox: Number(res.count.inboxUnreadCount) || 0,
          draft: Number(res.count.draftsCount) || 0
        }
        this.setCount.emit(count);
        this.messages = this.getMessageType();
        this.filterInbox();
        this.main.dismissLoading();
        this.loading = false;

        if (this.messageGuid) {
          let msg = this.rawMessages.find(r => r.messageGUID == this.messageGuid);
          if (msg) this.select(msg);
        }

      },
        (err: any) => {
          this.main.dismissLoading();
          this.loading = false;
        }
      );
  }

  updateMailbox() {
    this.label = this.allMsgsStr;
    this.searchStr = null;
    this.searchCtrl.setValue(null);
    this.messages = this.getMessageType();
    this.filterInbox();
  }

  getMessageType(): TargetMessageModel[] {
    if (this.mailbox == 'inbox') {
      return this.rawMessages.filter(msg => msg.isDraft.toLowerCase() === 'false');
    }
    return this.rawMessages.filter(msg => msg.isDraft.toLowerCase() === 'true');
  }

  filterInbox() {
    let searchTerm: string = "";
    this.filteredMessages = [];
    if (this.searchCtrl.value && this.searchCtrl.value.length > 0) searchTerm = this.searchCtrl.value;

    if (!this.selectedFilterType || this.selectedFilterType.code === '0') {
      this.label = this.allMsgsStr;
      this.messages.forEach(msg => {
        if (this.searchMessage(msg, searchTerm)) {
          this.filteredMessages.push(msg);
        }
      });
      return;
    }

    this.label = this.selectedFilterType.text;
    var code = this.selectedFilterType.code;
    this.messages.forEach(msg => {
      let typeMatch: boolean = false;
      switch (msg.messageTypeID) {
        case '1':
          if (code === '1000') typeMatch = true;
          break;
        case '2':
          if (code === '1001' && msg.senderFirstName.toLowerCase() === 'system') typeMatch = true;
          if (code === '1002' && msg.senderFirstName.toLowerCase() !== 'system') typeMatch = true;
          break;
        case '3':
          if (code === '2000') typeMatch = true;
          break;
        case '4':
          if (code === '2001' && msg.senderFirstName.toLowerCase() === 'system') typeMatch = true;
          if (code === '2002' && msg.senderFirstName.toLowerCase() !== 'system') typeMatch = true;
          break;
        case '5':
          if (code === '3000') typeMatch = true;
          break;
        default:
          break;
      }

      if (typeMatch && this.searchMessage(msg, searchTerm)) {
        this.filteredMessages.push(msg);
      }
    });
  }

  setFilterType(typ: messageTyp | undefined) {
    this.selectedFilterType = typ;
    this.filterInbox();
  }

  private searchMessage(msg: TargetMessageModel, val: string) {
    if (msg.messageTypeID === '5' || msg.messageTypeID === '6') return false;

    if (val.trim() == "") return true;
    val = val.toLowerCase();
    var columns = [
      'emailStatus',
      'fromEmail',
      'fromFullName',
      'messageSubject',
      'messageText',
      'messageType',
      'sendReceivedDate',
      'senderFirstName',
      'senderLastName'
    ];

    for (var i = 0; i < columns.length; i++) {
      let aMsg: any = msg as any;
      if (aMsg[columns[i]] && aMsg[columns[i]].toLowerCase().indexOf(val) !== -1) {
        return true;
      }
    }
    return false;

  }

  select(message: TargetMessageModel) {
    if (this.helperService.getBoolean(message.isDraft)) {
      this.loadDraft.emit(message);
      return;
    }
    this.selectedMessage = message;
  }

  backToInbox() {
    this.selectedMessage = null;
  }

  refresh() {
    this.searchStr = null;
    this.searchCtrl.setValue(null);
    this.label = this.allMsgsStr;
    this.selectedFilterType = this.filters[0];
    this.getInbox();
  }

  getItemBadgeStyle(msg: TargetMessageModel) {
    let badgeStyle = 'bg-cyan-500';
    if (this.selectedFilterType && this.selectedFilterType.code !== '0') {
      badgeStyle = this.selectedFilterType.class;
      return badgeStyle;
    }
    switch (msg.messageTypeID) {
      case '1':
        badgeStyle = this.filters.find(f => f.code === '1000')?.class || badgeStyle;
        break;
      case '2':
        if (msg.senderFirstName.toLowerCase() === 'system')
          badgeStyle = this.filters.find(f => f.code === '1001')?.class || badgeStyle;
        else
          badgeStyle = this.filters.find(f => f.code === '1002')?.class || badgeStyle;
        break;
      case '3':
        badgeStyle = this.filters.find(f => f.code === '2000')?.class || badgeStyle;
        break;
      case '4':
        if (msg.senderFirstName.toLowerCase() === 'system')
          badgeStyle = this.filters.find(f => f.code === '2001')?.class || badgeStyle;
        else
          badgeStyle = this.filters.find(f => f.code === '2002')?.class || badgeStyle;
        break;
      case '5':
        badgeStyle = this.filters.find(f => f.code === '3000')?.class || badgeStyle;
        break;
      default:
        break;
    }
    return badgeStyle;
  }

  replyToMsg(msg: TargetMessageDetails|null) {
    if (!msg) return;

    this.replyEvent.emit(msg);
  }
}