<div class="pt-3">
    <div class="flex pb-3 border-bottom-1 border-black-alpha-10">
        <div class="col-11 flex flex-wrap">
            <span class="text-2xl font-medium col-12">Compose</span>
            <div class="text-base col-12">{{replyGuid ? 'Reply To' : 'New'}} Email
                <span *ngIf="isTemplate">(<span class="font-medium">{{ templateName }}</span> Template)</span>
            </div>
        </div>
        <div class="col-1 align-self-center">
            <p3solved-button id="btnBack" class="outlined" (buttonClick)="back()">
                <i class="pi pi-arrow-left" aria-hidden="true"></i>
            </p3solved-button>
        </div>
    </div>
    <form [formGroup]="emailForm">
        <div class="pt-3">
            <p3solved-form-text id="txtTo" placeholder="To:" [disabled]="disableTo" formControlName="emailTo">
            </p3solved-form-text>
        </div>
        <div class="pt-3">
            <p3solved-dropdown id="ddFrom" placeholder="From:" [items]="fromItems" optionLabel="ddLabel"
                formControlName="emailFrom" [displayFirst]="false" [showClear]="true">
                <div class="w-12 my-1 p-error text-xs"
                    *ngIf="emailForm.controls['emailFrom'].touched && emailForm.controls['emailFrom'].invalid">
                    <p>From address is required</p>
                </div>
            </p3solved-dropdown>
        </div>
        <div class="pt-3">
            <p3solved-form-text id="txtSub" placeholder="Subject:" formControlName="emailSubject">
                <div class="w-12 my-1 p-error text-xs"
                    *ngIf="emailForm.controls['emailSubject'].touched && emailForm.controls['emailSubject'].invalid">
                    <p>Subject is required</p>
                </div>
            </p3solved-form-text>
        </div>
        <div class="pt-5">
            Email body:
            <p-editor formControlName="emailBody" [style]="{'height': '20rem'}"></p-editor>            
        </div>
        <div class="pt-3 flex flex-wrap">
            <span class="w-12 text-xl font-bold">Attachments</span>
            <div class="w-12 border-1 min-h-5rem max-h-10rem">
                <div class="py-3 pl-3">
                    <p3solved-button id="btnAttach" label="Attach" (buttonClick)="emailAttach.click()"></p3solved-button>
                    <input #emailAttach type="file" hidden multiple (change)="onFileChange($event)">
                </div>
            <div class="w-12 flex flex-auto" *ngIf="myFiles.length > 0">
                <div *ngFor="let file of myFiles; index as i" class="p-2">
                    <span>{{file.name}}</span>
                    <span class="pl-1 cds-text-color cursor-pointer" (click)="deleteFile(file)">delete</span>
                </div>
            </div>
            </div>
        </div>
        <div class="flex flex-row-reverse pt-3" *ngIf="hasFeature('CSR_CUSTOMER_COMMUNICATIONS_COMPOSE')">
            <span class="px-2">
                <p3solved-button id="btnDiscard" label="Discard" class="secondary" (buttonClick)="discard()"></p3solved-button>
            </span>
            <span class="px-2">
                <p3solved-button id="btnDraft" label="Save as Draft" class="warn" (buttonClick)="sendEmail(true)" [disabled]="!emailForm.valid"></p3solved-button>
            </span>
            <span class="px-2">
                <p3solved-button id="btnSend" label="Send" (buttonClick)="sendEmail()" [disabled]="!emailForm.valid"></p3solved-button>
            </span>
        </div>
    </form>
</div>
