
<div class="relative">
    <div class="card-body">
        <h4 class="card-title pb-3">Debit Card Adjustments</h4>
        <div class="mb-2 grid">
            <div class="col-12 mb-2">
                <p3solved-dropdown id="ddRef" placeholder="Refund Card Payments"
                    [items]="debitCardPayments" optionLabel="description"
                    [formControl]="selectedPayment">            
                </p3solved-dropdown>
            </div>
            <div>
                <p3solved-button id="btnDbAdj" label="Confirm Adjustment" [disabled]="!selectedPayment.value" (buttonClick)="adjustDebitPayment()"></p3solved-button>
            </div>
        </div>
    </div>
    <div class="pds-div-spinner" *ngIf="submitting">
        <p-progressSpinner></p-progressSpinner>
    </div>
</div>

<p3solved-dialog [isVisible]="showDebitCardModal" header="Card Adjustment" headerClass="text-2xl" [showHeader]="true"
    [isModal]="true" [isClosable]="true" (HideClick)="showDebitCardModal=false;" [style]="{'width': '25vw'}">
    <div body>
        <div class="row">
            <div class="text-center">
                <span>Mr/Ms. {{ accountSummary.lastName }} we are processing the amount of {{ manualRefundAmount | currency}}.
                    You should see this credit reflected in your account the next day.</span>
            </div>
        </div>
    </div>
    <div footer class="justify-content-around">
        <p3solved-button id="btnDialog1Cancel" label="Close" class="p-button-outlined w-3"
            (buttonClick)="showDebitCardModal=false;"></p3solved-button>
        <p3solved-button id="btnDialog1Ok" label="Save" class="w-3"
            (buttonClick)="showDebitCardModal=false;postCardAdjustment();"></p3solved-button>
    </div>
</p3solved-dialog>

