<app-dashboard-header></app-dashboard-header>
<div class="grid">
    <div class="col-12 pt-6">
        <span class="text-2xl">Customer Verifications</span>
    </div>
    <div class="col-12 lg:col-4">
        <div class="cds-card">
            <div class="m-3 pt-3 pds-divider-bottom">
                <div class="flex">
                    <div class="p-1 flex-grow-1">
                        <p class="h4 ml-2 m-0">Customer Info</p>
                    </div>
                    <div class="p-1" *ngIf="hasFeature('CSR_DELETE_CUSTOMER')">
                        <p3solved-button id="btnDelCust" label="Delete" class="danger"
                            (buttonClick)="deleteCustomerInfo()"></p3solved-button>
                    </div>
                    <div class="p-1" *ngIf="hasFeature('CSR_CUSTOMER_VERIFICATION_EDIT_INFO') && !showEditCusomer">
                        <p3solved-button id="btnEditCust" label="Edit" (buttonClick)="editCustomerInfo()"></p3solved-button>
                    </div>
                    <div class="p-1" *ngIf="hasFeature('CSR_CUSTOMER_VERIFICATION_EDIT_INFO') && showEditCusomer">
                        <p3solved-button id="btnCancelCust" label="Cancel" class="secondary"
                            (buttonClick)="cancelEditCustomerInfo()"></p3solved-button>
                    </div>
                    <div class="p-1" *ngIf="hasFeature('CSR_CUSTOMER_VERIFICATION_EDIT_INFO') && showEditCusomer">
                        <p3solved-button id="btnSaveCust" label="Save" (buttonClick)="saveCustomerInfo()"></p3solved-button>
                    </div>
                </div>                
            </div>
            <mat-card-content class="p-2 mb-0">
                <app-customer-info *ngIf="!showEditCusomer"
                    (customerInfoUpdated)="customerInfoUpdated($event)"
                    (cardInfoUpdated)="cardInfoUpdated($event)"></app-customer-info>
                <app-customer-info-edit #editCustomer *ngIf="showEditCusomer"
                    [customerInfo]="customerInfo"
                    [cardInfo]="cardInfo"
                    (updated)="customerUpdated($event)"></app-customer-info-edit>
            </mat-card-content>
        </div>
    </div>
    <div class="col-12 lg:col-4">
        <app-customer-verifications (reloadNotes)="reloadNotes()"></app-customer-verifications>
    </div>
    <div class="col-12 lg:col-4">
        <app-customer-notes #customerNotes></app-customer-notes>
    </div>
</div>