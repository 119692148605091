export class CSRLoanData {

    loanData: CustomerInfoLoanModel = {} as CustomerInfoLoanModel;
    docEmails: CustomerLoanDocEmail[] = [];
    cardInfo: CustomerLoanCardInfo = {} as CustomerLoanCardInfo;
}

export class CustomerInfoLoanModel {
    accountNumber = "";
    accountNumberFunding = "";
    accountNumberPayments = "";
    additionalJobIncomeType = "-";
    additionalMonthlyIncome = "-";
    address1 = "155 lone";
    address2 = "";
    alimonyVerificationPhone = "-";
    bankName = "-";
    branchLocationID = "";
    branchLocationName = "Corporate";
    campaignID = "1029";
    cellPhone = "-";
    city = "dallas";
    companyName = "-";
    companyPhone = "-";
    country = "US";
    dOB = "08/29/1975";
    emailAddress = "lones@p3solveduat.com";
    employerIncomeSource = "-";
    employerName = "-";
    employerPhoneNumber = "-";
    firstName = "michelle";
    funding_BankAccountGuid = "";
    homePayLastCheck = "-";
    iPAddress = "-";
    lastName = "none";
    leadPatientID = "test0911";
    middleName = "-";
    occupation = "-";
    payFrequency = "Monthly";
    paymentMethodDescription = "Debit Card";
    paymentMethodID = "7";
    payments_BankAccountGuid = "";
    phone = "972-365-7146";
    plaidLinked = "";
    positionOrDepartment = "-";
    reference1FirstName = "-";
    reference1LastName = "-";
    reference2FirstName = "-";
    reference2LastName = "-";
    routingNumber = "-";
    routingNumberFunding = "-";
    routingNumberPayments = "-";
    sSN = "666-74-9999";
    sSNMasked = "***-**-9999";
    serviceDate = "09/28/2021";
    state = "TX";
    suffix = "-";
    targetID = "4680588";
    workPhone = "-";
    zipCode = "75287";

}

export class CustomerLoanDocEmail {
    messageGUID: string = '';
    msgGuidFolder: string = '';
    attachmentList: string = '';
}

export class CustomerLoanCardInfo {
    loanCardID: number = 0;
	targetLoanID: number = 0;
	campaignID: number = 0;
	targetID: number = 0;
	tokenCardRef: string | null = null;
	tokenCardExpiration: string | null = null;
	tokenCardNumber: string | null = null;
	tokenCardType: string | null = null;
	createDate: Date = new Date();
	createUserID: number | null = null;
	deleted: boolean = false;
	deleteDate: Date | null = null;
	deleteUserID: number | null = null;
	invalid: boolean = false;
	expired: boolean = false;
	cardNumberH: string | null = null;
	oneTime: boolean = false;
	pending: boolean = false;
	loanCardGUID: string = '';
	agreeDate: Date | null = null;
    cardDescription: string = '';
    cardExpiration: string = '';
}