export class TargetMessageModel {
    attachmentList = "";
    attachmentScanResultList = "";
    attachmentTitleList = "";
    deliveryStatus = "";
    emailStatus = "";
    fileDescription = "";
    fileGuid = "";
    fileName = "";
    from = "";
    fromEmail = "";
    fromEmailOrNumber = "";
    fromFullName = "";
    isDraft = "";
    marketingTextMe = "";
    messageGUID = "";
    messageReplyLevel = "";
    messageSubject = " "
    messageType = "";
    messageText = "";
    messageTypeID = "";
    msgGuidFolder = "";
    needToAttachTarget = "";
    objectBody = "";
    replied = "";
    replyToMessageID = "";
    rowCount = "";
    rowNumber = "";
    sBTCallbackGUID = "";
    sMSDownloadErrorCode = "";
    sendReceivedDate = "";
    senderFirstName = "";
    senderLastName = "";
    tTPL = "";
    textMeAccountInfo = "";
    toEmailOrNumber = "";
    wasRead = "";
    attachmentUrl = "";
}

export class TargetMessageDetails {
    createUserFirstName: string = '';
    createUserLastName: string = '';
    createUserMiddleName: string = '';
    docFileDescription: string = '';
    docFileGUID: string = '';
    docFileName: string = '';
    docNotes: string = '';
    emailAttachments: string = '';
    emailAttachmentsTitle: string = '';
    emailBody: string = '';
    emailFrom: string = '';
    emailReplyTo: string = '';
    emailSentTo: string = '';
    emailSubject: string = '';
    isDraft: string = '';
    messageGUID: string = '';
    messageType: string = '';
    messageTypeID: string = '';
    msgGuidFolder: string = '';
    needToAttachTarget: string = '';
    sBTCallbackGUID: string = '';
    sMSAttachments: string = '';
    sMSAttachmentsTitle: string = '';
    sMSDownloadErrorCode: string = '';
    sMSMessage: string = '';
    sMSPhoneNumber: string = '';
    sendReceivedDate: string = '';
    targetFirstName: string = '';
    targetGUID: string = '';
    targetLastName: string = '';
    wasRead: string = '';
}

export class CommQueueMessage {
    accountNumber: string = '';
    attachmentList: string = '';
    campaignGUID: string = '';
    deliveryStatus: string = '';
    emailStatus: string = '';
    firstName: string = '';
    lastName: string = '';
    messageBody: string = '';
    messageFrom: string = '';
    messageGUID: string = '';
    messageSubject: string = '';
    messageType: string = '';
    messageTypeID: string = '';
    msgGuidFolder: string = '';
    outgoing: string = '';
    replies: string = '';
    replyToMessageID: string = '';
    sendReceivedDate: string = '';
    sentTo: string = '';
    targetGuid: string = '';
    wasRead: string = '';
}