import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TableColumn } from '@p3solved/p3solved-ui';
import { OverlayPanel } from 'primeng/overlaypanel';
import { CustomGridComponent } from 'src/app/components/custom-grid/custom-grid.component';
import { ActivatedIds } from 'src/app/models/activated-ids';
import { AvailableUser } from 'src/app/models/csr/available-user';
import { ApiService } from 'src/app/services/api.service';
import { CustomerService } from 'src/app/services/customer.service';
import { GuidService } from 'src/app/services/guid.service';
import { MainService } from 'src/app/services/main.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-verification-queue-page',
  templateUrl: './verification-queue-page.component.html',
  styleUrls: ['./verification-queue-page.component.scss']
})
export class VerificationQueuePageComponent implements OnInit {
  
  ids: ActivatedIds = {} as ActivatedIds;
  usersLoaded: boolean = false;
  customerGuid: string = '';
  availableUsers: AvailableUser[] = [];
  selectedUser: AvailableUser | null = null;
  userColumns: TableColumn[] = [];

  @ViewChild('verifications') verifications: CustomGridComponent = {} as CustomGridComponent;
  @ViewChild('opUsers') opUsers: OverlayPanel = {} as OverlayPanel;

  constructor(
    private activatedRoute: ActivatedRoute,
    private main: MainService,
    private apiService: ApiService,
    private toastService: ToastService,
    private guidService: GuidService,
    private customerService: CustomerService
  ) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(async paramMap => {
      let cGuid = paramMap.get('customerGuid')?.toString() ?? '';
      if (this.guidService.isValidGuid(cGuid)) {
        this.customerGuid = cGuid;
        this.customerService.initCustomer(this.customerGuid);
        // await this.userService.subscribeAccess();
        // let data = this.activatedRoute.snapshot.data;
        // if (data && data['pageId']) {
        //   this.userService.checkPageAccess(data['pageId']);
        // }
        this.main.activatedIds$.subscribe(ids => {
          if (ids && ids.customerGuid) this.ids = ids;
          else {
            this.ids = {} as ActivatedIds;
            this.ids.customerGuid = cGuid;
          }
          this.getAvailableUsers();
        });
      }
    });
  }

  rowSelected(row: any) {
    var url = `/verification/${row.CustomerGUID}/${row.CampaignGUID}/${row.TargetGUID}`;
    window.open(url, "_blank");
  }

  getAvailableUsers() {
    let gSub = this.apiService.get(`csr/available-users/${this.customerGuid}`)
    .subscribe({
      next: (users: AvailableUser[]) => { 
        this.availableUsers = users;
        this.setupUserTable();
        this.usersLoaded = true;
      },
      error: (err: any) => { 
        this.toastService.addError("Unable to get available users. See log for details.");
        console.error(err);
      },
      complete: () => { gSub.unsubscribe(); }
    });
    
  }

  setupUserTable() {
    this.userColumns = [
      {field: 'userID', header: 'User ID', class: null, sortable: false, show: false, clickable: false},
      {field: 'firstname', header: 'First Name', class: null, sortable: false, show: true, clickable: false},
      {field: 'lastName', header: 'Last Name', class: null, sortable: false, show: true, clickable: false},
      {field: 'countAppsAssigned', header: 'Number in Queue', class: null, sortable: false, show: true, clickable: false},
      {field: 'withinHours', header: 'Working Hours?', class: null, sortable: false, show: true, clickable: false},
      {field: 'formathourmin', header: 'Last Activity', class: null, sortable: false, show: true, clickable: false},
      {field: 'userTypeName', header: 'User Type', class: null, sortable: false, show: false, clickable: false},
      {field: 'userWithinWorkHours', header: 'User Working', class: null, sortable: false, show: false, clickable: false},
      {field: 'withinBusinessHours', header: 'User Businsess Hours', class: null, sortable: false, show: false, clickable: false}
    ];
  }

  getUserDisplayColumns() {
    return this.userColumns.filter(f => f.show);
  }

  assignUsers(doAction: string) {
    let selections = this.verifications.getSelections();

    if (selections.length == 0) {
      this.toastService.addWarn("Please select one or more Applications.");
      return;
    }

    let selValues: string[] =[];
    selections.forEach(sel => {
      selValues.push(`${sel['CampaignID']}:${sel['TargetID']}:${sel['AppStatusID']}`);
    });

    let body = {
      customerGuid: this.customerGuid,
      doAction,
      loanAppList: selValues.join(','),
      userId: this.selectedUser?.userID ?? 0
    };

    let pSub = this.apiService.post(`csr/loan-apps/manually-assign`, body)
    .subscribe({
      next: () => { 
        this.verifications.getDataFromSource();
        this.toastService.addSuccess("Application(s) successfully assigned.");
        this.opUsers.hide();
      },
      error: (err: any) => { 
        this.toastService.addError("Unable to assign Applications. See log for details.");
        console.error(err);
      },
      complete: () => { pSub.unsubscribe(); }
    })


  }

}
