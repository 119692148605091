import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { QueueCollectionCounts } from 'src/app/models/csr/queue-collection-counts';
import { CustomerModel } from 'src/app/models/customer-model';
import { CustomerService } from 'src/app/services/customer.service';
import { MainService } from 'src/app/services/main.service';
import { PortfolioService } from 'src/app/services/portfolio.service';

@Component({
  selector: 'app-nav-header',
  templateUrl: './nav-header.component.html',
  styleUrls: ['./nav-header.component.scss']
})
export class NavHeaderComponent implements OnInit {
  @Output() expandMenu = new EventEmitter<boolean>();

  searchDD: boolean = false;
  profileDD: boolean = false;
  leadPgEnabled: boolean = false;

  verifyBadge: string = '';
  verifySeverity: string = 'info';
  leadBadge: string = '';
  leadSeverity: string = 'info';
  commBadge: string = '';
  commSeverity: string = 'info';
  collectionBadge: string = '';
  collectionSeverity: string = 'info';
  providerDD = false;
  showHideSidenav = true;
  isOpenSearch = false;
  isOpenUser = false;
  wasExpanded = false;
  customerGuid: string | null = "";
  providerName = '';
  customer: CustomerModel | null = null;
  searchForm = this.fb.group({
    firstName: [''],
    lastName: [''],
    account: [''],
    phone: [''],
    email: ['']
  });
  constructor(
    private main: MainService,
    private customerService: CustomerService,
    private portfolioService: PortfolioService,
    private router: Router,
    private fb: FormBuilder) { }

  ngOnInit(): void {
    let custSub = this.customerService.getQueueCounts().subscribe(
      {
        next: (counts: QueueCollectionCounts) => {
          this.verifyBadge = this.setQueueValue(counts.queueVerificationBadgeCount);
          this.leadBadge = this.setQueueValue(counts.queueLeadProviderBadgeCount);
          this.commBadge = this.setQueueValue(counts.queueCommunicationBadgeCount);
          this.collectionBadge = this.setQueueValue(counts.queueCollectionBadgeCount);
        },
        error: (err: any) => { console.error(`Error in navHeader init queue counts: ${JSON.stringify(err)}`) },
        complete: () => { custSub.unsubscribe(); }
      }
    )

    this.main.showCsrSidenav$.subscribe((show: boolean) => {
      this.showHideSidenav = show;
    });

    this.main.queueSimpleSearchQuery$.subscribe(query => {
      this.isOpenSearch = false;
    });
    this.customerService.customer$.subscribe(cust => {
      this.customer = cust;
      if (cust) {
        this.leadPgEnabled = cust.isCSLeadPageEnabled == "1";
        if (cust.customerGUID) {
          this.customerGuid = cust.customerGUID;
          this.providerName = cust.customerName;
        }
      }
    });

    this.portfolioService.portfolio$.subscribe(c => {
      this.providerDD = false;
    })
  }

  setQueueValue(badgeCount: string): string {
    let numCount = Number(badgeCount);
    if (isNaN(numCount) || numCount < 1) {
      return '';
    }
    return numCount.toString();
  }

  menuClicked() {
    this.expandMenu.emit(true);
  }

  collectionsQueueClicked() {
    this.router.navigate([`/collections-queue/${this.customerGuid}`])
  }

  leadsClicked() {
    this.router.navigate([`/leads/${this.customerGuid}`]);
  }

  verificationQueueClicked() {
    this.router.navigate([`/verification-queue/${this.customerGuid}`]);
  }

  communicationsQueueClicked() {
    this.router.navigate([`communications-queue/${this.customerGuid}`]);
  }

  search() {
    if (this.searchForm.dirty) {
      if (this.searchForm.value.firstName ||
        this.searchForm.value.lastName ||
        this.searchForm.value.account ||
        this.searchForm.value.phone ||
        this.searchForm.value.email) {
        this.main.queueSimpleSearched(this.searchForm.value);
        this.searchDD = false;
        // this.myDrop.close();
        this.router.navigate([`/home/${this.customerGuid}`]);
      }
    }
  }

  advancedSearch() {
    this.searchDD = false;
    // this.myDrop.close();
    this.router.navigate([`/advanced-search/${this.customerGuid}`]);
  }

  toggleMenus() {
    if (this.searchDD) this.searchDD = false;
    if (this.profileDD) this.profileDD = false;
  }

  toggleCustomer() {
    this.providerDD = !this.providerDD;
  }
}
