<app-dashboard-header></app-dashboard-header>
<p class="text-2xl pt-5">System Tools</p>
<div class="row">
  <div class="md:col-9 col-12">
    <app-custom-grid [DataSourceName]="loginTrackingDataSource" [GridTitle]="'Login Tracking'" [ActivatedIds]="ids"
      [AllowPaging]="false"></app-custom-grid>
    <app-custom-grid [DataSourceName]="fieldChangeTrackingDataSource" [GridTitle]="'Field Change Tracking'" [ActivatedIds]="ids"
      [AllowPaging]="false"></app-custom-grid>
    <app-custom-grid [DataSourceName]="achStatusHistoryDataSource" [GridTitle]="'ACH Status History'" [ActivatedIds]="ids"
      [AllowPaging]="false"></app-custom-grid>
    <app-custom-grid [DataSourceName]="payStatusHistoryDataSource" [GridTitle]="'Pay Status History'" [ActivatedIds]="ids"
      [AllowPaging]="false"></app-custom-grid>
    <app-custom-grid [DataSourceName]="cardTransactionsTrackingDataSource" [GridTitle]="'Debit/Credit Card Tracking'" [ActivatedIds]="ids"
      [AllowPaging]="false"></app-custom-grid>
    <app-custom-grid [DataSourceName]="recentActivityDataSource" [GridTitle]="'Customer Recent Activity'" [ActivatedIds]="ids"
      [AllowPaging]="false"></app-custom-grid>
    <app-custom-grid [DataSourceName]="statusChangeTrackingDataSource" [GridTitle]="'Customer Status Changes'" [ActivatedIds]="ids"
      [AllowPaging]="false"></app-custom-grid>
    <app-custom-grid [DataSourceName]="legalStatusChangeTrackingDataSource" [ActivatedIds]="ids"
      [GridTitle]="'Customer Legal Status Changes'" [AllowPaging]="false"></app-custom-grid>
    <app-custom-grid [DataSourceName]="pageTrackingDataSource" [GridTitle]="'Page Tracking'" [ActivatedIds]="ids"
      [AllowPaging]="false"></app-custom-grid>
    <app-custom-grid [DataSourceName]="sessionTrackingDataSource" [GridTitle]="'Session Tracking'" [ActivatedIds]="ids"
      [AllowPaging]="true"></app-custom-grid>
    <app-custom-grid [DataSourceName]="nlsApiLogDataSource" [GridTitle]="'NLS API Logs'" [ActivatedIds]="ids"
      [AllowPaging]="false"></app-custom-grid>
    <app-custom-grid [DataSourceName]="loanAppDataSource" [GridTitle]="'Contract App Save History'" [ActivatedIds]="ids"
      [AllowPaging]="false"></app-custom-grid>
  </div>
  <div class="md:col-3 col-12">
    <app-customer-notes></app-customer-notes>
  </div>
</div>