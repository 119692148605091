<div [formGroup]="group" class="mb-3 mt-3">
  <label *ngIf="label" [for]="id" class="form-label clabel">{{ label }}</label>
  <select
    class="form-select form-select"
    [ngClass]="
      group &&
      group.controls[controlName].touched &&
      !group.controls[controlName].valid
        ? 'is-invalid'
        : ''
    "
    [formControlName]="controlName"
    [id]="id"
  >
    <option *ngFor="let item of options" [value]="item.id">
      {{ item.desc }}
    </option>
  </select>
  <div
    *ngIf="
      group &&
      group.controls[controlName].touched &&
      group.controls[controlName]?.invalid
    "
    class="invalid-feedback"
  >
    This field is invalid
  </div>
</div>
