<div class="cds-card p-4">

    <div class="flex flex-wrap align-items-center pb-4">
        <span class="w-12 text-xl">Settlement</span>
        <span class="pt-2 cds-placeholder-color" *ngIf="showSubTtl">Select Settlement Type</span>
    </div>

    <div *ngIf="showTabs">
        <p-tabMenu [model]="tabMenu" [activeItem]="activeItem"></p-tabMenu>

        <div *ngIf="showOneTime" class="p-4">
            <div class="flex">
                <div class="w-2">
                    <div class="mt-4 text-2xl"
                        [ngClass]="oneTimeStep1Active ? 'pds-step-active-circle' : oneTimeStep1Completed ? 'pds-step-completed-circle' : 'pds-step-neutral-circle'">
                        1</div>
                    <div
                        [ngClass]="oneTimeStep1Active ? 'pds-step-active-text' : oneTimeStep1Completed ? 'pds-step-completed-text' : 'pds-step-neutral-text'">
                        Write-off Amounts</div>

                    <div class="mt-4 text-2xl"
                        [ngClass]="oneTimeStep2Active ? 'pds-step-active-circle' : 'pds-step-neutral-circle'">2</div>
                    <div [ngClass]="oneTimeStep2Active ? 'pds-step-active-text' : 'pds-step-neutral-text'">Make a
                        Payment</div>
                </div>
                <div class="w-10 pl-2">

                    <div *ngIf="oneTimeStep1Active" class="grid grid-nogutter">
                        <div class="col-12">
                            <p3solved-input-number id="oneTP" placeholder="Principal" [formControl]="oneTimePpal">
                                <div class="w-12 my-1 p-error text-xs"
                                    *ngIf="oneTimePpal.touched && oneTimePpal.invalid">
                                    <p>Principal must be between $0.01 and {{principalBalance | currency }}</p>
                                </div>
                            </p3solved-input-number>
                        </div>
                        <div class="col-12">
                            <p3solved-input-number id="oneTCAF" placeholder="Cash Advance Fees"
                                [formControl]="oneTimeCAF">
                                <div class="w-12 my-1 p-error text-xs" *ngIf="oneTimeCAF.touched && oneTimeCAF.invalid">
                                    <p>Cash Advance Fee must be between $0.01 and {{cafBalance | currency }}</p>
                                </div>
                            </p3solved-input-number>
                        </div>
                        <div class="col-12">
                            <p3solved-input-number id="oneTFFC" placeholder="Fixed Finance Charges"
                                [formControl]="oneTimeFFC">
                                <div class="w-12 my-1 p-error text-xs" *ngIf="oneTimeFFC.touched && oneTimeFFC.invalid">
                                    <p>Fixed Finance Charge must be between $0.01 and {{ffcBalance | currency }}</p>
                                </div>
                            </p3solved-input-number>
                        </div>
                        <div class="col-12">
                            <p3solved-button id="btn1TStep1Confirm" label="Confirm Write-Off"
                                [disabled]="!oneTimeStep1Valid()"
                                (buttonClick)="validateOneTimeWriteOff()"></p3solved-button>
                        </div>
                    </div>

                    <div *ngIf="oneTimeStep2Active" class="grid grid-nogutter">
                        <div class="col-12">
                            <p3solved-input-number id="oneTRB" placeholder="Remaining Balance"
                                [formControl]="oneTimeRemainBal">
                                <div class="w-12 my-1 p-error text-xs"
                                    *ngIf="oneTimeRemainBal.touched && oneTimeRemainBal.invalid">
                                    <p>Remaining Balance must be between $0.01 and {{totalAccountBalance | currency }}
                                    </p>
                                </div>
                            </p3solved-input-number>
                        </div>
                        <div class="col-12">
                            <p3solved-button id="btn1TStep2Confirm" label="Confirm Payment"
                                [disabled]="!oneTimeRemainBal.valid"
                                (buttonClick)="validateOneTimePayment()"></p3solved-button>
                        </div>
                    </div>

                </div>
            </div>
        </div>


        <div *ngIf="showNoWrite" class="p-4">

            <div class="flex">
                <div class="w-2">
                    <div class="mt-4 text-2xl"
                        [ngClass]="noWriteStep1Active ? 'pds-step-active-circle' : noWriteStep1Completed ? 'pds-step-completed-circle' : 'pds-step-neutral-circle'">
                        1</div>
                    <div
                        [ngClass]="noWriteStep1Active ? 'pds-step-active-text' : noWriteStep1Completed ? 'pds-step-completed-text' : 'pds-step-neutral-text'">
                        Freeze Fees</div>

                    <div class="mt-4 text-2xl"
                        [ngClass]="noWriteStep2Active ? 'pds-step-active-circle' : 'pds-step-neutral-circle'">2</div>
                    <div [ngClass]="noWriteStep2Active ? 'pds-step-active-text' : 'pds-step-neutral-text'">Settlement
                        Plan</div>
                </div>
                <div class="w-10 pl-2">

                    <div *ngIf="noWriteStep1Active" class="grid grid-nogutter">
                        <div class="col-12">
                            <p3solved-datepicker id="dtNoWrtEffDt" placeholder="Enter Effective Date"
                                [formControl]="noWriteEffDate" [minDate]="freezeMinDate" [maxDate]="freezeMaxDate"
                                panelStyleClass="dp-max" [showIcon]="true">
                                <div class="w-12 my-1 p-error text-xs"
                                    *ngIf="noWriteEffDate.touched && noWriteEffDate.invalid">
                                    <p>Effective Date is required</p>
                                </div>
                            </p3solved-datepicker>
                        </div>
                        <div class="col-12">
                            <p3solved-button id="btnNWStep1Confirm" label="Next" [disabled]="!noWriteEffDate.valid"
                                (buttonClick)="validateNoWriteFreeze()"></p3solved-button>
                        </div>
                    </div>

                    <div *ngIf="noWriteStep2Active" class="grid grid-nogutter">
                        <div class="col-12">
                            <p3solved-input-number id="noWrtSettAmt" placeholder="Settlement Amount"
                                [(ngModel)]="totalAccountBalance" [disabled]="true">
                            </p3solved-input-number>
                        </div>
                        <div class="col-12">
                            <p3solved-input-number id="noWrtNoPay" placeholder="Number of Payments"
                                [formControl]="noWriteNoPayments" mode="decimal"
                                (blurEvent)="noPaymentsChange($event)">
                                <div class="w-12 my-1 p-error text-xs"
                                    *ngIf="noWriteNoPayments.touched && noWriteNoPayments.invalid">
                                    <p>Number of Payments is required and must be greater than 0</p>
                                </div>
                            </p3solved-input-number>
                        </div>
                        <div class="col-12">
                            <p3solved-input-number id="noWrtPayAmt" placeholder="Payment Amount"
                                [(ngModel)]="paymentAmount" [disabled]="true">
                            </p3solved-input-number>
                        </div>
                        <div class="col-12">
                            <app-pay-calendar-form #noWritePayCalendar [payForm]="noWritePayForm"
                                (datesCalculated)="noWriteDatesCalculated($event)"></app-pay-calendar-form>
                        </div>
                        <div class="col-12" *ngIf="noWriteNextPayDates.length > 0">
                            <p3solved-dropdown id="ddDayOfWeek2" placeholder="First Payment Due Date" appendTo="body"
                                [items]="noWriteNextPayDates" optionLabel="desc" optionValue="id"
                                [formControl]="noWriteFirstDueDate"></p3solved-dropdown>

                        </div>
                        <div class="col-12">
                            <p3solved-dropdown id="ddNoWriteReason" placeholder="Reason" [items]="settlementReasons"
                                optionLabel="desc" optionValue="id" [formControl]="noWriteReason"></p3solved-dropdown>
                        </div>
                        <div class="col-12 flex">
                            <span class="pr-6 align-self-center">AutoPay</span>
                            <p-selectButton [options]="autoPayOps" optionLabel="label" optionValue="value" 
                            [formControl]="noWriteAutoPay" (onChange)="noWriteAutoPayChanged($event)"></p-selectButton>
                        </div>

                        <div class="col-12">
                            <p3solved-button id="btnNoWriteSettleSave" label="Save"
                                [disabled]="!noWriteBtnSaveValid()" (buttonClick)="validateNoWriteSettlement()"></p3solved-button>
                        </div>
                    </div>
                </div>

            </div>
        </div>


        <div *ngIf="showSettle" class="p-4">

            <div class="flex">
                <div class="w-2">
                    <div class="mt-4 text-2xl"
                        [ngClass]="settleStep1Active ? 'pds-step-active-circle' : settleStep1Completed ? 'pds-step-completed-circle' : 'pds-step-neutral-circle'">
                        1</div>
                    <div
                        [ngClass]="settleStep1Active ? 'pds-step-active-text' : settleStep1Completed ? 'pds-step-completed-text' : 'pds-step-neutral-text'">
                        Write-off Amounts</div>

                    <div class="mt-4 text-2xl"
                        [ngClass]="settleStep2Active ? 'pds-step-active-circle' : 'pds-step-neutral-circle'">2</div>
                    <div [ngClass]="settleStep2Active ? 'pds-step-active-text' : 'pds-step-neutral-text'">Settlement
                        Plan</div>
                </div>
                <div class="w-10 pl-2">

                    <div *ngIf="settleStep1Active" class="grid grid-nogutter">
                        <div class="col-12">
                            <p3solved-input-number id="oneTP" placeholder="Principal" [formControl]="settlePpal">
                                <div class="w-12 my-1 p-error text-xs" *ngIf="settlePpal.touched && settlePpal.invalid">
                                    <p>Principal must be between $0.01 and {{principalBalance | currency }}</p>
                                </div>
                            </p3solved-input-number>
                        </div>
                        <div class="col-12">
                            <p3solved-input-number id="oneTCAF" placeholder="Cash Advance Fees"
                                [formControl]="settleCAF">
                                <div class="w-12 my-1 p-error text-xs" *ngIf="settleCAF.touched && settleCAF.invalid">
                                    <p>Cash Advance Fee must be between $0.01 and {{cafBalance | currency }}</p>
                                </div>
                            </p3solved-input-number>
                        </div>
                        <div class="col-12">
                            <p3solved-input-number id="oneTFFC" placeholder="Fixed Finance Charges"
                                [formControl]="settleFFC">
                                <div class="w-12 my-1 p-error text-xs" *ngIf="settleFFC.touched && settleFFC.invalid">
                                    <p>Fixed Finance Charge must be between $0.01 and {{ffcBalance | currency }}</p>
                                </div>
                            </p3solved-input-number>
                        </div>
                        <div class="col-12">
                            <p3solved-button id="btnSettStep1Confirm" label="Confirm Write-Off"
                                [disabled]="!settleStep1Valid()"
                                (buttonClick)="validateSettleWriteOff()"></p3solved-button>
                        </div>
                    </div>


                    <div *ngIf="settleStep2Active" class="grid grid-nogutter">
                        <div class="col-12">
                            <p3solved-input-number id="settleSettAmt" placeholder="Settlement Amount"
                                [(ngModel)]="totalAccountBalance" [disabled]="true">
                            </p3solved-input-number>
                        </div>
                        <div class="col-12">
                            <p3solved-input-number id="settleNoPay" placeholder="Number of Payments"
                                [formControl]="settleNoPayments" mode="decimal"
                                (blurEvent)="noPaymentsChange($event)">
                                <div class="w-12 my-1 p-error text-xs"
                                    *ngIf="settleNoPayments.touched && settleNoPayments.invalid">
                                    <p>Number of Payments is required and must be greater than 0</p>
                                </div>
                            </p3solved-input-number>
                        </div>
                        <div class="col-12">
                            <p3solved-input-number id="settlePayAmt" placeholder="Payment Amount"
                                [(ngModel)]="paymentAmount" [disabled]="true">
                            </p3solved-input-number>
                        </div>
                        <div class="col-12">
                            <app-pay-calendar-form #settlePayCalendar [payForm]="settlePayForm"
                                (datesCalculated)="settleDatesCalculated($event)"></app-pay-calendar-form>
                        </div>
                        <div class="col-12" *ngIf="settleNextPayDates.length > 0">
                            <p3solved-dropdown id="ddsettleDayOfWeek2" placeholder="First Payment Due Date" appendTo="body"
                                [items]="settleNextPayDates" optionLabel="desc" optionValue="id"
                                [formControl]="settleFirstDueDate"></p3solved-dropdown>

                        </div>
                        <div class="col-12">
                            <p3solved-dropdown id="ddsettleReason" placeholder="Reason" [items]="settlementReasons"
                                optionLabel="desc" optionValue="id" [formControl]="settleReason"></p3solved-dropdown>
                        </div>
                        <div class="col-12 flex">
                            <span class="pr-6 align-self-center">AutoPay</span>
                            <p-selectButton [options]="autoPayOps" optionLabel="label" optionValue="value" 
                            [formControl]="settleAutoPay" (onChange)="settleAutoPayChanged($event)"></p-selectButton>
                        </div>

                        <div class="col-12">
                            <p3solved-button id="btnNoWriteSettleSave" label="Save"
                                [disabled]="!settleBtnSaveValid()" (buttonClick)="validateSettleSettlement()"></p3solved-button>
                        </div>
                    </div>

                </div>

            </div>
        </div>

    </div>

    <div *ngIf="showCompleted">

        <div *ngIf="showNoWriteOffComp">
            <p>Settlement created on {{settlementInfo.noWriteOff.settlementPlanDate | date: 'MM/dd/yyyy'}}</p>
            <table class="w-10 mb-3 ml-3">
                <tr>
                    <td>Settlement Amount</td>
                    <td class="text-right">{{settlementInfo.noWriteOff.settlementAmount | currency}}</td>
                </tr>
                <tr>
                    <td>Number of Payments</td>
                    <td class="text-right">{{settlementInfo.noWriteOff.noOfPayments?.toString()}}</td>
                </tr>
                <tr>
                    <td>Per Payment Amount</td>
                    <td class="text-right">{{settlementInfo.noWriteOff.paymentAmount | currency}}</td>
                </tr>
            </table>

            <p>Settlement Schedule:</p>
            <table class="w-10 ml-3">
                <tr *ngFor="let pDt of payDates; index as i" [ngClass]="i > 0 ? 'pds-divider-top' : ''">
                    <td>Settlement Payment {{i + 1}}</td>
                    <td>{{getDate(pDt) | date: 'MM/dd/yyyy'}}</td>
                    <td class="text-right">{{settlementInfo.noWriteOff.paymentAmount | currency}}</td>
                </tr>
            </table>
        </div>

        <div *ngIf="showWithWriteOffComp">
            <p>Settlement Created on {{settlementInfo.withWriteOff.settlementPlanDate | date: 'MM/dd/yyyy'}}</p>
            <table class="w-10 mb-3 ml-3">
                <tr>
                    <td>Original Balance</td>
                    <td class="text-right">{{settlementInfo.withWriteOff.accountBalanceBeforeWO | currency}}</td>
                </tr>
                <tr>
                    <td>Principal Write-off</td>
                    <td class="text-right">{{settlementInfo.withWriteOff.principalWriteOffAmount | currency}}</td>
                </tr>
                <tr>
                    <td>Cash Advance Fee Write-off</td>
                    <td class="text-right">{{settlementInfo.withWriteOff.cafWriteOffAmount| currency}}</td>
                </tr>
                <tr>
                    <td>Fixed Finance Charge Write-off</td>
                    <td class="text-right">{{settlementInfo.withWriteOff.ffcWriteOffAmount| currency}}</td>
                </tr>
            </table>
            <table class="w-10 mb-3 ml-3">
                <tr>
                    <td>Settlement Amoun</td>
                    <td class="text-right">{{settlementInfo.withWriteOff.settlementAmount | currency}}</td>
                </tr>
                <tr>
                    <td>Number of Payments</td>
                    <td class="text-right">{{settlementInfo.withWriteOff.noOfPayments}}</td>
                </tr>
                <tr>
                    <td>Per Payment Amount</td>
                    <td class="text-right">{{settlementInfo.withWriteOff.paymentAmount| currency}}</td>
                </tr>
            </table>

            <p>Settlement Schedule:</p>
            <table class="w-10 ml-3">
                <tr *ngFor="let pDt of payDates; index as i" [ngClass]="i > 0 ? 'pds-divider-top' : ''">
                    <td>Settlement Payment {{i + 1}}</td>
                    <td>{{getDate(pDt) | date: 'MM/dd/yyyy'}}</td>
                    <td class="text-right">{{settlementInfo.withWriteOff.paymentAmount | currency}}</td>
                </tr>
            </table>
        </div>

    </div>
</div>

<p-confirmDialog key="{{collSettConfKey}}" [style]="{width: '25vw'}" [baseZIndex]="10000">
</p-confirmDialog>