export class RuleModel {
    applyDateTime = "11/18/2021 4:54:42 PM";
    campaignID = "1029";
    executionTime = "3234";
    rulesetApplyLogID = "57810";
    rulesetGUID = "b2944017-bd2b-475a-8da5-a98f96442a9d";
    rulesetID = "3027";
    rulesetName = "Dan GT";
    rulesetVersion = "40";
    success = "False";
    targetID = "4681413";
}