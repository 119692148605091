import { Component, Input, OnInit } from '@angular/core';
import { PaymentMethodModel } from 'src/app/models/payment-methods';

@Component({
  selector: 'app-payment-method-info',
  templateUrl: './payment-method-info.component.html',
  styleUrls: ['./payment-method-info.component.scss']
})
export class PaymentMethodInfoComponent implements OnInit {
  @Input() p: PaymentMethodModel = new PaymentMethodModel;
  constructor() { }

  ngOnInit(): void {
  }

}
