import { Component, Inject } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Router, NavigationEnd, NavigationError } from '@angular/router';
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionStatus, EventMessage, EventType, AuthenticationResult, AuthError } from '@azure/msal-browser';
import { sideNavMenuItem } from '@p3solved/p3solved-ui';
import { combineLatest, Subject, Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AppStorageKeys } from './configs/app-storage-keys';
import { ActivatedIds } from './models/activated-ids';
import { AuthService } from './services/auth.service';
import { CustomerService } from './services/customer.service';
import { MainService } from './services/main.service';
import { PortfolioService } from './services/portfolio.service';
import { ToastService } from './services/toast.service';
import { UserService } from './services/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  menuItems: sideNavMenuItem[] = [];
  ids: ActivatedIds = {};
  isIframe = false;
  authenticated = false;
  showHideSidenav = true;
  expanded = false;
  wasExpanded = false;
  enteredSide = false;
  preAppEnabled: boolean = false;
  verifEnabled: boolean = false;
  
  supportJson: string | null = null;
  toastKey: string = 'appCompToast';
  toastPos: string = 'bottom-center';
  toastSub: Subscription = {} as Subscription;
  private readonly _destroying$ = new Subject<void>();

  constructor(@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private broadcastService: MsalBroadcastService,
    private router: Router,
    private customerService: CustomerService,
    private meta: Meta,
    private main: MainService,
    private portfolioService: PortfolioService,
    private authService: AuthService,
    private toastService: ToastService,
    private userService: UserService,
    private msalService: MsalService
    ) {
      this.meta.addTag({ name: 'app_version', content: environment.appVersion }, true);
      this.portfolioService.setPortfolios();
      this.userService.initCustSub();
  
      this.router.events.subscribe((event: any) => {
  
        if (event instanceof NavigationEnd) {
          let currUrl = event?.url?.toLocaleLowerCase();
        }
  
        if (event instanceof NavigationError) {
          console.error(event.error);
        }
      });
  }

  ngOnInit() {
    this.toastSub = this.toastService.toastPos$
      .subscribe((position: string) => {
        this.toastPos = position;
      });

      let combSub = combineLatest([this.main.activatedIds$, this.customerService.customer$])
      .subscribe({
        next: ([ids, cust]) => {
          this.ids = ids;
          if (cust) {
            // console.log('app comp customer: ', cust);
            this.preAppEnabled = cust.isCSPreAppEnabled == "1";
            this.verifEnabled = cust.isVerificationsEnabled == "1";
            this.supportJson = cust.customerSupportJson;
            this.setMenus();
          }
        },
        error: (err: any) => {
          console.error(err);
        },
        complete: () => { combSub.unsubscribe(); }
      });

    setTimeout(() => {
      this.main.showCsrSidenav$.subscribe((show: boolean) => {
        if (this.showHideSidenav !== show)
          this.showHideSidenav = show;
      });
    });

    this.subscribeLoggedIn();
    this.subscribeSilentLogin();
    this.subscribeLoginFailure();
    this.isIframe = window !== window.parent && !window.opener;
    // var id = this.activatedRoute.snapshot.queryParams['customerGuid'];
    this.broadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe((data: any) => {
        this.checkAndSetActiveAccount();
        this.setAuthenticated();
      });
  }

  setMenus() {
    var params = `/${this.ids.customerGuid}/${this.ids.campaignGuid}/${this.ids.targetGuid}`;
    let menuItms = [
      {
         url: `/dashboard${params}`,
        icon: 'pi pi-th-large text-3xl',
        text: 'Dashboard'
      },
      {
        url: `/verification${params}`,
        icon: 'pi pi-id-card text-3xl',
        text: 'Verification'
      },
      {
        text: 'Pre Application',
        url: `/pre-application${params}`,
        icon: 'bi bi-person-lines-fill',       
      },
      {
        text: 'Application',
        url: `/application${params}`,
        icon: 'bi bi-person-check-fill',
      },
      {
        text: 'Decision',
        url: `/decision${params}`,
        icon: 'pi pi-sliders-v text-3xl',
      },
      {
        text: 'Account Servicing',
        url: `/account-servicing${params}`,
        icon: 'bi bi-folder',
      },
      {
        text: 'Communications',
        url: `/communications${params}`,
        icon: 'bi bi-envelope-open',
      },
      {
        text: 'Agent Tools',
        url: `/agent-tools${params}`,
        icon: 'bi bi-headset',
      },
      {
        text: 'Systems',
        url: `/systems${params}`,
        icon: 'bi bi-wrench',
      },
    ];
    if (!this.preAppEnabled) {
      let idx = menuItms.findIndex(m => m.text == 'Pre Application');
      if (idx > -1) menuItms.splice(idx, 1);
    }
    // if (!this.verifEnabled) {
    //   let vIdx = menuItms.findIndex(m => m.text == 'Verification');
    //   if (vIdx > -1) menuItms.splice(vIdx, 1);
    // }
    this.menuItems = menuItms;
  }

  setAuthenticated() {
    this.authenticated = this.msalService.instance.getAllAccounts().length > 0;
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  subscribeLoggedIn() {
    this.broadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
      )
      .subscribe((result: EventMessage) => {
        var payload = <AuthenticationResult>result.payload;
        this.authService.authenticated(payload);
      });
  }

  subscribeSilentLogin() {
    this.broadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
      )
      .subscribe((result: EventMessage) => {
        // var payload = <AuthenticationResult>result.payload;
        // this.authService.authenticated(payload);
      });
  }

  subscribeLoginFailure() {
    this.broadcastService.msalSubject$
      .subscribe((result: EventMessage) => {
        if (result && result.error) {
          var error = <AuthError>result.error;
          // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
          if (error && error.errorMessage.indexOf('AADB2C90118') > -1) {
            this.authService.resetPassword();
          }

          if (error && error.errorMessage.indexOf('AADB2C90091') > -1) {
            this.setAuthenticated();
            if (!this.authenticated) {
              this.authService.login();
            }
          }
        }
      });
  }

  checkAndSetActiveAccount() {
    let activeAccount = this.msalService.instance.getActiveAccount();

    if (!activeAccount && this.msalService.instance.getAllAccounts().length > 0) {
      let accounts = this.msalService.instance.getAllAccounts();
      activeAccount = accounts[0];
    }
    if (activeAccount) {
      if (activeAccount.homeAccountId) {
        localStorage.setItem(AppStorageKeys.homeAccountId, activeAccount.homeAccountId);
      }
      this.authService.authenticatedAccount(activeAccount);
      this.msalService.instance.setActiveAccount(activeAccount);
    }
  }

  mouseEnter() {
    this.enteredSide = true;
    setTimeout(() => {
      if (this.enteredSide) {
        this.wasExpanded = this.expanded ? true : false;
        this.expanded = true;
      }
    }, 500);
  }

  mouseLeave() {
    this.enteredSide = false;
    setTimeout(() => {
      if (!this.enteredSide) {
        this.expanded = false;
      }

      // if (!this.wasExpanded)
      //   this.expanded = false;
    }, 500);
  }

  expandMenu(ev: boolean) {
    this.expanded = !this.expanded;
  }
}
