
export const phoneRegex = "^(\\+\\d{1,2}\\s)?\\(?\\d{3}\\)?[\\s.-]?\\d{3}[\\s.-]?\\d{4}$";
export const ssnRegex = '^(\\d{3}-?\\d{2}-?\\d{4}|XXX-XX-XXXX)$';
export const emailRegex = '^[_A-Za-z0-9-\+]+(\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9]+)*(\.[A-Za-z]{2,})$';

export const getDateStr = (date: Date) => {
  var dd = String(date.getDate()).padStart(2, '0');
  var mm = String(date.getMonth() + 1).padStart(2, '0');
  var yyyy = date.getFullYear();
  var result = `${mm}/${dd}/${yyyy}`;
  return result;
}

export const getFileIcon = (ext: string) => {
  ext = ext ? ext.replace(".", "").toLowerCase().trim() : 'search';
  ext = ext == 'jpeg' ? 'jpg' : ext;
  var exts = ['csv', 'doc', 'jpg', 'pdf', 'png', 'search', 'svg', 'txt', 'xml', 'zip'];
  var i = exts.findIndex(x => x == ext);
  return i == -1 ? 'search.png' : exts[i] + '.png';
}