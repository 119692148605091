import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ActivatedIds } from 'src/app/models/activated-ids';
import { TargetMessageModel } from 'src/app/models/communications/message-model';
import { SendCommMessageModel } from 'src/app/models/communications/send-message-model';
import { ApiService } from 'src/app/services/api.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { MainService } from 'src/app/services/main.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-customer-attachments',
  templateUrl: './customer-attachments.component.html',
  styleUrls: ['./customer-attachments.component.scss']
})
export class CustomerAttachmentsComponent implements OnInit {
  ids: ActivatedIds = new ActivatedIds;
  loading = false;
  docs: TargetMessageModel[] = [];

  showAttach: boolean = false;
  toastKey: string = 'payToast';
  toastPos: string = 'bottom-center';

  constructor(private main: MainService,
    private userService: UserService,
    private api: ApiService,
    private messageService: MessageService) { }

  ngOnInit(): void {
    this.main.activatedIds$.subscribe(id => {
      if (id && this.ids.targetGuid !== id.targetGuid) {
        this.ids = id;
        this.getDocuments();
      }
    });
  }

  hasFeature(feature: string, mode: string = 'write') {
    switch (mode) {
      case 'read':
        return this.userService.hasRead(feature);
        break;
      case 'feature':
        return this.userService.hasFeature(feature);
        break;
      default:
        return this.userService.hasWrite(feature);
        break;
    }
  }

  getDocuments() {
    this.loading = true;
    var model = this.getTargetModel('5');
    var body = JSON.stringify(model);
    this.api.post(`csr/target-communications`, body)
      .subscribe((res: any) => {
        this.loading = false;
        this.docs = res.messages;
      },
        (err: any) => {
          this.loading = false;
          console.error(err);
        }
      );
  }

  private getSendModel(msgTypeId: number, messageCategoryId: number): SendCommMessageModel {
    let model: SendCommMessageModel = {
      customerGuid: this.ids.campaignGuid || null,
      campaignGuid: this.ids.campaignGuid || null,
      targetGuid: this.ids.targetGuid || null,
      messageTypeId: msgTypeId,
      messageCategoryId: messageCategoryId,
      fileGuid: null,
      fileName: '',
      fileDesc: '',
      messageGuid: null,
      emailSubject: '',
      emailBody: '',
      emailFrom: null,
      emailTo: '',
      emailCc: '',
      emailBcc: '',
      emailMsgGuidFolder: '',
      attachmentList: '',
      isDraft: false,
      smsPhoneNumber: '',
      smsMessage: '',
    }

    return model;
  }

  private getTargetModel(val: string) {
    return {
      CustomerGuid: this.ids.customerGuid,
      CampaignGuid: this.ids.campaignGuid,
      TargetGuid: this.ids.targetGuid,
      Value: val
    }
  }

  uploadError(msg: string) {
    this.displayMessage('error', msg, 'Unable to upload');
  }

  fileUploaded(fileData: any) {
    if (fileData && fileData.fileGuid) {
      this.main.showLoading();
      var model = this.getSendModel(5, 1);
      model.fileName = fileData.filename;
      model.fileGuid = fileData.fileGuid;
      model.fileDesc = fileData.filename;
      var body = JSON.stringify(model);
      this.api.postStringResponse(`csr/communication-send`, body)
        .subscribe(() => {
          this.displayMessage('success', 'File uploaded successfully');
          this.getDocuments();
        },
          (err: any) => {
            this.main.dismissLoading();
            var msg = err.error.text || "Error occured";
            this.displayMessage('error', msg, 'Unable to upload');
          }
        );
    }
    else {
      this.displayMessage('error', 'Unknown error.', 'Unable to upload');
    }
  }

  displayMessage(severity: string, detail: string, summary?: string) {
    if (summary?.length) {
      this.messageService.add({
        key: this.toastKey,
        severity: severity,
        summary: summary,
        detail: detail
      });
    }
    else {
      this.messageService.add({
        key: this.toastKey,
        severity: severity,
        detail: detail
      });
    }
  }
}
