import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { UpcomingPaymentModel } from 'src/app/models/csr/upcoming-payment-model';


@Component({
  selector: 'app-upcoming-payments',
  templateUrl: './upcoming-payments.component.html',
  styleUrls: ['./upcoming-payments.component.scss']
})
export class UpcomingPaymentsComponent implements OnInit {
  @Output() btnClick = new EventEmitter();
  @Input() payments: UpcomingPaymentModel[] = [];
  rows: number = 15;  
  first: number = 0;

  constructor(
  ) {}

  ngOnInit(): void {
  }
}
