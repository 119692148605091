import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SettingService } from './setting.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private accessToken: any;
  private url = environment.apiEndpoint;

  constructor(private http: HttpClient, private settings: SettingService) { 
    this.url = this.settings.getValue('API_Endpoint');
  }

  get(path: string) {
      var requestUrl = `${this.url}${path}`;
      // return this.http.get<any>(requestUrl, this.getHttpOptions());
      return this.http.get<any>(requestUrl);
  }

  post(path: string, body: any) {
      let options = this.getHttpOptions();
      var requestUrl = `${this.url}${path}`;
      return this.http.post<any>(requestUrl, body, options);
  }

  postStringBody(path: string, body: string) {
    let options = this.getHttpOptionsStringBody();
    var requestUrl = `${this.url}${path}`;
    return this.http.post<any>(requestUrl, body, options);
  }

  postStringResponse(path: string, body: any){
      let options = this.getHttpOptionsStringResponse();
      var requestUrl = `${this.url}${path}`
      return this.http.post(requestUrl, body, options);
  }

  postFile(path: string, body: any){
      let options = this.getHttpOptionsFile();
      var requestUrl = `${this.url}${path}`
      return this.http.post(requestUrl, body, options);
  }

  put(path: string, body: any){
      var requestUrl = `${this.url}${path}`;
      return this.http.put<any>(requestUrl, body, this.getHttpOptions());
  }

  getUpperCaseModel(obj: any){
      var newObj: {[k: string]: any} = {};
      for (const key in obj) {
          var newKey = key.charAt(0).toUpperCase() + key.slice(1);;
          newObj[newKey] = obj[key];
      }
      return newObj;
  }

  private getHttpOptions(){
      return {
          headers: new HttpHeaders({
            "Content-Type": 'application/json',
            Authorization: "Bearer " + this.getAccessToken(),
          })
        };
  }

  private getHttpOptionsFile(){
      return {
          headers: new HttpHeaders({
            // "Content-Type": 'multipart/form-data',
            Authorization: "Bearer " + this.getAccessToken(),
          })
        };
  }

  private getHttpOptionsStringBody() {
    return {
      headers: new HttpHeaders({
        "Content-Type": 'application/text',
        Authorization: "Bearer " + this.getAccessToken()
      })
    }
  }

  private getHttpOptionsStringResponse(){
    return {
        headers: new HttpHeaders({
          "Content-Type": 'application/json',
          Authorization: "Bearer " + this.getAccessToken(),
        }),
        responseType: 'text' as const
      }; 
  }

  private getAccessToken(): Observable<any> {
      if (
          sessionStorage.getItem("msal.idtoken") !== undefined &&
          sessionStorage.getItem("msal.idtoken") != null
      ) {
          this.accessToken = sessionStorage.getItem("msal.idtoken");
      }
      return this.accessToken;
  }
}
