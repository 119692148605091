import { Overlay, OverlayConfig, OverlayRef, PositionStrategy } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { ErrorModalComponent } from '../components/error-modal/error-modal.component';
import { FullScreenLoadingComponent } from '../components/full-screen-loading/full-screen-loading.component';
import { YesNoPopupComponent } from '../components/shared/yes-no-popup/yes-no-popup.component';
import { SuccessModalComponent } from '../components/success-modal/success-modal.component';
import { ActivatedIds } from '../models/activated-ids';
import { QueueSearchModel } from '../models/queue-search-model';
import {MatSnackBar} from '@angular/material/snack-bar';
import { ConfirmDeleteModalComponent } from '../components/shared/confirm-delete-modal/confirm-delete-modal.component';
import { AddressCorrectionComponent } from '../components/customer/address-correction/address-correction.component';
import { ConfirmChangesComponent } from '../components/customer/confirm-changes/confirm-changes.component';
import { EditEmailModalComponent } from '../components/customer/edit-email-modal/edit-email-modal.component';
import { ConfirmEmailCodeModalComponent } from '../components/customer/confirm-email-code-modal/confirm-email-code-modal.component';
import { AddPaymentMethodModalComponent } from '../components/customer/add-payment-method-modal/add-payment-method-modal.component';
import { UploadAttachmentComponent } from '../components/communications/upload-attachment/upload-attachment.component';

@Injectable({
  providedIn: 'root'
})
export class MainService {
  private _queueSimpleSearchQuery = new BehaviorSubject<QueueSearchModel | null>(null);
  private _showCsrSidenav = new BehaviorSubject<boolean>(false);
  private _activatedIds = new BehaviorSubject<ActivatedIds>({});
  private fullscreenOverlayFef: OverlayRef | undefined;
  queueSimpleSearchQuery$ = this._queueSimpleSearchQuery.asObservable();
  showCsrSidenav$ = this._showCsrSidenav.asObservable();
  activatedIds$ = this._activatedIds.asObservable();

  constructor(private overlay: Overlay, 
    private activatedRoute: ActivatedRoute,
    private _snackBar: MatSnackBar,
    private dialog: MatDialog) {

    }

    getParams(){
      this.activatedRoute.paramMap.subscribe(paramMap => {
        this.updateActivatedIdsMany([
          { propname: 'campaignGuid', propValue: paramMap.get('campaignGuid')?.toString() },
          { propname: 'targetGuid', propValue: paramMap.get('targetGuid')?.toString() },
          { propname: 'customerGuid', propValue: paramMap.get('customerGuid')?.toString() },
        ]);
      });
    }

  updateActivatedIds(propname: keyof ActivatedIds, propValue: any){
    var obj = this._activatedIds.getValue();
    obj[propname] = propValue;
    this._activatedIds.next(obj);
  }

  updateActivatedIdsMany(arr: {propname: keyof ActivatedIds, propValue: any}[]){
    var obj = this._activatedIds.getValue();
    arr.forEach(item => {
      obj[item.propname] = item.propValue;
    });
    this._activatedIds.next(obj);
  }

  showHideCsrSidenav(val: boolean) {
    this._showCsrSidenav.next(val);
  }

  queueSimpleSearched(query: any) {
    this._queueSimpleSearchQuery.next(query);
  }

  showSuccessModal(header: string, message: string){
    const dialogRef = this.dialog.open(SuccessModalComponent, {
      width: '350px',
      data: {
        header,
        message
      },
    });
  }

  showYesNoPopUp(title: string | null, msg: string, yesText: string = "OK", noText: string = "Cancel", width = '350px', subTitle = ""){
    return this.dialog.open(YesNoPopupComponent, {
      width: width || '350px',
      data: {
        title,
        msg,
        yesText,
        noText,
        subTitle
      },
    });
  }

  showAttachmentUpload(){
    return this.dialog.open(UploadAttachmentComponent, {
      width: '500px',
    });
  }

  showAddressCorrection(entered: string | null, correction: string){
    return this.dialog.open(AddressCorrectionComponent, {
      width: '500px',
      data: {
        addressEntered: entered,
        correction
      },
    });
  }

  showConfirmChanges(changes: any){
    return this.dialog.open(ConfirmChangesComponent, {
      width: '500px',
      data: changes,
    });
  }

  showEmailChangeModal(){
    return this.dialog.open(EditEmailModalComponent, {
      width: '500px'
    });
  }

  showConfirmEmailChangeModal(){
    return this.dialog.open(ConfirmEmailCodeModalComponent, {
      width: '500px'
    });
  }

  showAddPaymentMethod(){
    return this.dialog.open(AddPaymentMethodModalComponent, {
      width: '500px'
    });
  }

  confirmDelete(title: string | null, msg: string, width = '450px'){
    return this.dialog.open(ConfirmDeleteModalComponent, {
      width: width || '450px',
      data: {
        title,
        msg,
        yesText: "",
        noText: ""
      },
    });
  }

  showModal<T>(component: ComponentType<T>, data: any, width: string){
    return this.dialog.open(component, {
      width: width,
      data: data,
    });
  }

  showErrorModal(header: string, message: string){
    return this.dialog.open(ErrorModalComponent, {
      width: '350px',
      data: {
        header,
        message
      },
    });
  }

  showLoading(){
    var config = this.getOverlayLoadingConfig();
    this.fullscreenOverlayFef = this.overlay.create(config);
    this.fullscreenOverlayFef.attach(new ComponentPortal(FullScreenLoadingComponent));
  }

  dismissLoading(){
    this.fullscreenOverlayFef?.dispose();
  }

  showSnackBar(message: string) {
    this._snackBar.open(message, "Close", {
      duration: 4000,
    });
  }

  isSuccessResponse(res: string) {
    if (res && res.toLowerCase().indexOf('error') !== -1){
      return false;
    }else{     
      return true;
    }
  }

  showResponse(res: string){
    if (res && res.toLowerCase().indexOf('error') !== -1){
      this.showErrorModal("Oops...", res);
      return false;
    }else{
      this.showSuccessModal("Success", res);
      return true;
    }
  }

  private getOverlayLoadingConfig(){
    let config = new OverlayConfig();
    config.width = '100vw';
    config.height = '100vh';
    config.panelClass = 'loading-card';
    config.hasBackdrop = true;
    config.scrollStrategy = this.overlay.scrollStrategies.block();
    config.positionStrategy= this.positionGloballyCenter()
    return config;
  }

  private positionGloballyCenter(): PositionStrategy {
    return this.overlay.position().global().centerHorizontally().centerVertically();
  }
}
