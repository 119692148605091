import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PortfolioItem } from '../models/portfolio-item';
import { PortfoliosModel } from '../models/portfolios-model';
import { ApiService } from './api.service';
import { CustomerService } from './customer.service';

@Injectable({
  providedIn: 'root'
})
export class PortfolioService {
  private customerSettings = new BehaviorSubject<any | null>(null);
  private portfolios = new BehaviorSubject<PortfoliosModel | null>(null);
  private selectedPortfolioSource = new BehaviorSubject<PortfolioItem | null | undefined>(null);
  portfolio$ = this.selectedPortfolioSource.asObservable();
  customerSettings$ = this.customerSettings.asObservable();
  portfolios$ = this.portfolios.asObservable();
  cachedPortfolios: PortfoliosModel | null = null;


  constructor(private apiService: ApiService, 
    private customerService: CustomerService) {
      this.customerService.customerGuid$.subscribe(c => {
        if (c){
          this.porfolioChanged(c);
        }
      })
   }

   porfolioChanged(customerGuid: string){
    var p = this.portfolios.getValue()?.portfolios.find(x => x.customerGuid == customerGuid);
    this.selectedPortfolioSource.next(p);
   }

  setPortfolios() {
    var result = this.portfolios.getValue();
    if (result && result.portfolios && result.portfolios.length) {
      return;
    }

      result = new PortfoliosModel();
      result.loading = true;
      this.portfolios.next(result);
      this.apiService.get(`user/portfolios`)
        .subscribe((res: any) => {
          var list = res || [];
          if (list.length) {
            list.forEach((item: PortfolioItem) => {
              if (item.adminIcon.indexOf('bytearchive') !== -1)
                item.adminIcon = 'bytepay.ico';

              item.dashboardUrl = `/dashboard/${item.customerGuid}`;
              item.adminIcon = (item.adminIcon && item.adminIcon.indexOf('blank') === -1)
                ? `/assets/images/${item.adminIcon}`
                : '';
            });
          }
          result = new PortfoliosModel();
          result.loading = false;
          result.portfolios = list;
          this.cachedPortfolios = list;
          result.error = list.length ? '' : 'No portfolio found for authenticated user';
          this.portfolios.next(result);
        },
          (err: any) => {
            result = new PortfoliosModel();
            result.loading = false;
            result.error = 'Error occured while getting portfolios';
            this.portfolios.next(result);
          }
        );
  }
}
