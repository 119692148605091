import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedIds } from 'src/app/models/activated-ids';
import { LookupModel } from 'src/app/models/lookup-model';
import { PaymentMethodModel } from 'src/app/models/payment-methods';
import { TargetAccountSummary } from 'src/app/models/target-account-summary';
import { ApiService } from 'src/app/services/api.service';
import { MainService } from 'src/app/services/main.service';
import { getDateStr } from 'src/app/services/util.service';
import { isThisTypeNode } from 'typescript';

@Component({
  selector: 'app-schedule-payment-form',
  templateUrl: './schedule-payment-form.component.html',
  styleUrls: ['./schedule-payment-form.component.scss']
})
export class SchedulePaymentFormComponent implements OnInit {
  @Input() paymentAccounts: PaymentMethodModel[] = [];
  @Input() processors: LookupModel[] = [];
  @Input()
  get accSummary(): TargetAccountSummary { return this._accSummary; }
  set accSummary(accSummary: TargetAccountSummary) {
    this._accSummary = accSummary;
    if (accSummary) {
      this.initForm();
    }
  }
  private _accSummary: TargetAccountSummary = new TargetAccountSummary();
  @Output() onBack = new EventEmitter<boolean>();
  scheduleForm: FormGroup = new FormGroup({});
  paymentOptions: LookupModel[] = [
    { id: '1', desc: 'Change Upcoming Payment Date' },
    { id: '2', desc: 'Minimum Payment' },
    { id: '3', desc: 'Pay in Full' },
    { id: '4', desc: 'Other Amount' } 
  ];
  hideAccount = false;
  dateFocused = false;
  schedulePaymentCalendarDateConfirmationOverride = '0';
  prompts: any[] = [];
  ids: ActivatedIds = {};
  successTitle = '';
  successMessage = '';
  constructor(private fb: FormBuilder,
    private api: ApiService,
    private main: MainService) { }

  ngOnInit(): void {
    this.main.activatedIds$.subscribe((ids) => {
      if (ids && ids.targetGuid) {
        if (!this.ids || this.ids.targetGuid !== ids.targetGuid) {
          this.ids = ids;
        }
      }
    });
  }

  back(updated = false) {
    this.onBack.emit(updated);
  }

  onSubmit() {
    if (!this.scheduleForm.valid) {
      this.scheduleForm.markAllAsTouched();
      return;
    }

    this.prompts = [];
    var paymentDate = getDateStr(this.scheduleForm.value.date);
    var amt = this.scheduleForm.value.paymentAmount;
    var contentSet = false;
    var bank = this.paymentAccounts.find(x => x.isPrimary == 'True');
    var isAch = bank?.loanPaymentMethodType === 'ach';
    this.successTitle = 'Success';
    this.successMessage = 'Your payment was submitted successfully';
    if (this.scheduleForm.value.paymentOption === '4') {
      var amtFloat = parseFloat(amt);
      var standardFloat = parseFloat(this.accSummary.standardPaymentAmount);
      if (amtFloat < standardFloat) {
        contentSet = true;
        var desc = `You are requesting to make a partial payment of $${amt} on ${paymentDate} to be applied towards your next scheduled payment. Please Note: The remainder of your payment will become past due if not paid by the due date. Did you intend to pay only a partial payment?`;
        this.pushToPrompt(this.accSummary.partialPaymentMyModalTitle, desc);
      }
    }

    if (!contentSet) {
      if (this.scheduleForm.value.paymentOption === '1') {
        this.pushToPrompt(this.accSummary.pushPaymentMyModalTitle1, this.accSummary.pushPaymentMyModalDescription1);
        this.pushToPrompt(this.accSummary.pushPaymentMyModalTitle2, this.accSummary.pushPaymentMyModalDescription2);
      } else if (this.scheduleForm.value.paymentOption === '2') {
        if (isAch) {
          this.pushToPrompt(this.accSummary.standardPaymentMyModalTitle1, this.accSummary.standardPaymentMyModalDescription1);
          this.pushToPrompt(this.accSummary.standardPaymentMyModalTitle2, this.accSummary.standardPaymentMyModalDescription2);
          this.successTitle = this.accSummary.standardPaymentMyModalTitle3;
          this.successMessage = this.accSummary.standardPaymentMyModalDescription3;
        } else {
          this.pushToPrompt(this.accSummary.standardPaymentMyModalTitle1, this.accSummary.standardPaymentCardMyModalDescription1);
          this.pushToPrompt(this.accSummary.standardPaymentMyModalTitle2, this.accSummary.standardPaymentCardMyModalDescription2);
          this.successTitle = this.accSummary.standardPaymentMyModalTitle3;
          this.successMessage = this.accSummary.standardPaymentCardMyModalDescription3;
        }
      } else if (this.scheduleForm.value.paymentOption === '3') {
        if (isAch) {
          this.pushToPrompt(this.accSummary.otherAmountPaymentMyModalTitle1, this.accSummary.otherAmountPaymentMyModalDescription1);
          this.pushToPrompt(this.accSummary.standardPaymentMyModalTitle2, this.accSummary.otherAmountPaymentMyModalDescription2);
          this.successTitle = this.accSummary.standardPaymentMyModalTitle3;
          this.successMessage = this.accSummary.otherAmountPaymentMyModalDescription3;
        } else {
          this.pushToPrompt(this.accSummary.otherAmountPaymentCardMyModalTitle1, this.accSummary.otherAmountPaymentCardMyModalDescription1);
          this.pushToPrompt(this.accSummary.standardPaymentCardMyModalTitle2, this.accSummary.otherAmountPaymentCardMyModalDescription2);
          this.successTitle = this.accSummary.standardPaymentCardMyModalTitle3;
          this.successMessage = this.accSummary.otherAmountPaymentCardMyModalDescription3;
        }
      }
    }

    this.prompts.forEach(item => {
      item.message = this.cleanMessage(item.message, bank, amt, paymentDate);
    });
    this.successTitle = this.cleanMessage(this.successTitle, bank, amt, paymentDate);
    this.successMessage = this.cleanMessage(this.successMessage, bank, amt, paymentDate);

    if (this.prompts.length) {
      this.showPrompt();
    }
  }

  cleanMessage(message: string, bank: PaymentMethodModel | undefined, amt: string, date: string){
    return message.replace('<span class=\"page_card_type_selector\"></span>', bank?.loanPaymentMethodDescription || '')
        .replace('<span class=\"page_card_last4_selector\"></span>', bank?.paylianceCardLastFour || '')
        .replace('<span class=\"page_amount_selector\"></span>', amt)
        .replace('<span class=\"page_date_text_selector\"></span>', date)
        .replace('<span class=\"page_date_selector\"></span>', date)
        .replace('<span class=\"page_bankaccount_selector\"></span>', bank?.loanPaymentMethodDescription || '')
        .replace(/<p>/g, '')
        .replace('</p>', '');
  }

  showPrompt(index = 0) {
    var prompt = this.prompts[index];
    this.main.showYesNoPopUp(prompt.title, prompt.message, 'Confirm', 'Back', '520px').afterClosed().subscribe(result => {
      if (result === 'yes') {
        if (this.prompts.length > index + 1) {
          setTimeout(() => {
            this.showPrompt(index + 1);
          }, 100);
        } else {
          this.postPayment()
        }
      } else {
        if (index > 0) {
          this.showPrompt(index - 1);
        }
      }
    })
  }

  postPayment() {
    var paymentDate = getDateStr(this.scheduleForm.value.date);
    var bank = this.paymentAccounts.find(x => x.isPrimary == 'True');
    var amt = this.scheduleForm.value.paymentAmount;
    var model = {
      PaylianceCardCvv: '',
      CsrAction: false,
      PageUrl: window.location.href,
      LoanPaymentMethodGuid: bank?.loanPaymentMethodGUID,
      CustomerGuid: this.ids.customerGuid,
      TargetGuid: this.ids.targetGuid,
      CampaignGuid: this.ids.campaignGuid,
      PaymentAmount: amt,
      PaymentDate: paymentDate,
      OtherOption: '',
      OverrideScheduledPayment: this.schedulePaymentCalendarDateConfirmationOverride === '1',
      PaylianceCardExp: '',
      PaylianceCardNumber: '',
      PaylianceCardType: '',
      BankAccountNumber: '',
      BankRoutingNumber: ''
    }

    this.main.showLoading();
    var body = JSON.stringify(model);
    this.api.post(`csr/target-make-payment`, body)
      .subscribe((res: any) => {
        this.main.dismissLoading();
        if (res && res.errorCode == '0'){
          this.main.showSuccessModal(this.successTitle, this.successMessage);
          this.back(true);
        }else{
          this.main.showSnackBar(res.errorMessage);
        }
      },
        (err: any) => {
          this.main.dismissLoading();
          this.main.showSnackBar("Something went wrong. Please try again.");
        }
      );
  }

  pushToPrompt(title: any, desc: any) {
    this.prompts.push({
      title: title,
      message: desc,
      index: this.prompts.length
    });
  }

  onPaymentChange(ev: any, typ: string) {
    var value = ev.target.value;
    if (value) {
      if (typ === 'account') {
        this.paymentAccounts.forEach(p => {
          p.isPrimary = 'False';
          if (p.loanPaymentMethodDescription == value) {
            p.isPrimary = 'True';
            p.isSecondary = 'False';
            this.scheduleForm.patchValue({
              account: p.loanPaymentMethodGUID
            });
          }
        })
      }

      if (typ === 'processor') {
        this.processors.forEach(p => {
          this.scheduleForm.patchValue({
            processor: p.id
          });
        })
      }

    }

  }

  dateBlur() {
    this.dateFocused = false;
    var val = this.scheduleForm.controls['date'].value;
    if (val)
      this.showDateConfirm(val);
  }

  private initForm() {
    this.scheduleForm = this.fb.group({
      paymentOption: ['2', Validators.required],
      paymentAmount: [this.accSummary.minimumPaymentAmount, Validators.required],
      date: ['', Validators.required],
      account: [''],
      processor: ['']
    });

    this.scheduleForm.controls['paymentOption'].valueChanges.subscribe(val => {
      this.hideAccount = val === '1';
      var amt = '';
      if (val === '1' || val === '2') {
        amt = this.accSummary.minimumPaymentAmount;
      }
      if (val === '3') {
        amt = this.accSummary.payInFullAmount;
      }

      this.scheduleForm.patchValue({
        paymentAmount: amt
      });
    });

    this.scheduleForm.controls['date'].valueChanges.subscribe(val => {
      if (!this.dateFocused) {
        this.showDateConfirm(val);
      }
    });
  }

  private showDateConfirm(val: string | number | Date) {
    var dt = new Date(val);
    if (this.accSummary.schedulePaymentCalendarDateConfirmationMinDate) {
      var arr = this.accSummary.schedulePaymentCalendarDateConfirmationMinDate.split(' ');
      var arr2 = arr[0].split('/');
      var minDate = new Date(`${arr2[2]}/${arr2[0]}/${arr2[1]}`);
      if (dt.getTime() > minDate.getTime()) {
        this.main.showYesNoPopUp('Make a payment', this.accSummary.schedulePaymentCalendarDateConfirmationMessage, 'Yes', 'No', '500px')
          .afterClosed().subscribe(result => {
            this.schedulePaymentCalendarDateConfirmationOverride = result === 'yes' ? '1' : '0';
          })
      }
    }
  }

}
