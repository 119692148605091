import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-cc-input',
  templateUrl: './cc-input.component.html',
  styleUrls: ['./cc-input.component.scss']
})
export class CcInputComponent implements OnInit {
  @Input() controlName: string = '';
  @Input() isRequired: boolean = false;
  @Input() maxLen: string = '100';
  @Input() id?: string;
  @Input() label: string = "";
  @Input() type: string = "text";
  @Input() placeholder: string = "";
  @Input() readonly: string = "";
  @Input() val: string = "";
  @Input() group: FormGroup = new FormGroup({});

  iconRequired = faAsterisk;
  constructor() {
    var d = new Date();
    this.id = d.getTime().toString();
  }

  ngOnInit(): void {
  }

}
