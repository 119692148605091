import { Component, OnInit } from '@angular/core';
import { PaymentMethodModel } from 'src/app/models/payment-methods';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-bank-adjustment-option-one',
  templateUrl: './bank-adjustment-option-one.component.html',
  styleUrls: ['./bank-adjustment-option-one.component.scss']
})
export class BankAdjustmentOptionOneComponent implements OnInit {


  manualBankAdjBlur: boolean = false;
  disableBankPayment: boolean = true;
  showBankAcctModal: boolean = false;

  manualBankAdjustment: number = 0.00;


  selectedMethod: PaymentMethodModel | null = null;

  constructor(
    private toastService: ToastService
  ) { }

  ngOnInit(): void {
    
  }

  validateBankAmount() {
    if (!this.manualBankAdjBlur) {
      return;
    }
    setTimeout(() => {
      if (!this.manualBankAdjustment || this.manualBankAdjustment == 0.00 || !this.selectedMethod) {
        this.disableBankPayment = true;
      } else {
        this.disableBankPayment = false;
      }
    }, 100);

    return true;
  }


  confirmBankAdjustment() {
    if (this.disableBankPayment) {
      this.toastService.addError('Unable to process');
    } else {
      this.showBankAcctModal = true;
    }
  }

}


