<app-dashboard-header></app-dashboard-header>
<div class="row">
  <div class="col-12 md:col-8">
    <p3solved-dropdown
      [(ngModel)]="selectedPage"
      optionLabel="pageName"
      id="selectedPage"
      placeholder=""
      [items]="pages"
      (optionSelected)="pageSelected($event)"
    ></p3solved-dropdown>
    <div class="bg-white p-3">
      <iframe
        #campaignFrame
        id="idIframe"
        (load)="iFrameLoaded($event)"
        [src]="iframeUrl"
        style="width: 100%"
        title="Campaign"
      ></iframe>
    </div>
    <p3solved-dropdown
      [(ngModel)]="selectedPage"
      optionLabel="pageName"
      id="selectedPage"
      placeholder=""
      [items]="pages"
      (optionSelected)="pageSelected($event)"
    ></p3solved-dropdown>
  </div>
  <div class="col-12 md:col-4">
    <app-customer-notes></app-customer-notes>
  </div>
</div>
