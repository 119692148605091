<div class="">
    <div class="">
        <div class="flex">
            <div class="p-1">
                <button mat-icon-button (click)="back()">
                    <mat-icon>arrow_back_ios</mat-icon>
                </button>
            </div>
            <div class="p-1 flex-grow-1">
                <p class="text-center h4">Make a Payment</p>
            </div>
        </div>
        <div *ngIf="accSummary.upcomingPaymentScheduledPopup === '1' || accSummary.upcomingPaymentScheduledPopup === 'True'"
            class="alert alert-info flex align-items-center alert-purple" role="alert">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                class="bi bi-exclamation-triangle-fill flex-shrink-0 mr-2" viewBox="0 0 16 16" role="img"
                aria-label="Warning:">
                <path
                    d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
            </svg>
            <div>
                {{accSummary.upcomingPaymentScheduledMessage}}
            </div>
        </div>
        <form [formGroup]="scheduleForm" class="amount-form p-2" (ngSubmit)="onSubmit()">
            <div class="m-2">
                <app-cc-radio [options]="paymentOptions" [group]="scheduleForm" controlName="paymentOption">
                </app-cc-radio>
            </div>
            <div class="flex flex-column justify-content-center">
                <div class="flex justify-content-center">
                    <div class="my-2 form-container">
                        <input class="form-control input-standout append-dollar" id="confirmAmountInput"
                            formControlName="paymentAmount" />
                    </div>
                </div>
                <div class="flex justify-content-center">
                    <div class="my-2 form-container">
                        <mat-form-field appearance="fill">
                            <input matInput [matDatepicker]="picker" formControlName="date" (focus)="dateFocused = true" (blur)="dateBlur()">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                          </mat-form-field>
                    </div>
                </div>
                <div class="flex justify-content-center" *ngIf="!hideAccount">
                    <div class="my-2 form-container">
                        <label for="account" class="form-label clabel">Account to be used for payment</label>
                        <select class="form-select form-select-sm" (change)="onPaymentChange($event, 'account')"
                            id="account">
                            <option *ngFor="let item of paymentAccounts" [selected]="item.isPrimary === 'True'">
                                {{item.loanPaymentMethodDescription}}</option>
                        </select>
                    </div>
                </div>
                <div class="flex justify-content-center">
                    <div class="my-2 form-container">
                        <label for="account" class="form-label clabel">Processor / MCC to be used for payment</label>
                        <select class="form-select form-select-sm" (change)="onPaymentChange($event, 'processor')"
                            id="account">
                            <option *ngFor="let item of processors">
                                {{item.desc}}</option>
                        </select>
                    </div>
                </div>
                <div class="flex justify-content-center">
                    <div class="my-2 form-container">
                        <p3solved-button class="p-button-lg px-8 py-2" (click)="onSubmit()" label="CONFIRM PAYMENT">
                        </p3solved-button>
                    </div>


                </div>
                <div class="flex justify-content-center">
                    <div class="my-1 form-container">
                        <p3solved-button class="outline p-button-lg py-2" (click)="back()" label="Cancel">
                        </p3solved-button>
                    </div>
                </div>

            </div>
        </form>

    </div>
</div>