import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CategoryDefaultFormGroup } from 'src/app/models/form-groups';
import { MessageCategory } from 'src/app/models/message-template';
import { ApiService } from 'src/app/services/api.service';
import { ToastService } from 'src/app/services/toast.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-comm-template-default',
  templateUrl: './comm-template-default.component.html',
  styleUrls: ['./comm-template-default.component.scss']
})
export class CommTemplateDefaultComponent implements OnInit, OnChanges {

  @Output() onBack = new EventEmitter();
  @Output() onSave = new EventEmitter();
  @Input() category: MessageCategory | null = null;
  @Input() customerGuid: string | null = null;

  categoryFormLoaded: boolean = false;
  categoryForm: FormGroup<CategoryDefaultFormGroup> = {} as FormGroup;

  constructor(
    private userService: UserService,
    private apiService: ApiService,
    private toastService: ToastService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.category) {
      this.initForm();
    }
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.initForm();      
    });
  }

  hasFeature(feature: string, mode: string = 'write') {
    switch (mode) {
      case 'read':
        return this.userService.hasRead(feature);
        break;
      case 'feature':
        return this.userService.hasFeature(feature);
        break;
      default:
        return this.userService.hasWrite(feature);
        break;
    }
  }

  initForm() {
    this.categoryForm = new FormGroup<CategoryDefaultFormGroup>({
      subject: new FormControl<string>(this.category?.defaultEmailSubject ?? '', {nonNullable:true, validators: [Validators.required]}),
      body: new FormControl<string>(this.category?.defaultEmailBody ?? '', {nonNullable:true, validators: [Validators.required]})
    });
    this.categoryFormLoaded = true;
  }

  saveDefault() {
    let body = {
      customerGuid: this.customerGuid,
      categoryId: this.category?.messageCategoryID,
      subject: this.categoryForm.value.subject,
      body: this.categoryForm.value.body,
    };

    let pSub = this.apiService.post(`communications/category-default`, body)
    .subscribe({
      next: () => {
        this.toastService.addSuccess("Template default email saved.");
        this.categoryFormLoaded = false;
        this.categoryForm = {} as FormGroup;
        this.onSave.emit();
      },
      error: (err: any) => {
        this.toastService.addError("Unexpected Error. Unable to save default email. See log for details.");
        console.error(err);
      },
      complete: () => { pSub.unsubscribe(); }
    });
  }
}
