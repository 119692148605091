import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { PostCommunicationMessageModel } from 'src/app/models/communications/send-message-model';
import { ApiService } from 'src/app/services/api.service';
import { CustomerService } from 'src/app/services/customer.service';
import { GuidService } from 'src/app/services/guid.service';
import { ToastService } from 'src/app/services/toast.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-comm-queue-sms',
  templateUrl: './comm-queue-sms.component.html',
  styleUrls: ['./comm-queue-sms.component.scss']
})
export class CommQueueSmsComponent implements OnInit, OnChanges {

  @Output() onBack = new EventEmitter();
  @Output() onRefresh = new EventEmitter();
  
  @Input() messageTemplateGuid: string | null = null;
  @Input() replyMessage: any | null = null;
  @Input() customerGuid: string | null = null;

  smsTo: string | null = null;
  smsMsg: string | null = null;
  customerName: string = '';
  disableTo: boolean = false;

  defaultMessageTypeId: number = 4;
  isTemplate:boolean = false;
  templateName: string = '';

  constructor(
    private customerService: CustomerService,
    private apiService: ApiService,
    private toastService: ToastService,
    private guidService: GuidService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    // if (this.customerInfo && this.customerInfo.phone) {
    //   this.smsTo = `${this.customerInfo.phone} (${this.customerInfo.firstName} ${this.customerInfo.lastName})`;
    //   this.disableTo = true;
    // }

    this.checkGetMessageTemplate();
    
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['messageTemplateGuid']) {
      this.smsMsg = null;
      this.checkGetMessageTemplate();
    }
  }

  hasFeature(feature: string, mode: string = 'write') {
    switch (mode) {
      case 'read':
        return this.userService.hasRead(feature);
        break;
      case 'feature':
        return this.userService.hasFeature(feature);
        break;
      default:
        return this.userService.hasWrite(feature);
        break;
    }
  }

  checkGetMessageTemplate() { 

    if (this.messageTemplateGuid) {
      let custSub = this.customerService.getMessageTemplate(this.messageTemplateGuid)
      .subscribe({
        next: (res: any) => {
          if (res && res.body && res.body.length) {
            this.smsMsg = res.body;
            this.isTemplate = true;
            this.templateName = res.subject;
          }
        },
        error: (err: any) => { console.error(err); },
        complete: () => { custSub.unsubscribe(); }
      })
    }
    else {
      this.smsMsg = null;
      this.isTemplate = false;
    }
  }
  
  resetForm() {
    this.smsTo = null;
    this.smsMsg = null;
  }

  sendSMS() {
    let commMsg = new PostCommunicationMessageModel();
    commMsg.targetGuid = this.guidService.emptyGuid();
    commMsg.campaignGuid = this.guidService.emptyGuid();
    commMsg.customerGuid = this.customerGuid || this.guidService.emptyGuid();
    commMsg.messageTypeId = this.defaultMessageTypeId;
    commMsg.isDraft = false;
    commMsg.smsPhoneNumber = this.replyMessage?.messageGUID?.length ? '' : this.smsTo || '';
    commMsg.smsMessage = this.smsMsg || '';
    commMsg.messageCategoryId = 0;    
    
    let postUrl: string = `csr/communication-send`;
    if (this.replyMessage?.messageGUID?.length) postUrl = `communications/message-reply`;

    let apiPost = this.apiService.post(postUrl, commMsg)
    .subscribe({
      next: () => {            
        this.onRefresh.emit(); 
        this.toastService.addSuccess("Message will be sent shortly");
        this.back();
      },
      error: (err: any) => {
        console.error(err);
        this.toastService.addError('Error sending message!');
        console.error(err);
      },
      complete: () => { apiPost.unsubscribe(); }
    });

  }

  back() {
    this.onBack.emit();
  }

  discard() {
    this.smsTo = null;
    this.smsMsg = null;
    this.back();
  }
}
