export class AgentToolsButton {
    "bg-color": string = '';
    btn: string = "";
    color: string = "";
    icon: string = "";
    url: string = "";
}

export class CreditBureauLookups {
    commentCodes: CreditBureauCommentCode[] = [];
    consumerInfos: CreditBureauConsumerInfo[] = [];
    compliances: CreditBureauCompliance[] = [];
}

export class CreditBureauCommentCode {
    creditBureauSpecialCommentID: number = 0;
    specialCommentCode: string = '';
    specialCommentDescription: string = '';
    specialCommentFull: string = '';
}

export class CreditBureauConsumerInfo {
    creditBureauConsumerInfoIndicatorID: number = 0;
    consumerInfoIndicatorCode: string = '';
    consumerInfoIndicatorDescription: string = '';
    consumerInfoIndicatorFull: string = '';

}

export class CreditBureauCompliance {
    creditBureauComplianceID: number = 0;
    complianceCode: string = '';
    complianceDescription: string = '';
    complianceFull: string = '';
}

export class CreditBureauInfo {
    creditBureauExclusion: boolean = false;
    creditBureauSpecialCommentID: number = 0;
    creditBureauConsumerInfoIndicatorID: number = 0;
    creditBureauComplianceID: number = 0;
}

export class PaymentFeeCalculator {
    feeTemplateID: number = 0;
    templateName: string = '';
    paymentCalculatorIframeSRC: string = '';
}

export class CollectionInfo
	{
		label1Text: string = '';
		label1Value: number = 0;
		label2Text: string = '';
		label2Value: number = 0;
		label3Text: string = '';
		label3Value: number = 0;
		label4Text: string = '';
		label4Value: number = 0;
    }

    export class TargetInfo
    {
        campaignName: string | null = null;
        targetID: number = 0;
        campaignID: number = 0;
        campaignDomain: string | null = null;
        campaignDomainProtocol: string = '';
        campaignFolder: string | null = null;
        inVerificationMode: boolean = false;
        csrAggentPass: boolean = false;
        verificationHistory: boolean = false;
        verificationModeExpired: boolean = false;
        verificationModeExpiredText: string = '';
        docsIncompleteShow: boolean = false;
        frstName: string = '';
        lastName: string = '';
        fullName: string = '';
        invitationCode: string = '';
        emailAddress: string | null = null;
        loanNumber: string | null = null;
        loanAppDate: Date = new Date();
        acctrefno: number = 0;
        daysPastDue: number = 0;
        hideTotalPastDueSection: number = 0;
        totalPastDueBalance: number = 0;
        totalPastDue_PPal: number = 0;
        totalPastDue_CAF: number = 0;
        totalPastDue_FFC: number = 0;
        hideTotalCurrentDueSection: number = 0;
        totalCurrentDueBalance: number = 0;
        totalCurrentDue_PPal: number = 0;
        totalCurrentDue_CAF: number = 0;
        totalCurrentDue_FFC: number = 0;
        loanStatus: string = '';
        loanAppStatus: string = '';
        promiseToPay: boolean = false;
        promiseToPayDate: Date | null = null;
        loanOrgDate: Date | null = null;
        paymentMethodID: number = 0;
        legalStatusID: number = 0;
        paymentMethod: string = '';
        legalStatus: string = '';
        fundingType: string = '';
        marketingEmailMe: boolean = false;
        marketingTextMe: boolean = false;
        marketingPhoneMe: boolean = false;
        marketingDirectMailMe: boolean = false;
        shareNonpublic: boolean = false;
        arbitration: boolean = false;
        autoDial: boolean = false;
        accountPhoneMe: boolean = false;
        showCSRFundingBankAccount: boolean | null = null;
        rewardId: number = 0;
        reward: string = '';
        cashAdvanceStatusId: number = 0;
        cashAdvanceStatus: string = '';
        creditBureauExclusion: string = '';
    }

	export class PaymentDefer
	{
		acctrefno: number = 0;
        billedAmount: number = 0;
        billingDate: Date = new Date();
        billingDateDue: Date = new Date();
        cafAmount: number = 0;
        cycleNumber: number = 0;
        dateDue: Date | null = null;
        description: string = '';
        descriptionColor: string = '';
        displayDate: string | null = null; //null
        ffcAmount: number = 0;
        hintMessage: string = '';
        pPalAmountPPal: number = 0;
        paymentRemaining: number = 0;
        pushDate: Date | null = null;
        rn: number = 0;		
        trans: string = '';
	}

    export class SettlementInfo
	{
		oneTime: SettlementOneTime = {} as SettlementOneTime;
		noWriteOff: SettlementNoWriteOff = {} as SettlementNoWriteOff;
		withWriteOff: SettlementWithWriteOff = {} as SettlementWithWriteOff;
	}

	export class SettlementOneTime
	{
		writeOffStatus: string = '';
        makeAPaymentStatus: string = '';
        writeOff: boolean = false;
        makeAPayment: boolean = false;
        principalWriteOffAmount: number | null = null;
        cafWriteOffAmount: number | null = null;
        ffcWriteOffAmount: number | null = null;
        makeAPaymentAmount: number = 0;
        createDate: Date = new Date();			
    }

	export class SettlementNoWriteOff
	{
        freezeFeesStatus: string = '';
        settlementPlanStatus: string = '';
        freezeFees: boolean = false;
        settlementPlan: boolean = false;
        freezeFeesEffectiveDate: Date | null = null;
        settlementAmount: number | null = null;
        noOfPayments: number | null = null;
        paymentAmount: number | null = null;
        payFrequency: string | null = null;
        payDates: string | null = null;
        settlementReasonID: number | null = null;
        settlementReasonDescription: string = '';
        autoPay: boolean | null = null;
        settlementPlanDate: Date | null = null;		
    }

	export class SettlementWithWriteOff
	{
        writeOffStatus: string = '';
        settlementPlanStatus: string = '';
        writeOff: boolean = false;
        settlementPlan: boolean = false;
        principalWriteOffAmount: number | null = null;
        cafWriteOffAmount: number | null = null;
        ffcWriteOffAmount: number | null = null;
        accountBalanceBeforeWO: number | null = null;
        settlementAmount: number | null = null;
        noOfPayments: number | null = null;
        paymentAmount: number | null = null;
        payFrequency: string | null = null;
        payDates: string | null = null;
        settlementReasonID: number | null = null;
        settlementReasonDescription: string = '';
        autoPay: boolean | null = null;
        settlementPlanDate: Date | null = null;
    }

    export class ManualPaymentMethod
	{
        paymentMethodNo: number = 0;
        paymentMethod: string = '';
    }



	export class PaperCheckFundingInfo
	{
		checkFunding: PaperCheckFunding = {} as PaperCheckFunding;
		checkDates: PaperCheckDates = {} as PaperCheckDates;
	}

	export class PaperCheckFunding
	{
		firstName: string | null = null;
        lastName: string | null = null;
        fullName: string | null = null;
        paperCheckFundingAvailable: number = 0;
        paperCheckFundingAmount: string | null = null;
        paperCheckCampaignGuid: string | null = null;
        paperCheckTargetGuid: string | null = null;
    }

	export class PaperCheckDates
	{
		paymentMinDate: Date = new Date();
		paymentMaxDate: Date = new Date();
	}


	export class TransactionHistoryFees
	{
		caf: TransactionHistoryRemain[] = [];
		ffc: TransactionHistoryRemain[] = [];
		suspense: TransactionHistorySuspense = {} as TransactionHistorySuspense;
	}

	export class TransactionHistoryRemain
    {
		row_id: number = 0;
		payment_remaining: number = 0;
		date_due: Date = new Date();
		displayDescription: string = '';
	}

	export class TransactionHistorySuspense
    {
		suspenseAmount: number = 0;
	}