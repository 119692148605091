<p class="font-medium mb-3">Personal Info</p>
<form *ngIf="loanFormLoaded" [formGroup]="loanForm">

  <div class="grid">
    <div class="col-6 md:col-4">
      <p3solved-form-text id="Fname" placeholder="First Name" formControlName="firstName" [required]="isRequired('firstName')">
        <div class="w-12 my-1 p-error text-xs"
          *ngIf="loanForm.controls.firstName.touched && loanForm.controls.firstName.invalid">
          <p>Field is required</p>
        </div>  
      </p3solved-form-text>
    </div>
    <div class="col-6 md:col-4">
      <p3solved-form-text id="Mname" placeholder="Middle Name" formControlName="middleName" [required]="isRequired('middleName')">
        <div class="w-12 my-1 p-error text-xs"
          *ngIf="loanForm.controls.middleName.touched && loanForm.controls.middleName.invalid">
          <p>Field is required</p>
        </div>  
      </p3solved-form-text>
    </div>
    <div class="col-6 md:col-4">
      <p3solved-form-text id="Lname" placeholder="Last Name" formControlName="lastName" [required]="isRequired('lastName')">
        <div class="w-12 my-1 p-error text-xs"
          *ngIf="loanForm.controls.lastName.touched && loanForm.controls.lastName.invalid">
          <p>Field is required</p>
        </div>  
      </p3solved-form-text>
    </div>
    <div class="col-12 md:col-4">
      <p3solved-datepicker id="dtDOB" placeholder="Date of Birth" formControlName="dob" [maxDate]="today"
        panelStyleClass="dp-max" [showIcon]="true" [required]="isRequired('dob')">
        <div class="w-12 my-1 p-error text-xs"
          *ngIf="loanForm.controls.dob.touched && loanForm.controls.dob.invalid">
          <p>Field is required</p>
        </div>  
      </p3solved-datepicker>
    </div>
    <div class="col-12 md:col-8">
      <p class="clabel pt-4">
        <span class="mr-2">Email Address</span>
        <span class="cds-text-color cursor-pointer" (click)="editEmail()">Edit</span>
      </p>
      <p class="cvalue">{{customerInfo.emailAddress}}</p>
    </div>
    <div class="col-12">
      <p3solved-form-text id="Add1" placeholder="Address 1" formControlName="address1" [required]="isRequired('address1')">
        <div class="w-12 my-1 p-error text-xs"
          *ngIf="loanForm.controls.address1.touched && loanForm.controls.address1.invalid">
          <p>Field is required</p>
        </div>  </p3solved-form-text>
    </div>
    <div class="col-12">
      <p3solved-form-text id="Add2" placeholder="Address 2" formControlName="address2" [required]="isRequired('address2')">
        <div class="w-12 my-1 p-error text-xs"
          *ngIf="loanForm.controls.address2.touched && loanForm.controls.address2.invalid">
          <p>Field is required</p>
        </div>  </p3solved-form-text>
    </div>
    <div class="col-12 md:col-6">
      <p3solved-form-text id="city" placeholder="City" formControlName="city" [required]="isRequired('city')">
        <div class="w-12 my-1 p-error text-xs"
          *ngIf="loanForm.controls.city.touched && loanForm.controls.city.invalid">
          <p>Field is required</p>
        </div>  </p3solved-form-text>
    </div>
    <div class="col-12 md:col-6">
      <p3solved-dropdown id="ddST" placeholder="State" [items]="states" optionLabel="desc" optionValue="id"
        formControlName="state" [required]="isRequired('state')">
        <div class="w-12 my-1 p-error text-xs"
          *ngIf="loanForm.controls.state.touched && loanForm.controls.state.invalid">
          <p>Field is required</p>
        </div>  
      </p3solved-dropdown>
    </div>
    <div class="col-12 md:col-6">
      <p3solved-form-text id="zipCode" placeholder="Postal Code" formControlName="zipCode" [required]="isRequired('zipCode')">
        <div class="w-12 my-1 p-error text-xs"
          *ngIf="loanForm.controls.zipCode.touched && loanForm.controls.zipCode.invalid">
          <p>Field is required</p>
        </div>  
      </p3solved-form-text>
    </div>
    <div class="col-12 md:col-6">
      <p class="clabel pt-4">Country</p>
      <p class="cvalue">{{customerInfo.country}}</p>
    </div>
    <div class="col-12 md:col-6">
      <p3solved-input-mask id="phone" placeholder="Home Phone" formControlName="phone" [autoClear]="true"
        mask="999-999-9999" [required]="isRequired('phone')">
        <div class="w-12 my-1 p-error text-xs"
          *ngIf="loanForm.controls.phone.touched && loanForm.controls.phone.invalid">
          <p>Field is required</p>
        </div>  
      </p3solved-input-mask>
    </div>
    <div class="col-12 md:col-6">
      <p3solved-input-mask id="cellPhone" placeholder="Cell Phone" formControlName="cellPhone" [autoClear]="true"
        mask="999-999-9999" [required]="isRequired('cellPhone')">
        <div class="w-12 my-1 p-error text-xs"
          *ngIf="loanForm.controls.cellPhone.touched && loanForm.controls.cellPhone.invalid">
          <p>Field is required</p>
        </div>  
      </p3solved-input-mask>
    </div>
    <div class="col-12">
      <p3solved-input-mask id="ssn" placeholder="Social Security Number" formControlName="sSN" [autoClear]="true"
        mask="999-99-9999" [required]="isRequired('sSN')">
        <div class="w-12 my-1 p-error text-xs"
          *ngIf="loanForm.controls.sSN.touched && loanForm.controls.sSN.invalid">
          <p>Field is required</p>
        </div>  
      </p3solved-input-mask>
    </div>
  </div>

  <p class="font-medium mb-1 mt-2 py-4 pds-divider-top">Other</p>
  <div class="grid">
    <div class="col-12 md:col-6">
      <p3solved-form-text id="routeNum" placeholder="Routing Number" formControlName="routeNum" [required]="isRequired('routeNum')">
        <div class="w-12 my-1 p-error text-xs"
          *ngIf="loanForm.controls.routeNum.touched && loanForm.controls.routeNum.invalid">
          <p>Field is required</p>
        </div> 
      </p3solved-form-text>
    </div>
    <div class="col-12 md:col-6">
      <p3solved-form-text id="accountNum" placeholder="Bank Account Number"
        formControlName="accountNum" [required]="isRequired('accountNum')">
        <div class="w-12 my-1 p-error text-xs"
          *ngIf="loanForm.controls.accountNum.touched && loanForm.controls.accountNum.invalid">
          <p>Field is required</p>
        </div> 
      </p3solved-form-text>
    </div>
    <div class="col-12 md:col-6">
      <p3solved-form-text id="ipAdd" placeholder="IP Address" formControlName="ipAdd" [required]="isRequired('ipAdd')"
        [disabled]="true">
      </p3solved-form-text>
    </div>
    <div class="col-12 md:col-6">
      <p3solved-form-text id="bankName" placeholder="Bank Name" formControlName="bankName" [required]="isRequired('bankName')">
        <div class="w-12 my-1 p-error text-xs"
          *ngIf="loanForm.controls.bankName.touched && loanForm.controls.bankName.invalid">
          <p>Field is required</p>
        </div> 
      </p3solved-form-text>
    </div>
    <div class="col-12 md:col-6">
      <p class="clabel pt-4">
        <span class="mr-2">Card Number</span>
        <span class="cds-text-color cursor-pointer" (click)="editCreditCard()">Edit</span>
      </p>
      <p class="cvalue">{{hyphenForEmpty(cardInfo.cardDescription)}}</p>
    </div>
    <div class="col-12 md:col-6">
      <p class="clabel pt-4">
        <span class="mr-2">Card Expiration</span>
      </p>
      <p class="cvalue">{{hyphenForEmpty(cardInfo.cardExpiration)}}</p>
    </div>
    <div class="col-12 grid">
      <div class="col-12">Reference 1</div>
      <div class="col-12 md:col-6">
        <p3solved-form-text id="ref1FName" placeholder="First Name" formControlName="ref1FName" [required]="isRequired('ref1FName')">
          <div class="w-12 my-1 p-error text-xs"
            *ngIf="loanForm.controls.ref1FName.touched && loanForm.controls.ref1FName.invalid">
            <p>Field is required</p>
          </div> 
        </p3solved-form-text>
      </div>
      <div class="col-12 md:col-6">
        <p3solved-form-text id="ref1FLame" placeholder="Last Name" formControlName="ref1LName" [required]="isRequired('ref1LName')">
          <div class="w-12 my-1 p-error text-xs"
            *ngIf="loanForm.controls.ref1LName.touched && loanForm.controls.ref1LName.invalid">
            <p>Field is required</p>
          </div> 
        </p3solved-form-text>
      </div>
    </div>
    <div class="col-12 grid">
      <div class="col-12">Reference 2</div>
      <div class="col-12 md:col-6">
        <p3solved-form-text id="ref2FName" placeholder="First Name" formControlName="ref2FName" [required]="isRequired('ref2FName')">
          <div class="w-12 my-1 p-error text-xs"
            *ngIf="loanForm.controls.ref2FName.touched && loanForm.controls.ref2FName.invalid">
            <p>Field is required</p>
          </div> 
        </p3solved-form-text>
      </div>
      <div class="col-12 md:col-6">
        <p3solved-form-text id="ref2FLame" placeholder="Last Name" formControlName="ref2LName" [required]="isRequired('ref2LName')">
          <div class="w-12 my-1 p-error text-xs"
            *ngIf="loanForm.controls.ref2LName.touched && loanForm.controls.ref2LName.invalid">
            <p>Field is required</p>
          </div> 
        </p3solved-form-text>
      </div>
    </div>
  </div>

  <p class="font-medium mb-1 mt-2 py-4 pds-divider-top">Income Info</p>
  <div class="grid">
    <div class="col-12">
      <p3solved-dropdown id="ddIncType" placeholder="Income Type" [items]="incomeTypes" optionLabel="desc"
        optionValue="id" formControlName="incType" (optionSelected)="incomeTypeChanged($event)"
        [required]="isRequired('incType')">
        <div class="w-12 my-1 p-error text-xs"
          *ngIf="loanForm.controls.incType.touched && loanForm.controls.incType.invalid">
          <p>Field is required</p>
        </div> 
      </p3solved-dropdown>
    </div>


    <div class="col-12 grid" *ngIf="loanForm.value.incType == '1'">
      <div class="col-12 md:col-6">
        <p3solved-form-text id="empName" placeholder="Employer Name" formControlName="empName" [required]="isRequired('empName')">
          <div class="w-12 my-1 p-error text-xs"
            *ngIf="loanForm.controls.empName.touched && loanForm.controls.empName.invalid">
            <p>Field is required</p>
          </div> 
        </p3solved-form-text>
      </div>
      <div class="col-12 md:col-6">
        <p3solved-form-text id="jobTitle" placeholder="Job Title" formControlName="jobTitle" [required]="isRequired('jobTitle')">
          <div class="w-12 my-1 p-error text-xs"
            *ngIf="loanForm.controls.jobTitle.touched && loanForm.controls.jobTitle.invalid">
            <p>Field is required</p>
          </div> 
        </p3solved-form-text>
      </div>
      <div class="col-12 md:col-6">
        <p3solved-input-mask id="workPhone" placeholder="Work Phone" formControlName="workPhone" [required]="isRequired('workPhone')" 
          [autoClear]="true" mask="999-999-9999">
          <div class="w-12 my-1 p-error text-xs"
            *ngIf="loanForm.controls.workPhone.touched && loanForm.controls.workPhone.invalid">
            <p>Field is required</p>
          </div>           
        </p3solved-input-mask>
      </div>
      <div class="col-12 md:col-6">
        <p3solved-input-mask id="empPhone" placeholder="Employer Phone" formControlName="empPhone" [required]="isRequired('empPhone')" 
          [autoClear]="true" mask="999-999-9999">
          <div class="w-12 my-1 p-error text-xs"
            *ngIf="loanForm.controls.empPhone.touched && loanForm.controls.empPhone.invalid">
            <p>Field is required</p>
          </div> 
        </p3solved-input-mask>
      </div>
    </div>

    <div class="col-12 grid" *ngIf="loanForm.value.incType == '3'">
      <div class="col-12 md:col-6">
        <p3solved-form-text id="compName" placeholder="Company Name" formControlName="compName" [required]="isRequired('compName')">
          <div class="w-12 my-1 p-error text-xs"
            *ngIf="loanForm.controls.compName.touched && loanForm.controls.compName.invalid">
            <p>Field is required</p>
          </div> 
        </p3solved-form-text>
      </div>
      <div class="col-12 md:col-6">
        <p3solved-input-mask id="compPhone" placeholder="Company Phone" formControlName="compPhone" [required]="isRequired('compPhone')" 
          [autoClear]="true" mask="999-999-9999">
          <div class="w-12 my-1 p-error text-xs"
            *ngIf="loanForm.controls.compPhone.touched && loanForm.controls.compPhone.invalid">
            <p>Field is required</p>
          </div> 
        </p3solved-input-mask>
      </div>
    </div>

    <div class="col-12 grid" *ngIf="loanForm.value.incType == '4'">
      <div class="col-12 md:col-6">
        <p3solved-input-mask id="verPhone" placeholder="Verification Phone" formControlName="verPhone" [required]="isRequired('verPhone')"
          [autoClear]="true" mask="999-999-9999">
          <div class="w-12 my-1 p-error text-xs"
            *ngIf="loanForm.controls.verPhone.touched && loanForm.controls.verPhone.invalid">
            <p>Field is required</p>
          </div> 
        </p3solved-input-mask>
      </div>
    </div>

    <div class="col-12 md:col-6">
      <p3solved-input-number id="amtCheck" placeholder="Amount From Check"
        formControlName="amtCheck" [required]="isRequired('amtCheck')">
        <div class="w-12 my-1 p-error text-xs"
          *ngIf="loanForm.controls.amtCheck.touched && loanForm.controls.amtCheck.invalid">
          <p>Field is required</p>
        </div> 
      </p3solved-input-number>
    </div>
    <div class="col-12 md:col-6">
      <p><span class="cds-placeholder-color font-bold text-sm mr-2">Pay Frequency</span></p>
      <p3solved-button id="bntPayFreq" label="{{customerInfo.payFrequency}}" class="link" (buttonClick)="editPayFrequency()"></p3solved-button>
    </div>
    <div class="col-12 md:col-6">
      <p3solved-input-number id="addInc" placeholder="Additionaly Monthly Income"
        formControlName="addInc" [required]="isRequired('addInc')">
        <div class="w-12 my-1 p-error text-xs"
          *ngIf="loanForm.controls.addInc.touched && loanForm.controls.addInc.invalid">
          <p>Field is required</p>
        </div> 
      </p3solved-input-number>
    </div>
    <div class="col-12 md:col-6">
      <p3solved-dropdown id="ddAddIncType" placeholder="Additional Job Income Type" [items]="addIncomeTypes"
        optionLabel="desc" optionValue="id" formControlName="addIncType" [required]="isRequired('addIncType')">
        <div class="w-12 my-1 p-error text-xs"
          *ngIf="loanForm.controls.addIncType.touched && loanForm.controls.addIncType.invalid">
          <p>Field is required</p>
        </div> 
      </p3solved-dropdown>
    </div>

  </div>

</form>

<p3solved-dialog [isVisible]="showEditCard" header="Customer Card Change" headerClass="text-2xl" [showHeader]="true" [isModal]="true" 
    [isClosable]="true" (HideClick)="showEditCard=false;" [style]="{'width': '45vw'}">
    <div body>
      <form *ngIf="cardFormLoaded" [formGroup]="cardForm">
        <div class="grid">
          <div class="col-6">
            <p3solved-form-text id="txtCardNumber" placeholder="Card Number" formControlName="cardNum">
                <div class="w-12 my-1 p-error text-xs"
                    *ngIf="cardForm.controls.cardNum.touched && cardForm.controls.cardNum.invalid">
                    <p>Card Number is not valid</p>
                </div>
            </p3solved-form-text>
          </div>
          <div class="col-2">
              <p3solved-input-mask id="txtExpDate" placeholder="Exp Date" formControlName="expDate"
                  [autoClear]="true" mask="99/99">
                  <div class="w-12 my-1 p-error text-xs"
                      *ngIf="cardForm.controls.expDate.touched && cardForm.controls.expDate.invalid">
                      <p>Exp Date is required</p>
                  </div>
              </p3solved-input-mask>
          </div>
          <div class="col-2">
              <p3solved-form-text id="txtCVV" placeholder="CVV" formControlName="cvv" [maxlength]="3">
                  <div class="w-12 my-1 p-error text-xs"
                      *ngIf="cardForm.controls.cvv.touched && cardForm.controls.cvv.invalid">
                      <p>CVV is required</p>
                  </div>
              </p3solved-form-text>
          </div>
          <div class="col-2">
              <p3solved-form-text id="txtZipCode" placeholder="Zip Code" formControlName="zip"
                  [maxlength]="5">
                  <div class="w-12 my-1 p-error text-xs"
                      *ngIf="cardForm.controls.zip.touched && cardForm.controls.zip.invalid">
                      <p>Zip Code is required</p>
                  </div>
              </p3solved-form-text>
          </div>
        </div>
      </form>
    </div>  
    <div footer class="justify-content-around">
        <p3solved-button id="btnCancelCard" label="Cancel" class="secondary" (buttonClick)="showEditCard=false;"></p3solved-button>
        <p3solved-button id="btnSaveNewCard" label="Confirm" [disabled]="!cardForm.valid" (buttonClick)="saveNewCardInfo();"></p3solved-button>
    </div>
</p3solved-dialog>


<p3solved-dialog [isVisible]="showPayFreq" header="Update Pay Frequency 1 of 2" headerClass="text-2xl" [showHeader]="true" [isModal]="true" 
    [isClosable]="true" (HideClick)="showPayFreq=false;" [style]="{'width': '45vw'}">
    <div body>
      <app-pay-calendar-form [payForm]="payFreqForm" (datesCalculated)="datesCaclulated($event)"></app-pay-calendar-form>
    </div>
    <div footer class="justify-content-around">
        <!-- <p3solved-button id="btnTestForm" label="Test" class="outline" (buttonClick)="testForm()"></p3solved-button> -->
        <p3solved-button id="btnCancelPayFreq" label="Cancel" class="secondary" (buttonClick)="showPayFreq=false;"></p3solved-button>
        <p3solved-button id="btnPayFStep1" label="Confirm" [disabled]="nextPayDates.length < 1" (buttonClick)="payFrequencyStep1();"></p3solved-button>
    </div>    
</p3solved-dialog>



<p3solved-dialog [isVisible]="showPayFreq2" header="Update Pay Frequency 2 of 2" headerClass="text-2xl" [showHeader]="true" [isModal]="true" 
    [isClosable]="true" (HideClick)="showPayFreq2=false;" [style]="{'width': '45vw'}">
    <div body>
      <p><strong>You're new pay dates are as follows and thus your billing cycles will be updated as well:</strong></p>
      
        <ul *ngIf="nextPayDates.length > 0">
          <li *ngFor="dax of nextPayDates; index as i">{{nextPayDates[i]}}</li>
        </ul>
      
      <p><strong>
        <span class="text-danger">IMPORTANT:</span> All customers must make two minimum payments per month including customers who are paid monthly. 
        The dates shown above may not be your payment due dates. 
    </strong></p>

    <strong>Please Confirm this change.</strong><br />
    </div>
    <div footer class="justify-content-around">
        <p3solved-button id="btnCancelPayFreq" label="Cancel" class="secondary" (buttonClick)="showPayFreq2=false;showPayFreq=true;"></p3solved-button>
        <p3solved-button id="btnPayFStep1" label="Confirm" [disabled]="!payFreqForm.valid" (buttonClick)="payFrequencyStep2();"></p3solved-button>
    </div>    
</p3solved-dialog>