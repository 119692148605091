import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors, AbstractControl, ValidatorFn, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DebitCardPayment } from 'src/app/models/card-payment';
import { CourtesyAdjustmentInfo } from 'src/app/models/courtesy-adjustment-info';
import { PaymentMethodModel } from 'src/app/models/payment-methods';
import { ApiService } from 'src/app/services/api.service';
import { CustomerService } from 'src/app/services/customer.service';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { GuidService } from 'src/app/services/guid.service';
import { HelperService } from 'src/app/services/helper.service';
import { ToastService } from 'src/app/services/toast.service';
import { UserService } from 'src/app/services/user.service';
import { CustomerModel } from 'src/app/models/customer-model';
import { forkJoin, Subject } from 'rxjs';
import { TransactionHistoryFees } from 'src/app/models/csr/agent-tools';
import { LookupModel } from 'src/app/models/lookup-model';
import { AppCustomerSettings } from 'src/app/models/customer-settings';
import { AcctAdjustFormGroup, RewardAjustFormGroup } from 'src/app/models/form-groups';
import { AccountSummary } from 'src/app/models/account-summary';
import { TargetAccountSummary } from 'src/app/models/target-account-summary';
import { AccountAdjustmentOptionTwoComponent } from 'src/app/components/account-adjustment-options/account-adjustment-option-two/account-adjustment-option-two.component';
import { CustomerNotesComponent } from 'src/app/components/customer-notes/customer-notes.component';
import { BankAdjustmentOptionTwoComponent } from 'src/app/components/bank-adjustment-options/bank-adjustment-option-two/bank-adjustment-option-two.component';
import { CSRRewardTierInfo } from 'src/app/models/csr/reward-tier-info';
import { CustomerRewardInfoComponent } from 'src/app/components/customer-reward-info/customer-reward-info.component';

@Component({
  selector: 'app-courtesy-adjustment-page',
  templateUrl: './courtesy-adjustment-page.component.html',
  styleUrls: ['./courtesy-adjustment-page.component.scss']
})
export class CourtesyAdjustmentPageComponent implements OnInit {

  validateAccountAdjustment: boolean = false;
  accountAdjustmentsLoaded: boolean = false;
  disableCreditCardPayment: boolean = true;
  disableBankPayment: boolean = true;
  manualBankAdjBlur: boolean = false;
  showAcctAdjModal: boolean = false;
  showCreditCardModal: boolean = false;
  showBankAcctModal: boolean = false;
  rewardAdjustFormLoaded: boolean = false;
  showRewardAjust: boolean = false;
  
  customerGuid: any;
  targetGuid: any;
  campaignGuid: any;

  bcItems: MenuItem[] = [];
  pointAdjustments: LookupModel[] = [];
  debitCardPayments: DebitCardPayment[] = [];

  customerSettings: AppCustomerSettings = {} as AppCustomerSettings;
  customer: CustomerModel | null = null;
  accountSummary: TargetAccountSummary = new TargetAccountSummary();
  transactionHistoryFees: TransactionHistoryFees = new TransactionHistoryFees();
  courtesyAdjustinfo: CourtesyAdjustmentInfo = new CourtesyAdjustmentInfo();
  selectedMethod: PaymentMethodModel | null = null;
  rewardInfo: CSRRewardTierInfo = {} as CSRRewardTierInfo;

  serviceOption: string = '';

  bankAmount: number = 0;
  totalAdjustment: number = 0.00;
  manualRefundAmount: number = 0.00;
  manualBankAdjustment: number = 0.00;
  
  accountAdjustments: FormGroup<AcctAdjustFormGroup> = {} as FormGroup;
  rewardAdjustForm: FormGroup<RewardAjustFormGroup> = {} as FormGroup;
  
  @ViewChild('acctAdjustOptTwo') acctAdjustOptTwo: AccountAdjustmentOptionTwoComponent = {} as AccountAdjustmentOptionTwoComponent;
  @ViewChild('custNotes') custNotes: CustomerNotesComponent = {} as CustomerNotesComponent;
  @ViewChild('bankAdjustOpt2') bankAdjustOpt2: BankAdjustmentOptionTwoComponent = {} as BankAdjustmentOptionTwoComponent;
  @ViewChild('rewards') rewards: CustomerRewardInfoComponent = {} as CustomerRewardInfoComponent; 

  constructor(
    private activatedRoute: ActivatedRoute,
    private guidService: GuidService,
    private userService: UserService,
    private customerService: CustomerService,
    private confirmService: ConfirmationService,
    private helperService: HelperService,
    private toastService: ToastService,
    private fb: FormBuilder,
    private apiService: ApiService
    ) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(async paramMap => {
      let cGuid = paramMap.get('customerGuid')?.toString() ?? '';
      if (this.guidService.isValidGuid(cGuid)) {
        this.customerGuid = cGuid;
        this.customerService.initCustomer(this.customerGuid);
        await this.userService.subscribeAccess();
        let data = this.activatedRoute.snapshot.data;
        if (data && data['pageId']) {
          this.userService.checkPageAccess(data['pageId']);
        }
      }
      let campGuid = paramMap.get('campaignGuid')?.toString() ?? '';
      let tGuid = paramMap.get('targetGuid')?.toString() ?? '';
      if (this.guidService.isValidGuid(campGuid)) this.campaignGuid = campGuid;
      if (this.guidService.isValidGuid(tGuid)) this.targetGuid = tGuid;
      this.customerService.customer$.subscribe(cust => {
        this.customer = cust; 
        if (cust) {
          this.serviceOption = "2";//cust.cSAccountServicingOption;
          this.getData();
        }
      });
      this.bcItems = [
        { label: 'Agent Tools', routerLink: [`/agent-tools/${this.customerGuid}/${this.campaignGuid}/${this.targetGuid}`] },
        { label: "Courtesy Adjustment" }
      ];

    });


  }

  getData() {
    const fSub = forkJoin({
      acctSumm: this.apiService.get(`csr/target-account-summary/${this.serviceOption}/${this.customerGuid}/${this.campaignGuid}/${this.targetGuid}`),
      courtAdjInfo: this.apiService.get(`AgentTools/courtesy-adjustment-info/${this.customerGuid}/${this.campaignGuid}/${this.targetGuid}`),
      debitCardPayments: this.apiService.get(`AgentTools/card-payments/${this.customerGuid}/${this.campaignGuid}/${this.targetGuid}`),
      transFees: this.apiService.get(`AgentTools/transaction-history-fees/${this.customerGuid}/${this.campaignGuid}/${this.targetGuid}`),
      customerSettings: this.apiService.get(`app/customer-settings2/${this.customerGuid}/${this.guidService.newGuid()}`),
      rewardInfo: this.apiService.get(`csr/reward-info/${this.customerGuid}/${this.campaignGuid}/${this.targetGuid}`),
      lookupData: this.apiService.get(`csr/lookup-data/${this.customerGuid}`)
    })
      .subscribe({
        next: (data: any) => {
          this.accountSummary = data.acctSumm.accountSummary as TargetAccountSummary;
          this.courtesyAdjustinfo = data.courtAdjInfo;
          this.transactionHistoryFees = data.transFees;
          this.pointAdjustments = data.lookupData.pointAdjustment;
          this.debitCardPayments = data.debitCardPayments;
          this.customerSettings = data.customerSettings;
          this.rewardInfo = data.rewardInfo;
          this.initPage();
        },
        error: (err: any) => {
          this.toastService.addError("Unable to get initial Collections data. See log for details.");
          console.error(err);
        },
        complete: () => { fSub.unsubscribe(); }
      });
  }

  initPage() {
    if (this.acctAdjustOptTwo) this.acctAdjustOptTwo.initPage();
    if (this.bankAdjustOpt2) this.bankAdjustOpt2.initPage();
    if (this.rewards) {
      this.rewards.initPage();
      this.initRewardForm();
    }
  }

  reloadData() {
    this.custNotes.reload();
    this.getData();
  }

  initRewardForm() {
    this.rewardAdjustForm = new FormGroup<RewardAjustFormGroup>({
      points: new FormControl<string|null>(null, {nonNullable: true, validators: [Validators.required]}),
      comments: new FormControl<string|null>(null, {nonNullable: true, validators: [Validators.required]})
    });
    this.rewardAdjustFormLoaded = true;
  }
  
  postAccountAdjustment() {
    let cashAdvanceFeeAmount = 0 // +this.accountAdjustments?.value['principal'] ?? 0;
    let fixedFinanceFeeAmount = 0 // +this.accountAdjustments?.value['interest'] ?? 0;
    let lateFeeAmount = 0 //+this.accountAdjustments?.value['lateFee'] ?? 0;
    // if (cashAdvanceFeeAmount > 0 || fixedFinanceFeeAmount > 0 || lateFeeAmount > 0) {
    //   if ((cashAdvanceFeeAmount == 0 || cashAdvanceFeeAmount <= this.accountSummary.principalBalance) && (fixedFinanceFeeAmount == 0 || fixedFinanceFeeAmount <= this.accountSummary.interestBalance) && (lateFeeAmount == 0 || lateFeeAmount <= this.accountSummary.feesBalance)) {

    //     let body = {
    //       TargetGuid: this.targetGuid,
    //       CampaignGuid: this.campaignGuid,
    //       CustomerGuid: this.customerGuid,
    //       Type: 'account',
    //       CashAdvanceFeeAmount: cashAdvanceFeeAmount,
    //       FixedFinanceFeeAmount: fixedFinanceFeeAmount,
    //       LateFeeAmount: lateFeeAmount,
    //       PaylianceTransactionQueueId: -1
    //     };

    //     let postSub = this.apiService.postStringResponse(`AgentTools/courtesy-adjustment/`, body)
    //       .subscribe({
    //         next: () => {
    //           this.toastService.addSuccess('Account Adjustment added.');
    //         },
    //         error: (err: any) => {
    //           this.toastService.addError('Unable to make account adjustment');
    //           console.error(err);
    //         },
    //         complete: () => { postSub.unsubscribe(); }
    //       })
    //   }
    //   else {
    //     let msg = '';
    //     if (cashAdvanceFeeAmount > this.accountSummary.principalBalance) {
    //       msg = `Principal Adjustment must be less than or equal to $${this.accountSummary.principalBalance}.`
    //     } else if (fixedFinanceFeeAmount > this.accountSummary.interestBalance) {
    //       msg = `Interest Adjustment must be less than or equal to $${this.accountSummary.interestBalance}.`
    //     } else if (lateFeeAmount > this.accountSummary.feesBalance) {
    //       msg = `Late Fee Adjustment must be less than or equal to $${this.accountSummary.feesBalance}.`
    //     }
    //     if (msg) {
    //       this.toastService.addError('Unable to make account adjustment');
    //     }
    //   }
    // }
  }
  
  updateTotalAdjustment() {
    setTimeout(() => {
      this.totalAdjustment = (this.accountAdjustments.value.cafAmt ?? 0)
        + (this.accountAdjustments.value.ffcAmt ?? 0)
        + (this.accountAdjustments.value.princ ?? 0);
    }, 200);
  }

  confirmCardAdjustment() {
    if (this.disableCreditCardPayment) {
      this.toastService.addError('Unable to process');
    } 
    else {
      this.showCreditCardModal = true;
    }
  }

  postCardAdjustment() {
    // let body = {
    //   TargetGuid: this.targetGuid,
    //   CampaignGuid: this.campaignGuid,
    //   CustomerGuid: this.customerGuid,
    //   Type: 'card',
    //   CashAdvanceFeeAmount: 0,
    //   FixedFinanceFeeAmount: 0,
    //   LateFeeAmount: 0,
    //   cardAmount: this.manualRefundAmount,
    //   PaylianceTransactionQueueId: this.selectedPayment?.transactionQueueID
    // };

    // let postSub = this.apiService.postStringResponse(`AgentTools/courtesy-adjustment/`, body)
    //   .subscribe({
    //     next: () => {
    //       this.toastService.addSuccess('Credit Card Adjustment added.');
    //     },
    //     error: (err: any) => {
    //       this.toastService.addError('Unable to make credit card adjustment');
    //     },
    //     complete: () => { postSub.unsubscribe(); }
    //   })
  }

  confirmBankAdjustment() {
    if (this.disableBankPayment) {
      this.toastService.addError('Unable to process');
    } else {
      this.showBankAcctModal = true;
    }
  }

  postBankAdjustment() {
    // if (this.manualBankAdjustment && this.manualBankAdjustment <= this.accountSummary.principalBalance) {
    //   let body = {
    //     TargetGuid: this.targetGuid,
    //     CampaignGuid: this.campaignGuid,
    //     CustomerGuid: this.customerGuid,
    //     Type: 'bank',
    //     CashAdvanceFeeAmount: 0,
    //     FixedFinanceFeeAmount: 0,
    //     LateFeeAmount: 0,
    //     cardAmount: 0,
    //     PaylianceTransactionQueueId: -1,
    //     bankAccountAmount: this.manualBankAdjustment,
    //     loanPaymentMethodGuid: this.selectedMethod?.loanPaymentMethodGUID
    //   };

    //   let postSub = this.apiService.postStringResponse(`AgentTools/courtesy-adjustment/`, body)
    //     .subscribe({
    //       next: () => {
    //         this.toastService.addSuccess('Bank Adjustment made.');
    //       },
    //       error: (err: any) => {
    //         this.toastService.addError('Unable to make bank account adjustment');
    //       },
    //       complete: () => { postSub.unsubscribe(); }
    //     });
    // } else {
    //   this.toastService.addError('Unable to make bank account adjustment');
    // }
  }
  
  // setPaymentRefund(payment: CardPayment) {
  //   setTimeout(() => {
  //     // this.manualRefundAmount = payment.amount;
  //     // if (this.selectedPayment && (this.manualRefundAmount <= this.selectedPayment?.amount)) {
  //     //   this.disableCreditCardPayment = false;
  //     // } else {
  //     //   this.disableCreditCardPayment = true;
  //     // }
  //   }, 100);
  // }

  validateCreditCard() {
    setTimeout(() => {
      // if (this.selectedPayment && (this.manualRefundAmount <= this.selectedPayment?.amount)) {
      //   this.disableCreditCardPayment = false;
      // } else {
      //   this.disableCreditCardPayment = true;
      // }
    }, 100);

    return true;
  }

  validateBankAmount() {
    if (!this.manualBankAdjBlur) {
      return;
    }
    setTimeout(() => {
      if (!this.manualBankAdjustment || this.manualBankAdjustment == 0.00 || !this.selectedMethod) {
        this.disableBankPayment = true;
      } else {
        this.disableBankPayment = false;
      }
    }, 100);

    return true;
  }

  confirmRewardAdjust() {
    this.showRewardAjust = true;
  }

  postRewardAdjustment() { 
    let body = {
      customerGuid: this.customerGuid,
      campaignGuid: this.campaignGuid,
      targetGuid: this.targetGuid,
      pointAdjustment: +(this.rewardAdjustForm.value.points ?? 0),
      comments: this.rewardAdjustForm.value.comments
    };

    let pSub = this.apiService.post(`agenttools/reward-adjustment`, body)
    .subscribe({
      next: () => {
        this.custNotes.reload();
        this.rewardAdjustForm.reset();
        this.rewardAdjustForm.updateValueAndValidity();
        this.toastService.addSuccess("Reward Adjustment successfully created.");
      },
      error: (err: any) => {
        this.toastService.addError("Unable to create Reward Adjustment. See log for details.");
        console.error(err);
      },
      complete: () => { pSub.unsubscribe(); }
    });
  }

}

export function AdjustmentValidator(accountSummary: AccountSummary): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let form = control as FormGroup;
    let hasError: boolean = false;
    let errors: any = {};
    
    let principal = Number(form.get('principal')?.value ?? 0.00);
    let interest = Number(form.get('interest')?.value ?? 0.00);
    let lateFee = Number(form.get('lateFee')?.value ?? 0.00);

    let princAS = accountSummary.principalBalance;
    let intAS = accountSummary.interestBalance;
    let feeAS = accountSummary.feesBalance;

    if (principal == 0 && interest == 0 && lateFee == 0) {
      hasError = true;
      form.controls['principal'].setErrors({min:true});
      form.controls['interest'].setErrors({min:true});
      form.controls['lateFee'].setErrors({min:true});
      return null;
    }

    if (principal > princAS) {
      hasError = true;
      form.controls['principal'].setErrors({max:true});
    }
    else {
      form.controls['principal'].setErrors(null);
    }
    
    if (interest > intAS) {
      hasError = true;
      form.controls['interest'].setErrors({max:true});
    }
    else {
      form.controls['interest'].setErrors(null);
    }
    
    if (lateFee > feeAS) {
      hasError = true;
      form.controls['lateFee'].setErrors({max:true});
    }
    else {
      form.controls['lateFee'].setErrors(null);
    }
    
    return hasError ? errors : null;
  }
}
