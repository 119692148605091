<div class="px-3 mb-6">
    <div class="px-3">
        <p class="text-xl m-2">Contract Documents</p>

        <p-table [value]="docs" styleClass="p-datatable-lg mr-4" [rowHover]="true">
          <ng-template pTemplate="header">
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Date</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-doc>
            <tr>
              <td>
                <span class="mr-2">
                  <i class="pi pi-file" aria-hidden="true"></i>
                </span>
                <span>
                  <a [href]="doc.fileURL" target="_blank">{{doc.fileName}}</a>
                </span>
              </td>
              <td>{{ doc.createDate }}</td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="3">No documents to display.</td>
            </tr>
          </ng-template>
        </p-table>
    </div>
</div>