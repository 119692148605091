import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedIds } from 'src/app/models/activated-ids';
import { CSRLoanData, CustomerInfoLoanModel, CustomerLoanCardInfo } from 'src/app/models/customer-info-loan-model';
import { ApiService } from 'src/app/services/api.service';
import { CustomerService } from 'src/app/services/customer.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { MainService } from 'src/app/services/main.service';

@Component({
  selector: 'app-customer-info',
  templateUrl: './customer-info.component.html',
  styleUrls: ['./customer-info.component.scss']
})
export class CustomerInfoComponent implements OnInit {
  @Output() customerInfoUpdated: EventEmitter<CustomerInfoLoanModel> = new EventEmitter<CustomerInfoLoanModel>();
  @Output() cardInfoUpdated: EventEmitter<CustomerLoanCardInfo> = new EventEmitter<CustomerLoanCardInfo>();

  ids: ActivatedIds = new ActivatedIds;
  customerInfo: CustomerInfoLoanModel = new CustomerInfoLoanModel;
  loading = true;
  cardInfo: CustomerLoanCardInfo = new CustomerLoanCardInfo;
  constructor(private main: MainService, 
    private dashboardService: DashboardService,
    private customerService: CustomerService,
    private apiService: ApiService) { }

  ngOnInit(): void {
    this.main.activatedIds$.subscribe(id => {
      if (id && this.ids.targetGuid !== id.targetGuid){
        this.ids = id;
        this.main.showLoading();
        this.getLoanData();
      }
    });

    this.customerService.infoChanged$.subscribe(val => {
      if (val === 'changed'){
        this.getLoanData();
      }
    })
  }

  getLoanData() {
    this.loading = true;
    this.apiService.get(`csr/loan-data/${this.ids.customerGuid}/${this.ids.campaignGuid}/${this.ids.targetGuid}`)
    .subscribe((res: CSRLoanData) => {
      this.main.dismissLoading();
      this.loading = false;
      this.customerInfo = res.loanData;
      this.dashboardService.setLoanData(this.customerInfo);
      this.customerInfoUpdated.emit(this.customerInfo);
      if (res.cardInfo && res.cardInfo.cardDescription) {
        this.dashboardService.setCardData(res.cardInfo);
        this.cardInfoUpdated.emit(res.cardInfo);
        this.cardInfo = res.cardInfo;
      }
      else {
        this.dashboardService.setCardData(null);
        this.cardInfo = new CustomerLoanCardInfo;
      }
    },
      (err: any) => {
        this.loading = false;
        this.main.dismissLoading();
        console.error(err);
      }
    );
  }

  hyphenForEmpty(value: string | null) {
    if (!value || value.length == 0) return "-";
    return value;
  }

  getMoney(value: string) {
    let retVal: string = value;
    let amt: number = 0;
    if (retVal && retVal.length > 0 && !isNaN(+retVal)) {
      amt = +retVal;
      retVal = amt.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }
    return retVal;
  }

}
