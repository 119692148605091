import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { faUserCircle } from '@fortawesome/free-regular-svg-icons';
import { faBars, faThLarge, faTh, faPowerOff, faTable } from '@fortawesome/free-solid-svg-icons';
import { UserModel } from 'src/app/models/user-model';
import { AuthService } from 'src/app/services/auth.service';
import { CustomerService } from 'src/app/services/customer.service';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import { environment } from 'src/environments/environment';
import { SettingService } from 'src/app/services/setting.service';

@Component({
  selector: 'app-profile-menu',
  templateUrl: './profile-menu.component.html',
  styleUrls: ['./profile-menu.component.scss']
})
export class ProfileMenuComponent implements OnInit {
  @Output() itemClicked = new EventEmitter();

  user:UserModel = new UserModel;
  iconUserCircle = faUserCircle as IconProp;
  iconTable = faTable as IconProp;
  iconBars = faBars as IconProp;
  iconThLarge = faThLarge as IconProp;
  iconTh = faTh as IconProp;
  iconPowerOff = faPowerOff as IconProp;
  customerGuid: string | null = "";

  constructor(private authService:AuthService, 
    private customerService: CustomerService,
    private settings: SettingService,
    private router: Router) { }

  ngOnInit(): void {
    this.authService.user$.subscribe(u => {
      if (u) this.user = u;
    });
    this.customerService.customerGuid$.subscribe(c => {
      if (c) {
        this.customerGuid = c;
      }
    });
  }

  async doUserMenu(action: string){
    this.itemClicked.emit();
    switch (action) {
      case 'admin':
        var adminUrl = `${await this.settings.adminAppUrl()}dashboard/${this.customerGuid}`;
        window.location.href = adminUrl;
        break;
      case 'providers': 
        window.location.href = `${await this.settings.adminAppUrl()}`;
        break;
      case 'cs':
        this.router.navigate([`/`]);
        break;
      case 'logout':
        this.authService.logout();
        break;
      case 'profile':
        this.router.navigate([`my-profile`]);
        break;
      // case 'bop':
      //   var bopUrl = `${await this.settings.bopAppUrl()}home/${this.customerGuid}`;
      //   window.location.href = bopUrl;
      //   break;
      default:
      // how did I get here?
    }
  }
}
