import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { emailRegex } from 'src/app/services/util.service';

@Component({
  selector: 'app-edit-email-modal',
  templateUrl: './edit-email-modal.component.html',
  styleUrls: ['./edit-email-modal.component.scss']
})
export class EditEmailModalComponent implements OnInit {

  email = new FormControl('', [Validators.pattern(emailRegex), Validators.required]);
  
  constructor(
    public dialogRef: MatDialogRef<EditEmailModalComponent>,
  ) { }

  ngOnInit(): void {
  }

  onClick(action: string){
    if (action === 'confirmed'){
      if (this.email.valid){
        this.dialogRef.close(this.email.value);
      }else{
        this.email.markAllAsTouched();
      }
    }else{
      this.dialogRef.close(action);
    }
  }

}
