import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {faCheckCircle} from '@fortawesome/free-solid-svg-icons';
import {IconProp} from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss']
})
export class SuccessComponent implements OnInit {

  @Output() okClick = new EventEmitter();
  @Input() header: string = '';
  @Input() message: string = '';

  iconCheck = faCheckCircle as IconProp;
  constructor() { }

  ngOnInit(): void {
  }

}
