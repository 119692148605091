import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedIds } from 'src/app/models/activated-ids';
import { PostCommunicationMessageModel } from 'src/app/models/communications/send-message-model';
import { CustomerInfoLoanModel } from 'src/app/models/customer-info-loan-model';
import { ApiService } from 'src/app/services/api.service';
import { CustomerService } from 'src/app/services/customer.service';
import { ToastService } from 'src/app/services/toast.service';
import { TargetMessageDetails } from 'src/app/models/communications/message-model';

@Component({
  selector: 'app-comm-sms',
  templateUrl: './comm-sms.component.html',
  styleUrls: ['./comm-sms.component.scss']
})
export class CommSmsComponent implements OnInit, OnChanges {

  @Output() onBack = new EventEmitter();
  @Input() ids: ActivatedIds = new ActivatedIds;
  @Input() customerInfo: CustomerInfoLoanModel = {} as CustomerInfoLoanModel;
  @Input() messageTemplateGuid: any = null;

  smsTo: any;
  smsMsg: string | null = null;
  customerName: string = '';
  replyGuid: string | null = null;
  disableTo: boolean = false;

  defaultMessageTypeId: number = 4;
  isTemplate:boolean = false;
  templateName: string = '';
  smsState: string = 'New';

  constructor(
    private customerService: CustomerService,
    private apiService: ApiService,
    private toastService: ToastService
  ) { }

  ngOnInit(): void {
    if (this.customerInfo && this.customerInfo.phone) {
      this.smsTo = `${this.customerInfo.phone} (${this.customerInfo.firstName} ${this.customerInfo.lastName})`;
      this.disableTo = true;
    }
    this.smsState = this.replyGuid ? 'Reply to' : 'New';

    this.checkGetMessageTemplate();    
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['messageTemplateGuid']) {
      this.smsMsg = null;
      this.checkGetMessageTemplate();
    }
  }

  checkGetMessageTemplate() { 

    if (this.messageTemplateGuid) {
      let custSub = this.customerService.getMessageTemplate(this.messageTemplateGuid)
      .subscribe({
        next: (res: any) => {
          if (res && res.body && res.body.length) {
            this.smsMsg = res.body;
            this.isTemplate = true;
            this.templateName = res.subject;
          }
        },
        error: (err: any) => { console.error(err); },
        complete: () => { custSub.unsubscribe(); }
      })
    }
    else {
      this.smsMsg = null;
      this.isTemplate = false;
    }
  }
  
  resetForm() {
    this.smsTo = '';
    this.smsMsg = null;
  }

  setupReply(replyMsg: TargetMessageDetails) {
    this.replyGuid = replyMsg.messageGUID;
    this.smsTo = replyMsg.sMSPhoneNumber;
  }

  sendSMS() {
    let commMsg = new PostCommunicationMessageModel();
    commMsg.targetGuid = this.ids.targetGuid || null;
    commMsg.campaignGuid = this.ids.campaignGuid || null;
    commMsg.customerGuid = this.ids.customerGuid || null;
    commMsg.messageTypeId = this.defaultMessageTypeId;
    commMsg.messageCategoryId = this.getMessageCategory();
    commMsg.isDraft = false;
    commMsg.smsMessage = this.smsMsg || '';
    commMsg.smsPhoneNumber = this.customerInfo.phone;
  
    let postUrl: string = 'communications/message-post';
    if (this.replyGuid) {
      commMsg.replyToMessageGuid = this.replyGuid;
      postUrl = 'communications/message-reply';      
    }

    let msgSub = this.apiService.post(postUrl, commMsg)
    .subscribe({
      next: () => {
        this.toastService.addSuccess('Message will be sent shortly.');
        this.back();
        },
      error: (err: any) => { 
        this.toastService.addError('Unable to send/save message. See log for details.');
        console.error(err);
      },
      complete: () => { msgSub.unsubscribe(); }
    });


  }  

  getMessageCategory(): number {
    return 1;
  }

  back() {
    this.replyGuid = null;
    this.onBack.emit();
  }

  discard() {
    this.customerInfo = {} as CustomerInfoLoanModel;
    this.smsMsg = null;
    this.back();
  }
}
