<h1 mat-dialog-title>Address Correction</h1>
<div mat-dialog-content>
    <p class="m-0">Address Entered:</p>
    <p class="mb-4"><strong>{{data.addressEntered}}</strong></p>
    <p class="m-0">Please choose the correct suggested address:</p>
    <p class="mb-4">
        <mat-radio-group class="addr-radio-group" [(ngModel)]="data.correction">
            <mat-radio-button class="addr-radio-button" [value]="data.correction">
                <strong>{{data.correction}}</strong>
            </mat-radio-button>
        </mat-radio-group>
    </p>
</div>
<div mat-dialog-actions>
    <button pButton (click)="onClick('back')" class="p-button p-button-secondary" >Back</button>
    <span class="px-2"></span>
    <button pButton (click)="onClick('confirmed')" class="p-button">CONFIRM</button>
</div>