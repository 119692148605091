import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedIds } from 'src/app/models/activated-ids';
import { ScheduledPaymentModel } from 'src/app/models/csr/scheduled-payment-model';
import { LookupModel } from 'src/app/models/lookup-model';
import { TargetAccountSummary } from 'src/app/models/target-account-summary';
import { ApiService } from 'src/app/services/api.service';
import { MainService } from 'src/app/services/main.service';

@Component({
  selector: 'app-account-servicing-option-one',
  templateUrl: './account-servicing-option-one.component.html',
  styleUrls: ['./account-servicing-option-one.component.scss']
})
export class AccountServicingOptionOneComponent {

  @Input() accountSummary: TargetAccountSummary = new TargetAccountSummary();
  @Input() scheduledPayments: ScheduledPaymentModel[] = [];
  @Input() lateFees: string = '';
  @Input() ids: ActivatedIds = {};
  @Input() paymentOptions: any[] = [];
  @Input() processors: LookupModel[] = [];
  @Input() transactionHistory: any[] = [];
  @Input() upcomingPayments: any[] = [];

  @Output() getAccountSumm = new EventEmitter();

  showPayments: boolean = false;
  showScheduleEdit = false;

  rows: number = 15;
  first: number = 0;

  constructor(
    // private activatedRoute: ActivatedRoute,
    private apiService: ApiService,
    // private guidService: GuidService,
    // private userService: UserService,
    private main: MainService,
    // private customerService: CustomerService, 
    // config: NgbCarouselConfig
  ) {


  }
  sendSms() {
    this.main.showYesNoPopUp(null, 'Send "Make Payment" link (SMS)?', 'SEND').afterClosed().subscribe(result => {
      if (result && result == 'yes') {
        this.sendMakePayment('0');
      }
    });
  }

  sendEmail() {
    this.main.showYesNoPopUp(null, 'Send "Make Payment" link (Email)?', 'SEND').afterClosed().subscribe(result => {
      if (result && result == 'yes') {
        this.sendMakePayment('1');
      }
    });
  }

  copyLink() {
    this.main.showSnackBar("Link copied to clipboard");
  }

  makePayment() {
    this.showScheduleEdit = true;
  }

  onBack(updated: boolean) {
    this.showScheduleEdit = false;
    if (updated) {
      this.getAccountSumm.emit();
    }
  }

  // showLoanAreementPdf(){
  //   window.open(this.accountSummary.loanAgreementPdfUrl, '_blank');
  // }
  // showWelcomePacket() {
  //   window.open(this.accountSummary.welcomePacketPDFUrl, '_blank'); 
  // }
  //
  // onSlid() {
  //   if (this.showScheduleEdit) {
  //     this.showSchedule = false;
  //   } else {
  //     this.showScheduleEdit = false;
  //   }
  // }


  private sendMakePayment(sendEmail: string) {
    this.main.showLoading();
    var model = {
      CustomerGuid: this.ids.customerGuid,
      CampaignGuid: this.ids.campaignGuid,
      TargetGuid: this.ids.targetGuid,
      Value: sendEmail
    }
    var body = JSON.stringify(model);
    this.apiService.post(`csr/send-make-payment`, body)
      .subscribe((res: any) => {
        this.main.dismissLoading();
        var success = sendEmail === '1' ? "Sending Payment Link (email) message will be completed soon!" : "Sending Payment Link (SMS) message will be completed soon!";
        this.main.showSuccessModal("Success!", success);
      },
        (err: any) => {
          this.main.dismissLoading();
          this.main.showSnackBar("Ops. Error happened while sending");
        }
      );
  }

}
