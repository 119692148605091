<div class="app-error text-center">
  <div class="text-error">
    <fa-icon
      [icon]="iconTimes"
      size="5x"
      [classes]="['m-3 text-error']"
    ></fa-icon>
  </div>
  <p class="h3 mb-3">{{ data.header }}</p>
  <p class="text-l font-light mb-4">{{ data.message }}</p>
  <div class="Mm-3">
    <button pButton class="p-button" (click)="cancel()">OK</button>
  </div>
</div>
