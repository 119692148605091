import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationService, ConfirmEventType, MenuItem } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel';
import { forkJoin } from 'rxjs';
import { CustomerNotesComponent } from 'src/app/components/customer-notes/customer-notes.component';
import { CollectionInfo, PaymentDefer, TargetInfo } from 'src/app/models/csr/agent-tools';
import { CustomerModel } from 'src/app/models/customer-model';
import { ApiService } from 'src/app/services/api.service';
import { CustomerService } from 'src/app/services/customer.service';
import { GuidService } from 'src/app/services/guid.service';
import { HelperService } from 'src/app/services/helper.service';
import { ToastService } from 'src/app/services/toast.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-customer-collections',
  templateUrl: './customer-collections.component.html',
  styleUrls: ['./customer-collections.component.scss']
})
export class CustomerCollectionsComponent implements OnInit {

  showPromiseToPay: boolean = false;
  showTotalCurrentDueSection: boolean = false;
  showTotalPastDueSection: boolean = false;

  DeferConfirmKey: string = 'AgntToolDeferConfKey';

  customerGuid: string | null = null;
  campaignGuid: string | null = null;
  targetGuid: string | null = null;

  customer: CustomerModel | null = null;
  collectionsInfo: CollectionInfo | null = null;
  targetInfo: TargetInfo | null = null;
  defers: PaymentDefer[] = [];
  bcItems: MenuItem[] = [];
  selectedDefers: PaymentDefer[] = [];

  defer_PPal: number = 0;
  defer_CAF: number = 0;
  defer_FFC: number = 0;

  ptpMinDate: Date = new Date();
  ptpMaxDate: Date = new Date();
  promiseToPayDate: Date | null = null;

  @ViewChild('custNotes') custNotes: CustomerNotesComponent = {} as CustomerNotesComponent;
  @ViewChild('opDefer') opDefer: OverlayPanel = {} as OverlayPanel;

  currencyPipe = new CurrencyPipe('en-US');
  datePipe = new DatePipe('en-US');

  private parser = new DOMParser();

  constructor(
    private activatedRoute: ActivatedRoute, 
    private customerService: CustomerService,
    private confirmService: ConfirmationService,
    private helperService: HelperService,
    private guidService: GuidService,
    private userService: UserService,
    private toastService: ToastService,
    private apiService: ApiService
    ) { }

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe(async paramMap => {
      let cGuid = paramMap.get('customerGuid')?.toString() ?? '';
      if (this.guidService.isValidGuid(cGuid)) {
        this.customerGuid = cGuid;
        this.customerService.initCustomer(this.customerGuid);
        await this.userService.subscribeAccess();
        let data = this.activatedRoute.snapshot.data;
        if (data && data['pageId']) {
          this.userService.checkPageAccess(data['pageId']);
        }        
      }
      let campGuid = paramMap.get('campaignGuid')?.toString() ?? '';
      let tGuid = paramMap.get('targetGuid')?.toString() ?? '';
      if (this.guidService.isValidGuid(campGuid)) this.campaignGuid = campGuid;
      if (this.guidService.isValidGuid(tGuid)) this.targetGuid = tGuid;
      this.customerService.customer$.subscribe(cust => {
        this.customer = cust;        
      })
      this.bcItems = [
        { label: 'Agent Tools', routerLink: [`/agent-tools/${this.customerGuid}/${this.campaignGuid}/${this.targetGuid}`]},
        { label: 'Collections' }
      ];

      this.getData();
    });
    
  }

  getData() {
    const fSub = forkJoin({
      collInfo: this.apiService.get(`agenttools/collections-info/${this.customerGuid}/${this.campaignGuid}/${this.targetGuid}`),
      targetInfo: this.apiService.get(`agenttools/target-info/${this.customerGuid}/${this.campaignGuid}/${this.targetGuid}`),
      defers: this.apiService.get(`agenttools/payments-defer/${this.customerGuid}/${this.campaignGuid}/${this.targetGuid}`),
    })
    .subscribe({
      next: (data: any) => { 
        this.collectionsInfo = data.collInfo;
        this.targetInfo = data.targetInfo;
        this.defers = data.defers;
        this.initPage();
      },
      error: (err: any) => {
        this.toastService.addError("Unable to get initial Collections data. See log for details.");
        console.error(err);
      },
      complete: () => { fSub.unsubscribe(); }
    });
  }

  initPage() {
    if (this.targetInfo?.promiseToPayDate) this.promiseToPayDate = new Date(this.targetInfo.promiseToPayDate);
    this.showPromiseToPay = this.targetInfo?.promiseToPay ?? false;

    this.ptpMinDate = new Date();
    let today = new Date();
    this.ptpMaxDate.setDate(today.getDate() + 30);

    this.showTotalCurrentDueSection = !this.helperService.getBoolean(this.targetInfo?.hideTotalCurrentDueSection);
    this.showTotalPastDueSection = !this.helperService.getBoolean(this.targetInfo?.hideTotalPastDueSection);
  }

  getMoney(value: string): string {
    let retVal: string = "";
    let amt: number = 0;
    if (value && value.length > 0 && !isNaN(+value)) {
      amt = +value;
    }
    retVal = amt.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    return retVal;
  }

  savePromiseToPayDate() {
    let body = {
      customerGuid: this.customerGuid, 
      campaignGuid: this.campaignGuid, 
      targetGuid: this.targetGuid,
      promiseToPay: this.promiseToPayDate
    };

    let ptpSub = this.apiService.post(`agenttools/promise-to-pay/save`, body)
    .subscribe({
      next: () => { 
        this.toastService.addSuccess('Promise to Pay Date successfully saved.');
        this.promiseToPayDate = null;
        this.getData();
        this.custNotes.reload();
      },
      error: (err: any) => { 
        this.toastService.addError('Unable to save Promise to Pay Date. See log for details');
        console.error(err);
     },
      complete: () => { ptpSub.unsubscribe(); },
    });

  }

  removePromiseToPay() {
    let body = {
      customerGuid: this.customerGuid, 
      campaignGuid: this.campaignGuid, 
      targetGuid: this.targetGuid
    };

    let ptpSub = this.apiService.post(`agenttools/promise-to-pay/remove`, body)
    .subscribe({
      next: () => { 
        this.toastService.addSuccess('Promise to Pay Date successfully removed.');
        this.promiseToPayDate = null;
        this.getData();
        this.custNotes.reload();
      },
      error: (err: any) => { 
        this.toastService.addError('Unable to remove Promise to Pay Date. See log for details');
        console.error(err);
     },
      complete: () => { ptpSub.unsubscribe(); },
    });
  }

  confirmPromiseToPay() {
    let body = {
      customerGuid: this.customerGuid, 
      campaignGuid: this.campaignGuid, 
      targetGuid: this.targetGuid
    };

    let ptpSub = this.apiService.post(`agenttools/promise-to-pay/confirm`, body)
    .subscribe({
      next: () => { 
        this.toastService.addSuccess('Promise to Pay Date confirmed.');
        this.promiseToPayDate = null;
        this.getData();
        this.custNotes.reload();
      },
      error: (err: any) => { 
        this.toastService.addError('Unable to confirm Promise to Pay Date. See log for details');
        console.error(err);
     },
      complete: () => { ptpSub.unsubscribe(); },
    });
  }

  showOpDefer(event: any, defer: PaymentDefer) {
    this.defer_CAF = defer.cafAmount;
    this.defer_FFC = defer.ffcAmount;
    this.defer_PPal = defer.pPalAmountPPal;
    this.opDefer.show(event);
  }

  toggleOpDefer(event: any, defer: PaymentDefer) {
    this.defer_CAF = defer.cafAmount;
    this.defer_FFC = defer.ffcAmount;
    this.defer_PPal = defer.pPalAmountPPal;
    this.opDefer.toggle(event);
  }

  formatDate(dt: any): string {
    let tempDt: Date = new Date(dt);
    let fmtDt = this.datePipe.transform(tempDt, 'MM/dd/yyy');
    return fmtDt ?? '';
  }

  getSelectedDeferDates() {
    let strDates: string[] = [];
    this.selectedDefers.forEach(d => {      
      if (d.displayDate) strDates.push(d.displayDate);
    });
    return strDates.join(', ');
  }

  confirmDefers() {
    let msg = `
    <div>
      <p>I want to confirm that today ${this.formatDate(new Date())} you, ${this.targetInfo?.fullName}, are requesting to defer your payment(s) originally schedule to process on ${this.getSelectedDeferDates()}.</p>
      <p>Please note that if any previously made payments are returned to us, they will be due immediately and not considered a part of this agreement.</p>
    </div>`;
    let doc = this.parser.parseFromString(msg, 'text/html');
    const serializer = new XMLSerializer();
    let confMsg = serializer.serializeToString(doc);
    this.confirmService.confirm({
      key: this.DeferConfirmKey,
      message: confMsg,
      header: 'Confirm Deferral',
      rejectLabel: 'Back',
      rejectButtonStyleClass: 'p-button-outlined',
      acceptLabel: 'Confirm',
      accept: () => {
        this.saveDefers();
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.toastService.add({ severity: 'warn', summary: 'Declined', detail: 'Payment(s) will not be deferred.' });
            break;
          case ConfirmEventType.CANCEL:
            this.toastService.add({ severity: 'warn', summary: 'Cancelled', detail: 'Operation cancelled.' });
            break;
        }
      }
    });
  }

  saveDefers() {
    let doc = document.implementation.createDocument('', 'payments');
    let payments = doc.querySelector('payments');
    this.selectedDefers.forEach(defer => {   
        let childNode = doc.createElement('p');
    
        childNode.setAttribute('acctrefno', defer.acctrefno.toString());
        childNode.setAttribute('CycleNumber', defer.cycleNumber.toString());
        childNode.setAttribute('BillingDate', this.formatDate(defer.billingDate));
        childNode.setAttribute('BillingDateDue', this.formatDate(defer.billingDateDue));
        childNode.setAttribute('DateDue', defer.dateDue ? this.formatDate(defer.dateDue) : '');
        childNode.setAttribute('PaymentRemaining', defer.paymentRemaining.toString());

        childNode.innerHTML = defer.trans;
        
        payments?.appendChild(childNode);
      
    });    
    const serializer = new XMLSerializer();      
    let paymentXml = serializer.serializeToString(doc);

    let body = {
      customerGuid: this.customerGuid, 
      campaignGuid: this.campaignGuid, 
      targetGuid: this.targetGuid,
      paymentXml
    };

    let defSub = this.apiService.post(`agenttools/payments/defer`, body)
    .subscribe({
      next: () => { 
        this.toastService.addSuccess('Payment(s) successfully deferred.');
        this.getData();
        this.custNotes.reload();
      },
      error: (err: any) => { 
        this.toastService.addError('Possible error deferring payment. See log for details');
        console.error(err);
        this.getData();
        this.custNotes.reload();
     },
      complete: () => { defSub.unsubscribe(); },
    });
  }

  reloadNotes() {
    this.custNotes.reload();
  }

}
