import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { start } from 'repl';
import { LookupModel } from 'src/app/models/lookup-model';
import { LookupService } from 'src/app/services/lookup.service';

@Component({
  selector: 'app-pay-calendar-form',
  templateUrl: './pay-calendar-form.component.html',
  styleUrls: ['./pay-calendar-form.component.scss']
})
export class PayCalendarFormComponent implements OnInit {
  @Output() datesCalculated: EventEmitter<Date[]> = new EventEmitter<Date[]>();
  @Input() payForm: FormGroup = new FormGroup({});
  @Input() strDateFormat: string = 'fullDate';

  payFormLoaded: boolean = false;

  payFrequencies: LookupModel[] = [];
  daysOfWeek: LookupModel[] = [];
  semiMonthDays: LookupModel[] = [];
  twoMonthOther1: LookupModel[] = [];
  twoMonthOther2: LookupModel[] = [];
  twoMonthOtherWhen: LookupModel[] = [];
  days: LookupModel[] = [];
  monthWhens: LookupModel[] = [];
  next2WeekPayDates: LookupModel[] = [];

  datePipe = new DatePipe('en-US');

  constructor(
    private fb: FormBuilder,
    private lookupService: LookupService
  ) { }

  ngOnInit(): void {
    this.setUpdatePaymentDD();    
    this.initPayForm();
  }

  initPayForm() {

    this.payForm = this.fb.group({
      payFrequency: [null, Validators.required],
      dayOfWeek: [null],
      twoWeekNext: [null],
      semiMonthDay: [null],
      twoMonthOther: [null],
      twoMonthOtherDate1: ['1'],
      twoMonthOtherDate2: ['15'],
      twoMonthOtherWhen: ['13'],
      twoMonthOtherDay: ['0'],
      radioMonth: [null],
      monthDay: ['1'],
      monthWhen: ['1'],
      monthWhenWeekday: ['0']
    });
    this.payFormLoaded = true;

    this.payForm.controls.twoWeekNext.valueChanges.subscribe(val => {
      if (val != null || val != undefined) {
        setTimeout(() => {
          this.calculatePerWeeks(14, true, val);
        });
      }      
    });

    this.payForm.controls.twoMonthOther.valueChanges.subscribe(val => {
      if (val != null || val != undefined) {
        setTimeout(() => {
          this.calculateSemiMonthly(); 
        });
      }
    });

    this.payForm.controls.radioMonth.valueChanges.subscribe(val => {
      if (val != null || val != undefined) {
        setTimeout(() => {
          this.calculateMonthly(); 
        });
      }
    });
  }

  setUpdatePaymentDD() {
    this.daysOfWeek = this.lookupService.getDaysOfWeekNum();
    this.payFrequencies = [
      { id: 'week', desc: 'Weekly' },
      { id: '2week', desc: 'Bi-Weekly' },
      { id: '2month', desc: 'Semi-Monthly' },
      { id: 'month', desc: 'Monthly' }
    ];
    this.semiMonthDays = [
      { id: '2month1st', desc: 'The 1st and 15th of each month' },
      { id: '2month15th', desc: 'The 15th and last day of each month' },
      { id: '2monthother', desc: 'Other' }
    ];
    this.twoMonthOther1 = [
      { id: '1', desc: '1st' },
      { id: '2', desc: '2nd' },
      { id: '3', desc: '3rd' },
      { id: '4', desc: '4th' },
      { id: '5', desc: '5th' },
      { id: '6', desc: '6th' },
      { id: '7', desc: '7th' },
      { id: '8', desc: '8th' },
      { id: '9', desc: '9th' },
      { id: '10', desc: '10th' },
      { id: '11', desc: '11th' },
      { id: '12', desc: '12th' },
      { id: '13', desc: '13th' },
      { id: '14', desc: '14th' },
      { id: '15', desc: '15th' },
      { id: '16', desc: '16th' }
    ];
    this.twoMonthOther2 = [
      { id: '15', desc: '15th' },
      { id: '16', desc: '16th' },
      { id: '17', desc: '17th' }
    ];
    this.twoMonthOtherWhen = [
      { id: '13', desc: 'First and Third' },
      { id: '24', desc: 'Second and Fourth' },
    ];
    this.monthWhens = [
      { id: '1', desc: 'First' },
      { id: '2', desc: 'Second' },
      { id: '3', desc: 'Third' },
      { id: '4', desc: 'Fourth' }
    ];

    for (let i: number = 1; i < 31; i++) {
      let strI = i.toString();
      let lastChar = strI.substring(strI.length - 1);
      let suffix = lastChar == '1' ? 'st' :
        lastChar == '2' ? 'nd' :
          lastChar == '3' ? 'rd' : 'th'
      this.days.push({ id: strI, desc: `${strI}${suffix}` });
    }
    this.days.push({ id: '31', desc: 'EOM' });
  }


  payFrequencyChanged(freq: string) {
    this.next2WeekPayDates = [];
    this.datesCalculated.emit([]);
    this.payForm.controls.dayOfWeek.clearValidators();
    this.payForm.controls.dayOfWeek.reset();
    this.payForm.controls.twoWeekNext.clearValidators();
    this.payForm.controls.twoWeekNext.reset();
    this.payForm.controls.semiMonthDay.clearValidators();
    this.payForm.controls.semiMonthDay.reset();
    this.payForm.controls.twoMonthOther.clearValidators();
    this.payForm.controls.twoMonthOther.reset();
    this.payForm.controls.twoMonthOtherDate1.clearValidators();
    this.payForm.controls.twoMonthOtherDate1.reset();
    this.payForm.controls.twoMonthOtherDate2.clearValidators();
    this.payForm.controls.twoMonthOtherDate2.reset();
    this.payForm.controls.twoMonthOtherWhen.clearValidators();
    this.payForm.controls.twoMonthOtherWhen.reset();
    this.payForm.controls.twoMonthOtherDay.clearValidators();
    this.payForm.controls.radioMonth.clearValidators();
    this.payForm.controls.radioMonth.reset();

    switch (freq) {
      case 'week':
        this.payForm.controls.dayOfWeek.setValidators([Validators.required]);
        break;
      case '2week':
        this.payForm.controls.dayOfWeek.setValidators([Validators.required]);
        this.payForm.controls.twoWeekNext.setValidators([Validators.required]);
        break;
        break;
      case '2month':
        this.payForm.controls.semiMonthDay.setValidators([Validators.required]);
        break;
      case 'month':
        this.payForm.controls.radioMonth.setValidators([Validators.required]);
        break;

      default:
        break;
    };

    this.payForm.patchValue({
      twoMonthOtherDate1: '1',
      twoMonthOtherDate2: '15',
      twoMonthOtherWhen: '13',
      twoMonthOtherDay: '0',
      monthDay: '1',
      monthWhen: '1',
      monthWhenWeekday: '0'
    });

    this.payForm.updateValueAndValidity();

  }

  dayOfWeekChanged(dayOfWeek: string) {
    this.datesCalculated.emit([]);
    this.payForm.controls.twoWeekNext.reset()
    this.payForm.updateValueAndValidity();
    if (this.payForm.value.payFrequency == 'week') {
      setTimeout(() => {
        this.calculateDays();      
      });
    }
    else {
      setTimeout(() => {
        this.calculatePerWeeks(14, false);        
      });
    }
      
  }

  semiMonthDayChanged(monthDay: string) {
    this.datesCalculated.emit([]);
    this.payForm.controls.twoMonthOther.clearValidators();
    if (monthDay == '2monthother') {
      this.payForm.controls.twoMonthOther.setValidators([Validators.required]);
      this.payForm.controls.twoMonthOther.reset();
    }
    this.payForm.updateValueAndValidity();
    if (monthDay != '2monthother') {
      setTimeout(() => {
        this.calculateSemiMonthly();        
      });
    }
  }

  twoMonthOther1Changed(day: string) {
    let numDay = +day;
    let day1 = numDay + 14;
    let day2 = numDay + 15;
    let day3 = numDay + 16;
    this.twoMonthOther2 = [];
    this.twoMonthOther2.push({ id: day1.toString(), desc: day1 == 31 ? 'EOM' : day1 == 21 ? '21st' : day1 == 22 ? '22nd' : `${day1.toString()}th` });
    if (day2 < 32) this.twoMonthOther2.push({ id: day2.toString(), desc: day2 == 31 ? 'EOM' : (day2 == 21 ? '21st' : (day2 == 22 ? '22nd' : day2 + 'th')) });
    if (day3 < 32) this.twoMonthOther2.push({ id: day3.toString(), desc: day3 == 31 ? 'EOM' : (day3 == 21 ? '21st' : (day3 == 22 ? '22nd' : day3 + 'th')) });

    setTimeout(() => {
      this.payForm.patchValue({
        twoMonthOtherDate2: day1.toString()
      });
      this.payForm.updateValueAndValidity();
      setTimeout(() => {
        this.twoMonthOtherChanged();
      });
    });
  }

  twoMonthOtherChanged() {
    setTimeout(() => {
      if (this.payForm.value.twoMonthOther == '1') this.calculateSemiMonthly(); 
    });
  }

  twoMonthOther2Changed() {
    setTimeout(() => {
      if (this.payForm.value.twoMonthOther == '2') this.calculateSemiMonthly(); 
    });
  }

  monthDayChanged() {
    setTimeout(() => {
      if (this.payForm.value.radioMonth == '1') this.calculateMonthly();      
    });
  }

  monthDay2Changed() {
    setTimeout(() => {
      if (this.payForm.value.radioMonth == '2') this.calculateMonthly();      
    });
  }

  calculateDays() {
    let freq = this.payForm.value.payFrequency;
    switch (freq) {
      case 'week':
        this.calculatePerWeeks(7);
        break;
      case '2week':
        this.calculatePerWeeks(14);
        break;
      case '2month':
        this.calculateSemiMonthly();
        break;
      case 'month':
        this.calculateMonthly();
        break;
    };
  }

  calculatePerWeeks(skipDays: number, emitDates: boolean = true, startDate: string = '') {
    let strtDate: Date = new Date();
    if (startDate.length > 0) {
      strtDate = new Date(startDate);
    }

    let today = new Date();
    let currDay = today.getDay();
    let dayOfWeek: number = +this.payForm.value.dayOfWeek;
    let d1 = new Date();
    let dayAdd: number = 0;
    if (currDay > dayOfWeek) {
      dayAdd = (6 - currDay) + dayOfWeek + 1;
      d1.setDate(today.getDate() + dayAdd);
    }
    else {
      dayAdd = dayOfWeek - currDay;
      d1.setDate(today.getDate() + dayAdd);;
    }

    if (startDate.length > 0) {
      d1 = new Date(strtDate.getTime());
    }

    let d2 = new Date();
    d2.setDate(d1.getDate() + skipDays);
    let d3 = new Date();
    d3.setDate(d2.getDate() + skipDays);

    if (emitDates) {
      let nextPayDates: Date[] = [];
      nextPayDates.push(d1);
      nextPayDates.push(d2);
      nextPayDates.push(d3);
      this.datesCalculated.emit(nextPayDates);
    }
    else  {
      this.next2WeekPayDates = [];
      this.next2WeekPayDates.push({ id: this.datePipe.transform(d1, 'MM/dd/yyyy') ?? 'Calculation Error', desc: this.datePipe.transform(d1, 'fullDate') ?? 'Calculation Error'});
      this.next2WeekPayDates.push({ id: this.datePipe.transform(d2, 'MM/dd/yyyy') ?? 'Calculation Error', desc: this.datePipe.transform(d2, 'fullDate') ?? 'Calculation Error'});
    }
  }

  calculateSemiMonthly() {
    let temp = new Date();
    let today = new Date(temp.getFullYear(), temp.getMonth(), temp.getDate(), 12, 0, 0, 0);
    let currDay = today.getDate();
    let currMonth = today.getMonth();
    let semiMonthDay = this.payForm.value.semiMonthDay;
    let d1 = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 12, 0, 0, 0);
    let d2 = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 12, 0, 0, 0);
    let d3 = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 12, 0, 0, 0);
    
    if (semiMonthDay == '2month1st') {
      if (currDay > 1 && currDay <= 15) {
        d1.setDate(15);
        d2.setMonth(d1.getMonth() + 1);
        d2.setDate(1);
        d3.setMonth(d1.getMonth() + 1);
        d3.setDate(15);
      }
      else if (currDay > 15) {
        d1.setMonth(currMonth++);
        d1.setDate(1);
        d2.setMonth(d1.getMonth());
        d2.setDate(15);
        d3.setMonth(d2.getMonth() + 1);
        d3.setDate(1);
      }
      else {
        d2.setDate(15);
        d3.setMonth(d2.getMonth() + 1);
        d3.setDate(1);
      }
    }
    else if (semiMonthDay == '2month15th') {
      if (currDay <= 15) {
        d1.setDate(15);
        d2 = new Date(d1.getFullYear(), d1.getMonth() + 1, 0);
        d3.setMonth(d2.getMonth() + 1);
        d3.setDate(15);
      }
      else {
        d1 = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        d2.setMonth(d1.getMonth() + 1);
        d2.setDate(15);
        d3 = new Date(d2.getFullYear(), d2.getMonth() + 1, 0);
      }
    }
    else {
      let twoMonthOther = this.payForm.value.twoMonthOther;
      if (twoMonthOther == '1') {
        let twoMonthOtherDate1 = +this.payForm.value.twoMonthOtherDate1;
        let twoMonthOtherDate2 = +this.payForm.value.twoMonthOtherDate2;
        if (twoMonthOtherDate1 <= currDay) {
          d1.setDate(twoMonthOtherDate1);
          d3.setMonth(d1.getMonth() + 1);
          d3.setDate(twoMonthOtherDate1);
          if (twoMonthOtherDate2 == 31) {
            d2 = new Date(d1.getFullYear(), d1.getMonth() + 1, 0);
          }
          else {
            d2.setDate(twoMonthOtherDate2);
          }
        }
        else {
          if (twoMonthOtherDate2 == 31) {
            d1 = new Date(d1.getFullYear(), d1.getMonth() + 1, 0);
            d3 = new Date(d1.getFullYear(), d1.getMonth() + 2, 0);
          }
          else {
            d1.setDate(twoMonthOtherDate2);
            d3.setDate(twoMonthOtherDate2);
            d3.setMonth(d1.getMonth() + 1);
          }
          d2.setMonth(d1.getMonth() + 1);
          d2.setDate(twoMonthOtherDate1);
        }

      }
      else {
        let otherWhen = +this.payForm.value.twoMonthOtherWhen;
        let otherDay = +this.payForm.value.twoMonthOtherDay
        let thisMonth = new Date(today.getFullYear(), today.getMonth(), 1);
        let thisMonthDay = thisMonth.getDay();

        let dayAdd: number = 0;
        if (thisMonthDay > otherDay) {
          dayAdd = (6 - thisMonthDay) + otherDay + 1;
          d1.setDate(today.getDate() + dayAdd);
        }
        else {
          dayAdd = otherDay - thisMonthDay;
          d1.setDate(today.getDate() + dayAdd);;
        }

        thisMonth.setDate(thisMonth.getDate() + dayAdd);

        if (otherWhen == 24) {
          thisMonth.setDate(thisMonth.getDate() + 7);
        }

        if (thisMonth.getDate() < today.getDate()) {
          thisMonth.setDate(thisMonth.getDate() + 14);
          if (thisMonth.getDate() < today.getDate()) {
            thisMonth.setDate(thisMonth.getDate() + 14);
          }
        }

        d1 = new Date(thisMonth.getFullYear(), thisMonth.getMonth(), thisMonth.getDate());
        d2 = new Date(thisMonth.getFullYear(), thisMonth.getMonth(), thisMonth.getDate());
        d3 = new Date(thisMonth.getFullYear(), thisMonth.getMonth(), thisMonth.getDate());
        d2.setDate(d1.getDate() + 14);
        d3.setDate(d2.getDate() + 14);

      }

    }

    let nextPayDates: Date[] = [];
    nextPayDates.push(d1);
    nextPayDates.push(d2);
    nextPayDates.push(d3);
    this.datesCalculated.emit(nextPayDates);

  }

  calculateMonthly() {

    let today = new Date();
    let currDay = today.getDate();
    let d1 = new Date();
    let d2 = new Date();
    let d3 = new Date();
    let radioMonth = +this.payForm.value.radioMonth;

    if (radioMonth == 1) {
      let monthDay = +this.payForm.value.monthDay;
      if (monthDay == 31) {
        d1 = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        d2 = new Date(d1.getFullYear(), d1.getMonth() + 1, 0);
        d3 = new Date(d2.getFullYear(), d2.getMonth() + 1, 0);
      }
      else {
        d1.setDate(monthDay);
        d2.setDate(monthDay);
        d3.setDate(monthDay);
        if (currDay > monthDay) {
          d1.setMonth(d1.getMonth() + 1);
        }
        d2.setMonth(d1.getMonth() + 1);
        d3.setMonth(d2.getMonth() + 1);
      }

    }
    else {
      let monthWhenWeekday = +this.payForm.value.monthWhenWeekday;
      let thisMonth = new Date(today.getFullYear(), today.getMonth(), 1);
      let thisMonthDay = thisMonth.getDay();

      let dayAdd: number = 0;
      if (thisMonthDay > monthWhenWeekday) {
        dayAdd = (6 - thisMonthDay) + monthWhenWeekday + 1;
      }
      else {
        dayAdd = monthWhenWeekday - thisMonthDay;
      }

      thisMonth.setDate(thisMonth.getDate() + dayAdd);

      d1 = new Date(thisMonth.getFullYear(), thisMonth.getMonth(), thisMonth.getDate());
      if (d1.getDate() < currDay) {
        d1.setDate(d1.getDate() + 28);
        if (d1.getMonth() == today.getMonth()) d1.setDate(d1.getDate() + 7);
      }

      d2 = new Date(d1.getFullYear(), d1.getMonth(), d1.getDate());
      d3 = new Date(d1.getFullYear(), d1.getMonth(), d1.getDate());
      d2.setDate(d1.getDate() + 28);
      if (d2.getMonth() == d1.getMonth()) d2.setDate(d2.getDate() + 7);

      d3.setDate(d1.getDate() + 56);
      if (d3.getMonth() == d2.getMonth()) d3.setDate(d3.getDate() + 7);      
    }

    let nextPayDates: Date[] = [];
    nextPayDates.push(d1);
    nextPayDates.push(d2);
    nextPayDates.push(d3);
    this.datesCalculated.emit(nextPayDates);
  }

  getPayFrequencyString(): string | null {
    return null;
    // let payFreq: string = '';


    // payFreq += this.payForm.value.payFrequency;
    // if (this.payForm.value.payFrequency == 'week' || this.payForm.value.payFrequency == '2week') {
    //   let dow = this.daysOfWeek.find(d => d.id == this.payForm.value.dayOfWeek);
    //   if (dow) payFreq += `#${dow.desc}`

    //   if (this.payForm.value.payFrequency == '2week') {
    //     let idx = this.next2WeekPayDates.findIndex(n => n.id == this.payForm.value.twoWeekNext);
    //     if (idx) payFreq += `#2week_input_${(idx + 1)}`
    //   }

    // }

    // return payFreq;
  }
  
  
}
