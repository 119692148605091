import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { ActivatedIds } from 'src/app/models/activated-ids';
import { CustomerInfoLoanModel, CustomerLoanCardInfo } from 'src/app/models/customer-info-loan-model';
import { LookupModel } from 'src/app/models/lookup-model';
import { ApiService } from 'src/app/services/api.service';
import { LookupService } from 'src/app/services/lookup.service';
import { MainService } from 'src/app/services/main.service';
import { ToastService } from 'src/app/services/toast.service';
import { UserService } from 'src/app/services/user.service';
import { phoneRegex, ssnRegex } from 'src/app/services/util.service';
import { fieldChanged } from '../confirm-changes/confirm-changes.component';

@Component({
  selector: 'app-customer-info-edit',
  templateUrl: './customer-info-edit.component.html',
  styleUrls: ['./customer-info-edit.component.scss']
})
export class CustomerInfoEditComponent implements OnInit {
  @Output() updated = new EventEmitter<boolean>();
  
  @Input() customerInfo: CustomerInfoLoanModel = new CustomerInfoLoanModel;
  @Input() cardInfo: CustomerLoanCardInfo = new CustomerLoanCardInfo;

  loanFormLoaded: boolean = false;
  showEditCard: boolean = false;
  cardFormLoaded: boolean = false;
  showPayFreq: boolean = false;
  showPayFreq2: boolean = false;
  // payFreqFormLoaded: boolean = false;

  today: Date = new Date();

  loanForm: FormGroup = new FormGroup({});
  cardForm: FormGroup = new FormGroup({});
  payFreqForm: FormGroup = new FormGroup({});

  ids: ActivatedIds = {};
  states: LookupModel[] = [];
  incomeTypes: LookupModel[] = [];
  addIncomeTypes: LookupModel[] = [];
  // payFrequencies: LookupModel[] = [];
  // daysOfWeek: LookupModel[] = [];
  // semiMonthDays: LookupModel[] = [];
  // twoMonthOther1: LookupModel[] = [];
  // twoMonthOther2: LookupModel[] = [];
  // twoMonthOtherWhen: LookupModel[] = [];
  // days: LookupModel[] = [];
  // monthWhens: LookupModel[] = [];
  changes: fieldChanged[] = [];
  values: any[] = [];
  nextPayDates: string[] = [];

  datePipe = new DatePipe('en-US');
  constructor(
    private toastService: ToastService,
    private main: MainService,
    private api: ApiService,
    private fb: FormBuilder,
    private lookupService: LookupService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.states = this.lookupService.getStates();
    

    this.main.activatedIds$.subscribe((ids) => {
      if (ids && ids.targetGuid) {
        if (!this.ids || this.ids.targetGuid !== ids.targetGuid) {
          this.ids = ids;
        }

        this.api.get(`csr/lookup-data/${this.ids.customerGuid}`)
          .subscribe(lookups => {
            if (lookups && lookups.incomeTypes && lookups.incomeTypes.length) {
              this.incomeTypes = lookups.incomeTypes;
            }
            if (lookups && lookups.addIncomeTypes && lookups.addIncomeTypes.length) {
              this.addIncomeTypes = lookups.addIncomeTypes;
            }
            // this.setUpdatePaymentDD();
            this.initForm();
          }, err => {
            this.toastService.addError('Unable to get Lookup Tables. See log for details.');
            console.error(err);
          });
      }
    });
  }

  // setUpdatePaymentDD() {
  //   this.payFrequencies = [
  //     { id: 'week', desc: 'Weekly' },
  //     { id: '2week', desc: 'Bi-Weekly' },
  //     { id: '2month', desc: 'Semi-Monthly' },
  //     { id: 'month', desc: 'Monthly' }
  //   ];
  //   this.semiMonthDays = [
  //     { id: '2month1st', desc: 'The 1st and 15th of each month' },
  //     { id: '2month15th', desc: 'The 15th and last day of each month' },
  //     { id: '2monthother', desc: 'Other' }
  //   ];
  //   this.twoMonthOther1 = [
  //     { id: '1', desc: '1st' },
  //     { id: '2', desc: '2nd' },
  //     { id: '3', desc: '3rd' },
  //     { id: '4', desc: '4th' },
  //     { id: '5', desc: '5th' },
  //     { id: '6', desc: '6th' },
  //     { id: '7', desc: '7th' },
  //     { id: '8', desc: '8th' },
  //     { id: '9', desc: '9th' },
  //     { id: '10', desc: '10th' },
  //     { id: '11', desc: '11th' },
  //     { id: '12', desc: '12th' },
  //     { id: '13', desc: '13th' },
  //     { id: '14', desc: '14th' },
  //     { id: '15', desc: '15th' },
  //     { id: '16', desc: '16th' }
  //   ];
  //   this.twoMonthOther2 = [
  //     { id: '15', desc: '15th' },
  //     { id: '16', desc: '16th' },
  //     { id: '17', desc: '17th' }
  //   ];
  //   this.twoMonthOtherWhen = [
  //     { id: '13', desc: 'First and Third' },
  //     { id: '24', desc: 'Second and Fourth' },
  //   ];
  //   this.monthWhens = [
  //     { id: '1', desc: 'First' },
  //     { id: '2', desc: 'Second' },
  //     { id: '3', desc: 'Third' },
  //     { id: '4', desc: 'Fourth' }
  //   ];

  //   for (let i: number = 1; i < 31; i++) {
  //     let strI = i.toString();
  //     let lastChar = strI.substring(strI.length - 1);
  //     let suffix = lastChar == '1' ? 'st' :
  //       lastChar == '2' ? 'nd' :
  //         lastChar == '3' ? 'rd' : 'th'
  //     this.days.push({ id: strI, desc: `${strI}${suffix}` });
  //   }
  //   this.days.push({ id: '31', desc: 'EOM' });
  // }

  initForm() {
    let incType: string | null = null;
    let addIncType: string | null = null;

    let custInc = this.customerInfo.employerIncomeSource;
    if (custInc && custInc.length) {
      let incT = this.incomeTypes.find(i => i.desc == custInc);
      if (incT) incType = incT.id;
    }

    let addInc = this.customerInfo.additionalJobIncomeType;
    if (addInc && addInc.length) {
      let addIncT = this.addIncomeTypes.find(i => i.desc == addInc);
      if (addIncT) addIncType = addIncT.id;
    }

    this.loanForm = this.fb.group({
      firstName: [this.customerInfo.firstName, Validators.required],
      lastName: [this.customerInfo.lastName, Validators.required],
      middleName: [this.customerInfo.middleName],
      dob: [new Date(this.customerInfo.dOB), Validators.required],
      address1: [this.customerInfo.address1, Validators.required],
      address2: [this.customerInfo.address2],
      city: [this.customerInfo.city, Validators.required],
      zipCode: [this.customerInfo.zipCode, Validators.required],
      state: [this.customerInfo.state, Validators.required],
      phone: [this.customerInfo.phone, Validators.pattern(phoneRegex)],
      cellPhone: [this.customerInfo.cellPhone, Validators.pattern(phoneRegex)],
      sSN: [this.customerInfo.sSN, [Validators.pattern(ssnRegex), Validators.required]],

      routeNum: [this.customerInfo.routingNumber, Validators.required],
      accountNum: [this.customerInfo.accountNumber, Validators.required],
      ipAdd: [this.customerInfo.iPAddress],
      bankName: [this.customerInfo.bankName],
      ref1FName: [this.customerInfo.reference1FirstName],
      ref1LName: [this.customerInfo.reference1LastName],
      ref2FName: [this.customerInfo.reference2FirstName],
      ref2LName: [this.customerInfo.reference2LastName],
      incType: [incType],

      empName: [this.customerInfo.employerName],
      jobTitle: [this.customerInfo.positionOrDepartment],
      workPhone: [this.customerInfo.workPhone],
      empPhone: [this.customerInfo.employerPhoneNumber],

      compName: [this.customerInfo.companyName],
      compPhone: [this.customerInfo.companyPhone],

      verPhone: [this.customerInfo.alimonyVerificationPhone],

      amtCheck: [this.customerInfo.homePayLastCheck],
      addInc: [this.customerInfo.additionalMonthlyIncome],
      addIncType: [addIncType]
    });
    this.incomeTypeChanged(incType ?? '0');
    this.loanFormLoaded = true;
  }

  isRequired(fieldName: string): boolean {
    let control = this.loanForm.controls[fieldName];
    if (!control || !control.validator) return false;

    const validat = control.validator({} as AbstractControl);
    return (validat && validat.required);
  }

  hyphenForEmpty(value: string | null) {
    if (!value || value.length == 0) return "-";
    return value;
  }

  editEmail() {
    this.main.showEmailChangeModal().afterClosed().subscribe(result => {
      if (result && result !== 'back') {
        this.main.showLoading();
        var model = this.getTargetModel(`request|${result}`);
        var body = JSON.stringify(model);
        this.api.postStringResponse(`csr/update-email`, body)
          .subscribe((res: any) => {
            this.main.dismissLoading();
            if (res == "Success") {
              this.showConfirmEmailCode();
            } else {
              this.main.showErrorModal("Oops...", "Error occured");
            }
          },
            (err: any) => {
              this.main.dismissLoading();
              var msg = err.error.text || "Error occured";
              this.main.showErrorModal("Oops...", msg);
            }
          );
      }
    });
  }

  editCreditCard() {
    this.cardForm = this.fb.group({
      cardNum: ['', [Validators.required, creditCardValidator()]],
      expDate: ['', Validators.required],
      cvv: ['', Validators.required],
      zip: ['', Validators.required]
    });
    this.cardFormLoaded = true;
    this.showEditCard = true;
  }

  saveNewCardInfo() {
    let body = {
      customerGuid: this.ids.customerGuid,
      campaignGuid: this.ids.campaignGuid,
      targetGuid: this.ids.targetGuid,
      cardNumber: this.cardForm.value.cardNum,
      cardExpiration: this.cardForm.value.expDate,
      cardCVV: this.cardForm.value.cvv,
      cardZipCode: this.cardForm.value.zip
    };

    let postSub = this.api.post(``, body)
      .subscribe({
        next: () => {
          this.showEditCard = false;
          this.toastService.addSuccess('Card successfully saved.')
        },
        error: (err: any) => {
          this.toastService.addError('Unable to save card information. See log for details.');
          console.error(err);
        },
        complete: () => { postSub.unsubscribe(); }
      });
  }

  editPayFrequency() {

    // this.payFreqFormLoaded = true;
    this.showPayFreq = true;
  }

  // payFrequencyChanged(freq: string) {
  //   this.payFreqForm.controls.dayOfWeek.clearValidators();
  //   this.payFreqForm.controls.dayOfWeek.reset();
  //   this.payFreqForm.controls.semiMonthDay.clearValidators();
  //   this.payFreqForm.controls.semiMonthDay.reset();
  //   this.payFreqForm.controls.twoMonthOther.clearValidators();
  //   this.payFreqForm.controls.twoMonthOther.reset();
  //   this.payFreqForm.controls.twoMonthOtherDate1.clearValidators();
  //   this.payFreqForm.controls.twoMonthOtherDate1.reset();
  //   this.payFreqForm.controls.twoMonthOtherDate2.clearValidators();
  //   this.payFreqForm.controls.twoMonthOtherDate2.reset();
  //   this.payFreqForm.controls.twoMonthOtherWhen.clearValidators();
  //   this.payFreqForm.controls.twoMonthOtherWhen.reset();
  //   this.payFreqForm.controls.twoMonthOtherDay.clearValidators();
  //   this.payFreqForm.controls.radioMonth.clearValidators();
  //   this.payFreqForm.controls.radioMonth.reset();

  //   switch (freq) {
  //     case 'week':
  //     case '2week':
  //       this.payFreqForm.controls.dayOfWeek.setValidators([Validators.required]);
  //       break;
  //     case '2month':
  //       this.payFreqForm.controls.semiMonthDay.setValidators([Validators.required]);
  //       break;
  //     case 'month':
  //       this.payFreqForm.controls.radioMonth.setValidators([Validators.required]);
  //       break;

  //     default:
  //       break;
  //   };

  //   this.payFreqForm.patchValue({
  //     twoMonthOtherDate1: '1',
  //     twoMonthOtherDate2: '15',
  //     twoMonthOtherWhen: '13',
  //     twoMonthOtherDay: '0',
  //     monthDay: '1',
  //     monthWhen: '1',
  //     monthWhenWeekday: '0'
  //   });

  //   this.payFreqForm.updateValueAndValidity();

  // };

  // semiMonthDayChanged(monthDay: string) {
  //   this.payFreqForm.controls.twoMonthOther.clearValidators();
  //   if (monthDay == '2monthother') {
  //     this.payFreqForm.controls.twoMonthOther.setValidators([Validators.required]);
  //     this.payFreqForm.controls.twoMonthOther.reset();
  //   }
  //   this.payFreqForm.updateValueAndValidity();
  // }

  // twoMonthOther1Changed(day: string) {
  //   let numDay = +day;
  //   let day1 = numDay + 14;
  //   let day2 = numDay + 15;
  //   let day3 = numDay + 16;
  //   this.twoMonthOther2 = [];
  //   this.twoMonthOther2.push({ id: day1.toString(), desc: day1 == 31 ? 'EOM' : day1 == 21 ? '21st' : day1 == 22 ? '22nd' : `${day1.toString()}th` });
  //   if (day2 < 32) this.twoMonthOther2.push({ id: day2.toString(), desc: day2 == 31 ? 'EOM' : (day2 == 21 ? '21st' : (day2 == 22 ? '22nd' : day2 + 'th')) });
  //   if (day3 < 32) this.twoMonthOther2.push({ id: day3.toString(), desc: day3 == 31 ? 'EOM' : (day3 == 21 ? '21st' : (day3 == 22 ? '22nd' : day3 + 'th')) });

  //   setTimeout(() => {
  //     this.payFreqForm.patchValue({
  //       twoMonthOtherDate2: day1.toString()
  //     });
  //     this.payFreqForm.updateValueAndValidity();
  //   });
  // }

  payFrequencyStep1() {

    // this.calculateDays();
    this.showPayFreq = false;
    this.showPayFreq2 = true;
  }

  payFrequencyStep2() {
    this.showPayFreq2 = false;
  }

  datesCaclulated(dates: Date[]) {
    this.nextPayDates = [];
    if (dates && dates.length > 0) {
      dates.forEach(dt => {        
        this.nextPayDates.push(this.datePipe.transform(dt, 'fullDate') ?? 'Calculation Error');
      });
    }
  }

  // calculateDays() {
  //   let freq = this.payFreqForm.value.payFrequency;
  //   switch (freq) {
  //     case 'week':
  //       this.calculatePerWeeks(7);
  //       break;
  //     case '2week':
  //       this.calculatePerWeeks(14);
  //       break;
  //     case '2month':
  //       this.calculateSemiMonthly();
  //       break;
  //     case 'month':
  //       this.calculateMonthly();
  //       break;
  //   };
  // }

  // calculatePerWeeks(skipDays: number) {
  //   let today = new Date();
  //   let currDay = today.getDay();
  //   let dayOfWeek: number = +this.payFreqForm.value.dayOfWeek;
  //   let d1 = new Date();
  //   let dayAdd: number = 0;
  //   if (currDay > dayOfWeek) {
  //     dayAdd = (6 - currDay) + dayOfWeek + 1;
  //     d1.setDate(today.getDate() + dayAdd);
  //   }
  //   else {
  //     dayAdd = dayOfWeek - currDay;
  //     d1.setDate(today.getDate() + dayAdd);;
  //   }
  //   let d2 = new Date();
  //   d2.setDate(d1.getDate() + skipDays);
  //   let d3 = new Date();
  //   d3.setDate(d2.getDate() + skipDays);

  //   this.nextPayDates = [];
  //   this.nextPayDates.push(this.datePipe.transform(d1, 'fullDate') ?? 'Calculation Error');
  //   this.nextPayDates.push(this.datePipe.transform(d2, 'fullDate') ?? 'Calculation Error');
  //   this.nextPayDates.push(this.datePipe.transform(d3, 'fullDate') ?? 'Calculation Error');
  // }

  // calculateSemiMonthly() {
  //   let today = new Date();
  //   let currDay = today.getDate();
  //   let currMonth = today.getMonth();
  //   let semiMonthDay = this.payFreqForm.value.semiMonthDay;
  //   let d1 = new Date();
  //   let d2 = new Date();
  //   let d3 = new Date();
  //   if (semiMonthDay == '2month1st') {
  //     if (currDay > 1 && currDay <= 15) {
  //       d1.setDate(15);
  //       d2.setMonth(d1.getMonth() + 1);
  //       d2.setDate(1);
  //       d3.setMonth(d1.getMonth() + 1);
  //       d3.setDate(15);
  //     }
  //     else if (currDay > 15) {
  //       d1.setMonth(currMonth++);
  //       d1.setDate(1);
  //       d2.setMonth(d1.getMonth());
  //       d2.setDate(15);
  //       d3.setMonth(d2.getMonth() + 1);
  //       d3.setDate(1);
  //     }
  //     else {
  //       d2.setDate(15);
  //       d3.setMonth(d2.getMonth() + 1);
  //       d3.setDate(1);
  //     }
  //   }
  //   else if (semiMonthDay == '2month15th') {
  //     if (currDay <= 15) {
  //       d1.setDate(15);
  //       d2 = new Date(d1.getFullYear(), d1.getMonth() + 1, 0);
  //       d3.setMonth(d2.getMonth() + 1);
  //       d3.setDate(15);
  //     }
  //     else {
  //       d1 = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  //       d2.setMonth(d1.getMonth() + 1);
  //       d2.setDate(15);
  //       d3 = new Date(d2.getFullYear(), d2.getMonth() + 1, 0);
  //     }
  //   }
  //   else {
  //     let twoMonthOther = this.payFreqForm.value.twoMonthOther;
  //     if (twoMonthOther == '1') {
  //       let twoMonthOtherDate1 = +this.payFreqForm.value.twoMonthOtherDate1;
  //       let twoMonthOtherDate2 = +this.payFreqForm.value.twoMonthOtherDate2;
  //       if (twoMonthOtherDate1 <= currDay) {
  //         d1.setDate(twoMonthOtherDate1);
  //         d3.setMonth(d1.getMonth() + 1);
  //         d3.setDate(twoMonthOtherDate1);
  //         if (twoMonthOtherDate2 == 31) {
  //           d2 = new Date(d1.getFullYear(), d1.getMonth() + 1, 0);
  //         }
  //         else {
  //           d2.setDate(twoMonthOtherDate2);
  //         }
  //       }
  //       else {
  //         if (twoMonthOtherDate2 == 31) {
  //           d1 = new Date(d1.getFullYear(), d1.getMonth() + 1, 0);
  //           d3 = new Date(d1.getFullYear(), d1.getMonth() + 2, 0);
  //         }
  //         else {
  //           d1.setDate(twoMonthOtherDate2);
  //           d3.setDate(twoMonthOtherDate2);
  //           d3.setMonth(d1.getMonth() + 1);
  //         }
  //         d2.setMonth(d1.getMonth() + 1);
  //         d2.setDate(twoMonthOtherDate1);
  //       }

  //     }
  //     else {
  //       let otherWhen = +this.payFreqForm.value.twoMonthOtherWhen;
  //       let otherDay = +this.payFreqForm.value.twoMonthOtherDay
  //       let thisMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  //       let thisMonthDay = thisMonth.getDay();

  //       let dayAdd: number = 0;
  //       if (thisMonthDay > otherDay) {
  //         dayAdd = (6 - thisMonthDay) + otherDay + 1;
  //         d1.setDate(today.getDate() + dayAdd);
  //       }
  //       else {
  //         dayAdd = otherDay - thisMonthDay;
  //         d1.setDate(today.getDate() + dayAdd);;
  //       }

  //       thisMonth.setDate(thisMonth.getDate() + dayAdd);

  //       if (otherWhen == 24) {
  //         thisMonth.setDate(thisMonth.getDate() + 7);
  //       }

  //       if (thisMonth.getDate() < today.getDate()) {
  //         thisMonth.setDate(thisMonth.getDate() + 14);
  //         if (thisMonth.getDate() < today.getDate()) {
  //           thisMonth.setDate(thisMonth.getDate() + 14);
  //         }
  //       }

  //       d1 = new Date(thisMonth.getFullYear(), thisMonth.getMonth(), thisMonth.getDate());
  //       d2 = new Date(thisMonth.getFullYear(), thisMonth.getMonth(), thisMonth.getDate());
  //       d3 = new Date(thisMonth.getFullYear(), thisMonth.getMonth(), thisMonth.getDate());
  //       d2.setDate(d1.getDate() + 14);
  //       d3.setDate(d2.getDate() + 14);

  //     }

  //   }

  //   this.nextPayDates = [];
  //   this.nextPayDates.push(this.datePipe.transform(d1, 'fullDate') ?? 'Calculation Error');
  //   this.nextPayDates.push(this.datePipe.transform(d2, 'fullDate') ?? 'Calculation Error');
  //   this.nextPayDates.push(this.datePipe.transform(d3, 'fullDate') ?? 'Calculation Error');

  // }

  // calculateMonthly() {

  //   let today = new Date();
  //   let currDay = today.getDate();
  //   let d1 = new Date();
  //   let d2 = new Date();
  //   let d3 = new Date();
  //   let radioMonth = +this.payFreqForm.value.radioMonth;

  //   if (radioMonth == 1) {
  //     let monthDay = +this.payFreqForm.value.monthDay;
  //     if (monthDay == 31) {
  //       d1 = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  //       d2 = new Date(d1.getFullYear(), d1.getMonth() + 1, 0);
  //       d3 = new Date(d2.getFullYear(), d2.getMonth() + 1, 0);
  //     }
  //     else {
  //       d1.setDate(monthDay);
  //       d2.setDate(monthDay);
  //       d3.setDate(monthDay);
  //       if (currDay > monthDay) {
  //         d1.setMonth(d1.getMonth() + 1);
  //       }
  //       d2.setMonth(d1.getMonth() + 1);
  //       d3.setMonth(d2.getMonth() + 1);
  //     }

  //   }
  //   else {
  //     let monthWhenWeekday = +this.payFreqForm.value.monthWhenWeekday;
  //     let thisMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  //     let thisMonthDay = thisMonth.getDay();

  //     let dayAdd: number = 0;
  //     if (thisMonthDay > monthWhenWeekday) {
  //       dayAdd = (6 - thisMonthDay) + monthWhenWeekday + 1;
  //     }
  //     else {
  //       dayAdd = monthWhenWeekday - thisMonthDay;
  //     }

  //     thisMonth.setDate(thisMonth.getDate() + dayAdd);

  //     d1 = new Date(thisMonth.getFullYear(), thisMonth.getMonth(), thisMonth.getDate());
  //     if (d1.getDate() < currDay) {
  //       d1.setDate(d1.getDate() + 28);
  //       if (d1.getMonth() == today.getMonth()) d1.setDate(d1.getDate() + 7);
  //     }

  //     d2 = new Date(d1.getFullYear(), d1.getMonth(), d1.getDate());
  //     d3 = new Date(d1.getFullYear(), d1.getMonth(), d1.getDate());
  //     d2.setDate(d1.getDate() + 28);
  //     if (d2.getMonth() == d1.getMonth()) d2.setDate(d2.getDate() + 7);

  //     d3.setDate(d1.getDate() + 56);
  //     if (d3.getMonth() == d2.getMonth()) d3.setDate(d3.getDate() + 7);      
  //   }

  //   this.nextPayDates = [];
  //   this.nextPayDates.push(this.datePipe.transform(d1, 'fullDate') ?? 'Calculation Error');
  //   this.nextPayDates.push(this.datePipe.transform(d2, 'fullDate') ?? 'Calculation Error');
  //   this.nextPayDates.push(this.datePipe.transform(d3, 'fullDate') ?? 'Calculation Error');
  // }


  incomeTypeChanged(typeId: string) {
    this.loanForm.controls.empName.clearValidators();
    this.loanForm.controls.jobTitle.clearValidators();
    this.loanForm.controls.workPhone.clearValidators();
    this.loanForm.controls.empPhone.clearValidators();
    this.loanForm.controls.compName.clearValidators();
    this.loanForm.controls.compPhone.clearValidators();
    this.loanForm.controls.verPhone.clearValidators();

    switch (+typeId) {
      case 1:
        this.loanForm.controls.empName.setValidators([Validators.required]);
        this.loanForm.controls.jobTitle.setValidators([Validators.required]);
        this.loanForm.controls.workPhone.setValidators([Validators.required]);
        this.loanForm.controls.empPhone.setValidators([Validators.required]);
        break;
      case 3:
        this.loanForm.controls.compName.setValidators([Validators.required]);
        this.loanForm.controls.compPhone.setValidators([Validators.required]);
        break;
      case 4:
        this.loanForm.controls.verPhone.setValidators([Validators.required]);
        break;
      default:
        break;
    }
  }

  showConfirmEmailCode() {
    this.main.showConfirmEmailChangeModal().afterClosed().subscribe(result => {
      if (result && result !== 'back') {
        this.main.showLoading();
        var model = this.getTargetModel(`confirm|${result}`);
        var body = JSON.stringify(model);
        this.api.postStringResponse(`csr/update-email`, body)
          .subscribe((res: any) => {
            this.main.dismissLoading();
            if (res && res.toLowerCase().indexOf('invalid code') !== -1) {
              this.main.showErrorModal("Oops...", res).afterClosed().subscribe(() => {
                this.showConfirmEmailCode();
                return;
              })
            }
            if (this.main.showResponse(res || "Customer Email Updated!")) {
              this.updated.emit(true);
            }
          },
            (err: any) => {
              this.main.dismissLoading();
              var msg = err.error.text || "Error occured";
              this.main.showErrorModal("Oops...", msg);
            }
          );
      }
    });
  }

  saveInfo() {
    this.changes = [];
    this.values = [];
    if (!this.loanForm.valid) {
      // this.userService.testFormValidators(this.loanForm);
      this.toastService.addError('Please correct form errors before saving.');
      this.loanForm.markAllAsTouched();
      this.loanForm.markAsDirty();
      return;
    }

    this.main.showLoading();
    this.values.push({ "name": "First Name", "value": this.loanForm.value.firstName });
    this.addChanges("First Name", this.customerInfo.firstName, this.loanForm.value.firstName);

    this.values.push({ "name": "Middle Name", "value": this.loanForm.value.middleName });
    this.addChanges("Middle Name", this.customerInfo.middleName, this.loanForm.value.middleName);

    this.values.push({ "name": "Last Name", "value": this.loanForm.value.lastName });
    this.addChanges("Last Name", this.customerInfo.lastName, this.loanForm.value.lastName);

    this.values.push({ "name": "Date of Birth", "value": this.datePipe.transform(this.loanForm.value.dob, 'MM/dd/yyyy')});
    this.addChanges("Date of Birth", this.customerInfo.dOB, this.datePipe.transform(this.loanForm.value.dob, 'MM/dd/yyyy') ?? '');

    this.values.push({ "name": "Address Line 1", "value": this.loanForm.value.address1 });
    this.addChanges("Address Line 1", this.customerInfo.address1, this.loanForm.value.address1);

    this.values.push({ "name": "Address Line 2", "value": this.loanForm.value.address2 });
    this.addChanges("Address Line 2", this.customerInfo.address2, this.loanForm.value.address2);

    this.values.push({ "name": "City", "value": this.loanForm.value.city });
    this.addChanges("City", this.customerInfo.city, this.loanForm.value.city);

    this.values.push({ "name": "State", "value": this.loanForm.value.state });
    this.addChanges("State", this.customerInfo.state, this.loanForm.value.state);

    this.values.push({ "name": "Zip Code", "value": this.loanForm.value.zipCode });
    this.addChanges("Zip Code", this.customerInfo.zipCode, this.loanForm.value.zipCode);

    this.values.push({ "name": "Cell Phone", "value": this.loanForm.value.cellPhone });
    this.addChanges("Cell Phone", this.customerInfo.cellPhone, this.loanForm.value.cellPhone);

    this.values.push({ "name": "Phone", "value": this.loanForm.value.phone });
    this.addChanges("Phone", this.customerInfo.phone, this.loanForm.value.phone);

    this.values.push({ "name": "SSN", "value": this.loanForm.value.sSN });
    this.addChanges("SSN", this.customerInfo.sSN, this.loanForm.value.sSN);

    this.values.push({ "name": "Reference 1 First Name", "value": this.loanForm.value.ref1FName });
    this.addChanges("Reference 1 First Name", this.customerInfo.reference1FirstName, this.loanForm.value.ref1FName);

    this.values.push({ "name": "Reference 1 Last Name", "value": this.loanForm.value.ref1LName });
    this.addChanges("Reference 1 Last Name", this.customerInfo.reference1LastName, this.loanForm.value.ref1LName);

    this.values.push({ "name": "Reference 2 First Name", "value": this.loanForm.value.ref2FName });
    this.addChanges("Reference 2 First Name", this.customerInfo.reference2FirstName, this.loanForm.value.ref2FName);

    this.values.push({ "name": "Reference 2 Last Name", "value": this.loanForm.value.ref2LName });
    this.addChanges("Reference 2 Last Name", this.customerInfo.reference2LastName, this.loanForm.value.ref2LName);

    this.values.push({ "name": "Bank Routing Number", "value": this.loanForm.value.routeNum });
    this.addChanges("Bank Routing Number", this.customerInfo.routingNumber, this.loanForm.value.routeNum);

    this.values.push({ "name": "Bank Account Number", "value": this.loanForm.value.accountNum });
    this.addChanges("Bank Account Number", this.customerInfo.accountNumber, this.loanForm.value.accountNum);

    this.values.push({ "name": "Bank Name", "value": this.loanForm.value.bankName });
    this.addChanges("Bank Name", this.customerInfo.bankName, this.loanForm.value.bankName);

    this.values.push({ "name": "Employer Income Source", "value": this.loanForm.value.incType });
    this.addChanges("Employer Income Source", this.customerInfo.employerIncomeSource, this.incomeTypes.find(i => i.id == this.loanForm.value.incType)?.desc ?? 'Unknown');

    this.values.push({ "name": "Employer Name", "value": this.loanForm.value.empName });
    this.addChanges("Employer Name", this.customerInfo.employerName, this.loanForm.value.empName);

    this.values.push({ "name": "Position or Department", "value": this.loanForm.value.jobTitle });
    this.addChanges("Position or Department", this.customerInfo.positionOrDepartment, this.loanForm.value.jobTitle);

    this.values.push({ "name": "Work Phone", "value": this.loanForm.value.workPhone });
    this.addChanges("Work Phone", this.customerInfo.workPhone, this.loanForm.value.workPhone);

    this.values.push({ "name": "Work Phone", "value": this.loanForm.value.empPhone });
    this.addChanges("Work Phone", this.customerInfo.workPhone, this.loanForm.value.empPhone);

    this.values.push({ "name": "Company Name", "value": this.loanForm.value.compName });
    this.addChanges("Company Name", this.customerInfo.companyName, this.loanForm.value.compName);

    this.values.push({ "name": "Company Phone", "value": this.loanForm.value.compPhone });
    this.addChanges("Company Phone", this.customerInfo.companyPhone, this.loanForm.value.compPhone);

    this.values.push({ "name": "ValidPhone", "value": this.loanForm.value.verPhone });
    this.addChanges("ValidPhone", this.customerInfo.alimonyVerificationPhone, this.loanForm.value.verPhone);

    this.values.push({ "name": "Home Pay Last Check", "value": this.loanForm.value.amtCheck });
    this.addChanges("Home Pay Last Check", this.customerInfo.homePayLastCheck, this.loanForm.value.amtCheck);

    this.confirmChanges();
  }

  confirmChanges() {
    this.main.dismissLoading();
    if (this.changes.length) {
      this.main.showConfirmChanges(this.changes).afterClosed().subscribe(result => {
        if (result == "confirmed") {
          if (this.addressChanged()) {
            this.validateAddress();
          } else {
            this.postChanges();
          }
        }
      })
    }
  }

  private postChanges() {
    this.main.showLoading();
    var model = this.getTargetModel(JSON.stringify({ "values": this.values }));
    var body = JSON.stringify(model);
    this.api.postStringResponse(`csr/update-customer`, body)
      .subscribe((res: any) => {
        // this.postPaymentMethodChanges(res);
        this.main.dismissLoading();
        this.toastService.addSuccess("Information successfully updated.");
        this.updated.emit(true);
      },
        (err: any) => {
          this.main.dismissLoading();
          var msg = err.error.text || "Error occured";
          this.toastService.addError('Unable to update information. See log for details.');
          console.error(err);        
        }
      );
  }

  private addChanges(field: string, from: string, to: string) {
    if (from && from.trim().length == 1 && from.trim() === '-') from = '';
    if (to && to.trim().length == 1 && to.trim() === '-') to = '';
    if (!this.changes.length) {
      this.changes = [];
    }

    if (from !== to) {
      this.changes.push({
        field, from: from, to: to
      });
    }
  }

  private validateAddress() {
    this.main.showLoading();
    var model = {
      CustomerGuid: this.ids.customerGuid,
      CampaignGuid: this.ids.campaignGuid,
      TargetGuid: this.ids.targetGuid,
      Address1: this.loanForm.value.address1,
      Address2: this.loanForm.value.address2,
      City: this.loanForm.value.city,
      State: this.loanForm.value.state,
      ZipCode: this.loanForm.value.zipCode
    }
    var body = JSON.stringify(model);
    this.api.post(`csr/validate-address`, body)
      .subscribe((res: any) => {
        this.main.dismissLoading();
        if (res) {
          if (res.hasCorrection == "True") {
            var entered = `${this.loanForm.value.address1}, ${this.loanForm.value.address2}, ${this.loanForm.value.city}, ${this.loanForm.value.state}, ${this.loanForm.value.zipCode}`;
            var correction = `${res.address1}, ${res.address2}, ${res.city}, ${res.state}, ${res.zipCode}`;
            this.main.showAddressCorrection(entered.replace(', ,', ','), correction.replace(', ,', ',')).afterClosed().subscribe(action => {
              if (action === 'confirmed') {
                this.updateValues("Address Line 1", res.address1);
                this.updateValues("Address Line 2", res.address2);
                this.updateValues("City", res.city);
                this.updateValues("State", res.state);
                this.updateValues("Zip Code", res.zipCode);
                this.postChanges();
              }
            })
          } else {
            this.postChanges();
          }
        }
      },
        (err: any) => {
          this.main.showErrorModal("Oops...", "Customer Address could not be validated");
        }
      );
  }

  private addressChanged() {
    if (this.loanForm.value.address1 !== this.customerInfo.address1 ||
      this.loanForm.value.address2 !== this.customerInfo.address2 ||
      this.loanForm.value.city !== this.customerInfo.city ||
      this.loanForm.value.state !== this.customerInfo.state ||
      this.loanForm.value.zipCode !== this.customerInfo.zipCode)
      return true;

    return false;
  }

  updateValues(name: string, value: string) {
    for (var i = 0; i < this.values.length; i++) {
      if (this.values[i].name == name) {
        this.values[i].value = value;
        return;
      }
    }
  }

  private getTargetModel(val: string) {
    return {
      CustomerGuid: this.ids.customerGuid,
      CampaignGuid: this.ids.campaignGuid,
      TargetGuid: this.ids.targetGuid,
      Value: val
    }
  }


  testForm() {
    this.userService.testFormValidators(this.payFreqForm);
  }

}

export function creditCardValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {

    let val = control.value;
    let sum = 0;
    let valid: boolean = false;
    if (val && val.length) {
      for (let i = 0; i < val.length; i++) {
        let cardNum = parseInt(val[i]);

        if ((val.length - i) % 2 === 0) {
          cardNum = cardNum * 2;

          if (cardNum > 9) {
            cardNum = cardNum - 9;
          }
        }

        sum += cardNum;
      }

      valid = sum % 10 === 0;

    }
    if (!valid) return { invalidcard: true };
    else return null;

    // return null;
  }


}