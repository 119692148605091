<div elemClickOutside (clickOutside)="toggleMenus()">
    <div class="cds-header align-items-center">
        <div class="w-7 ml-5 flex">
            <div><img src="assets/images/p3-logo.png" class="img-center max-h-3rem" alt=""></div>
            <div class="mt-3 ml-4 text-3xl font-bold">{{ providerName }}</div>
            <div>
                <div *ngIf="customer" (click)="toggleCustomer()" class="ml-8 relative cursor-pointer" [title]="customer.customerGUID">{{customer.customerName}}</div>
                <div *ngIf="!customer" (click)="toggleCustomer()" class="ml-8 relative cursor-pointer">Select Customer</div>
                <div *ngIf="providerDD" class="nav-menu-container provider animate__animated animate__slideDown">
                    <app-provider-menu></app-provider-menu>
                </div>
            </div>
        </div>
        <div class="w-3 flex flex-row-reverse mr-7">
            <div class="cursor-pointer relative">
                <div (click)="searchDD = false; profileDD = !profileDD">
                    <i class="pi pi-user text-3xl" aria-hidden="true"></i>
                </div>
                <div *ngIf="profileDD" class="cs-menu-container animate__animated animate__flipInY">
                    <app-profile-menu (itemClicked)="profileDD = false;"></app-profile-menu>
                </div>
            </div>
            <div class="mr-7 cursor-pointer" (click)="toggleMenus(); collectionsQueueClicked()">
                <i *ngIf="collectionBadge.length; else collectionNoBadge" class="pi pi-wallet text-3xl" pBadge
                    [value]="collectionBadge" [severity]="collectionSeverity" aria-hidden="true"></i>
                <ng-template #collectionNoBadge>
                    <i class="pi pi-wallet text-3xl" aria-hidden="true"></i>
                </ng-template>
            </div>
            <div class="mr-7 cursor-pointer" (click)="toggleMenus(); communicationsQueueClicked()">
                <i *ngIf="commBadge.length; else commNoBadge" class="pi pi-comments text-3xl" pBadge [value]="commBadge"
                    [severity]="commSeverity" aria-hidden="true"></i>
                <ng-template #commNoBadge>
                    <i class="pi pi-comments text-3xl" aria-hidden="true"></i>
                </ng-template>
            </div>
            <div *ngIf="leadPgEnabled" class="mr-7 cursor-pointer" (click)="toggleMenus(); leadsClicked()">
                <i *ngIf="leadBadge.length; else leadNoBadge" class="pi pi-user-plus text-3xl" pBadge
                    [value]="leadBadge" [severity]="leadSeverity" aria-hidden="true"></i>
                <ng-template #leadNoBadge>
                    <i class="pi pi-user-plus text-3xl" aria-hidden="true"></i>
                </ng-template>
            </div>
            <div class="mr-7 cursor-pointer" (click)="toggleMenus(); verificationQueueClicked()">
                <i *ngIf="verifyBadge.length; else verifyNoBadge" class="pi pi-check-square text-3xl" pBadge
                    [value]="verifyBadge" [severity]="verifySeverity" aria-hidden="true"></i>
                <ng-template #verifyNoBadge>
                    <i class="pi pi-check-square text-3xl" aria-hidden="true"></i>
                </ng-template>
            </div>
            <div class="mr-7 cursor-pointer relative">
                <div (click)="profileDD = false; searchDD = !searchDD">
                    <i class="pi pi-search text-3xl" aria-hidden="true"></i>
                </div>
                <div *ngIf="searchDD" class="cs-menu-container animate__animated animate__bounceInDown">
                    <div class="search-card">
                        <div class="p-2 mb-1 bg-secondary text-white">
                            <p class="cs-search-header">Search</p>
                        </div>
                        <div class="card-body p-2">
                            <form [formGroup]="searchForm" (ngSubmit)="search()" class="p-1">
                                <div class="mb-1">
                                    <app-cc-input [group]="searchForm" controlName="firstName" placeholder="First Name">
                                    </app-cc-input>
                                </div>
                                <div class="mb-1">
                                    <app-cc-input [group]="searchForm" controlName="lastName" placeholder="Last Name">
                                    </app-cc-input>
                                </div>
                                <div class="mb-1">
                                    <app-cc-input [group]="searchForm" controlName="phone" placeholder="Phone Number">
                                    </app-cc-input>
                                </div>
                                <div class="mb-1">
                                    <app-cc-input [group]="searchForm" controlName="account"
                                        placeholder="Account Number (Exact Search)"></app-cc-input>
                                </div>
                                <div class="mb-1">
                                    <app-cc-input [group]="searchForm" controlName="email" placeholder="Email">
                                    </app-cc-input>
                                </div>
                                <div class="flex flex-wrap">
                                    <div class="col-12">
                                        <p3solved-button buttonType="submit" id="btnSearch" label="Search"
                                            icon="pi pi-search">
                                        </p3solved-button>
                                    </div>
                                    <div class="col-12">
                                        <a [routerLink]="['/advanced-search', customerGuid]" (click)="searchDD = false;"
                                            href="" class="text-success">Advanced
                                            Search</a>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>