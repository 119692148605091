<div class="p-card -mt-5 -mr-5 -ml-5">
  <div class="flex flex-wrap lg:flex-nowrap pt-1 pl-3 pr-3">
    <div class="lg:flex-auto p-2" *ngIf="showCustomerName">
      <span class="font-light">{{ customerNameLabel }}</span>
      <p class="m-0 h6">{{ targetInfo.fullName }}</p>
    </div>
    <div class="lg:flex-auto p-2" *ngIf="showAccountNumber">
      <div class="clickable" placement="right" [ngbPopover]="popContent" popoverTitle="">
        <span class="font-light">{{ accountNumLabel }}</span>
        <p class="m-0 h6">{{ targetInfo.loanNumber }}</p>
      </div>
      <ng-template #popContent>
        <p class="m-0" *ngFor="let p of popItems">{{ p }}</p>
      </ng-template>
    </div>
    <div *ngIf="
        showPayStatus &&
        (hasFeature('CSR_CUSTOMER_CHANGE_PAY_STATUS') ||
          hasFeature('CSR_CUSTOMER_READ_PAY_STATUS', 'read'))" class="lg:flex-auto p-2">
      <span class="font-light">{{ paystatusLabel }}</span>
      <p *ngIf="hasFeature('CSR_CUSTOMER_CHANGE_PAY_STATUS')" class="h6">
        <p3solved-button [matMenuTriggerFor]="changePayStatus"
          class="p-button-text p-button-plain cds-font cds-text-color font-bold">
          <span class="">{{ targetInfo.paymentMethod }}</span>
          <i class="pi pi-chevron-down pl-2" aria-hidden="true"></i>
        </p3solved-button>
        <mat-menu #changePayStatus="matMenu">
          <button mat-menu-item *ngFor="let opt of payMethods" (click)="payStatusChange(opt)">
            {{ opt.desc }}
          </button>
        </mat-menu>
      </p>
      <p *ngIf="!hasFeature('CSR_CUSTOMER_CHANGE_PAY_STATUS')" class="m-0 h6">
        {{ targetInfo.paymentMethod }}
      </p>
    </div>
    <div *ngIf="showFundType && targetInfo.showCSRFundingBankAccount == 'True'" class="lg:flex-auto p-2">
      <span class="font-light">{{ fundTypeLabel }}</span>
      <p class="m-0 h6">{{ targetInfo.fundingType }}</p>
    </div>
    <div class="lg:flex-auto p-2" *ngIf="showDpd">
      <span class="font-light">{{ dpdLabel }}</span>
      <p class="m-0 h6">
        <span *ngIf="targetInfo.daysPastDue === '0'">ZERO</span>
        <span *ngIf="targetInfo.daysPastDue != '0'">{{
          targetInfo.daysPastDue
          }}</span>
      </p>
    </div>
    <div class="lg:flex-auto p-2" *ngIf="showAppStatus">
      <span class="font-light">{{ appStatusLabel }}</span>
      <p class="m-0 h6">{{ targetInfo.loanAppStatus }}</p>
    </div>
    <div class="lg:flex-auto p-2" *ngIf="showLoanStatus">
      <span class="font-light">{{ loanStatusLabel }}</span>
      <p *ngIf="hasFeature('CSR_CUSTOMER_CHANGE_CONTRACT_STATUS')" class="m-0 h6">
        <p3solved-button [matMenuTriggerFor]="changeContractStatus"
          class="p-button-text p-button-plain cds-font text-color font-bold">
          <span class="mr-1">{{ targetInfo.loanStatus }}</span>
          <!-- <mat-icon>keyboard_arrow_down</mat-icon> -->
          <i class="pi pi-chevron-down pl-2" aria-hidden="true"></i>
        </p3solved-button>
        <mat-menu #changeContractStatus="matMenu">
          <button mat-menu-item *ngFor="let opt of loanStatuses" (click)="cantractStatusChange(opt)">
            {{ opt.desc }}
          </button>
        </mat-menu>
      </p>
      <p *ngIf="!hasFeature('CSR_CUSTOMER_CHANGE_CONTRACT_STATUS')" class="m-0 h6">
        {{ targetInfo.loanStatus }}
      </p>
    </div>
    <div
      *ngIf="showLegalStatus && (hasFeature('CSR_CUSTOMER_READ_LEGAL_STATUS', 'read') || hasFeature('CSR_CUSTOMER_CHANGE_LEGAL_STATUS'))"
      class="lg:flex-auto p-2">
      <span class="font-light">{{ legalStatusLabel }}</span>
      <p *ngIf="hasFeature('CSR_CUSTOMER_CHANGE_LEGAL_STATUS')" class="m-0 h6">
        <p3solved-button [matMenuTriggerFor]="changeLegalStatus"
          class="p-button-text p-button-plain cds-font cds-text-color font-bold">
          <span [ngClass]="
              targetInfo.legalStatus.toLowerCase() == 'none'
                ? 'mr-1'
                : 'mr-1 text-danger'
            ">
            {{ targetInfo.legalStatus }}
          </span>
          <i class="pi pi-chevron-down pl-2" aria-hidden="true" [ngClass]="
              targetInfo.legalStatus.toLowerCase() == 'none'
                ? ''
                : 'text-danger'
            "></i>
        </p3solved-button>
        <mat-menu #changeLegalStatus="matMenu">
          <button mat-menu-item *ngFor="let opt of legalStatuses" (click)="legalStatusChange(opt)">
            {{ opt.desc }}
          </button>
        </mat-menu>
      </p>
      <p *ngIf="!hasFeature('CSR_CUSTOMER_CHANGE_LEGAL_STATUS')" class="m-0 h6">
        {{ targetInfo.legalStatus }}
      </p>
    </div>
    <div *ngIf="showRewards" class="lg:flex-auto p-2">
      <span class="font-light">{{ rewardsLabel }}</span>
      <p class="m-0 h6">
        <span>{{ targetInfo.reward }}</span>
      </p>
    </div>
    <div *ngIf="showCashAdv" class="lg:flex-auto p-2">
      <span class="font-light">{{ cashAdvLabel }}</span>
      <p3solved-button [matMenuTriggerFor]="changeCashAdvance"
        class="p-button-text p-button-plain cds-font text-color font-bold">
        <span class="mr-1">{{ targetInfo.cashAdvanceStatus }}</span>
        <!-- <mat-icon>keyboard_arrow_down</mat-icon> -->
        <i class="pi pi-chevron-down pl-2" aria-hidden="true"></i>
      </p3solved-button>
      <mat-menu #changeCashAdvance="matMenu">
        <button mat-menu-item *ngFor="let opt of cashAdvStatuses" (click)="cashAdvStatusChange(opt)">
          {{ opt.desc }}
        </button>
      </mat-menu>
    </div>
    <div *ngIf="showCreditFlag" class="lg:flex-auto p-2">
      <span class="font-light">{{ creditFlagLabel }}</span>
      <p class="m-0 h6">
        <span>{{ targetInfo.creditBureauExclusion }}</span>
      </p>
    </div>
    <!-- <div *ngIf="hasFeature('CSR_CUSTOMER_READ_CONCESSION', 'read') || hasFeature('CSR_CUSTOMER_CHANGE_CONCESSION')" class="lg:flex-auto p-2">
            <span class="font-light">Concession</p>
            <p *ngIf="hasFeature('CSR_CUSTOMER_CHANGE_CONCESSION')" class="m-0 h6">
                <p3solved-button [matMenuTriggerFor]="changeConcession" class="p-button-text p-button-plain cds-font cds-text-color">
                    <span *ngIf="targetInfo.isConcession == 'False'" class="mr-1">OFF</span>
                    <span *ngIf="targetInfo.isConcession == 'True'" class="mr-1">ON</span>
                    <i class="pi pi-chevron-down pl-2" aria-hidden="true"></i>
                  </p3solved-button>
                  <mat-menu #changeConcession="matMenu">
                    <button mat-menu-item (click)="concessionChange()">
                        <span *ngIf="targetInfo.isConcession == 'False'">ON</span>
                        <span *ngIf="targetInfo.isConcession == 'True'">YES</span>
                    </button>
                  </mat-menu>
            </p>
            <p *ngIf="!hasFeature('CSR_CUSTOMER_CHANGE_CONCESSION')" class="m-0 h6">
                <span *ngIf="targetInfo.isConcession == 'False'">OFF</span>
                <span *ngIf="targetInfo.isConcession == 'True'">ON</span>
            </p>
        </div> -->
    <!-- <div *ngIf="targetInfo.cancelShow === 'True'" class="lg:flex-auto p-2pr-5 pt-4">
            <p3solved-button color="primary" (click)="cancelShow()">Cancel</p3solved-button>
        </div>
        <div *ngIf="targetInfo.withdrawShow == 'True'" class="lg:flex-auto p-2pr-5 pt-4">
            <p3solved-button color="primary" (click)="withdrawShow()">Withdraw</p3solved-button>
        </div> -->
  </div>
</div>


<p3solved-dialog [isVisible]="showNatDis" header="Loan Status Change Confirmation" headerClass="text-2xl"
  [showHeader]="true" [isModal]="true" [isClosable]="true" (HideClick)="showNatDis=false;" [style]="{'width': '25vw'}">
  <div body>
    <p>Status change to: <strong>Natural Disaster</strong></p>
    <p>REMINDER: When placing an account in holiday(freeze), any payments already billed (current due or past due) will
      automatically be deferred to the end of the loan</p>
    <p>Please enter <strong>ONLY the ADDITIONAL cycles</strong> that need to be on holiday. Do NOT include any payments
      already shown under the "scheduled payments section"</p>
    <p>Enter the number of payment cycles to be included in the payment holiday(freeze). This is the number of cycles
      <strong>not yet billed(due)</strong> on the account summary.</p>
    <p>Number of holiday cycles:
      <p3solved-input-number *ngIf="cyclesNoLoaded" id="txtCycles" mode="decimal" [formControl]="cyclesNo">
        <div class="w-12 my-1 p-error text-xs" *ngIf="cyclesNo.touched && cyclesNo.invalid">
          <p>Number of cycles must be between 1 and 6</p>
        </div>
      </p3solved-input-number>
    </p>
  </div>
  <div footer class="justify-content-around">
    <p3solved-button id="btnDialog1Cancel" label="Cancel" class="danger w-3"
      (buttonClick)="showNatDis=false;"></p3solved-button>
    <p3solved-button id="btnDialog1Ok" label="Confirm" class="w-3" (buttonClick)="setCycles()"
      [disabled]="cyclesNo.invalid"></p3solved-button>
  </div>
</p3solved-dialog>

<p3solved-dialog [isVisible]="showNatDis2" header="Loan Status Change Confirmation" headerClass="text-2xl"
  [showHeader]="true" [isModal]="true" [isClosable]="true" (HideClick)="showNatDis2=false;" [style]="{'width': '25vw'}">
  <div body>
    <p>Status change to: <strong>Natural Disaster</strong></p>
    <p><strong>Please Confirm!</strong></p>
    <p>You are requesting to place this account on payment holiday for {{cyclesNo.value}} cycles.This count is in
      addition to any payments that will be automatically deferred to the end.</p>
    <p>The holiday cycle will end on {{holidayCycleEndDate}} and the next payment due will be on
      {{holidayNextPaymentDueDate}}.</p>
  </div>
  <div footer class="justify-content-around">
    <p3solved-button id="btnDialog1Cancel" label="Cancel" class="danger w-3"
      (buttonClick)="showNatDis2=false;"></p3solved-button>
    <p3solved-button id="btnDialog1Ok" label="Confirm" class="w-3"
      (buttonClick)="showNatDis2=false;updateContractStatus('Natural Disaster Status change', naturalDisasterOption)"
      [disabled]="cyclesNo.invalid"></p3solved-button>
  </div>
</p3solved-dialog>

<p3solved-dialog [isVisible]="showActMil" header="Military - Active Duty" headerClass="text-2xl" [showHeader]="true"
  [isModal]="true" [isClosable]="true" (HideClick)="showActMil=false;activeDutyDate.reset()"
  [style]="{'width': '25vw'}">
  <div body>
    <p3solved-datepicker id="dtActDuty" placeholder="Selec date entered into Active Duty" [formControl]="activeDutyDate"
      appendTo="body">
      <div class="w-12 my-1 p-error text-xs" *ngIf="activeDutyDate.touched && activeDutyDate.invalid">
        <p>Date is required</p>
      </div>
    </p3solved-datepicker>
  </div>
  <div footer class="justify-content-around">
    <p3solved-button id="btnDialog1Cancel" label="Cancel" class="danger w-3"
      (buttonClick)="showActMil=false;activeDutyDate.reset()"></p3solved-button>
    <p3solved-button id="btnDialog1Ok" label="Confirm" class="w-3"
      (buttonClick)="showActMil=false;updateLegalStatus('', legalStatusOption)"
      [disabled]="activeDutyDate.invalid"></p3solved-button>
  </div>
</p3solved-dialog>

<p3solved-dialog [isVisible]="showConfFraud" header="Legal Status Change Confirmation" headerClass="text-2xl"
  [showHeader]="true" [isModal]="true" [isClosable]="true" (HideClick)="showConfFraud=false;" [style]="{'width': '25vw'}">
  <div body>
    <p>Status change to: <strong>{{legalStatusOption.desc}}</strong></p>
    <p>Please validate that the customer has submitted the following:</p>
    <form *ngIf="confFraudFormLoaded" [formGroup]="confFraudForm">
      <div class="grid">
        <div class="col-12">
          <p-checkbox [binary]="true" label="Police Report" formControlName="police"></p-checkbox>
        </div>
        <div class="col-12">
          <p-checkbox [binary]="true" label="Signed Affidavit" formControlName="affidavit"></p-checkbox>
        </div>
        <div class="col-12">
          <p-checkbox [binary]="true" label="2 Proofs of Address" formControlName="address"></p-checkbox>
        </div>
        <div class="col-12">
          <p-checkbox [binary]="true" label="Copy of Drivers License" formControlName="dl"></p-checkbox>
        </div>
      </div>
    </form>
  </div>
  <div footer class="justify-content-around">
    <p3solved-button id="btnDialog1Cancel" label="Cancel" class="danger w-3"
      (buttonClick)="showConfFraud=false; confFraudForm.reset();"></p3solved-button>
    <p3solved-button id="btnDialog1Ok" label="Confirm" class="w-3"
      (buttonClick)="showConfFraud=false;showConfFraud2=true;"
      [disabled]="confFraudForm.invalid"></p3solved-button>
  </div>
</p3solved-dialog>

<p3solved-dialog [isVisible]="showConfFraud2" header="Legal Status Change Confirmation" headerClass="text-2xl"
  [showHeader]="true" [isModal]="true" [isClosable]="true" (HideClick)="showConfFraud2=false;" [style]="{'width': '25vw'}">
  <div body>
    <p>Status change to: <strong>Confirmed Fraud</strong></p>
    <div>
        <p>Are you sure you want to confirm this account as Fraud?</p>
        <p>The following will take place:
            <br/>-Loan status will be set to Fraud Chargeoff 
            <br/>-Balances will be written off to $0.00
            <br/>-Account will be closed and cannot be reopened
        </p>
    </div>
  </div>
  <div footer class="justify-content-around">
    <p3solved-button id="btnDialog1Cancel" label="Cancel" class="danger w-3"
      (buttonClick)="showConfFraud2=false;"></p3solved-button>
    <p3solved-button id="btnDialog1Ok" label="Confirm" class="w-3"
      (buttonClick)="showConfFraud2=false;updateLegalStatus('', legalStatusOption)"
      [disabled]="cyclesNo.invalid"></p3solved-button>
  </div>
</p3solved-dialog>



<p3solved-dialog [isVisible]="showConfBankrupt" header="Legal Status Change Confirmation" headerClass="text-2xl"
  [showHeader]="true" [isModal]="true" [isClosable]="true" (HideClick)="showConfBankrupt=false;" [style]="{'width': '25vw'}">
  <div body>
    <p>Status change to: <strong>{{legalStatusOption.desc}}</strong></p>
    <div class="grid">
      <div class="col-12 pb-4">
        <p3solved-form-text id="phone" placeholder="Case Number" [formControl]="caseNumber">
          <div class="w-12 my-1 p-error text-xs"
            *ngIf="caseNumber.touched && caseNumber.invalid">
            <p>Case Number is required</p>
          </div>  
        </p3solved-form-text>        
      </div>
      <div class="col-12 pb-4">
        <p3solved-input-mask id="phone" placeholder="Attorney Phone Number" [formControl]="attorneyPhone" [autoClear]="true"
          mask="999-999-9999">
          <div class="w-12 my-1 p-error text-xs"
            *ngIf="attorneyPhone.touched && attorneyPhone.invalid">
            <p>Attorney Phone is required</p>
          </div>  
        </p3solved-input-mask>
      </div>
    </div>
    
  </div>
  <div footer class="justify-content-around">
    <p3solved-button id="btnDialog1Cancel" label="Cancel" class="danger w-3"
      (buttonClick)="showConfBankrupt=false; confFraudForm.reset();"></p3solved-button>
    <p3solved-button id="btnDialog1Ok" label="Confirm" class="w-3"
      (buttonClick)="showConfBankrupt=false;showConfBankrupt2=true;"
      [disabled]="caseNumber.invalid || attorneyPhone.invalid"></p3solved-button>
  </div>
</p3solved-dialog>

<p3solved-dialog [isVisible]="showConfBankrupt2" header="Legal Status Change Confirmation" headerClass="text-2xl"
  [showHeader]="true" [isModal]="true" [isClosable]="true" (HideClick)="showConfBankrupt2=false;" [style]="{'width': '25vw'}">
  <div body>
    <p>Status change to: <strong>Confirmed Bankruptcy</strong></p>
    <div>
        <p>Are you sure you want to confirm this account as Bankruptcy?</p>
        <p>The loan status will be changed to Bankruptcy Chargeoff</p>
    </div>
  </div>
  <div footer class="justify-content-around">
    <p3solved-button id="btnDialog1Cancel" label="Cancel" class="danger w-3"
      (buttonClick)="showConfBankrupt2=false;"></p3solved-button>
    <p3solved-button id="btnDialog1Ok" label="Confirm" class="w-3"
      (buttonClick)="showConfBankrupt2=false;updateLegalStatus('Case Number {{caseNumber.value}}, Attorney Number {{attorneyPhone.value}}', legalStatusOption)"
      [disabled]="cyclesNo.invalid"></p3solved-button>
  </div>
</p3solved-dialog>
