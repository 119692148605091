import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import { LookupModel } from 'src/app/models/lookup-model';

@Component({
  selector: 'app-cc-radio',
  templateUrl: './cc-radio.component.html',
  styleUrls: ['./cc-radio.component.scss']
})
export class CcRadioComponent implements OnInit {
  @Input() controlName: string = '';
  @Input() id?: string;
  @Input() options: LookupModel[] = [];
  @Input() label: string = "";
  @Input() disabled = false;
  iconRequired = faAsterisk;
  @Input() group: FormGroup = new FormGroup({});
  constructor() {
    var d = new Date();
    this.id = d.getTime().toString();
  }

  ngOnInit(): void {
  }

}
