import { Component, OnInit, ViewChild } from '@angular/core';
import { MainService } from 'src/app/services/main.service';
import { ApiService } from 'src/app/services/api.service';
import { ActivatedRoute } from '@angular/router';
import { NoteModel, NoteModelRaw } from 'src/app/models/note-model';
import { Table } from 'primeng/table';
import { TableColumn } from '@p3solved/p3solved-ui';
import { LookupModel } from 'src/app/models/lookup-model';

@Component({
  selector: 'app-note-list-page',
  templateUrl: './note-list-page.component.html',
  styleUrls: ['./note-list-page.component.scss']
})
export class NoteListPageComponent implements OnInit {

  rawNotes: NoteModelRaw[] = [];
  notesMaster: NoteModel[] = [];
  notes: NoteModel[] = [];
  customerGuid: string | undefined = '';

  loading = true;
  first: number = 0;

  notesTblStoreKey: string = 'notesTblStorage-local';
  columns: TableColumn[] = [];
  displayedColumns: string[] = ['createDate', 'loanNumber', 'customerFullName', 'noteCategoryDescription', 'note', 'userFullName'];

  noteSearchFrom: Date | null = null;
  noteSearchTo: Date | null = null;
  noteSearchAgent: string | null = null;
  noteSearchKeyword: string | null = null;

  agents: LookupModel[] = [];

  @ViewChild('dtNotes') dtNotes: Table = {} as Table;

  constructor(private main: MainService,
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(paramMap => {
      this.customerGuid = paramMap.get('customerGuid')?.toString();
      this.setupColumns();
      this.getNotes();
    });
    this.main.showHideCsrSidenav(false);
  }
  
  setupColumns() {
    this.columns = [
      {field: 'rowNumber', header: 'Row number', class: null, sortable: false, show: false, clickable: false},
      {field: 'rowCount', header: 'Row count', class: null, sortable: false, show: false, clickable: false},
      {field: 'campaignGUID', header: 'Campaign Guid', class: null, sortable: false, show: false, clickable: false},
      {field: 'targetGUID', header: 'Target Guid', class: null, sortable: false, show: false, clickable: false},
      {field: 'noteGUID', header: 'Note Guid', class: null, sortable: false, show: false, clickable: false},
      {field: 'createDate', header: 'Create Date', class: null, sortable: true, show: true, clickable: false},
      {field: 'loanNumber', header: 'App Number', class: null, sortable: true, show: true, clickable: false},
      {field: 'customerFirst', header: 'Customer First Name', class: null, sortable: false, show: false, clickable: false},
      {field: 'customerLast', header: 'Customer Last Name', class: null, sortable: false, show: false, clickable: false},
      {field: 'customerFullName', header: 'Customer', class: null, sortable: true, show: true, clickable: false},
      {field: 'noteCategoryDescription', header: 'Comment Code', class: null, sortable: true, show: true, clickable: false},
      {field: 'note', header: 'Note', class: null, sortable: true, show: true, clickable: false},
      {field: 'firstName', header: 'User First Name', class: null, sortable: false, show: false, clickable: false},
      {field: 'lastName', header: 'v Last Name', class: null, sortable: false, show: false, clickable: false},
      {field: 'userFullName', header: 'User', class: null, sortable: true, show: true, clickable: false}
    ];
  }

  getNotes() {
    this.apiService.get(`csr/customer-notes/${this.customerGuid}`)
      .subscribe((res: any) => {
        this.rawNotes = res || [];
        this.formatNotes();
        this.loading = false;
      },
        (err: any) => {
          console.error(err);
          this.loading = false;
        }
      );
  }

  formatNotes() {
    this.notes = [];
    this.rawNotes.forEach(rn => {
      this.notesMaster.push({
        campaignGUID: rn.campaignGUID,
        createDate: new Date(rn.createDate),
        customerFirst: rn.customerFirst,
        customerFullName: rn.customerFullName,
        customerLast: rn.customerLast,
        firstName: rn.firstName,
        lastName: rn.lastName,
        loanNumber: rn.loanNumber,
        note: rn.note,
        noteCategoryDescription: rn.noteCategoryDescription,
        noteGUID: rn.noteGUID,
        rowCount: +rn.rowCount,
        rowNumber: +rn.rowNumber,
        targetGUID: rn.targetGUID,
        userFullName: rn.userFullName,
      });
    });
    this.notes = this.notesMaster;
    this.getAgents();
  }

  getAgents() {
    let agentStr = [...new Set(this.notesMaster.map(m => m.userFullName).sort((a, b) => (a.toLowerCase() > b.toLowerCase() ? 1 : -1)))];
    agentStr.forEach(a => {
      this.agents.push({ id: a, desc: a });
    });

  }

  searchNotes() {
    let tempNotes = this.notesMaster;
    if (this.noteSearchFrom) tempNotes = tempNotes.filter(t => this.dateCompare(t.createDate, this.noteSearchFrom, '>='));
    if (this.noteSearchTo) tempNotes = tempNotes.filter(t => this.dateCompare(t.createDate, this.noteSearchTo, '<='));
    if (this.noteSearchAgent) tempNotes = tempNotes.filter(t => t.userFullName == this.noteSearchAgent);
    if (this.noteSearchKeyword) {
      let keyW = this.noteSearchKeyword;
      tempNotes = tempNotes.filter(f => f.note.indexOf(keyW) > -1 || f.noteCategoryDescription.indexOf(keyW) > -1 || f.customerFullName.indexOf(keyW) > -1 || f.loanNumber.indexOf(keyW) > -1);
    }
    this.notes = tempNotes;
    this.first = 0;
    if (this.dtNotes) this.dtNotes.reset();
  }

  dateCompare(dt1: Date | null, dt2: Date | null, comp: string): boolean {
    if (!dt1 || !dt2) return false;
    dt1.setHours(0,0,0,0);
    dt2.setHours(0,0,0,0);

    if (comp == '>=') {
      return dt1 >= dt2;
    }
    else if (comp == '<=') {
      return dt1 <= dt2;
    }
    else return false;
    
  }

  goToApp(note: NoteModel) {
    var url = `/dashboard/${this.customerGuid}/${note.campaignGUID}/${note.targetGUID}`;
    window.open(url, "_blank");
  }

}