import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { YesNoDialogData } from '../yes-no-popup/yes-no-popup.component';
import {IconProp} from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'app-confirm-delete-modal',
  templateUrl: './confirm-delete-modal.component.html',
  styleUrls: ['./confirm-delete-modal.component.scss']
})
export class ConfirmDeleteModalComponent implements OnInit {

  iconExclam = faExclamationCircle as IconProp;
  constructor(
    public dialogRef: MatDialogRef<ConfirmDeleteModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: YesNoDialogData,
  ) {}

  ngOnInit(): void {
  }

  ok(action: string){
    this.dialogRef.close(action);
  }

}
