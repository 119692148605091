import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedIds } from 'src/app/models/activated-ids';
import { TargetMessageDetails, TargetMessageModel } from 'src/app/models/communications/message-model';
import { ApiService } from 'src/app/services/api.service';
import { MainService } from 'src/app/services/main.service';

@Component({
  selector: 'app-comm-mailbox-item',
  templateUrl: './comm-mailbox-item.component.html',
  styleUrls: ['./comm-mailbox-item.component.scss']
})
export class CommMailboxItemComponent implements OnInit {

  @Input() message:TargetMessageModel = new TargetMessageModel();
  @Output() onBack = new EventEmitter<boolean>();
  @Output() onReply = new EventEmitter<TargetMessageDetails|null>();

  loading = false;
  hasAttachments: boolean = false;
  showReplyButton: boolean = false;

  attachmentCount: number = 0;
  attachments: string[] = [];
  ids: ActivatedIds = {};
  emailBody: any;
  messageSubject: string = '';
  messageDetails: TargetMessageDetails | null = null;

  constructor(
    private main: MainService,
    private api: ApiService,
  ) { }

  ngOnInit(): void {
    this.main.activatedIds$.subscribe((ids) => {
      if (ids && ids.targetGuid) {
        if (!this.ids || this.ids.targetGuid !== ids.targetGuid) {
          this.ids = ids;
          this.getDetails();
        }
      }
    });
  }

  getDetails(){
    this.loading = true;
    this.main.showLoading();
    var model = {
      CustomerGuid: this.ids.customerGuid,
      CampaignGuid: this.ids.campaignGuid,
      TargetGuid: this.ids.targetGuid,
      Value: this.message.messageGUID
    }
    var body = JSON.stringify(model);
    this.api.post(`csr/target-message-details`, body)
      .subscribe((res: TargetMessageDetails) => {  
        this.messageDetails = res;
        let typeId = +res.messageTypeID;
        this.showReplyButton = typeId == 1 || typeId == 3;

        if (res.messageTypeID === '4') {
          this.messageSubject = "SMS";          
        }
        else {
          this.messageSubject = res.emailSubject;
        }
        this.emailBody = res.emailBody || this.message.objectBody;
        if (res.emailAttachments != '' && res.emailAttachments.length > 0) {
          this.hasAttachments = true;
          this.attachments = (res.emailAttachments as string).split(',');
          this.attachmentCount = this.attachments.length;          
        }
        this.main.dismissLoading();
        this.loading = false;

      },
        (err: any) => {
          this.main.dismissLoading();
          this.main.showSnackBar("Ops. Error happened");
          this.loading = false;
        }
      );
  }

  getAttachemntIcon(attachment: string): string {
    let lastIdx = attachment.lastIndexOf('.');
    let extension: string = 'file';
    if (lastIdx > 0) {
      extension = attachment.substring(lastIdx + 1);
    }
    let file: string = 'blank.png';
    switch (extension) {
      case 'csv':
          file = 'csv.png';
        break;
      
      case 'doc':
      case 'docx':
        file = 'doc.png';
        break;

      case 'jpg':
        file = 'jpg.png';
        break;

      case 'pdf': 
        file = 'pdf.png';
        break;

      case 'png':
        file = 'png.png';
        break;

      case 'svg':
        file = 'svg.png';
        break;

      case 'txt':
        file = 'txt.png';
        break;

      case 'xml':
        file = 'xml.png';
        break;

      case 'zip':
        file = 'zip.png';
        break;

    }
    return `assets/icons/${file}`;
  }

  getAttachment(attachment: string) {
    let url: string = this.message.attachmentUrl;
    url += url.endsWith('/') ? this.message.msgGuidFolder : `/${this.message.msgGuidFolder}`;
    url += url.endsWith('/') ? attachment : `/${attachment}`;
    window.open(url, '_blank');
  }

  replyToMsg() {
    this.onReply.emit(this.messageDetails);
  }

  back(){
    this.onBack.emit(true);
  }

}
