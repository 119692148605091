import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';
import { ActivatedIds } from 'src/app/models/activated-ids';
import { TargetMessageDetails, TargetMessageModel } from 'src/app/models/communications/message-model';
import { CustomerInfoLoanModel } from 'src/app/models/customer-info-loan-model';
import { LookupModel } from 'src/app/models/lookup-model';
import { MessageTemplate } from 'src/app/models/message-template';
import { MailboxCount } from 'src/app/pages/communications-page/communications-page.component';
import { ApiService } from 'src/app/services/api.service';
import { CustomerService } from 'src/app/services/customer.service';
import { MainService } from 'src/app/services/main.service';
import { CommEmailComponent } from '../comm-email/comm-email.component';
import { CommMailboxComponent } from '../comm-mailbox/comm-mailbox.component';
import { CommSmsComponent } from '../comm-sms/comm-sms.component';

@Component({
  selector: 'app-comm-tab',
  templateUrl: './comm-tab.component.html',
  styleUrls: ['./comm-tab.component.scss']
})
export class CommTabComponent implements OnInit {

  @Input() customerSMS: boolean = false;

  ids: ActivatedIds = new ActivatedIds;
  customerInfo: CustomerInfoLoanModel = {} as CustomerInfoLoanModel;

  mailbox: 'inbox' | 'draft' = 'inbox';
  mailboxLabel: string = 'Mailbox';
  inboxUnread = 0;
  draftsCount = 0;
  templatesMaster: MessageTemplate[] = [];
  templates: MessageTemplate[] = [];
  templateCategories: LookupModel[] = [];
  draftMessage: TargetMessageModel | null = null;
  
  showTemplateChoices: boolean = false;
  showMailboxes: boolean = true;
  showTemplates: boolean = false;
  showSMS: boolean = false;
  showEmail: boolean = false;
  msgTempGuid: any = null;

  toastKey: string = 'payToast';
  toastPos: string = 'center';
  templateCategoryName: string = '';

  @ViewChild('commEmail') commEmail: CommEmailComponent = {} as CommEmailComponent;
  @ViewChild('commSMS') commSMS: CommSmsComponent = {} as CommSmsComponent;
  @ViewChild('opCompose') opCompose: OverlayPanel = {} as OverlayPanel;
  @ViewChild('commMailbox') commMailbox: CommMailboxComponent = {} as CommMailboxComponent;

  constructor(
    private customerService: CustomerService,
    private mainService: MainService,
    private apiService: ApiService
  ) { }

  ngOnInit(): void {
    this.mainService.activatedIds$.subscribe(id => {
      if (id && this.ids.targetGuid !== id.targetGuid) {
        this.ids = id;
        this.getLoadData();
        this.getTemplates();
      }
    });
  }

  getLoadData() {
    this.apiService.get(`csr/loan-data/${this.ids.customerGuid}/${this.ids.campaignGuid}/${this.ids.targetGuid}`)
      .subscribe((res: any) => {
        this.customerInfo = res.loanData;
      },
        (err: any) => {
          console.error(err);
        }
      );
  }

  setCount(count: MailboxCount) {
    this.inboxUnread = count.inbox;
    this.draftsCount = count.draft;
  }

  showMailbox(box: 'inbox' | 'draft') {
    this.showTemplates = false;
    this.showEmail = false;
    this.showSMS = false;
    this.showMailboxes = true;
    this.mailbox = box;
    this.mailboxLabel = box === 'inbox' ? 'Mailbox' : 'Drafts';
    setTimeout(() => {
      if (this.commMailbox) this.commMailbox.selectedMessage = null;      
    }, 100);
  }

  getTemplates() {
    let commSub = this.customerService.getAllCommunications()
      .subscribe({
        next: (res: MessageTemplate[]) => {
          this.templatesMaster = res;
          this.setupMessageCategories();
        },
        error: (err: any) => {
          console.error('comm-tab component ngOnInit customerService.getAllCommunications error: ', err);
        },
        complete: () => { commSub.unsubscribe(); }
      })
  }

  setupMessageCategories() {
    this.templateCategories = [];
    let uniqueIds = [...new Set(this.templatesMaster.map(t => t.messageCategoryID))];
    uniqueIds.forEach(id => {
      let tObj = this.templatesMaster.find(t => t.messageCategoryID == id);
      if (tObj) {
        this.templateCategories.push({ id: id, desc: tObj.messageCategoryName });
      }
    });
  }

  getBadgeStyle(template: any): string {
    let badgeStyle: string = 'bg-orange-500';

    if (template.messageTypeID === '4')
      badgeStyle = 'surface-500';

    return badgeStyle;
  }

  loadTemplates(category: LookupModel) {
    this.templates = this.templatesMaster.filter(m => m.messageCategoryID == category.id);
    this.templateCategoryName = category.desc;
    this.showTemplates = true;
    this.showEmail = false;
    this.showSMS = false;
    this.showMailboxes = false;
  }

  clearSMS() {
    this.msgTempGuid = null;
  }

  loadSMS(clearPrev: boolean = false) {
    if (clearPrev) {
      this.msgTempGuid = null;
      if (this.commSMS) this.commSMS.resetForm();
    }
    this.showTemplates = false;
    this.showEmail = false;
    this.showSMS = true;
    this.showMailboxes = false;
  }

  loadEmail(clearPrev: boolean = false) {
    this.showTemplates = false;
    this.showEmail = true;
    this.showSMS = false;
    this.showMailboxes = false;
    if (clearPrev) {
      this.msgTempGuid = null;
      this.draftMessage = null;
      if (this.commEmail) this.commEmail.resetForm();
    }
  }

  loadTemplate(template: MessageTemplate) {
    this.msgTempGuid = template.messageTemplateGUID;
    if (template.messageTypeID === '4') {   //  Load SMS      
      this.loadSMS();
    }
    else {                                  //  Load Email
      this.loadEmail();
    }
  }

  loadDraft(message: TargetMessageModel) {
    this.draftMessage = message;
    this.loadEmail(true);
    
  }

  refreshMailboxes() {
    this.getLoadData();
    this.getTemplates();
    this.showMailbox('inbox');
    this.commMailbox.getInbox();
  }

  setupReply(msg: TargetMessageDetails | null) {
    if (!msg) return;
    
    let typeId = +msg.messageTypeID;
    if (typeId == 1) {
      this.loadEmail(true);
      setTimeout(() => {
        if (this.commEmail) {
          this.commEmail.setupReply(msg);
        }
      });
    }
    else if (typeId == 3) {
      this.loadSMS(true);
      setTimeout(() => {
        if (this.commSMS) {
          this.commSMS.setupReply(msg);
        }
      });
    }
    else console.warn('Unknown MessageTypeId for Reply: ', typeId);      

  }

}