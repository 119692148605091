<app-dashboard-header></app-dashboard-header>
<p class="text-2xl pt-5">Customer Communications</p>
<div class="flex">
  <div class="md:col-9 col-12">
    <div class="cds-card">
      <div class="py-1">
        <p-tabMenu [model]="tabMenuItems" styleClass="px-3" [activeItem]="activeItem"></p-tabMenu>
        <div *ngIf="showCommunications">
          <app-comm-tab [customerSMS]="getBoolean(customer?.communicationShowSMS)"></app-comm-tab>
        </div>
        <div *ngIf="showDocuments">
          <app-contract-documents></app-contract-documents>
          <app-customer-attachments></app-customer-attachments>
        </div>
      </div>
    </div>
  </div>
  <div class="md:col-3 col-12">
    <app-customer-notes></app-customer-notes>
  </div>
</div>
