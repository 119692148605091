import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, ConfirmEventType } from 'primeng/api';
import { DynamicDirective } from 'src/app/directives/dynamic.directive';
import { ActivatedIds } from 'src/app/models/activated-ids';
import { CSRTargetVerifications, TargetDecisionReason } from 'src/app/models/csr/target-verifications';
import { ApiService } from 'src/app/services/api.service';
import { CustomerService } from 'src/app/services/customer.service';
import { GuidService } from 'src/app/services/guid.service';
import { HelperService } from 'src/app/services/helper.service';
import { MainService } from 'src/app/services/main.service';
import { ToastService } from 'src/app/services/toast.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-customer-verifications',
  templateUrl: './customer-verifications.component.html',
  styleUrls: ['./customer-verifications.component.scss']
})
export class CustomerVerificationsComponent implements OnInit {
  @Output() reloadNotes = new EventEmitter();

  ids: ActivatedIds = {};
  targetInfo: any;

  loaded: boolean = false;
  inVerificationMode: boolean = false;
  cSRAggentPass: boolean = false;
  verificationHistory: boolean = false;
  verificationModeExpired: boolean = false;
  verificationDisabled: boolean = false;
  docsIncompleteShow: boolean = false;
  haveAtLeastOneVerifOrDoc: boolean = false;

  finalStatus: string = 'NONE';
  customerVerificationsConfirmKey: string = 'confirmKeyCustomerVerification';

  verificationData: CSRTargetVerifications = {} as CSRTargetVerifications;
  // Decisions = resultDs.Tables[0]
  // Verificaitons = resultDs.Tables[1]
  // Documents = resultDs.Tables[2]
  // Reasons = resultDs.Tables[3]

  reasonGroups: TargetDecisionReason[][] = [];

  constructor(
    private main: MainService,
    private userService: UserService,
    private confirmService: ConfirmationService,
    private helperService: HelperService,
    private guidService: GuidService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private customerService: CustomerService,
    private sanitizer: DomSanitizer,
    private apiSerivce: ApiService,
    private toastService: ToastService
  ) { }

  ngOnInit(): void {
    this.loaded = false;
    this.main.activatedIds$.subscribe((ids) => {
      if (ids && ids.targetGuid) {
        if (!this.ids || this.ids.targetGuid !== ids.targetGuid) {
          this.ids = ids;
          this.getTargetInfo();
        }
      }
    });
  }

  hasFeature(feature: string, mode: string = 'write') {
    switch (mode) {
      case 'read':
        return this.userService.hasRead(feature);
        break;
      case 'feature':
        return this.userService.hasFeature(feature);
        break;
      default:
        return this.userService.hasWrite(feature);
        break;
    }
  }

  getTargetInfo() {
    let body = {
      customerGuid: this.ids.customerGuid,
      campaignGuid: this.ids.campaignGuid,
      targetGuid: this.ids.targetGuid
    };
    let postSub = this.apiSerivce.post(`campaign/get-target-info`, body)
      .subscribe({
        next: (info: any) => {
          if (info && info.length) {
            this.targetInfo = info[0];
            this.setupChecks();
            if (this.inVerificationMode || this.verificationHistory) {
              this.fillVerifications();
            }
            else {
              this.loaded = true;
            }
          }
        },
        error: (err: any) => {
          this.toastService.addError("Unable to get target information. See log for details.")
          console.error(err);
        },
        complete: () => { postSub.unsubscribe(); }
      });
  }

  setupChecks() {
    this.inVerificationMode = this.helperService.getBoolean(this.targetInfo.inVerificationMode);
    this.cSRAggentPass = this.helperService.getBoolean(this.targetInfo.cSRAggentPass);
    this.verificationHistory = this.helperService.getBoolean(this.targetInfo.verificationHistory);
    this.verificationModeExpired = this.helperService.getBoolean(this.targetInfo.verificationModeExpired);
    this.docsIncompleteShow = this.helperService.getBoolean(this.targetInfo.docsIncompleteShow);
    this.verificationDisabled = this.verificationModeExpired || this.cSRAggentPass;

  }

  fillVerifications() {
    let tgtSub = this.apiSerivce.get(`csr/target-verifications/${this.ids.customerGuid}/${this.ids.campaignGuid}/${this.ids.targetGuid}`)
      .subscribe({
        next: (data: CSRTargetVerifications) => {
          this.verificationData = data;
          this.haveAtLeastOneVerifOrDoc = this.verificationData.verificaitons.length > 0 || this.verificationData.documents.length > 0;
          this.getVerDocFinalDecision();
          if (this.verificationHistory && this.verificationData.reasons.length > 0) this.setupReasonGroups();
          this.loaded = true;
        },
        error: (err: any) => {
          this.toastService.addError("Unable to get Verification Information. See log for details.");
          console.error(err);
        },
        complete: () => { tgtSub.unsubscribe(); }
      });
  }

  getVerDocFinalDecisionCSS(): string {
    switch (this.finalStatus) {
      case 'INCOMPLETE':
        return 'var(--text-color-secondary)';
        break;

      case 'FAIL':
      case 'DECLINE':
        return 'var(--text-error)';
        break;

      case 'NEUTRAL':
        return 'var(--text-color-warn)';
        break;

      case 'PASS':
      case 'APPROVE':
        return 'var(--text-color-success)';
        break;

      default:
        return '';
        break;
    }

  }

  getVerDocFinalDecision() {
    let incVerCount = this.verificationData.verificaitons.filter(v => v.status == 'incomplete').length;
    let incDocCount = this.verificationData.documents.filter(d => d.status == 'incomplete').length;

    let neutVerCount = this.verificationData.verificaitons.filter(v => v.status == 'neutral').length;
    let neutDocCount = this.verificationData.documents.filter(d => d.status == 'neutral').length;

    let failVerCount = this.verificationData.verificaitons.filter(v => v.status == 'fail').length;
    let failDocCount = this.verificationData.documents.filter(d => d.status == 'fail').length;

    let passVerCount = this.verificationData.verificaitons.filter(v => v.status == 'pass').length;
    let passDocCount = this.verificationData.documents.filter(d => d.status == 'pass').length;

    let incCount = incVerCount + incDocCount;
    let neutCount = neutVerCount + neutDocCount;
    let failCount = failVerCount + failDocCount;
    let passCount = passVerCount + passDocCount;

    let decisionType = this.verificationData.decisions[0].decisionType.toUpperCase();

    if (decisionType === 'FACTA') {
      if (incCount > 0) this.finalStatus = 'INCOMPLETE';
      else if (failCount > 0) this.finalStatus = 'FAIL';
      else if (neutCount > 0) this.finalStatus = 'NEUTRAL';
      else if (passCount > 0) this.finalStatus = 'PASS';
    }
    else {
      if (incCount > 0) this.finalStatus = 'INCOMPLETE';
      else if (failCount > 0 || neutCount > 0) this.finalStatus = 'DECLINE';
      else if (passCount > 0) this.finalStatus = 'APPROVE';
    }

  }

  setupReasonGroups() {
    this.reasonGroups = [];
    let uniqueIds = [...new Set(this.verificationData.reasons.map(r => r.failRuleReasonID))];

    uniqueIds.forEach(id => {
      this.reasonGroups.push(this.verificationData.reasons.filter(r => r.failRuleReasonID == id));
    });
  }

  updateVerOrDoc(id: number, type: string, status: string) {

    if (type == 'verification') {
      let verf = this.verificationData.verificaitons.find(v => v.verificationID == id);
      if (verf && verf.status == status) return;
    }
    else {
      let doc = this.verificationData.documents.find(d => d.documentID == id);
      if (doc && doc.status == status) return;
    }

    let body = {
      customerGuid: this.ids.customerGuid,
      campaignGuid: this.ids.campaignGuid,
      targetGuid: this.ids.targetGuid,
      entityId: id,
      entityType: type,
      newStatus: status
    };

    let postSub = this.apiSerivce.post(`csr/change-verification`, body)
      .subscribe({
        next: () => {
          this.reloadNotes.emit();
          this.fillVerifications();
        },
        error: (err: any) => {
          this.toastService.addError('Unable to update status. See log for details.');
          console.error(err);
        },
        complete: () => { postSub.unsubscribe(); }
      });
  }

  cvConfirmChangeStatus(type: number) {
    let msgStatus: string = '';
    let dbStatus: string = '';
    switch (type) {
      case 0:
        msgStatus = 'DECLINE';
        dbStatus = 'decline';
        break;
      case 1:
        msgStatus = 'APPROVE';
        dbStatus = 'approve';
        break;
      case 2:
        msgStatus = 'FORCE APPROVE';
        dbStatus = 'force_approve';
        break;
      case 3:
        msgStatus = 'FORCE RFAI';
        dbStatus = 'force_rfai';
        break;
      case 11:
        msgStatus = 'PASS';
        dbStatus = 'facta_pass';
        break;
      case 12:
        msgStatus = 'REQUEST DOCS for';
        dbStatus = 'facta_neutral';
        break;
      case 13:
        msgStatus = 'FAIL';
        dbStatus = 'facta_fail';
        break;

    };

    let msg = `Are you sure you want to ${msgStatus} this applicaiton`;

    this.confirmService.confirm({
      key: this.customerVerificationsConfirmKey,
      message: msg,
      rejectLabel: 'Cancel',
      acceptLabel: 'Confirm',
      accept: () => {
        let body = {
          customerGuid: this.ids.customerGuid,
          campaignGuid: this.ids.campaignGuid,
          targetGuid: this.ids.targetGuid,
          status: dbStatus
        };

        let postSub = this.apiSerivce.post(`csr/change-loan-status`, body)
          .subscribe({
            next: () => {
              this.reloadNotes.emit();
              this.fillVerifications();
              this.toastService.addSuccess('Loan Status successfully changed.');
            },
            error: (err: any) => {
              this.toastService.addError('Unable to update loan status. See log for details.');
              console.error(err);
            },
            complete: () => { postSub.unsubscribe(); }
          });
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.toastService.add({ severity: 'warn', summary: 'Declined', detail: 'Change will not be submitted.' });
            break;
          case ConfirmEventType.CANCEL:
            this.toastService.add({ severity: 'warn', summary: 'Cancelled', detail: 'Operation cancelled.' });
            break;
        }
      }

    });
  }

  docsIncomplete() {
    let postSub = this.apiSerivce.post(`docs-incomplete/${this.ids.customerGuid}/${this.ids.campaignGuid}/${this.ids.targetGuid}`, {})
      .subscribe({
        next: () => {
          this.reloadNotes.emit();
          this.fillVerifications();
          this.toastService.addSuccess('Document InComplete successful.');
        },
        error: (err: any) => {
          this.toastService.addError('Unable to run Documents InComplete. See log for details.');
          console.error(err);
        },
        complete: () => { postSub.unsubscribe(); }
      });
  }


  showGroupVerifySteps(group: TargetDecisionReason[]): boolean {
    return group.filter(g => g.documentID > 0 || g.verificationID > 0).length > 0;
  }

  getGroupDocs(group: TargetDecisionReason[]): TargetDecisionReason[] {
    return group.filter(g => g.documentID > 0 || g.verificationID > 0);
  }

  getGroupDocDescription(verDoc: TargetDecisionReason): string {
    let desc: string = '';
    if (verDoc.verificationID > 0) desc = `${verDoc.verificationDescription}`;
    else if (verDoc.documentID > 0) desc = `${verDoc.document} - ${verDoc.documentDescription}`;
    return desc;
  }

  getGroupObjectCount(group: TargetDecisionReason[]): number {
    let totalIds = group.filter(g => g.documentID > 0 || g.verificationID > 0).length;
    if (totalIds > 0) return totalIds - 1;
    return 0;
  }

  getGroupDocButtonCSS(verDoc: TargetDecisionReason, status: string): string {
    let docStatus: string = '';
    if (verDoc.verificationID > 0) {
      docStatus = verDoc.verificationStatus;
    }
    else if (verDoc.documentID > 0) {
      docStatus = verDoc.documentStatus;
    }
    switch (status) {
      case 'pass':
        return docStatus == status ? 'success' : 'secondary';
        break;
      case 'neutral':
        return docStatus == status ? 'warn' : 'secondary';
        break;
      case 'fail':
        return docStatus == status ? 'danger' : 'secondary'
      default:
        return 'secondary';
        break;
    }
  }

  showGroupDocButton(verDoc: TargetDecisionReason, status: string): boolean {
    let showStatus: boolean = false;
    if (verDoc.verificationID > 0) {
      if (status == 'pass') showStatus = verDoc.verificationPassShow;
      else if (status == 'neutral') showStatus = verDoc.verificationNeutralShow;
      else showStatus = verDoc.verificationFailShow;
    }
    else if (verDoc.documentID > 0) {
      if (status == 'pass') showStatus = verDoc.documentPassShow;
      else if (status == 'neutral') showStatus = verDoc.documentNeutralShow;
      else showStatus = verDoc.documentFailShow;
    }
    return showStatus;
  }

  getGroupFinalDecisionCSS(group: TargetDecisionReason[]): string {
    
    let finalStatus: string = this.getGroupFinalStatus(group);
    switch (finalStatus) {
      case 'INCOMPLETE':
        return 'var(--text-color-secondary)';
        break;

      case 'FAIL':
      case 'DECLINE':
        return 'var(--text-error)';
        break;

      case 'NEUTRAL':
        return 'var(--text-color-warn)';
        break;

      case 'PASS':
      case 'APPROVE':
        return 'var(--text-color-success)';
        break;

      default:
        return '';
        break;
    }
  }

  getGroupFinalStatus(group: TargetDecisionReason[]): string {
    let lastItm = group[group.length - 1];
    let decisionType: string = lastItm.decisionType.toUpperCase();
    let incCount = group.filter(g => (g.documentID > 0 && g.documentStatus == 'incomplete') || (g.verificationID > 0 && g.verificationStatus == 'incomplete')).length;
    let neutCount = group.filter(g => (g.documentID > 0 && g.documentStatus == 'neutral') || (g.verificationID > 0 && g.verificationStatus == 'neutral')).length;
    let failCount = group.filter(g => (g.documentID > 0 && g.documentStatus == 'fail') || (g.verificationID > 0 && g.verificationStatus == 'fail')).length;
    let passCount = group.filter(g => (g.documentID > 0 && g.documentStatus == 'pass') || (g.verificationID > 0 && g.verificationStatus == 'pass')).length;

    let finalStatus: string = '';
    if (decisionType === 'FACTA') {
      if (incCount > 0) finalStatus = 'INCOMPLETE';
      else if (failCount > 0) finalStatus = 'FAIL';
      else if (neutCount > 0) finalStatus = 'NEUTRAL';
      else if (passCount > 0) finalStatus = 'PASS';
    }
    else {
      if (incCount > 0) finalStatus = 'INCOMPLETE';
      else if (failCount > 0 || neutCount > 0) finalStatus = 'DECLINE';
      else if (passCount > 0) finalStatus = 'APPROVE';
    }

    return finalStatus;
  }

  showGroupRecommendButtons(group: TargetDecisionReason[]): boolean {
    let showButtons: boolean = false;

    let lastItm = group[group.length - 1];
    let finalStatus = this.getGroupFinalStatus(group);
    let decisionType: string = lastItm.decisionType.toUpperCase();

    showButtons = finalStatus.length > 0 && finalStatus != 'INCOMPLETE';
    if (!finalStatus?.length && decisionType === 'FACTA' && !showButtons) {
      
      let groupCount = this.getGroupObjectCount(group);
      showButtons = groupCount == 0;
    }


    
    return showButtons;
  }

}
