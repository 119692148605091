<div class="cds-card cs-profile-layout">
  <div class="py-4 pl-6 text-2xl font-medium">My Profile</div>
  <div class="p-4" *ngIf="loading">
    <p3solved-progress-bar
      [style]="{ height: '0.5rem' }"
    ></p3solved-progress-bar>
  </div>
  <div *ngIf="!loading" class="p-4">
    <form [formGroup]="profileForm" (ngSubmit)="submit()">
      <div class="w-12 px-4 pb-4 flex flex-row">
        <div class="w-6 mr-4">
          <p3solved-form-text
            formControlName="firstName"
            id="firstname"
            placeholder="First Name"
          ></p3solved-form-text>
        </div>
        <div class="w-6 flex-grow-1">
          <p3solved-form-text
            formControlName="lastName"
            id="lastname"
            placeholder="Last Name"
          ></p3solved-form-text>
        </div>
      </div>
      <div class="w-12 px-4 pb-4">
        <p3solved-form-text
          formControlName="company"
          id="company"
          placeholder="Company"
          [disabled]="true"
        ></p3solved-form-text>
      </div>
      <div class="w-12 px-4 pb-4">
        <p3solved-form-text
          formControlName="email"
          id="email"
          placeholder="Email"
          [disabled]="true"
        ></p3solved-form-text>
      </div>
      <div class="w-12 px-4 pb-4">
        <p3solved-form-text
          formControlName="cellPhone"
          id="cellphone"
          placeholder="Cell Phone"
        ></p3solved-form-text>
      </div>
      <div class="w-12 px-4 pb-4">
        <p3solved-form-text
          formControlName="workPhone"
          id="workphone"
          placeholder="Work Phone"
        ></p3solved-form-text>
      </div>
      <div class="w-12 px-4 pb-4">
        <p3solved-dropdown
          formControlName="timeZoneId"
          id="timezone"
          placeholder="Time Zone"
          [items]="timeZones"
          optionLabel="desc"
          optionValue="id"
        ></p3solved-dropdown>
      </div>
      <div class="col-offset-9 col-3 pr-4">
        <p3solved-button
          buttonType="submit"
          label="Save"
          class="p-button-lg w-12"
        ></p3solved-button>
      </div>
    </form>
  </div>
</div>

<p3solved-toast [key]="toastKey" [position]="toastPos"></p3solved-toast>

<!-- <p-dialog [modal]="true" [(visible)]="showSuccess" [closable]="false">
  <app-success header="Profile Updated Successfully" (okClick)="showSuccess = false;"></app-success>
</p-dialog> -->
