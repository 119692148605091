import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ReRunRulesComponent } from 'src/app/components/decision/re-run-rules/re-run-rules.component';
import { ActivatedIds } from 'src/app/models/activated-ids';
import { CsrLoanModel } from 'src/app/models/csr/csr-loan-model';
import { RiskDecisionModel } from 'src/app/models/csr/risk-decision-model';
import { RuleDetailModel } from 'src/app/models/csr/rule-details-model';
import { RuleModel } from 'src/app/models/csr/rule-model';
import { ApiService } from 'src/app/services/api.service';
import { CustomerService } from 'src/app/services/customer.service';
import { GuidService } from 'src/app/services/guid.service';
import { MainService } from 'src/app/services/main.service';
import { UserService } from 'src/app/services/user.service';
declare var require: any

@Component({
  selector: 'app-decision-page',
  templateUrl: './decision-page.component.html',
  styleUrls: ['./decision-page.component.scss']
})
export class DecisionPageComponent implements OnInit {
  rules: RuleModel = new RuleModel();
  ruleDetails: RuleDetailModel[] = [];
  risk: RiskDecisionModel[] = [];
  csrLoan: CsrLoanModel = new CsrLoanModel();
  ruleSetName = "";
  ruleSetExecTime = "";
  loading = true;
  ids: ActivatedIds = {};
  rows: number = 30;
  first: number = 0;
  constructor(private activatedRoute: ActivatedRoute,
    private main: MainService,
    private api: ApiService,
    private userService: UserService,
    private guidService: GuidService,
    private customerService: CustomerService) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(async paramMap => {
      let cGuid = paramMap.get('customerGuid')?.toString() ?? '';
      if (this.guidService.isValidGuid(cGuid)) {
        let customerGuid = cGuid;
        this.customerService.initCustomer(customerGuid);
        await this.userService.subscribeAccess();
        let data = this.activatedRoute.snapshot.data;
        if (data && data['pageId']) {
          this.userService.checkPageAccess(data['pageId']);
        }
        this.initPage();
      }
    });
  }

  initPage() {
    this.main.activatedIds$.subscribe((ids) => {
      if (ids && ids.targetGuid) {
        if (!this.ids || this.ids.targetGuid !== ids.targetGuid) {
          this.ids = ids;
          this.getDecisionData();
          // this.getAccountSummary();
          // this.getTargetTransactionHistory();
        }
      }
    });
  }

  private getDecisionData() {
    this.loading = true;
    this.main.showLoading();
    var model = {
      CustomerGuid: this.ids.customerGuid,
      CampaignGuid: this.ids.campaignGuid,
      TargetGuid: this.ids.targetGuid,
      Value: ""
    }
    var body = JSON.stringify(model);
    this.api.post(`csr/decision-data`, body)
      .subscribe((res: any) => {
        this.csrLoan = res.csrLoan;
        this.setRisks(res.risk);
        this.ruleDetails = res.ruleDetails;
        this.rules = res.rules;
        this.ruleSetName = `${this.rules.rulesetName} ${this.rules.rulesetVersion}`;
        if (this.rules.executionTime) {
          this.ruleSetExecTime = this.getExecutionSeconds(this.rules.executionTime);
        }
        this.main.dismissLoading();
        this.loading = false;

      },
        (err: any) => {
          this.main.dismissLoading();
          this.main.showSnackBar("Ops. Error happened while sending");
          this.loading = false;
        }
      );
  }

  setRisks(arr: any[]) {
    arr.forEach(item => {
      var formated = this.findXml(item.decisionXML, '<inquiry>', '</inquiry>');
      if (formated) {
        item.decisionXML = formated;
      }
      formated = this.findXml(item.decisionXML, '<xml-response>', '</xml-response>');
      if (formated) {
        item.decisionXML = formated;
      }
      formated = this.findXml(item.decisionXML, '<LeadInformation', '</LeadInformation>');
      if (formated) {
        item.decisionXML = formated;
      }
      formated = this.findXml(item.decisionXML, '<COACK>', '</COACK>');
      if (formated) {
        item.decisionXML = formated;
      }
    });
    this.risk = arr;
  }

  private findXml(data: string, startTag: string, endTag: string) {
    if (data.indexOf(startTag) !== -1 && data.indexOf(endTag) !== -1) {
      var startIndex = data.indexOf(startTag);
      var endIndex = data.indexOf(endTag) + endTag.length;
      var str = data.substring(startIndex, endIndex);
      const formatter = require('xml-formatter');
      var formated = formatter(str);
      var result = `${data.substring(0, startIndex)} ${formated} ${data.substring(endIndex)}`;
      return result;
    }

    return null;
  }

  getExecutionSeconds(ms: string) {
    if (!ms)
      return '0 second';
    return `${Number(ms) / 1000.0} seconds`;
  }

  getOutCome(val: string) {
    return val === 'True' ? 'False' : "True";
  }

  viewAsTree() {

  }

  hasFeature(feature: string, mode: string = 'write') {
    switch (mode) {
      case 'read':
        return this.userService.hasRead(feature);
        break;
      case 'feature': 
        return this.userService.hasFeature(feature);
        break;
      default:
        return this.userService.hasWrite(feature);
        break;
    }
  }

  rerun() {
    this.main.showModal(ReRunRulesComponent, null, '500px').afterClosed().subscribe(res => {
      if (res == 'save' || res == 'reset') {
        this.postRerun(res);
      }
    });
  }

  postRerun(val: string) {
    this.customerService.customer$.subscribe(cust => {
      var value = `${cust?.customerFolderName}|${val}`;
      this.main.showLoading();
      var model = {
        CustomerGuid: this.ids.customerGuid,
        CampaignGuid: this.ids.campaignGuid,
        TargetGuid: this.ids.targetGuid,
        Value: value
      }
      var body = JSON.stringify(model);
      this.api.post(`csr/target-rerun-rules`, body)
        .subscribe((res: any) => {
          this.main.dismissLoading();
          if (res) {
            var msg = `Rules Re-ran OK (${res})!`;
            this.main.showSuccessModal("Success", msg);
          }
        },
          (err: any) => {
            this.main.dismissLoading();
            this.main.showSnackBar("Ops. Error happened while sending");
          }
        );
    })
  }

}
