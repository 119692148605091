<app-dashboard-header></app-dashboard-header>
<div class="col-5 align-self-center">
    <div class="flex flex-column">
        <div class="text-xl pl-3">Collections</div>
        <p-breadcrumb [model]="bcItems"></p-breadcrumb>
    </div>
</div>
<div class="grid">
    <div class="col-12 lg:col-8">
        <div class="grid">

            <div class="col-12" *ngIf="collectionsInfo">
                <div class="cds-card p-4 pds-collections-bg">
                    <div class="grid">
                        <div class="col-5 pds-collections-text text-xl">
                            {{collectionsInfo.label1Text}} {{collectionsInfo.label1Value | currency}}
                        </div>
                        <div class="col-7 pds-collections-text text-xl">
                            <table class="col-12">
                                <tr>
                                    <td>{{collectionsInfo.label2Text}}</td>
                                    <td class="text-right">{{collectionsInfo.label2Value | currency}}</td>
                                </tr>
                                <tr>
                                    <td>{{collectionsInfo.label3Text}}</td>
                                    <td class="text-right">{{collectionsInfo.label3Value | currency}}</td>
                                </tr>
                                <tr>
                                    <td>{{collectionsInfo.label4Text}}</td>
                                    <td class="text-right">{{collectionsInfo.label4Value | currency}}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12 lg:col-5">
                <div class="cds-card p-4">
                    <div class="flex flex-wrap align-items-center">
                        <span class="text-xl">Promise to Pay</span>
                    </div>
                    <div class="py-2">
                        <p3solved-datepicker id="ddPromTPay" placeholder="Select Promise to Pay Date"
                            [(ngModel)]="promiseToPayDate" [minDate]="ptpMinDate" [maxDate]="ptpMaxDate"
                            panelStyleClass="dp-max" [showIcon]="true">
                        </p3solved-datepicker>
                    </div>
                    <div>
                        <p3solved-button id="btnSavePTP" label="Save" [disabled]="!promiseToPayDate"
                            (buttonClick)="savePromiseToPayDate()"></p3solved-button>
                    </div>
                    <div *ngIf="showPromiseToPay" class="pt-4 pb-2 flex">
                        <span class="pr-3 align-self-center">{{ this.targetInfo?.promiseToPayDate | date: 'MM/dd/yyyy'
                            }}</span>
                        <span class="pr-3">
                            <p3solved-button id="btnRemPTP" label="Cancel"
                                (buttonClick)="removePromiseToPay()"></p3solved-button>
                        </span>
                        <span class="pr-3">
                            <p3solved-button id="btnConfPTP" label="Confirmed"
                                (buttonClick)="confirmPromiseToPay()"></p3solved-button>
                        </span>
                    </div>
                </div>

                <div class="cds-card p-4">
                    <div class="flex flex-wrap align-items-center">
                        <span class="w-12 text-xl">Defer a Payment</span>
                        <span class="pt-2 cds-placeholder-color">Select Payment(s) to defer</span>
                    </div>
                    <div *ngIf="showTotalCurrentDueSection || showTotalPastDueSection" class="py-4">
                        <table class="col-12">
                            <tr *ngIf="showTotalPastDueSection" class="pds-divider-top py-2">
                                <td class="py-2">Total Past Due</td>
                                <td class="py-2 text-right">
                                    {{ targetInfo?.totalPastDueBalance | currency }}
                                    <span class="pl-2 pi pi-info-circle" (mouseenter)="opPastDue.show($event)"
                                        (mouseleave)="opPastDue.hide()" (click)="opPastDue.toggle($event)"></span>
                                </td>
                            </tr>
                            <tr *ngIf="showTotalCurrentDueSection" class="pds-divider-top py-2">
                                <td class="py-2">Total Amount Due</td>
                                <td class="text-right py-2">
                                    {{ targetInfo?.totalCurrentDueBalance | currency }}
                                    <span class="pl-2 pi pi-info-circle" (mouseenter)="opCurrDue.show($event)"
                                        (mouseleave)="opCurrDue.hide()" (click)="opCurrDue.toggle($event)"></span>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="py-2">
                        <p-table [value]="defers" [(selection)]="selectedDefers" dataKey="rn"
                            styleClass="p-datatable-sm">
                            <ng-template pTemplate="body" let-defer>
                                <tr class="py-2">
                                    <td>
                                        <p-tableCheckbox [value]="defer"></p-tableCheckbox>
                                    </td>
                                    <td>{{ defer.displayDate }}</td>
                                    <td [style.color]="defer.descriptionColor">{{ defer.description }}</td>
                                    <td class="text-right">
                                        <strong>{{ defer.paymentRemaining | currency }}</strong>
                                        <span class="pl-2 pi pi-info-circle" (mouseenter)="showOpDefer($event, defer)"
                                            (mouseleave)="opDefer.hide()" (click)="toggleOpDefer($event, defer)">
                                        </span>
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td [colSpan]="4">No payments to defer.</td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                    <div class="py-2" *ngIf="defers.length > 0">
                        <p3solved-button id="bntSaveDefers" label="Defer" (buttonClick)="confirmDefers()"
                            [disabled]="selectedDefers.length == 0"></p3solved-button>
                    </div>
                </div>
            </div>


            <div class="col-12 lg:col-7">
                <app-collection-settlement (reload)="reloadNotes()"></app-collection-settlement>
            </div>

        </div>
    </div>
    <div class="col-12 lg:col-4">
        <app-customer-notes #custNotes></app-customer-notes>
    </div>
</div>

<p-overlayPanel #opPastDue [showCloseIcon]="false" [showTransitionOptions]="'0.3s'" styleClass="past-due">
    <ng-template pTemplate>
        <div class="grid p-4">
            <div class="col-10">Principal</div>
            <div class="col-2">{{ targetInfo?.totalPastDue_PPal | currency}}</div>

            <div class="col-10">Cash Advance Fee</div>
            <div class="col-2">{{ targetInfo?.totalPastDue_CAF | currency }}</div>

            <div class="col-10">Fixed Finance Charge</div>
            <div class="col-2">{{ targetInfo?.totalPastDue_FFC | currency }}</div>
        </div>
    </ng-template>
</p-overlayPanel>


<p-overlayPanel #opCurrDue [showCloseIcon]="false" [showTransitionOptions]="'0.3s'" styleClass="past-due">
    <ng-template pTemplate>
        <div class="grid p-4">
            <div class="col-10">Principal</div>
            <div class="col-2">{{ targetInfo?.totalCurrentDue_PPal | currency}}</div>

            <div class="col-10">Cash Advance Fee</div>
            <div class="col-2">{{ targetInfo?.totalCurrentDue_CAF | currency }}</div>

            <div class="col-10">Fixed Finance Charge</div>
            <div class="col-2">{{ targetInfo?.totalCurrentDue_FFC | currency }}</div>
        </div>
    </ng-template>
</p-overlayPanel>


<p-overlayPanel #opDefer [showCloseIcon]="false" [showTransitionOptions]="'0.3s'" styleClass="past-due">
    <ng-template pTemplate>
        <div class="grid p-4">
            <div class="col-10">Principal</div>
            <div class="col-2">{{ defer_PPal | currency}}</div>

            <div class="col-10">Cash Advance Fee</div>
            <div class="col-2">{{ defer_CAF | currency }}</div>

            <div class="col-10">Fixed Finance Charge</div>
            <div class="col-2">{{ defer_FFC | currency }}</div>
        </div>
    </ng-template>
</p-overlayPanel>


<p-confirmDialog key="{{DeferConfirmKey}}" [style]="{width: '25vw'}" [baseZIndex]="10000">
</p-confirmDialog>