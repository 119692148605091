import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { Observable, Subscription } from 'rxjs';
import { CourtesyAdjustmentInfo } from 'src/app/models/courtesy-adjustment-info';
import { TransactionHistoryFees } from 'src/app/models/csr/agent-tools';
import { AcctAdjustFormGroup } from 'src/app/models/form-groups';
import { TargetAccountSummary } from 'src/app/models/target-account-summary';
import { ApiService } from 'src/app/services/api.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-account-adjustment-option-two',
  templateUrl: './account-adjustment-option-two.component.html',
  styleUrls: ['./account-adjustment-option-two.component.scss']
})
export class AccountAdjustmentOptionTwoComponent implements OnInit {
  @Output() reload = new EventEmitter();
  @Input() transactionHistoryFees: TransactionHistoryFees = new TransactionHistoryFees();
  @Input() courtesyAdjustinfo: CourtesyAdjustmentInfo = new CourtesyAdjustmentInfo();
  @Input() accountSummary: TargetAccountSummary = {} as TargetAccountSummary;
  @Input() customerGuid: any;
  @Input() targetGuid: any;
  @Input() campaignGuid: any;


  accountAdjustmentsLoaded: boolean = false;
  showAcctAdjModal: boolean = false;
  submitting: boolean = false;

  totalAdjustment: number = 0;
  principalBalance: number = 0;

  effDtMinDate: Date = new Date();
  effDtMaxDate: Date = new Date();

  accountAdjustments: FormGroup<AcctAdjustFormGroup> = {} as FormGroup;
  constructor(
    private fb: FormBuilder,
    private apiService: ApiService,
    private toastService: ToastService
    ) { }

  ngOnInit(): void {
  
  }

  initPage() {
    setTimeout(() => {
      this.initForm();
      if (this.courtesyAdjustinfo.courtesyAdjustmentMinDate) this.effDtMinDate = new Date(this.courtesyAdjustinfo.courtesyAdjustmentMinDate);
      if (this.courtesyAdjustinfo.courtesyAdjustmentMaxDate) this.effDtMaxDate = new Date(this.courtesyAdjustinfo.courtesyAdjustmentMaxDate);
    });
  }

  initForm() {
    if (this.accountSummary.currentPrincipalBalanceNoPending.length > 0) this.principalBalance = +this.accountSummary.currentPrincipalBalanceNoPending;
    this.accountAdjustments = this.fb.group<AcctAdjustFormGroup>({
      caf: new FormControl<number|null>(null, {nonNullable: true}),
      cafAmt: new FormControl<number|null>(null, {nonNullable: true}),
      ffc: new FormControl<number|null>(null, {nonNullable: true}),
      ffcAmt: new FormControl<number|null>(null, {nonNullable: true}),
      princ: new FormControl<number|null>(0, {nonNullable: true, validators: [Validators.required, Validators.min(0), Validators.max(this.principalBalance)]}),
      effDt: new FormControl<Date|null>(null, {nonNullable: true, validators: [Validators.required]}),
    });
    this.accountAdjustmentsLoaded = true;

    this.accountAdjustments.controls.caf.valueChanges.subscribe(val => {
      if (val) {
        let opt = this.transactionHistoryFees.caf.find(c => c.row_id == val);
        if (opt) {
          this.accountAdjustments.controls.cafAmt.setValidators([Validators.required, Validators.min(0.01), Validators.max(opt.payment_remaining)]);
          this.accountAdjustments.controls.cafAmt.patchValue(opt.payment_remaining);
        }
      }
      else {
        this.accountAdjustments.controls.cafAmt.clearValidators();
        this.accountAdjustments.controls.cafAmt.patchValue(null);
      }
      this.accountAdjustments.updateValueAndValidity();
      this.updateTotalAdjustment();
    });

    this.accountAdjustments.controls.ffc.valueChanges.subscribe(val => {
      if (val) {
        let opt = this.transactionHistoryFees.ffc.find(c => c.row_id == val);
        if (opt) {
          this.accountAdjustments.controls.ffcAmt.setValidators([Validators.required, Validators.min(0.01), Validators.max(opt.payment_remaining)]);
          this.accountAdjustments.controls.ffcAmt.patchValue(opt.payment_remaining);
        }
      }
      else {
        this.accountAdjustments.controls.ffcAmt.clearValidators();
        this.accountAdjustments.controls.ffcAmt.patchValue(null);
      }
      this.accountAdjustments.updateValueAndValidity();
      this.updateTotalAdjustment();
    });
  }

  updateTotalAdjustment() {
    setTimeout(() => {
      this.totalAdjustment = (this.accountAdjustments.value.cafAmt ?? 0)
        + (this.accountAdjustments.value.ffcAmt ?? 0)
        + (this.accountAdjustments.value.princ ?? 0);
    });
  }

  showAdjustConfirmModal() {
    if (this.totalAdjustment > 0 && this.accountAdjustments.valid) this.showAcctAdjModal = true;
  }

  postAccountAdjustment() {
    this.submitting = true;

    let body = {
      customerGuid: this.customerGuid,
      campaignGuid: this.campaignGuid,
      targetGuid: this.targetGuid,
      courtesyAdjustmentType: 'account',
      cafRowId: this.accountAdjustments.value.caf ?? 0,
      ffcRowId: this.accountAdjustments.value.ffc ?? 0,
      cafAmount: this.accountAdjustments.value.cafAmt ?? 0,
      ffcAmount: this.accountAdjustments.value.ffcAmt ?? 0,
      principalAmount: this.accountAdjustments.value.princ ?? 0,
      bankAccountAmount: 0,
      usaepayTransactionQueueId: -1,
      suspenseRefund: false
    };

    let pSub = this.apiService.post(`agenttools/account-adjustment/courtesy-adjustment`, body)
    .subscribe({
      next: () => {
        this.reload.emit();
        this.submitting = false;
        this.accountAdjustments.reset();
        this.accountAdjustments.updateValueAndValidity();
        this.toastService.addSuccess("Courtesy Adjustment successfully created.");
      },
      error: (err: any) => {
        this.submitting = false;
        this.toastService.addError("Unable to create Courtesy Adjustment. See log for details.");
        console.error(err);
      },
      complete: () => { pSub.unsubscribe(); }
    });
  }

}
